import React from 'react';
import styles from './RadioMenu.module.css';

function RadioMenuUI({ handler, value }) {
  return (
    <div className={styles.box}>
      <div
        className={`${styles.btnBox} ${value === 'save' ? styles.btnAct : ''}`}
        onClick={() => handler('save')}
      >
        <a className={styles.btn}>Сохранить Значение</a>
      </div>
      <div
        className={`${styles.btnBox} ${value === 'send' ? styles.btnAct : ''}`}
        onClick={() => handler('send')}
      >
        <a className={`${styles.btn}`}>Отправить Сообщение</a>
      </div>
    </div>
  );
}

export default RadioMenuUI;
