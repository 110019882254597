import React from 'react';
import styles from './NotBlock.module.css';
import { Handle, Position } from 'reactflow';
import { handleStyleOutputFilterNot } from '../../flowStyle/styleHandler';

function NotBlock() {
  return (
    <div className={styles.block}>
      <Handle
        type="source"
        id={`filter-0`}
        position={Position.Right}
        style={handleStyleOutputFilterNot}
      />
      <div className={styles.void}>НЕТ</div>
    </div>
  );
}

export default NotBlock;
