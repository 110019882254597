import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../index.css';
import styles from './BotStats.module.css';
import refreshToken from '../../../../refreshToken';
import adapter from '../../../../adapter';
import chartsIcon from '../../../../assets/free-icon-stats-164424.png';
import botIcon from '../../../../assets/bot.png';
import Preloader from '../../../../Preloader';
import StatsPage from './statsPage/StatsPage';
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});

const BotStats = ({refreshComponent}) => {

    const storedData = JSON.parse(localStorage.getItem('dashboardData'));
    const dashboardData = storedData ? storedData: null;
    let storedAppState = localStorage.getItem('storedAppState');
    storedAppState = storedAppState? JSON.parse(storedAppState) : null;
    const [botStats, setBotStats] = useState([]);
    const [selectedBot, setSelectedBot] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const getAllStatsBot = async () => {
        try {
            const postData = {
              user_tg_id: dashboardData.user['telegram_id'],
              access_token: dashboardData.token,
              route: 'getAllStatsBot',
            };
            setIsLoading(true); 
            const response = await adapter.post('', postData);
      
            if (response.data.result === 'success') {
              setIsLoading(false);
              setBotStats(response.data.array);
            }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
              try {
                const refreshTokenResult = await refreshToken();
              
                if (refreshTokenResult === 'logout') {
                  navigate('/', { replace: true });
                }else{
                  dashboardData.token = refreshTokenResult;
                  const postData = {
                    user_tg_id: dashboardData.user['telegram_id'],
                    access_token: dashboardData.token,
                    route: 'getAllStatsBot',
                  };
                  setIsLoading(true); 
                  const response = await adapter.post('', postData);
                  if (response.data.result === 'success') {
                    setIsLoading(false);
                    setBotStats(response.data.array);
                  }
                  else {
                    setIsLoading(false); 
                    console.log(response.data);
                  }
                }
              
              } catch (error) {
                console.error(error);
              }
            } else {
              setIsLoading(false);
              console.log(response.data);
            }
          
        } catch (error) {
          console.error(error);
          rollbar.error('Error during request:', error);
        }
    };
    const handleReturnToBotStats = () => {
  
      setSelectedBot(null);
      localStorage.setItem('storedAppState', JSON.stringify({
        ...storedAppState,
        state: '',
        botData:''
  
      }));
    }
    useEffect(() => {
      if (!selectedBot) {
        getAllStatsBot();
      }
    }, [selectedBot]);

    useEffect(() => {
      if (refreshComponent) {
        handleReturnToBotStats();
      }
        
    }, [refreshComponent]);

    const storedAppData = {};

    useEffect(() => {
      if(storedAppState.state === 'BotStats' && storedAppState.botData) {
        setSelectedBot(storedAppState.botData);
      }
    }, []);
    return(
        <>
        <h1 className="main-title">Статистика ботов</h1>
        <p className="breadcrumbs" onClick={handleReturnToBotStats}> Вернуться к выбору бота </p>
        <div className="card ">
            {isLoading ? (
                <>
                    <p className='preloader-title'>Подождите идёт загрузка</p>
                    <Preloader></Preloader>
                </>
            ): !selectedBot && !isLoading ?  (
                <>
                    {botStats.length > 0 ? (
                    <p>Выберите бота для просмотра статистики:</p>
                    ):(
                    <p> Данные для отображения отсутствуют!</p>
                    )}
                    {botStats.length > 0 ? ( 
                      <> 
                        {botStats.map((bot, index) => (
                            <div key={index} className={styles['botlist-item']} onClick={
                              () => {
                                setSelectedBot({id:bot.bot.id, name:bot.bot.bot_name});
                                localStorage.setItem('storedAppState', JSON.stringify({
                                  ...storedAppData,
                                  page:'BotStats',
                                  state: 'BotStats',
                                  botData:{id:bot.bot.id, name:bot.bot.bot_name}
                                }));
                              }
                              }>
                              <p className={styles['botlist-item-title']}>
                              {bot.bot.bot_name}
                              </p>
                              <div className={styles['botlist-item__stats']}>
                                <div className={styles['stats-item']}>
                                    <span>{bot.start_bot_total}</span>
                                    <p>Подписчиков всего</p>
                                </div>
                                <div className={styles['stats-item']}>
                                    <span>{bot.active_leads}</span>
                                    <p>Подписчиков активных</p>
                                </div>
                                <div className={styles['stats-item']}>
                                    <span>{bot.start_today}</span>
                                    <p>Подписчиков за сегодня</p>
                                </div>
                                <div className={styles['stats-item']}>
                                    <span>{bot.sessions_today}</span>
                                    <p>Сессий за сегодня</p>
                                </div>
                                <div className={styles['stats-item']}>
                                    <span>{bot.sent_messages}</span>
                                    <p>Отправлено всего</p>
                                </div>
                              </div>
                            </div>
                        ))}
                       
                      </>
                    ) : null }
                    
                </>
            ):(
                <StatsPage selectedBot={selectedBot} handleReturnToBotStats={handleReturnToBotStats}/>
            )}
        </div>
      </>
    );

}
    
    
    
    
export default BotStats;