export const isURL = (string) => {
  const url = string.trim();
  try {
    if (!url) throw Error("empty field");
    if (url.includes(" ")) throw Error("invalid string");

    // url must start at https:// or http://
    if (!(url.startsWith("https://") || url.startsWith("http://"))) {
      throw Error("bad request");
    }
    if (!url.includes(".")) throw Error("invalid string");
    return url;
  } catch (error) {
    
    console.error(error);
    return false;
  }
};
