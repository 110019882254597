import React, { useEffect, useState } from "react";
import RadioBoxUI from "../../UI/radioBoxUi";
import { actionBlocks, dataActionBlock } from "../../../../config/actionBlocks";

import styles from "./ActionContent.module.css";
import SetConstant from "../../UI/blocksAction/setConstant";
import RemoveConstant from "../../UI/blocksAction/removeConstant";
import SendWebhook from "../../UI/blocksAction/sendWebhook";
import Alert from "../../UI/blocksAction/alert";
import BlockUser from "../../UI/blocksAction/blockUser";
import UnblockUser from "../../UI/blocksAction/unblockUser";
import AddTags from "../../UI/blocksAction/addTags";
import RemoveTags from "../../UI/blocksAction/removeTags";
import Giveaway from "../../UI/blocksAction/giveaway";
import Unsubscribe from "../../UI/blocksAction/unsubscribe";

const switchBlock = (block, handler, initData) => {
  switch (block) {
    case "unsubscribe":
      return (
        <Unsubscribe
          handler={handler}
          data={initData.type === block ? initData : dataActionBlock[block]}
        />
      );
    case "block_user":
      return (
        <BlockUser
          handler={handler}
          data={initData.type === block ? initData : dataActionBlock[block]}
        />
      );
    case "unblock_user":
      return (
        <UnblockUser
          handler={handler}
          data={initData.type === block ? initData : dataActionBlock[block]}
        />
      );
    case "add_tags":
      return (
        <AddTags
          handler={handler}
          data={initData.type === block ? initData : dataActionBlock[block]}
        />
      );
    case "remove_tags":
      return (
        <RemoveTags
          handler={handler}
          data={initData.type === block ? initData : dataActionBlock[block]}
        />
      );
    case "giveaway":
      return (
        <Giveaway
          handler={handler}
          data={initData.type === block ? initData : dataActionBlock[block]}
        />
      );
    case "set_constant":
      return (
        <SetConstant
          handler={handler}
          data={initData.type === block ? initData : dataActionBlock[block]}
        />
      );
    case "remove_constant":
      return (
        <RemoveConstant
          handler={handler}
          data={initData.type === block ? initData : dataActionBlock[block]}
        />
      );
    case "send_webhook":
      return (
        <SendWebhook
          handler={handler}
          data={initData.type === block ? initData : dataActionBlock[block]}
        />
      );
    case "alert":
      return (
        <Alert
          handler={handler}
          data={initData.type === block ? initData : dataActionBlock[block]}
        />
      );
    default:
      return <></>;
  }
};

function ActionContent({ nodes, nodeID, addData }) {
  const [value, setValue] = useState("");
  const [data, setData] = useState(false);
  const [init, setInit] = useState(false);
  const handler = (newValue) => setValue(newValue);
  const handleData = (data) => setData(data);

  useEffect(() => {
    const dataNode = nodes.data.block;
    if (dataNode && !init) {
      setInit(true);
      setData(dataNode);
      setValue(dataNode.type);
    }
  }, [nodes.data.block, init]);

  useEffect(() => {
    if (init) {
      addData(data, "", nodeID);
    }
  }, [data]);
  console.log(data);
  return (
    <div className={styles.box}>
      <p className={styles.title}>Выберите Действие</p>
      {actionBlocks &&
        actionBlocks.map((elem) => (
          <RadioBoxUI
            name={elem.name}
            key={elem.name}
            title={elem.title}
            description={elem.description}
            value={value}
            handler={handler}>
            {value === elem.name && switchBlock(value, handleData, data)}
          </RadioBoxUI>
        ))}
    </div>
  );
}

export default ActionContent;
