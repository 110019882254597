import React from "react";
import styles from "./ButtonActionUI.module.css";

function ButtonActionUI({ text, handler, type, active, variant = "default" }) {
  const style =
    type === "radio"
      ? `${styles.btn} ${styles.radio} ${active ? styles.activeRadio : ""}`
      : variant === "default"
      ? styles.btn
      : `${styles.btn} ${styles.btnSecondary}`;

  return (
    <button className={style} onClick={() => handler(text)}>
      {text}
    </button>
  );
}

export default ButtonActionUI;
