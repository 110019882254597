import React, { useEffect, useState } from 'react';

import '../../../../index.css';
import './MailingPage.css';
import MailingCreate from './mailingCreate/MailingCreate';
import MailingSchedule from './mailingSchedule/MailingSchedule';
import MailingArchive from './mailingArchive/MailingArchive';
import ConnectBot from './connectBot/ConnectBot';
import adapter from '../../../../adapter';
import refreshToken from '../../../../refreshToken';
import { useNavigate } from 'react-router-dom';
import mailingIcon  from '../../../../assets/mass.png';
import { FaEnvelopeOpenText, FaCalendarAlt ,FaArchive   } from 'react-icons/fa';

const MailingPage = ({refreshComponent}) => {

    const storedData = JSON.parse(localStorage.getItem('dashboardData'));
    const dashboardData = storedData ? storedData: null;
    const [mailingPageState, setMailingPageState] = useState('mailing-create');
    const [botsData, setBotsData] = useState([]);
    const [spamsSchaludeData, setSchaludeSpamsData] = useState([]);
    const [spamsArchivedData, setSpamsArchivedData] = useState([]);

    const [refreshData, setRefreshData] = useState(false);

    const handleRefreshSpams = (refreshSpamsData) => {
        setRefreshData(refreshSpamsData);
    }

    const renderComponent = () =>{
        switch (mailingPageState) {
            case 'mailing-create':
                return <MailingCreate botsData={botsData} refreshSpamsData={handleRefreshSpams}></MailingCreate>;
            case 'mailing-schadule':
                return <MailingSchedule botsData={botsData} spamsSchaludeData={spamsSchaludeData} refreshSpamsData={handleRefreshSpams}></MailingSchedule>;
            case 'mailing-archive':
                return <MailingArchive spamsArchivedData={spamsArchivedData} ></MailingArchive>;
            case 'connect-bot':
                return <ConnectBot></ConnectBot>;
            default:
                return <MailingCreate botsData={botsData} refreshSpamsData={handleRefreshSpams}></MailingCreate>;
        }
    };

    const handleMailingCreate = (e) => {
        setMailingPageState('mailing-create');
        
    };
    const handleMailingSchedule = (e) => {
        setMailingPageState('mailing-schadule');
        
    };
    const handleMailingArchive = (e) => {
        setMailingPageState('mailing-archive');
        
    };


    const navigate = useNavigate();
    const handleGetBots = async () => {
      
        try{
            const data = {
                user_tg_id : dashboardData.user['telegram_id'],
                access_token : dashboardData.token,
                route : 'getbotsinfo',
            };
            const response = await adapter.post('', data);
            if (response.data.result === 'success') {
                if (response.data.array.length <= 0) {
                    setMailingPageState('connect-bot')
                }else{
                    setBotsData(response.data.array);
                }

            }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
                console.log('Токен протух');
                try {
                const refreshTokenResult = await refreshToken();
                
                if (refreshTokenResult === 'logout') {
                    navigate('/', { replace: true });
                }else{
                    console.log('повторный запрос');
                    dashboardData.token = refreshTokenResult;
                    const data = {
                        user_tg_id : dashboardData.user['telegram_id'],
                        access_token : dashboardData.token,
                        route : 'getbotsinfo',
                    };
                    const response = await adapter.post('', data);
                    if (response.data.result === 'success') {
                        if (response.data.result.length <= 0) {
                            setMailingPageState('connect-bot')
                        }else{
                            setBotsData(response.data.array);
                        }
                    }
                    else {
                    console.log('повторный запрос НЕ выполнен');
                    console.log(response.data);
                    setBotsData(null);
                    }
                }
                
                } catch (error) {
                console.error(error);
                setBotsData(null);
                }
            }
            else {
                console.log('Произошла ошибка:  ' + response.data.text);
                setMailingPageState('connect-bot')
                setBotsData(null);
            }
        } catch (error){
            rollbar.error('Error during request:', error);
        }
    };
    

    const handleGetSpams = async () => {
        try{
            const data = {
                route : 'getSpams',
                user_tg_id: dashboardData.user['telegram_id'],
                access_token:dashboardData.token
            };
            const response = await adapter.post('', data);
            if (response.data.result === 'success') {

                const schadulePosts = response.data.array.filter(item => item.status === 'wait');
                const archivedPosts = response.data.array.filter(item => item.status === 'sent');

                const updatedSchedulePosts = schadulePosts.map(post => ({
                    ...post,
                    bot_id: JSON.parse(post.bot_id),
                }));
                if (updatedSchedulePosts) {
                    setSchaludeSpamsData(updatedSchedulePosts);
                }
                if (archivedPosts) {
                    setSpamsArchivedData(archivedPosts);
                }
                
            }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
                try {
                const refreshTokenResult = await refreshToken();
                
                if (refreshTokenResult === 'logout') {
                    navigate('/', { replace: true });
                }else{
                    dashboardData.token = refreshTokenResult;
                    const data = {
                        route : 'getSpams',
                        user_tg_id: dashboardData.user['telegram_id'],
                        access_token:dashboardData.token
                    };
                
                    const response = await adapter.post('', data);
                    if (response.data.result === 'success') {
                        const schadulePosts = response.data.array.filter(item => item.status === 'wait');
                        const archivedPosts = response.data.array.filter(item => item.status === 'sent');
                        
                        if (schadulePosts) {
                            setSchaludeSpamsData(schadulePosts);
                        }
                        if (archivedPosts) {
                            setSpamsArchivedData(archivedPosts);
                        }

                    }
                    else {
                        console.log(response.data);
                    }
                }
                } catch (error) {
                console.error(error);
                }
            }
            else {
            console.log('Произошла ошибка:  ' + response.data.text);
            }
        } catch (error){
            rollbar.error('Error during request:', error);
        }
    };

    

    useEffect(() => {
        if (refreshData || mailingPageState) {
            handleGetSpams();

            handleGetBots();
         
            setRefreshData(false);
        }
       
    }, [mailingPageState, refreshData]);

    useEffect(() => {
        if (refreshComponent) {
            setMailingPageState('mailing-create');
        }
        
    }, [refreshComponent]);



    return(
        <div id='mailingPage'>
            {mailingPageState !== 'connect-bot' ? (
                <>
                    <div className="card">
                    <h1 className="main-title">
                        <p>Управление рассылками</p> 
                        <img className='icon' src={mailingIcon}/>
                    </h1>
                    <div className='mailing-pages'>
                        <button className={`main-btn ${mailingPageState === 'mailing-create' ? 'selected-item' : ''}`} type="button" onClick={handleMailingCreate}>
                            <FaEnvelopeOpenText className="fa" />
                            Создать рассылку
                        </button>
                        <button className={`main-btn ${mailingPageState === 'mailing-schadule' ? 'selected-item' : ''}`}  type="button" onClick={handleMailingSchedule}>
                            <FaCalendarAlt  className="fa" />
                            Отложенные рассылки
                        </button>
                        <button className={`main-btn ${mailingPageState === 'mailing-archive' ? 'selected-item' : ''}`}  type="button" onClick={handleMailingArchive}>
                            <FaArchive  className="fa" />
                            Архив рассылок
                        </button>
                    </div>
                    
                    </div>
                    <div className="card">
                        {renderComponent()}
                    </div>
                </>
            ): (
            <div className="card">
                {renderComponent()}
            </div>
            )}
            
        
        </div>
    );
};

export default MailingPage;