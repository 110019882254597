import React, { useState, useEffect } from 'react';
import styles from './FilterCondition.module.css';
import { BiTrash } from 'react-icons/bi';
import { useStore } from 'reactflow';
import { logDOM } from '@testing-library/react';

// скелет условий
const equalData = {
  part_chain: {
    label: ['Участвовал', 'Не участвовал'],
    value: ['yes', 'not'],
  },
  subscribe_date: {
    label: ['Равно', 'До', 'После'],
    value: ['equally', 'smaller', 'bigger'],
  },
  received_spam: {
    label: ['Получал', 'Не получал'],
    value: ['yes', 'not'],
  },
  channel_subscribe: {
    label: ['Да', 'Нет'],
    value: ['yes', 'not'],
  },
};

function Condition({ data, handler, index, deleteCnd, length }) {
  const [typeSelect, setTypeSelect] = useState('');
  const [equalSelect, setEqualSelect] = useState('');
  const [stateSelect, setStateSelect] = useState('');

  // иннициализация стора
  const [listData, setListData] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const store = useStore();
  const { apiInfo } = store;

  function bifurcation(array) {
    const label = [];
    const value = [];
    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      label.push(element.name);
      value.push(element.id);
    }
    return { label, value };
  }

  useEffect(() => {
    setIsLoadingData(true);
    const objList = {
      part_chain: bifurcation(apiInfo.diaglogs),
      received_spam: bifurcation(apiInfo.spams),
      channel_subscribe: bifurcation(apiInfo.channel),
    };
    setListData(objList);
    setIsLoadingData(false);
  }, [apiInfo]);
  //

  useEffect(() => {
    if (typeSelect) {
      const obj = {
        name: typeSelect,
        condition: equalSelect,
        value: stateSelect,
      };
      handler(index, obj);
    }
  }, [typeSelect, equalSelect, stateSelect]);
  useEffect(() => {
    if (data.name) {
      setTypeSelect(data.name);
      setEqualSelect(data.condition);
      setStateSelect(data.value);
    }
  }, []);

  const handleTypeChange = (event) => {
    const text = event.target.value;
    setTypeSelect(text);
    if (!text) {
      setEqualSelect(text);
      return;
    }
    setEqualSelect(equalData[text].value[0]);
    setStateSelect('');
  };

  const handleEqualChange = (event) => {
    setEqualSelect(event.target.value);
  };
  const handleStateChange = (event) => {
    setStateSelect(event.target.value);
  };

  return (
    <div className={styles.block}>
      <div className={styles.conditionWrapper}>
        <select
          className={styles.condition}
          value={typeSelect}
          onChange={handleTypeChange}
        >
          <option value="">Выберите условие…</option>
          <option label="Участие в цепочке" value="part_chain">
            Участие в цепочке
          </option>
          <option label="Получал рассылку" value="received_spam">
            Получал рассылку
          </option>
          <option label="Дата подписки" value="subscribe_date">
            Дата подписки
          </option>
          <option label="Участник канала или группы" value="channel_subscribe">
            Участник канала или группы
          </option>
        </select>
      </div>
      <div className={styles.conditionWrapper}>
        <select
          value={equalSelect}
          className={styles.condition}
          onChange={handleEqualChange}
          disabled={!typeSelect || isLoadingData}
        >
          {typeSelect &&
            equalData[typeSelect].label.map((option, i) => (
              <option
                label={option}
                key={i}
                value={equalData[typeSelect].value[i]}
              >
                {option}
              </option>
            ))}
        </select>
      </div>
      <div className={styles.conditionWrapper}>
        {typeSelect === 'subscribe_date' ? (
          <input
            className={`${styles.condition} ${styles.calendar}`}
            type="date"
            value={stateSelect}
            onChange={handleStateChange}
          />
        ) : (
          <select
            value={stateSelect}
            className={styles.condition}
            onChange={handleStateChange}
            disabled={!typeSelect}
          >
            {typeSelect && (
              <>
                <option label="Выберите значение..." value={''}>
                  Выберите значение...
                </option>
                {listData &&
                  listData[typeSelect].label.map((option, i) => (
                    <option
                      label={option}
                      key={i}
                      value={listData[typeSelect].value[i]}
                    >
                      {option}
                    </option>
                  ))}
              </>
            )}
          </select>
        )}
      </div>
      <BiTrash
        className={`${styles.trash} ${
          length === 1 ? styles.trashDeactive : ''
        } `}
        onClick={deleteCnd}
      />
    </div>
  );
}

export default Condition;
