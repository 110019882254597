import React, { useState } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';

import styles from './SelectFile.module.css';

function SelectFile({ handleSelect, selectData }) {
  const [popupStatus, setPopupStatus] = useState(false);
  const dropDownRef = useDetectClickOutside({
    onTriggered: () => {
      setPopupStatus(false);
    },
  });
  return (
    <>
      <div
        ref={dropDownRef}
        onClick={() => {
          setPopupStatus(!popupStatus);
        }}
        className={styles.switchFile}
      >
        {selectData === 'file' ? 'Файл' : 'URL изображения'}
        <span
          className={`${styles.caret}  ${
            popupStatus ? styles.caretAnimate : ''
          }`}
        ></span>
      </div>
      {popupStatus && (
        <ul className={styles.dropDown}>
          <li
            className={styles.dropDownElement}
            onClick={() => {
              handleSelect('file');
              setPopupStatus(false);
            }}
          >
            Файл
          </li>
          <li
            className={styles.dropDownElement}
            onClick={() => {
              handleSelect('link');
              setPopupStatus(false);
            }}
          >
            URL изображения
          </li>
        </ul>
      )}
    </>
  );
}

export default SelectFile;
