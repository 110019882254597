import React, { useEffect, useState } from "react";
import styles from "./RemoveTags.module.css";
import TagsInputUI from "../../inputUI/TagsInputUI";

function RemoveTags({ data, handler }) {
  const [tags, setTags] = useState([]);
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (data.tags && !init) {
      setTags(data.tags);
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      handler({ ...data, ...(tags && { tags }) });
    }
  }, [tags, init]);
  return (
    <div className={styles.box}>
      <TagsInputUI tags={tags} handler={(newTags) => setTags(newTags)} />
    </div>
  );
}

export default RemoveTags;
