import React, { useState, useEffect } from 'react';
import DropZoneUpload from '../dropZoneUpload';
import DropZoneURL from '../dropZoneURL';
import ErrorUploadFile from '../errorUploadFile';
import SelectFile from '../selectFile';

import ImageBlock from '../imageBlock';
import styles from './DropZone.module.css';

function DropZone({ initFileData, fileHandler }) {
  const [select, setSelect] = useState('file');
  const [selectStatus, setSelectStatus] = useState(false);
  const [fileData, setFileData] = useState({
    typeFile: 'file',
    imageValue: '',
    imageName: '',
    imageSize: '',
    imageType: '',
    textValue: '',
    hash: '',
  });
  const [isInitialized, setIsInitialized] = useState(false);
  //инициализация данных
  useEffect(() => {
    if (!isInitialized && initFileData?.imageValue) {
      setFileData(initFileData);
      setSelect(initFileData.typeFile);
      setIsInitialized(true);
    }
  }, [initFileData]);
  // для скрытия дропа когда указали файл
  useEffect(() => {
    if (fileData.imageValue) {
      setSelectStatus(true);
    }
  }, [fileData.imageValue]);

  // для выбора типа загрузки
  function handleSelect(type) {
    setSelect(type);
  }

  function imageHandler(imageInfo) {
    setFileData((previous) => {
      const newFileData = {
        ...previous,
        ...imageInfo,
        typeFile: select,
      };
      fileHandler(newFileData);
      return { ...newFileData };
    });
    if (!imageInfo.imageValue) {
      setSelectStatus(false);
    }
  }
  function urlHandler(link) {
    setFileData((previous) => {
      const newFileData = {
        ...previous,
        imageValue: link,
        typeFile: select,
      };
      fileHandler(newFileData);
      return { ...newFileData };
    });
    if (!link) {
      setSelectStatus(false);
    }
  }
  return (
    <>
      <div className={styles.dropZoneBlock}>
        {/* <ErrorUploadFile /> */}
        {!selectStatus && (
          <SelectFile handleSelect={handleSelect} selectData={select} />
        )}
      </div>
      {select === 'file' ? (
        !fileData.imageValue && <DropZoneUpload imageHandler={imageHandler} />
      ) : (
        <DropZoneURL
          handler={urlHandler}
          initUrl={fileData.typeFile === 'link' ? fileData.imageValue : false}
        />
      )}
      {fileData.imageValue && fileData.typeFile === 'file' && (
        <ImageBlock imageHandler={imageHandler} image={fileData.imageValue} />
      )}
    </>
  );
}

export default DropZone;
