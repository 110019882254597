import React, { useState, useEffect } from 'react';
import styles from './PopupChainContent.module.css';
import { useStoreApi } from 'reactflow';

function ChainContent({ addData, nodeID, nodes }) {
  const [selectedChain, setSelectedChain] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [chains, setChains] = useState(false);
  const [chainData, setChainData] = useState(false);

  useEffect(() => {
    if (isInitialized) {
      addData(chainData, 'select', nodeID);
    }
  }, [chainData]);

  // иннициализация
  useEffect(() => {
    const dataNode = nodes.data.block;
    if (dataNode && !isInitialized) {
      // если данные есть
      if (dataNode && chains) {
        const chainKnow = filterChains(chains, dataNode.select.id);
        setIsInitialized(true);
        if (chainKnow) {
          setSelectedChain(dataNode.select.id);
          setChainData(dataNode.select);
        }
        if (!chainKnow) {
          setSelectedChain('Выберите цепочку...');
        }
      }
    }
  }, [nodes.data.block, chains]);

  // иннициализация стора
  const store = useStoreApi();
  const { apiInfo } = store.getState();

  useEffect(() => {
    
    setChains(apiInfo.diaglogs);
  }, [apiInfo]);

  const handleSelectChange = (event) => {
    setSelectedChain(event.target.value);
    const chain = filterChains(chains, event.target.value);
    setChainData(chain);
  };

  function filterChains(chains, id) {
    const chain = chains.filter((chain) => chain.id === id);
    return chain[0] ? chain[0] : false;
  }

  return (
    <div>
      <label className={styles.title}>Запустить другую цепочку</label>
      <select
        value={selectedChain}
        onChange={handleSelectChange}
        className={styles.select}
      >
        {(selectedChain === 'Выберите цепочку...' || !selectedChain) && (
          <option value="Выберите цепочку...">Выберите цепочку...</option>
        )}
        {chains &&
          chains.map((chain) => (
            <option value={chain.id} key={chain.id} aria-label={chain.name}>
              {chain.name}
            </option>
          ))}
      </select>
    </div>
  );
}

export default ChainContent;

// скелет
{
  /* <optgroup label="Свободные цепочки">
          <option value="Цепочка 28.05, 20:14">Цепочка 28.05, 20:14</option>
          <option value="Тестовая Цепочка">Тестовая Цепочка</option>
        </optgroup>
        <optgroup label="Задействованные цепочки">
          <option value="Покупка">Покупка</option>
          <option value="Цепочка Уточенения">Цепочка Уточенения</option>
        </optgroup> */
}
