import React, { useEffect, useState, useContext } from 'react';
import {useNavigate  } from 'react-router-dom';
import '../../../../../../index.css';
import './BotStructure.css';
import refreshToken from '../../../../../../refreshToken';
import adapter from '../../../../../../adapter';
import { AppContext } from '../../../../../../App';
import { toast } from 'react-toastify'; 
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});



const BotStructure = ({ openCreateTriggerWindow, scenarioBotData, setActiveTabContent,setSelectedTrigger, selectedChainDialog, refreshChainsData, refreshTriggersData, setDataState, setDefaultTrigger,triggerMenuLock, setChainsOptions }) => {
  
    const storedData = JSON.parse(localStorage.getItem('dashboardData'));
    const dashboardData = storedData ? storedData: null;
    const [activeTab, setActiveTab] = useState('Chains');
    const [triggersArr, setTriggersArr] = useState([]);
    const [chainsArr, setChainsArr] = useState([]);
    const [triggersDataLoaded, setTriggersDataLoaded] = useState(false);
    const navigate = useNavigate();
    const { createNewChain } = useContext(AppContext);

    const [selectedTriggerId, setSelectedTriggerId] = useState(null);
    const [selectedChainId, setSelectedChainId] = useState(null);

    const handleTabChange = (e) => {
      setActiveTab(e);
      setActiveTabContent(e);
      if (e === 'Chains') {
        getChainsArr();
      } else if(e === 'Triggers'){
        getTriggersArr();
        getShortDialogs();
      }
    };
  
    const handleCreateNewTrigger = () => {
      openCreateTriggerWindow('createTriggerWindow');
    };
  
    const handleCreateNewChain = () => {
      createNewChain(scenarioBotData.id, scenarioBotData.name);
    };


    const getTriggersArr = async () => {
      try {
        const postData = {
          user_tg_id: dashboardData.user['telegram_id'],
          bot_id: scenarioBotData.id,
          access_token: dashboardData.token,
          route: 'getTrigers'
        };

        const response = await adapter.post('', postData);
      
        if (response.data.result === 'success') {
          setTriggersArr(response.data.array.reverse());
          const defaultTriger = response.data.array.find(() => true);
        
          setDefaultTrigger(defaultTriger);
          setTriggersDataLoaded(true);
        } 
        else if(response.data.result === 'error' && response.data.text === "Don't have trigers") {
          setDefaultTrigger(undefined);
         
        }
        else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
          try {
            const refreshTokenResult = await refreshToken();
          
            if (refreshTokenResult === 'logout') {
              navigate('/', { replace: true });
            }
            else{
              dashboardData.token = refreshTokenResult;
              const postData = {
                user_tg_id: dashboardData.user['telegram_id'],
                bot_id: scenarioBotData.id,
                access_token: dashboardData.token,
                route: 'getTrigers'
              };
        
              const response = await adapter.post('', postData);
             
              if (response.data.result === 'success') {
                setTriggersArr(response.data.array);
                const defaultTriger = response.data.array.find(() => true);
                setDefaultTrigger(defaultTriger);
                setTriggersDataLoaded(true);
              } 
              else if(response.data.result === 'error' && response.data.text === "Don't have trigers") {
             
                setDefaultTrigger(undefined);
              }
              else {
                console.log(response.data);
              }
            }
          
          } catch (error) {
            console.error(error);
          }
        }
        else {
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
        rollbar.error('Error during request:', error);
      }
    };
     //Получаем массив цепочек
    const getChainsArr  = async () => {

      try {
        const data = {
          route : 'getDialogs',
          bot_id: scenarioBotData.id,
          user_tg_id: dashboardData.user['telegram_id'],
          type:'long',
          access_token:dashboardData.token
        };
        const response = await adapter.post('', data);
        if (response.data.result === 'success') {
      
          const sortedChains = response.data.array.sort((a, b) => b.id - a.id);
          setChainsArr(sortedChains);
          const firstDialog = response.data.array.find(() => true);
          selectedChainDialog(firstDialog);
        } 
        else if(response.data.result === 'error' && response.data.text === "Don't have dialogs") {
          let chainsOptions = [{ value: 'Не найдено ни одной сохраненной цепочки!', label: '' }];
          setChainsArr('');
          selectedChainDialog([]);
        }
        else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
          try {
            const refreshTokenResult = await refreshToken();
          
            if (refreshTokenResult === 'logout') {
              navigate('/', { replace: true });
            }else{
              dashboardData.token = refreshTokenResult;
              const data = {
                route : 'getDialogs',
                bot_id: scenarioBotData.id,
                user_tg_id: dashboardData.user['telegram_id'],
                type:'long',
                access_token:dashboardData.token
              };
              const response = await adapter.post('', data);
            
              if (response.data.result === 'success') {
                setChainsArr(response.data.array);
                const firstDialog = response.data.array.find(() => true);
                selectedChainDialog(firstDialog);
              } 
              else {
                console.log(response.data);
              }
            }
          
          } catch (error) {
            console.error(error);
          }
        }
        else {
            console.error(response.data);
        }
      } catch (error) {
        rollbar.error('Error during request:', error);
      }
   
    };
    const getShortDialogs =  async () => {
    try{
      const data = {
        route : 'getDialogs',
        bot_id: scenarioBotData.id,
        user_tg_id: dashboardData.user['telegram_id'],
        type:'short',
        access_token:dashboardData.token
      };
      const response = await adapter.post('', data);
      if (response.data.result === 'success') {
        setChainsOptions(response.data.array);
      }
      else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
        try {
          const refreshTokenResult = await refreshToken();
          if (refreshTokenResult === 'logout') {
            navigate('/', { replace: true });
          }else{
          dashboardData.token = refreshTokenResult;
          const data = {
            route : 'getDialogs',
            bot_id: scenarioBotData.id,
            user_tg_id: dashboardData.user['telegram_id'],
            type:'short',
            access_token:dashboardData.token
          };
          const response = await adapter.post('', data);
          if (response.data.result === 'success') {
            setChainsOptions(response.data.array);
          }else{
            console.log(response.data);
          }
        }
        } catch (error) {
          console.error(error);
          toast.error(error);
        }
      }
      else if(response.data.result === 'error' && response.data.text === "Don't have dialogs"){
        setChainsOptions([]);
      }
    } catch (error) {
      rollbar.error('Error during request:', error);
    }

  }
  

    //выбираем цепочку в меню
    const handleSelectedChain = (e) => {
      const selectedId = e.target.getAttribute('dataid');
      const selectedDialog = chainsArr.find((item) => item.id === selectedId);
      selectedChainDialog(selectedDialog);
      setSelectedChainId(selectedId);
    };

    //выбираем триггер в меню
    const handleSelectedTrigger = (e) => { 
      const selectedId = e.target.getAttribute('dataid');
      setSelectedTriggerId(selectedId);
      const selectedTrigger= triggersArr.find((item) => item.id === selectedId);
      setSelectedTrigger(selectedTrigger);
    };


    //вкладка триггеров
    const triggersTab = (
      <div className="tabs-panel">
        <div className="structure__trigers-create">
          <button className="action-btn bot__triger-add" type="button" onClick={handleCreateNewTrigger}>
            Создать новый тригер
          </button>
          <p>Выбрать существующий:</p>
        <div id='chainsFrame' className='table-body'>
          <p>Выбрать существующий:</p>
          {
              triggersArr.length > 0 ? (
                triggersArr.map((item) => (
              
                  <button
                    key={item.id}
                    className={`template-dilogs-btn ${selectedTriggerId === item.id ? 'template-dilogs-btn__active' : ''}`}
                    dataid={item.id}
                    onClick={handleSelectedTrigger}
                    disabled={triggerMenuLock}
                  >
                    {item.name}
                  </button>
                  
                ))
              ) : (
                <small>Не найдено ни одного сохраненного триггера!</small>
              )
            }
        </div>
         
        </div>
      </div>
    );
    //вкладка цепочек
    const chainsTab = (
      <div className="tabs-panel">
        <div className="structure__trigers-create">
          <button className="action-btn bot__triger-add" type="button" onClick={handleCreateNewChain}>
            Создать новую цепочку
          </button>

         
          <p>Выбрать существующую:</p>
          <div>
            <p>Выбрать существующую:</p>
            <div id='chainsFrame' className='table-body'>
              {
                chainsArr.length > 0 ? (
                  chainsArr.map((item) => (
                    
                      <button
                        key={item.id}
                        className={`template-dilogs-btn ${selectedChainId === item.id ? 'template-dilogs-btn__active' : ''}`}
                        dataid={item.id}
                        onClick={handleSelectedChain}
                      >
                        {item.name}
                      </button>
                  
                  ))
                ) : (
                  <small>Не найдено ни одной сохраненной цепочки!</small>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );


       
  
    const renderTabs = () => {
      switch (activeTab) {
        case 'Triggers':
          return triggersTab;
        case 'Chains':
          return chainsTab;
        default:
          return triggersTab;
      }
    };
  
    useEffect(() => {

      if (refreshChainsData) {
        getTriggersArr();
        setDataState(false);
      } else if (refreshTriggersData) {
        getTriggersArr();
        setDataState(false);
      }
      getTriggersArr();
      getChainsArr();
      getShortDialogs();
    }, [refreshChainsData, refreshTriggersData]);


    useEffect(() => {
      const defaultTrigger = triggersArr.find(() => true);
      setSelectedTriggerId(defaultTrigger?.id || null);

    }, [triggersArr]);
  

    useEffect(() => {
      const defaultChain = chainsArr.length > 0 ? chainsArr[0] : null;
      setSelectedChainId(defaultChain?.id || null);
      
    }, [chainsArr]);
   


    return (
      <>
        <div className="card">
          <div className="scenario-menu">
            <ul className="scenario-menu-list">
              <li className={`scenario-menu__item ${activeTab === 'Triggers' ? 'scenario-menu__item--active' : ''}`}>
                <button className="scenario-menu__item-link" onClick={() => handleTabChange('Triggers')}>
                  Триггеры
                </button>
              </li>
              <li className={`scenario-menu__item ${activeTab === 'Chains' ? 'scenario-menu__item--active' : ''}`}>
                <button className="scenario-menu__item-link" onClick={() => handleTabChange('Chains')}>
                  Цепочки
                </button>
              </li>
            </ul>
          </div>
          <div className="scenario-body">{renderTabs()}</div>
        </div>
      </>
    );
};
    
export default BotStructure;