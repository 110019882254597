import React from 'react';
import { MdArrowBackIos } from 'react-icons/md';
import { BiTrash } from 'react-icons/bi';

import styles from './MessageMoveType.module.css';

function MessageMoveType({
  blockStatus,
  moveUp,
  moveDown,
  deleteBlock,
  index,
  length,
}) {
  return (
    <div>
      <div className={styles.arrows}>
        {blockStatus && (
          <>
            <BiTrash
              className={`${styles.trash} `}
              style={{ display: length === 1 ? 'none' : 'block' }}
              onClick={deleteBlock}
            />
            <MdArrowBackIos
              className={`${styles.arrow} ${styles.up}`}
              style={{ display: index === 0 ? 'none' : 'block' }}
              onClick={moveUp}
            />
            <MdArrowBackIos
              className={`${styles.arrow} ${styles.down}`}
              style={{ display: index === length - 1 ? 'none' : 'block' }}
              onClick={moveDown}
            />
          </>
        )}
      </div>
    </div>
  );
}

export default MessageMoveType;
