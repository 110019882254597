import React, { useEffect, useState } from 'react';
import '../../../../index.css';
import styles from './styles.module.css';
import ReactModal from 'react-modal';
import adapter from '../../../../adapter';
import refreshToken from '../../../../refreshToken';
import { useNavigate } from 'react-router-dom';
import Preloader from '../../../../Preloader';
import BotScript from './BotScript/BotScript'
import DrawPage from './DrawPage';
import { toast } from 'react-toastify';
import iconPlus from "/public/plus.png";
ReactModal.setAppElement('#root');



const AdminPanel = ({refreshComponent, setWorkspaceStyles}) => {
  const storedData = JSON.parse(localStorage.getItem('dashboardData'));
  const dashboardData = storedData ? storedData: null;
  const userData = dashboardData? dashboardData.user : null;
  let storedAppState = localStorage.getItem('storedAppState');
  if (storedAppState !== null) {
    storedAppState = {
      page: 'AdminPanel',
    };
  } else {
    storedAppState = JSON.parse(storedAppState);
  }


  const [botManagement, setBotManagement] = useState('managmentPage');
  const [modalOpen, setModalOpen] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const [botToken, setBotToken] = useState('');
  const [voronkaBotsInfo, setVoronkaBotsInfo] = useState([]);
  const [drawBotsInfo, setDrawBotsInfo] = useState([]);
  const [drawBotsData, setDrawBotsData] = useState('');
  const [helperBotsInfo, setHelperBotsInfo] = useState([]);
  const navigate = useNavigate();


  const [isLoading, setIsLoading] = useState('');
  const [preloaderText, setPreloaderText] = useState('');


  useEffect(() => {
    if(isLoading !== ''){
      setWorkspaceStyles(true);
    }else{
      setWorkspaceStyles(false);
    }
  }, [isLoading]);

  const [typeOfBot, setTypeOfBot] = useState('');

  const handleSelectTypeOfBot = (e) => {
    setTypeOfBot(e.target.value);
  }

  const handleOpenModal =  async (alertType, preloaderText) => {
    if(!typeOfBot){
      toast.error('Вы не выбрали тип бота!');
      return;
    }
    setIsLoading(alertType);
    setPreloaderText(preloaderText);
    try {
      const formData = {
        tg_id  : dashboardData.user['telegram_id'],
        bot_token : botToken,
        access_token : dashboardData.token,
        type: typeOfBot,
        route : 'addbot',
        
      };
      const response = await adapter.post('', formData);

      if (response.data.result === 'success') {
        getBotsInfo();
        setTypeOfBot('');
        if (alertType === 'addBot') {
            toast.success('Бот успешно добавлен!');
        }
        setTimeout(() => {
          setIsLoading('');
          setPreloaderText('');
          handleReturnToPanel();
        }, 3000);
       
      }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
        try {
          const refreshTokenResult = await refreshToken();
        
          if (refreshTokenResult === 'logout') {
            navigate('/', { replace: true });
          }else{
            dashboardData.token = refreshTokenResult;
            const formData = {
              tg_id  : dashboardData.user['telegram_id'],
              bot_token : botToken,
              access_token : dashboardData.token,
              type: typeOfBot,
              route : 'addbot',
              
            };
            setIsLoading(alertType);
            setPreloaderText(preloaderText);
            const response = await adapter.post('', formData);
           
            if (response.data.result === 'success') {
              getBotsInfo();
              setTypeOfBot('');
              if (alertType === 'addBot') {
                  toast.success('Бот успешно добавлен!');
              }
              setTimeout(() => {
                setIsLoading('');
                setPreloaderText('');
                handleReturnToPanel();
              }, 3000);
             
            }
            else {
              toast.error(response.data.error);
              setIsLoading('');
              console.log(response.data);
            }
          }
        
        } catch (error) {
          toast.error(error);
          setIsLoading('');
          console.error(error);
        }
      }else {
        toast.error(response.data.text);
        console.log(response.data);
        setIsLoading('');
      }
    } catch (error) {
      toast.error(error);
      setIsLoading('');
      console.error(error);
    }

    
  };
  const  handleBotEnabled= async (botType, botApi, preloaderText) => {
    setIsLoading(botType);
    setPreloaderText(preloaderText);
    try {
        const data = {
          tg_id  : dashboardData.user['telegram_id'],
          bot_token : botApi,
          access_token : dashboardData.token,
          type: botType === 'drawBotsInfo' ? 'draw' : 'voronka',
          route : 'addbot',
          
        };
        const response = await adapter.post('', data);
        
        if (response.data.result === 'success') {
          getBotsInfo();
          switch (botType) {
            case 'voronkaBotsInfo':
              toast.success('Бот успешно подключён!');
              break;
            case 'drawBotsInfo':
              toast.success('Бот успешно подключён!');
              break;
            case 'helperBotsInfo':
              toast.success('Бот успешно подключён!');
              break;
          }
          setTimeout(() => {
            setIsLoading('');
            setPreloaderText('');
          }, 3000);
        }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
          try {
            const refreshTokenResult = await refreshToken();
          
            if (refreshTokenResult === 'logout') {
              navigate('/', { replace: true });
            }else{
              dashboardData.token = refreshTokenResult;
              const data = {
                tg_id  : dashboardData.user['telegram_id'],
                bot_token : botApi,
                access_token : dashboardData.token,
                type: typeOfBot,
                route : 'addbot',
                
              };
              const response = await adapter.post('', data);
              
              if (response.data.result === 'success') {
                getBotsInfo();
                switch (botType) {
                  case 'voronkaBotsInfo':
                    toast.success('Бот успешно подключён!');
                    break;
                  case 'drawBotsInfo':
                    toast.success('Бот успешно подключён!');
                    break;
                  case 'helperBotsInfo':
                    toast.success('Бот успешно подключён!');
                    break;
                }
                
                setTimeout(() => {
                  setIsLoading('');
                  setPreloaderText('');
                }, 3000);
                
              }
              else {
                toast.error(response.data.text);
                setIsLoading('');
                console.log(response.data);
              }
            }
          
          } catch (error) {
            setIsLoading('');
            toast.error(error);
            console.error(error);
          }
        }
        
        else {
          setIsLoading('');
          toast.error(response.data.text);
          console.log(response.data);
        }
        } catch (error) {
          toast.error(error);
          console.error(error);
        }
  }
  const  handleBotDisabled = async (botType, botApi, preloaderText) => { 
    setIsLoading(botType);
    setPreloaderText(preloaderText);
    try {
          const data = {
            bot_token : botApi,
            access_token : dashboardData.token,
            route : 'removebot',
            
          };
          const response = await adapter.post('', data);
          if (response.data.result === 'success') {
            getBotsInfo();
            switch (botType) {
              case 'voronkaBotsInfo':
                toast.success('Бот успешно отключён!');
                break;
              case 'drawBotsInfo':
                toast.success('Бот успешно отключён!');
                break;
              case 'helperBotsInfo':
                toast.success('Бот успешно отключён!');
                break;
            }
            setTimeout(() => {
              setIsLoading('');
              setPreloaderText('');
            }, 3000);

          }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
            try {
              const refreshTokenResult = await refreshToken();
              if (refreshTokenResult === 'logout') {
                navigate('/', { replace: true });
              }else{
                dashboardData.token = refreshTokenResult;
                setIsLoading('');
                const data = {
                  bot_token : botApi,
                  access_token : dashboardData.token,
                  route : 'removebot',
                  
                };
                const response = await adapter.post('', data);
                if (response.data.result === 'success') {
                  getBotsInfo();
                  switch (botType) {
                    case 'voronkaBotsInfo':
                      toast.success('Бот успешно отключён!');
                      break;
                    case 'drawBotsInfo':
                      toast.success('Бот успешно отключён!');
                      break;
                    case 'helperBotsInfo':
                      toast.success('Бот успешно отключён!');
                      break;
                  }
                  setTimeout(() => {
                    setIsLoading('');
                    setPreloaderText('');
                  }, 3000);
                }
                else {
                  setIsLoading('');
                  toast.error(response.data.text);
                  console.log(response.data);
                }
              }
            
            } catch (error) { 
                toast.error(error);
                setIsLoading('');
                console.error(error);
            }
          }
          else {
            setIsLoading('');
            toast.error(response.data.text);
            console.log(response.data);
          }
        } catch (error) {
          toast.error(error);
          setIsLoading('');
          console.error(error);
        }
  }
  const  handleBotDelete = async (botType, botApi, preloaderText) => { 
    const userConfirmed = window.confirm('Вы уверены, что хотите удалить этого бота?');
    if (userConfirmed) {
      setIsLoading(botType);
      setPreloaderText(preloaderText);
      try {
              const data = {
                bot_token : botApi,
                user_tg_id  : dashboardData.user['telegram_id'],
                access_token : dashboardData.token,
                route : 'deleteBot',
                
              };
              const response = await adapter.post('', data);
              if (response.data.result === 'success') {
                getBotsInfo();
                switch (botType) {
                  case 'voronkaBotsInfo':
                    toast.success('Бот успешно удалён!');
                    break;
                  case 'drawBotsInfo':
                    toast.success('Бот успешно удалён!');
                    break;
                  case 'helperBotsInfo':
                    toast.success('Бот успешно удалён!');
                    break;
                }
                setTimeout(() => {
                  setIsLoading('');
                  setPreloaderText('');
                }, 3000);
              }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
                try {
                  const refreshTokenResult = await refreshToken();
                  if (refreshTokenResult === 'logout') {
                    navigate('/', { replace: true });
                  }else{
                    dashboardData.token = refreshTokenResult;
                    setIsLoading('');
                    const data = {
                      bot_token : botApi,
                      access_token : dashboardData.token,
                      route : 'deleteBot',
                      
                    };
                    const response = await adapter.post('', data);
                    if (response.data.result === 'success') {
                    
                      getBotsInfo();
                      switch (botType) {
                        case 'voronkaBotsInfo':
                          toast.success('Бот успешно удалён!');
                          break;
                        case 'drawBotsInfo':
                          toast.success('Бот успешно удалён!');
                          break;
                        case 'helperBotsInfo':
                          toast.success('Бот успешно удалён!');
                          break;
                      }
                      setTimeout(() => {
                        setIsLoading('');
                        setPreloaderText('');
                      }, 3000);
                    }
                    else {
                      setIsLoading('');
                      toast.error(response.data.text);
                      console.log(response.data);
                    }
                  }
                
                } catch (error) { 
                  setIsLoading('');
                  toast.error(error);
                  console.error(error);
                }
              }
            else {
                console.log(response.data);
                setIsLoading('');
                toast.error(response.data.text);
              }
          } catch (error) {
            toast.error(error);
            setIsLoading('');
            console.error(error);
          }
    }
  }
  
  const [scenarioBotData, setScenarioBotData] = useState(null);



  const  handleBotScenario = async (botId, botName) => {
    setBotManagement('BotScript');
    const botData = {
      id:botId,
      name:botName
    }
    localStorage.setItem('storedAppState', JSON.stringify({
      ...storedAppState,
      page:'AdminPanel',
      state: 'BotScript',
      botData:botData
    }));
    setScenarioBotData(botData);
  }
  

  const handleDrawManagment = (id,name) => {
    setDrawBotsData({
      id:id,
      name:name
      });
    setBotManagement('drawPage');
  };


  const handleCloseModal = () => {
    setFadeOut(true);
   
  };

  useEffect(() => {
    if (fadeOut) {
      const timeoutId = setTimeout(() => {
        setModalOpen(false);
        setFadeOut(false);
      }, 300); 

      return () => clearTimeout(timeoutId);
    }
    if (dashboardData && userData.telegram_id) {
      getBotsInfo();
    }
  }, [fadeOut]);

  const handleAddBot = () => {
    
    setBotManagement('addBotPage');
  }

  const handleReturnToPanel = () => {
    setBotManagement('managmentPage');
    localStorage.setItem('storedAppState', JSON.stringify({
      ...storedAppState,
      state: '',
      botData:''

    }));
  }
  const getBotsInfo = async () => {
    
    try {
      const postData = {
          user_tg_id : dashboardData.user['telegram_id'],
          access_token : dashboardData.token,
          route : 'getbotsinfo',
        };
      const response = await adapter.post('', postData);

      if (response.data.result === 'success') {

        const voronkaBots = response.data.array.filter(item => item.type === 'voronka');
        const drawBots = response.data.array.filter(item => item.type === 'draw');
        const helperBots = response.data.array.filter(item => item.type === 'helper');
        setVoronkaBotsInfo(voronkaBots);
        setDrawBotsInfo(drawBots);
        setHelperBotsInfo(helperBots);
      } else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
        try {
          const refreshTokenResult = await refreshToken();
        
          if (refreshTokenResult === 'logout') {
            navigate('/', { replace: true });
          }else{
            const postData = {
              user_tg_id : dashboardData.user['telegram_id'],
              access_token : dashboardData.token,
              route : 'getbotsinfo',
            };
            const response = await adapter.post('', postData);
            if (response.data.result === 'success') {
              const voronkaBots = response.data.array.filter(item => item.type === 'voronka');
              const drawBots = response.data.array.filter(item => item.type === 'draw');
              const helperBots = response.data.array.filter(item => item.type === 'helper');
              setVoronkaBotsInfo(voronkaBots);
              setDrawBotsInfo(drawBots);
              setHelperBotsInfo(helperBots);
            }
            else {
              navigate('/', { replace: true });
            }
          }
        } catch (error) {
          console.error(error);
        }
      }
      else {
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
    }

  }  
  const [hideDisconnectedVoronka, setHideDisconnectedVoronka] = useState(true);
  const [hideDisconnectedDraw, setHideDisconnectedDraw] = useState(true);
  const [hideDisconnectedHelper, setHideDisconnectedHelper] = useState(true);

  useEffect(() => {
    if (refreshComponent) {
      handleReturnToPanel();
    }
      
  }, [refreshComponent]);

  useEffect(() => {
    if(storedAppState.state === 'BotScript' && storedAppState.botData) {
      setBotManagement(storedAppState.state);
      setScenarioBotData(storedAppState.botData);
    }
  }, []);
  

  if (botManagement === 'managmentPage') {
    return(
          <>
            <h1 className='main-title'>
              Создайте и управляйте собственным ботом
            </h1>
            <button className={`${styles['add-bot-btn']} main-btn`} type="button" onClick={handleAddBot}><img src={iconPlus} alt='icon'/>Добавить бота</button>
            {/* </div> */}
            <div className="card ">
            <h3 className='card-title'>
              Статистика по текущим ботам
            </h3>
            {voronkaBotsInfo.length === 0 && drawBotsInfo.length === 0 ? (
              <p className={styles['empty-bots']}>Данные для отображения отсутствуют! Чтобы отобразить статистику, <span> добавьте хотя бы одного бота</span></p>
            ) : (
              <>
                {voronkaBotsInfo.length > 0 ? (
                  <div className={`${styles['bots-card']} ${voronkaBotsInfo.length > 0 && styles.line}`}>
                    <div className={styles['title-wrapper']}>
                      <h3>Боты для воронки</h3>
                      <div className={`${styles['checkbox-group']} ${styles['hide-bots']}`}>
                        <label htmlFor="hideBotsVoronka" className={styles.chexbox}>
                          <input
                            className={styles['checkbox-input']}
                            type="checkbox"
                            checked={!hideDisconnectedVoronka}
                            onChange={() => setHideDisconnectedVoronka(!hideDisconnectedVoronka)}
                            id='hideBotsVoronka'
                          />
                          <div className={styles['checkbox-box']}>
                            <div className={styles['checkbox-check']}></div>
                          </div>
                          <span>Показать отключенные</span>
                        </label>
                      </div>
                    </div>
                    <div className={styles['table-body']}>
                      <table className={`${styles['bot-management']} ${styles['voronka-bots']}`}>
                        <thead>
                          <tr>
                            <th>Имя бота</th>
                            <th>Тип бота</th>
                            <th>Статус</th>
                            <th></th>
                            <th>Действие</th>
                          </tr>
                        </thead>
                        <tbody>
                          {isLoading === 'voronkaBotsInfo'   ? (<><p className='preloader-title'>{preloaderText}</p><Preloader></Preloader></>) : (
                          <>
                            {voronkaBotsInfo.filter(item => !hideDisconnectedVoronka || item.status !== 'disconnected')
                              .sort((a, b) => {
                                if (a.status === 'disconnected' && b.status !== 'disconnected') {
                                  return 1; 
                                } else if (a.status !== 'disconnected' && b.status === 'disconnected') {
                                  return -1; 
                                } else {
                                  return 0;
                                }
                              })
                              .map(item => (
                                
                                <tr key={item.id}>
                                  <td>{item.bot_name}</td>
                                  <td>{item.type}</td>
                                  <td>
                                    <p className={`${item.status === 'connected' ? styles.connected : styles.disconnected}`}>{item.status}</p>
                                  </td>
                                  <td></td>
                                  <td>
                                  <div>
                                    <button className={`action-btn ${styles['btn-connect']}`} onClick={()=>handleBotEnabled('voronkaBotsInfo', item.bot_api, 'Подключаем бота')} disabled={item.status !== 'disconnected'} data-status={item.status}>Подключить</button>
                                    <button type='button' className={`action-btn ${styles['btn-disconnect']}`} onClick={()=>handleBotDisabled('voronkaBotsInfo', item.bot_api, 'Отключаем бота')} disabled={item.status !== 'connected'} data-status={item.status}>Отключить</button>
                                  </div>
                                  <div>
                                    <button type='button' className={`action-btn  ${styles['btn-scenario']}`} onClick={() => handleBotScenario(item.id, item.bot_name)}>Сценарий</button>
                                    <button type='button' className={`action-btn  ${styles['btn-delete']}`}  onClick={()=>handleBotDelete('voronkaBotsInfo', item.bot_api,'Удаляем бота')}>Удалить</button>
                                  </div>
                                    
                                  </td>
                                </tr>
                              ))}
                              
                            </>
                            )
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  ) : (
                  <div className={styles['bots-card']}>
                    <h3>Боты для воронки</h3>
                  </div>
                )}
                {drawBotsInfo.length > 0 ? (
                  <div className={` ${styles['bots-card']} ${drawBotsInfo.length > 0 && styles.line}`}>
                  <div className={styles['title-wrapper']}>
                  <h3>Боты для розыгрыша</h3>
                    <div className={`${styles['checkbox-group']} ${styles['hide-bots']}`}>
                      <label htmlFor="hideBotsDraw"  className={styles.chexbox}>
                        <input  className={styles['checkbox-input']} type="checkbox"   checked={!hideDisconnectedDraw}
                          onChange={() => setHideDisconnectedDraw(!hideDisconnectedDraw)} id='hideBotsDraw'/>
                        <div className={styles['checkbox-box']}>
                          <div className={styles['checkbox-check']}></div>
                        </div>
                        <span>Показать отключенные</span>
                            
                      </label>
                    </div>
                  </div>
                  
                  <div className={styles['table-body']}>
                    <table  className={`${styles['bot-management']} ${styles['draw-bots']}`}>
                      <thead>
                        <tr>
                          <th>Имя бота</th>
                          <th>Тип бота</th>
                          <th>Статус</th>
                          <th>Рейтинг</th>
                          <th>Действие</th>
                        </tr>
                      </thead>
                      <tbody>
                      {isLoading === 'drawBotsInfo'   ? (<><p className='preloader-title'>{preloaderText}</p><Preloader></Preloader></>) : (
                      <>
                          {drawBotsInfo
                            .filter(item => !hideDisconnectedDraw || item.status !== 'disconnected')
                            .sort((a, b) => {
                              if (a.status === 'disconnected' && b.status !== 'disconnected') {
                                return 1; 
                              } else if (a.status !== 'disconnected' && b.status === 'disconnected') {
                                return -1; 
                              } else {
                                return 0;
                              }
                            })
                            .map(item => {
                              const googleSheetData = JSON.parse(item.google_sheet);
                              const startElements = googleSheetData.filter(item => item.status === 'start');
                              let tableId;

                              if (startElements.length > 0) {
                                const latestStartElement = startElements[0];
                                tableId = latestStartElement.table_id;
                              }else{
                                tableId = '';
                              }
                            
                              return (
                                <>
                                  <tr>
                                    <td>{item.bot_name}</td>
                                    <td>{item.type}</td>
                                    <td>
                                      <p className={`${item.status === 'connected' ? styles.connected : styles.disconnected}`}>{item.status}</p>
                                    </td>
                                    <td>
                                      <a target='blank_' href={`https://docs.google.com/spreadsheets/d/${tableId}`}>Просмотреть</a>
                                    </td>
                                    <td >
                                      <div>
                                        <button className={`action-btn ${styles['btn-connect']}`} onClick={()=>handleBotEnabled('drawBotsInfo', item.bot_api, 'Подключаем бота')} disabled={item.status !== 'disconnected'} data-status={item.status}>Подключить</button>
                                        <button type='button' className={`action-btn ${styles['btn-disconnect']}`} onClick={()=>handleBotDisabled('drawBotsInfo', item.bot_api, 'Отключаем бота')} disabled={item.status !== 'connected'} data-status={item.status}>Отключить</button>
                                      </div>
                                      <div>
                                        <button type='button' className={`action-btn  ${styles['btn-scenario']}`}onClick={() => handleBotScenario(item.id, item.bot_name)}>Сценарий</button>
                                        <button type='button' className={`action-btn  ${styles['btn-delete']}`}  onClick={()=>handleBotDelete('drawBotsInfo', item.bot_api,'Удаляем бота')}>Удалить</button>
                                      </div>
                                      <button className={`action-btn  ${styles['btn-draw']}`} onClick={() => handleDrawManagment(item.id, item.bot_name)}>Управлять розыгрышами</button>
                                    </td>
                                    
                                  </tr>
                                  
                                </>
                              );
                            })}
                      </>)}
                      </tbody>
                    </table>
                  </div>
                  
                  
                  </div>
                ) : (
                  <div className={styles['bots-card']}>
                    <h3>Боты для розыгрышей</h3>
                  </div>
                )}
                {/* { {helperBotsInfo.length > 0 ? (
                    <div className='card bots-card'>
                      <div className="form-elem ">
                      <h3><p>Боты помощники</p><img className='icon' src={helperIcon}/>  </h3>
                      <div className="checkbox-group hide-bots">
                        <label htmlFor="hideBotsHelper" className="checkbox">
                          <input className="checkbox-input" type="checkbox"  checked={!hideDisconnectedHelper}
                            onChange={() => setHideDisconnectedHelper(!hideDisconnectedHelper)} id='hideBotsHelper'/>
                          <div className="checkbox-box">
                            <div className="checkbox-check"></div>
                          </div>
                          {hideDisconnectedHelper ?(
                              <span>Показать отключенных</span>
                          ):(
                            <span>Скрыть отключенных</span>
                              
                          )}
                              
                        </label>
                      </div>
                    </div>
                    
                    <table className="bot-management helper-bots">
                      <thead>
                        <tr>
                          <th>Имя бота</th>
                          <th>Тип бота</th>
                          <th>Статус</th>
                          <th></th>
                          <th>Действие</th>
                        </tr>
                      </thead>
                      <tbody>
                      
                        {isLoading === 'helperBotsInfo'   ? (<><p className='preloader-title'>{preloaderText}</p><Preloader></Preloader></>) : (
                        <>
                        {helperBotsInfo
                          .filter(item => !hideDisconnectedHelper || item.status !== 'disconnected')
                          .sort((a, b) => {
                            if (a.status === 'disconnected' && b.status !== 'disconnected') {
                              return 1; 
                            } else if (a.status !== 'disconnected' && b.status === 'disconnected') {
                              return -1; 
                            } else {
                              return 0;
                            }
                          })
                          .map(item => (
                            <tr key={item.id}>
                              <td>{item.bot_name}</td>
                              <td>{item.type}</td>
                              <td>
                                <span className={`${item.status === 'connected' ? 'status__connect' : 'status__disconnect'}`}></span>
                                <p>{item.status}</p>
                              </td>
                              <td></td>
                              <td>
                              <div>
                                          <button className='action-btn btn_connect' onClick={()=>handleBotEnabled('helperBotsInfo', item.bot_api, 'Подключаем бота.....')} disabled={item.status !== 'disconnected'} data-status={item.status}>Подключить</button>
                                          <button type='button' className='action-btn btn_disconnect' onClick={()=>handleBotDisabled('helperBotsInfo', item.bot_api, 'Отключаем бота.....')} disabled={item.status !== 'connected'} data-status={item.status}>Отключить</button>
                                        </div>
                                        <div>
                                          <button type='button' className='action-btn btn_scenario' onClick={() => handleBotScenario(item.id, item.bot_name)}>Сценарий</button>
                                          <button type='button' className='action-btn btn_delete'  onClick={()=>handleBotDelete('helperBotsInfo', item.bot_api,'Удаляем бота.....')}>Удалить</button>
                                        </div>
                              
                              </td>
                            </tr>
                          ))}
                        </>
                          )}
                      </tbody>
                    </table>
                    </div>
                ) : (
                      <div className='card bots-card'>
                      <div className='form-elem'> <h3><p>Боты помощники</p></h3></div>
                          <p className='empty-bot'>Для отображения статистики добавьте хотя бы одного бота!</p>
                      </div>
                )}  */}
                      
              </>
            )} 
            </div>
          </>
       
  );
  }else if(botManagement === 'drawPage') {
    return(

      <DrawPage drawBotsData={drawBotsData} handleReturnToPanel={handleReturnToPanel}></DrawPage>

    )
  }
  else if(botManagement === 'addBotPage') {
    return(
      <>
      
      {isLoading === 'addBot'   ? (<div className='preloader-container'><p className='preloader-title'>{preloaderText}</p><Preloader></Preloader></div>) : (
       
              <>
                <h1 className="main-title">
                  <p>Управление ботами</p>
                </h1>
                {/* <a className="get-back" onClick={handleReturnToPanel}>
                  Вернуться назад
                </a> */}
                <div className="card">
                  <div id='connectBot' >
                    <h3 className={`card-title ${styles['add-bot-title']}`}>
                    Чат-бот Telegram
                    </h3>
                    <div className={styles['add-bot-desc']}>
                      Создавайте Telegram ботов, которые информируют, консультируют и продают товары. Чтобы автоматизировать ответы, нам понадобятся ключи доступа ваших ботов
                    </div>
                    
                    <form className={styles['add-bot-form']}>
                      <div>
                        <p className={styles['add-bot-option']}>Тип бота:</p>
                        <div className="radio-group form-elem">
                            <label htmlFor="voronkaBot" className="radio">
                            <input className="radio-input" type="radio" id="voronkaBot" name="voronka" value="voronka" checked={typeOfBot === "voronka"} onChange={handleSelectTypeOfBot} />
                            <div className="radio-circle">
                                <div className="radio-mark"></div>
                            </div>
                            <small>Для воронки</small>
                            </label>
                            <label htmlFor="drawBot" className="radio">
                            <input className="radio-input" type="radio" id="drawBot" name="draw" value="draw" checked={typeOfBot === "draw"} onChange={handleSelectTypeOfBot} />
                            <div className="radio-circle">
                                <div className="radio-mark"></div>
                            </div>
                            <small>Для розыгрыша</small>
                            </label>
                            {/* <label htmlFor="helperBot" className="radio">
                            <input className="radio-input" type="radio" id="helperBot" name="helper" value="helper" checked={typeOfBot === "helper"} onChange={handleSelectTypeOfBot} />
                            <div className="radio-circle">
                                <div className="radio-mark"></div>
                            </div>
                            <small>Помощник</small>
                            </label> */}
                        </div>
                      </div>
                      <div>
                      <p>Ключ доступа:</p>
                      <div className={styles['token-input']}>
                          <input type="text" size="50" className="input" onChange={(e) => setBotToken(e.target.value)} placeholder='Введите ключ доступа (токен бота)'/>
                        </div>
                        <div className={styles['add-token-btn']}>
                          <button className="main-btn" type="button" onClick={()=>handleOpenModal('addBot','Добавляем бота')} disabled={!botToken}>Подключить</button>
                        </div>
                      </div>
                      <div className='d-flex space-between form-elem'>
                        
                      </div>
                     
                    </form>
                  </div>    

                </div>
              </>
      )}
        {modalOpen && (
        <ReactModal
        isOpen={modalOpen}
        onRequestClose={handleCloseModal}
        className="modal-content"
        overlayclassName="modal-overlay"
        contentLabel="Модальное окно"
      >
       <div className="modal-header">
                <div className="modal-circles">
                  <span className="dot"></span>
                  <span className="dot-focus"></span>
                  <span className="dot"></span>
                </div>
        </div>
        <div className="modal-body">  
          <div className="bot-wait">
            <h2 className="bot-wait-title"><b>Ожидание подписки</b></h2>
            <p className="bot-wait-desc">
              Нажмите "Запустить" в Telegram. После этого вы будете
              перенаправлены на страницу добавления бота
            </p>
          </div>
          <p className="bot-wait-error">
            Если возникли проблемы с подпиской в приложении
            откройте веб-версию
          </p>
          <a href='https://google.com' target='_blank' className="main-btn btn-telegram" onClick={handleCloseModal}>Открыть в браузере</a>
          
        </div>
        </ReactModal>
        )}
        
      </>
    );
    
  }
  else if(botManagement === 'BotScript') {
    return(

      <BotScript scenarioBotData={scenarioBotData} handleReturnToPanel={handleReturnToPanel}/>
     
    );
    
  }


}

export default AdminPanel;