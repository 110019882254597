import React from 'react';
import styles from './ErrorUi.module.css';

function ErrorPopUp({ text, clear }) {
  return (
    <span className={styles.error}>
      {text}
      <span className={styles.button} onClick={clear}>
        ×
      </span>
    </span>
  );
}

export default ErrorPopUp;
