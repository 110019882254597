import React from 'react';
import JsonView from 'react18-json-view';

import styles from './JsonAutoComplete.module.css';
import '../BodyJsonUI/json.css';
import './property.css';
import { getJsonSyntaxPath } from '../../../../utils/getJsonSyntaxPath';

function JsonAutoCompleteUI({ data, handler, close, hideIndex, arrayTarget }) {
  return (
    <div
      className={`${styles.body} ${arrayTarget ? 'arrJson' : 'allJson'}`}
      onClick={(e) => {
        if (e.target.className === 'json-view--property') {
          const path = getJsonSyntaxPath(
            data,
            e.target.innerText.trim(),
            arrayTarget
          );
          if (path) {
            const isPathHideNumber = hideIndex
              ? path.replace(/\['\d+'\]/g, "['*']")
              : path;
            handler(isPathHideNumber);
            close();
          }
        }
      }}
    >
      {data ? (
        <JsonView
          src={data}
          collapsed={1}
          customizeCopy={() => ''}
          enableClipboard={false}
        />
      ) : (
        <p className={styles.tip}>
          Нажмите "Проверить запрос", чтобы увидеть структуру данных
        </p>
      )}
    </div>
  );
}

export default JsonAutoCompleteUI;
