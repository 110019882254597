// шаблоны для блоков
export function blockDataCreate(type) {
  if (type === "message") {
    return {
      role: "message",
      block: {
        elements: [
          {
            id: 1,
            type: "message text",
            textValue: "",
            buttons: [],
            maxBtnRow: "1",
          },
        ],
        quickAnswer: {
          type: "quick answer",
          buttons: [],
          maxBtnRow: "1",
        },
      },
    };
  }
  if (type === "chain") {
    return { role: "chain", block: { select: false } };
  }
  if (type === "filter") {
    return {
      role: "filter",
      block: {
        conditions: [
          {
            id: 1,
            typeLogic: "and",
            subConditions: [
              {
                name: "",
                condition: "",
                value: "",
              },
            ],
          },
        ],
        denial: {
          id: "denial",
          text: "no",
        },
      },
    };
  }
  if (type === "pause") {
    return {
      role: "pause",
      block: {
        pause: {
          type: "pause",
          priority: "",
          pause_type: "time",
          duration: "",
          active_type: "passive",
          // priority: 'high',
        },
      },
    };
  }
  if (type === "captcha") {
    return {
      role: "captcha",
      block: {
        pause: {
          type: "captcha",
        },
      },
    };
  }
  if (type === "api") {
    return {
      role: "api",
      block: {
        requestType: "get",
        requestUrl: "",
        headers: [],
        body: "",
        storeValues: [],
        sendMessage: {
          arrayAnchor: "",
          imageUrl: "",
          text: "",
          description: "",
          buttons: [],
        },
      },
    };
  }
  if (type === "action") {
    return {
      role: "action",
      block: {},
    };
  }
}

export function capitalize(text) {
  return text[0].toUpperCase() + text.slice(1);
}

// узнать максимальное значения id
export function counterInitialized(array) {
  let maxId = 1;
  if (!array) return maxId;

  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    const numID = Number(element.id);
    if (numID >= maxId) {
      maxId = numID + 1;
    }
  }
  return maxId;
}

// базовые значение
export const nodesDefault = () => [
  {
    id: "1",
    type: "Start",
    data: { label: "Start" },
    position: { x: 30, y: 30 },
    draggable: false,
  },
  {
    id: "2",
    position: {
      x: 218.13626226444808,
      y: 115.80778828563196,
    },
    data: {
      role: "message",
      block: {
        elements: [
          {
            id: 1,
            type: "message text",
            textValue: "Зазвичай, ми відповідаємо впродовж одного дня.",
            buttons: [],
            maxBtnRow: "1",
          },
        ],
        quickAnswer: {
          type: "quick answer",
          buttons: [],
          maxBtnRow: "1",
        },
      },
    },
    type: "Message",
    positionAbsolute: {
      x: 218.13626226444808,
      y: 115.80778828563196,
    },
  },
];

export const edgesDefault = () => [
  {
    id: "StartConnection",
    source: "1",
    target: "2",
    type: "EdgeLine",
    sourceHandle: "output-0",
  },
];
//

//сохраняет данные
export function createDiagram(edges, nodes) {
  // делаю глубокое копирования
  const edg = JSON.parse(JSON.stringify(edges));
  const nds = JSON.parse(JSON.stringify(nodes));

  const newArray = [];
  for (let i = 0; i < nds.length; i++) {
    const elem = nds[i];

    // скелет каждого блока
    const newObj = {
      type: elem.type.toLowerCase(),
      id: elem.id,
      continue: isContinue(elem.id, edg),
    };

    if (elem.type === "Message") {
      //Двойная итерация для того чтобы добрать до кнопок и передать свойство continue
      const arrElements = [...elem.data.block.elements];
      for (let i = 0; i < arrElements.length; i++) {
        const element = arrElements[i];

        // если тип пауза который не имеет кнопок то пропускаем
        if (element.type !== "message pause") {
          const buttons = element.buttons;
          for (let i = 0; i < buttons.length; i++) {
            const button = buttons[i];
            button.continue = isContinue(
              elem.id,
              edg,
              `button-${element.id}-${button.id}`
            );
          }
        }
      }
      newObj.elements = arrElements;
    }
    if (elem.type === "Chain") {
      newObj["chain_id"] = elem.data.block.select ? elem.data.block.select : "";
    }
    if (elem.type === "Filter") {
      const filterObj = { ...elem.data.block };
      for (let i = 0; i < filterObj.conditions.length; i++) {
        const condition = filterObj.conditions[i];
        condition.continue = isContinue(elem.id, edg, `filter-${condition.id}`);
      }

      filterObj.denial.continue = isContinue(elem.id, edg, "filter-0");
      newObj.conditions = filterObj.conditions;
      newObj.denial = filterObj.denial;
    }
    if (elem.type === "Pause") {
      newObj.pause = elem.data.block.pause;
    }
    if (elem.type === "Api") {
      newObj.error = isContinue(elem.id, edg, `api-error-${elem.id}`);
      newObj.elements = [{ ...elem.data.block }];
    }
    if (elem.type === "Action") {
      newObj.elements = [{ ...elem.data.block }];
    }

    newArray.push(newObj);
  }
  return newArray;
}

function isContinue(id, edg, keyWord = "output") {
  let continueArr = "0";

  // проходит по всем св'язям, ищет который подходит по двум параметрам и возвращает ид target блока
  for (let i = 0; i < edg.length; i++) {
    const elem = edg[i];
    if (elem.source === id && elem.sourceHandle.includes(keyWord)) {
      continueArr = elem.target;
    }
  }
  return continueArr;
}
