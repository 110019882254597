import React, { useState, useEffect } from 'react';
import { useStoreApi } from 'reactflow';

import styles from './SubCondition.module.css';

const equalData = {
  part_chain: {
    label: ['Участвовал', 'Не участвовал'],
    value: ['yes', 'not'],
  },
  subscribe_date: {
    label: ['Равно', 'До', 'После'],
    value: ['equally', 'smaller', 'bigger'],
  },
  received_spam: {
    label: ['Получал', 'Не получал'],
    value: ['yes', 'not'],
  },
  channel_subscribe: {
    label: ['Да', 'Нет'],
    value: ['yes', 'not'],
  },
};

const typeData = {
  part_chain: 'Участие в цепочке',
  received_spam: 'Получал рассылку',
  subscribe_date: 'Дата подписки',
  channel_subscribe: 'Участник канала или группы',
};

function SubCondition({ data, line }) {
  const [listData, setListData] = useState(false);

  const store = useStoreApi();
  const { apiInfo } = store.getState();

  function bifurcation(array) {
    const label = [];
    const value = [];
    if (array) {
      if (array[0]) {
        for (let i = 0; i < array.length; i++) {
          const element = array[i];
          label.push(element.name);
          value.push(element.id);
        }
      }
    }
  
    return { label, value };
  }

  useEffect(() => {
    if (apiInfo) {
      const objList = {
        part_chain: bifurcation(apiInfo?.diaglogs),
        received_spam: bifurcation(apiInfo?.spams),
        channel_subscribe: bifurcation(apiInfo?.channel),
      };
      setListData(objList);
    }
  }, [apiInfo]);
  console.log('hello world', apiInfo);

  function getTypeLabel(name) {
    return typeData[name];
  }
  function getCondition(valueKey, name, dataValue) {
    if (name === 'subscribe_date' && dataValue === listData) {
      return valueKey;
    }
    let index = -1;
    for (let i = 0; i < dataValue[name].value.length; i++) {
      const element = dataValue[name].value[i];
      if (element === valueKey) {
        index = i;
        break;
      }
    }
    return dataValue[name].label[index];
  }
  let name;
  let equal;
  let state;
  if (data.value && listData) {
    name = getTypeLabel(data.name);
    equal = getCondition(data.condition, data.name, equalData);
    state = getCondition(data.value, data.name, listData);
  }

  return (
    <div className={` ${styles.content} ${line ? styles.block : ''}`}>
      {data.value && listData ? (
        <>
          <code className={styles.code}>{name}</code>
          <code className={styles.code}>{equal} </code>
          <code className={`${styles.code} ${styles.codeState}`}>{state}</code>
        </>
      ) : null}
      {line && <br />}
    </div>
  );
}

export default SubCondition;
