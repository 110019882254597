import React from 'react';
import './NotFoundPage.css'
import errorImage from './assets/robot-404-error-errors.svg'

const NotFoundPage = () => {
  return (
    <div className='container not-found-page'>
      <h1>404 - Страница не найдена</h1>
      <p>Извините, запрошенная страница не существует.</p>
      <img src={errorImage}/>
    </div>
  );
};

export default NotFoundPage;