import React, { useState, useEffect } from "react";
import SaveValue from "../SaveValue";

import ButtonActionUI from "../inputUI/ButtonsUI/ButtonActionUI";
import SaveValueButton from "../SaveValueButton";

import styles from "./SendMessageTab.module.css";
import { apiBlockSendMessageInputInitial } from "../../../../config/initialValueApiBlock";

const initialWord = {
  arrayAnchor: "Отправить список сообщений на основе указанного массива:",
  imageUrl: "URL изображения",
  text: "Текст заголовка *",
  description: "Описание",
};

const initialButton = {
  text: "",
  url: "",
};

function SendMessageTab({ dataHandler, json, data }) {
  const [inptValue, setInptValue] = useState(apiBlockSendMessageInputInitial);
  const [buttons, setButtons] = useState([]);

  const [initialiaze, setInitialiaze] = useState(false);

  useEffect(() => {
    if (initialiaze) return;
    if (!data) return;

    const { arrayAnchor, imageUrl, text, description, buttons } = data;

    setInptValue({ arrayAnchor, imageUrl, text, description });
    setButtons(buttons);

    setInitialiaze(true);
  }, [initialiaze]);

  useEffect(() => {
    dataHandler({ ...inptValue, buttons });
  }, [buttons, inptValue]);

  const changeValue = (newValue, key) => {
    const newObj = { ...inptValue };
    newObj[key] = newValue;
    setInptValue(newObj);
  };

  const addButton = () => {
    const newButton = { ...initialButton, id: Date.now() };
    setButtons([...buttons, newButton]);
  };

  const deleteButton = (id) => {
    const newButtons = buttons.filter((btn) => btn.id !== id);
    setButtons(newButtons);
  };

  const changeButton = (id, newValue, key) => {
    const newArrBtns = buttons.map((btn) => {
      if (btn.id === id) {
        return { ...btn, [key]: newValue };
      }
      return btn;
    });
    setButtons(newArrBtns);
  };

  return (
    <>
      <div className={styles.box}>
        {Object.entries(inptValue).map(([key, value], index) => {
          return (
            <SaveValue
              key={key}
              keyValue={key}
              value={value}
              label={initialWord[key]}
              json={json}
              placeholder="$[somekey]"
              changeValue={changeValue}
            />
          );
        })}
        <div>
          {buttons.map((btn) => (
            <SaveValueButton
              data={btn}
              changeBtn={changeButton}
              deleteBtn={deleteButton}
              json={json}
              key={btn.id}
            />
          ))}
        </div>
      </div>
      {buttons.length < 2 && (
        <ButtonActionUI text="+Добавить кнопку" handler={addButton} />
      )}
    </>
  );
}

export default SendMessageTab;
