import React from "react";
import TextUI from "../TextUI";
import styles from "./HeaderInputUI.module.css";
import ButtonDeleteUI from "../ButtonsUI/ButtonDeleteUI";
function HeaderInputUI({ deleteHeader, index, changeHeader, data }) {
  const handler = (text, type) => {
    const newObj = { ...data };
    newObj[type] = text;
    changeHeader(newObj);
  };

  return (
    <div className={styles.box}>
      <TextUI
        text={data.key}
        placeholder="key"
        textHandler={(text) => handler(text, "key")}
      />
      <TextUI
        placeholder="value"
        storeValues={true}
        text={data.value}
        textHandler={(text) => handler(text, "value")}
      />
      <ButtonDeleteUI deleteHandler={() => deleteHeader(index)} />
    </div>
  );
}
export default HeaderInputUI;
