import React, { memo } from 'react';
import styles from './Counter.module.css';
function Counter({ number, status, limit }) {
  return (
    <span
      className={status ? `${styles.counter} ${styles.active}` : styles.counter}
      style={{ color: `${number > limit ? 'red' : '#31c178'}` }}
    >
      {limit - number}
    </span>
  );
}

export default memo(Counter);
