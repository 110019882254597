import React from "react";
import styles from "./SideBar.module.css";

function SideBar({ popupShow }) {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <div className={styles.buttonGroup}>
      {popupShow && <div className={styles.disabledButtons}></div>}
      <button
        className={`${styles.buttonNode} ${styles.message}`}
        onDragStart={(event) => {
          onDragStart(event, "message");
        }}
        draggable>
        Сообщения
      </button>
      <button
        className={`${styles.buttonNode} ${styles.chain}`}
        onDragStart={(event) => onDragStart(event, "chain")}
        draggable>
        Цепочка
      </button>
      <button
        className={`${styles.buttonNode} ${styles.filter}`}
        onDragStart={(event) => onDragStart(event, "filter")}
        draggable>
        Фильтр
      </button>
      <button
        className={`${styles.buttonNode} ${styles.pause}`}
        onDragStart={(event) => onDragStart(event, "pause")}
        draggable>
        Пауза
      </button>
      <button
        className={`${styles.buttonNode} ${styles.captcha}`}
        onDragStart={(event) => onDragStart(event, "captcha")}
        draggable>
        Капча
      </button>
      <button
        className={`${styles.buttonNode} ${styles.api}`}
        onDragStart={(event) => onDragStart(event, "api")}
        draggable>
        Запрос API
      </button>
      <button
        className={`${styles.buttonNode} ${styles.action}`}
        onDragStart={(event) => onDragStart(event, "action")}
        draggable>
        Действие
      </button>
    </div>
  );
}
export default SideBar;
