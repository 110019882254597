import React, { useState, useEffect } from 'react';
import { Handle, Position } from 'reactflow';
import styles from './Message.module.css';
import {
  handleStyleOutput,
  handleStyleInput,
} from '../../flowStyle/styleHandler';
import BlockText from '../blockText';
import BlockImage from '../blockImage';
import BlockFile from '../blockFile';
import BlockPause from '../blockPause';
import QuickAnswer from '../blockQuickAnswer';
import DeleteBlock from '../../deleteBlock';
import CopyBlock from '../../copyBlock';
function Message({ data, selected, id }) {
  const [elements, setElements] = useState(false);
  const [quickAnswer, setQuickAnswer] = useState(false);

  useEffect(() => {
    const { elements, quickAnswer } = data.block;
    setElements(elements);
    setQuickAnswer(quickAnswer);
  }, [data.block.elements, data.block.quickAnswer]);

  function determinate(type) {
    if (type === 'message text') return BlockText;
    if (type === 'message image') return BlockImage;
    if (type === 'message pause') return BlockPause;
    if (type === 'message file') return BlockFile;
    return null;
  }

  return (
    <>
      <Handle
        type="target"
        id={`input-${id}`}
        position={Position.Left}
        style={handleStyleInput}
      />
      <div
        className={
          selected ? `${styles.block} ${styles.blockSelected}` : styles.block
        }
      >
        <div className={styles.title}>Сообщение</div>
        <div className={styles.contentBlock}>
          {elements
            ? elements.map((element) => {
                const BlockType = determinate(element.type);
                return (
                  <BlockType idNode={id} data={element} key={element.id} />
                );
              })
            : null}

          {quickAnswer && (
            <div className={styles.contentQuickAnswer}>
              {quickAnswer.buttons.map((button) => (
                <QuickAnswer
                  text={button.textValue}
                  key={button.id}
                  id={`'Node':${id} 'quickAnswer':${button.id}`}
                />
              ))}
            </div>
          )}
          <div className={styles.continue}>
            <span>Продолжить</span>
          </div>
        </div>
      </div>
      {selected && <DeleteBlock id={id} />}
      {selected && <CopyBlock id={id} />}

      <Handle
        type="source"
        position={Position.Right}
        id={`output-${id}`}
        style={handleStyleOutput}
      />
    </>
  );
}

export default Message;
