export const apiBlockSendMessageInputInitial = {
  arrayAnchor: "",
  imageUrl: "",
  text: "",
  description: "",
};

export const apiBlockSendMessageButtonsInitial = {
  buttons: [],
};

export const apiBlockSendMessageInitial = {
  ...apiBlockSendMessageInputInitial,
  ...apiBlockSendMessageButtonsInitial,
};

const apiBlockInitial = {
  requestType: "get",
  reguestUrl: "",
  headers: [],
  body: "",
  storeValues: [],
  sendMessage: apiBlockSendMessageInitial,
};
