import React from "react";
import styles from "./SelectUI.module.css";

function SelectUI({ options, handler, fullWidth, initialValue, placeholder }) {
  return (
    <select
      className={`${styles.select} ${fullWidth ? styles.fullWidth : ""}`}
      onChange={(e) => handler(e.target.value)}>
      {placeholder && (
        <option value="" disabled selected={initialValue ? false : true}>
          {placeholder}
        </option>
      )}
      {options.map((opt, i) => (
        <option key={i} value={opt.value} selected={initialValue === opt.value}>
          {opt.label}
        </option>
      ))}
    </select>
  );
}

export default SelectUI;
