const styleColor = {
  backgroundColor: '#009fc1',
  width: 20,
  height: 20,
  borderWidth: 5,
  zIndex: '1',
  boxShadow: '0 2px 6px rgba(0,0,0,.15)',
};

export const handleStyleOutput = {
  ...styleColor,
  bottom: -5,
  right: -10,
  top: 'auto',
};
export const handleStyleInput = {
  ...styleColor,
  top: 17,
  left: -10,
};

export const handleStyleOutputButton = {
  ...styleColor,
  right: 5,
};

export const handleStyleOutputFilter = {
  ...styleColor,
  top: '13px',
  left: 'auto',
  right: '5px',
  backgroundColor: '#31c178',
};

export const handleStyleOutputFilterNot = {
  ...handleStyleOutputFilter,
  backgroundColor: '#d4696a',
};

export const start = {
  ...handleStyleOutput,
  right: '10px',
  top: '15px',
};

export const handleStyleOutputApiBlock = {
  ...styleColor,
  right: '10px',
  bottom: '29px',
  left: 'auto',
  top: 'auto',
  backgroundColor: '#00b175'
}
export const handleStyleOutputErrorApiBlock = {
  ...styleColor,
  right: '10px',
  bottom: '-1px',
  left: 'auto',
  top: 'auto',
  backgroundColor: '#d94b4d'
}