import React, { useState, useEffect, useRef } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import Counter from "../counter";
import { limitText } from "../../../../valid/limitText";

import styles from "./TextDescription.module.css";
import StoreValues from "../../StoreValues";
import { insertText } from "../../../../../utils/insertText";

function TextDescription({ textData, limit, initTextData, placeholder, sx }) {
  const [txtAreaStatus, setTxtAreaStatus] = useState(false);
  const [popup, setPopup] = useState(false);
  const [text, setText] = useState("");
  const [isInitialized, setIsInitialized] = useState(false);

  const textAreaRef = useRef(null);
  const dropDownRef = useDetectClickOutside({
    onTriggered: () => {
      setPopup(false);
    },
  });

  //Подписка на начальные данные
  useEffect(() => {
    if (!isInitialized && initTextData) {
      setText(initTextData);
      setIsInitialized(true);
    }
  }, [initTextData, isInitialized]);

  useEffect(() => {
    textData(text);
  }, [text]);

  const writeText = (e) => {
    const txt = limitText(e, limit);
    if (e.nativeEvent.data === "'") {
      setText((prevText) => prevText + "'");
    } else {
      setText(txt);
    }
  };

  const getSelectIndex = () => {
    const txt = textAreaRef.current;
    return { start: txt.selectionStart, end: txt.selectionEnd };
  };

  const dropDownHandle = (value) => {
    // вставить значения у выделенный текст
    const { start, end } = getSelectIndex();
    const newText = insertText(text, value, start, end);

    setText(newText);
    setPopup(false);

    // // для чего это нужно ?
    // const newPostion = start + value.length;
    // textareaRef.current.selectionStart = newPostion;
    // textareaRef.current.selectionEnd = newPostion;
  };
  const popupToggle = () => setPopup(!popup);

  return (
    <>
      {Number.isInteger(limit) && (
        <Counter number={text.length} status={txtAreaStatus} limit={limit} />
      )}
      <StoreValues
        dropDownRef={dropDownRef}
        dropDownHandle={dropDownHandle}
        popupStatus={popup}
        popupToggle={popupToggle}
      />
      <textarea
        style={sx}
        ref={textAreaRef}
        className={styles.textArea}
        onChange={writeText}
        value={text}
        // style={{ borderColor: `${text.length > limit ? 'red' : '#31c178'}` }}
        placeholder={placeholder ? placeholder : "Введите текст сообщения"}
        onFocus={() => setTxtAreaStatus(true)}
        onBlur={() => setTxtAreaStatus(false)}></textarea>
    </>
  );
}

export default TextDescription;
