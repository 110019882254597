import React from "react";
import styles from "./StoreAutoComplete.module.css";

function StoreAutoComplete({
  dropDownHandle,
  typeHandler,
  initialValue,
  text,
  size,
}) {
  return (
    <ul
      className={`${styles.dropDown} ${size === "small" ? styles.small : ""}`}>
      {text && <span className={styles.dropDownTitle}>{text}</span>}
      {initialValue.map((v, i) => {
        return (
          <li
            key={i}
            className={styles.dropDownElement}
            onClick={() => {
              dropDownHandle(v.action);
              if (typeHandler) {
                typeHandler(v.type);
              }
            }}>
            <span className={styles.label}>{v.label}</span>
            {v.type && <span className={styles.type}>{v.type}</span>}
          </li>
        );
      })}
    </ul>
  );
}

export default StoreAutoComplete;
