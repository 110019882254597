import React, { useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";

import styles from "./SelectValueUI.module.css";
import StoreAutoComplete from "../../StoreAutoComplete";
import { useStore } from "reactflow";
import { variablesForSelect } from "../../../../../utils/variablesForSelect";

function SelectValueUI({ handler, value, typeHandler }) {
  const { apiInfo } = useStore();
  const values = variablesForSelect(apiInfo.variables);

  const [popup, setPopup] = useState(false);
  const dropDownRef = useDetectClickOutside({
    onTriggered: () => {
      setPopup(false);
    },
  });

  const popupToggle = () => {
    setPopup(!popup);
  };

  const dropDownHandle = (newValue) => {
    handler(newValue);
    setPopup(false);
  };

  return (
    <div
      className={`${styles.box} ${popup ? styles.focus : ""}`}
      ref={dropDownRef}>
      <span className={styles.content} onClick={popupToggle}>
        <span className={!value ? styles.muted : ""}>
          {value || "Выберите переменную"}
        </span>
        <i className={styles.caret}></i>
      </span>
      {popup && (
        <StoreAutoComplete
          size="small"
          dropDownHandle={dropDownHandle}
          typeHandler={typeHandler}
          initialValue={values}
          text={""}
        />
      )}
    </div>
  );
}

export default SelectValueUI;
