
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../../../../../../index.css';
import './PeriodFilter.css';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import refreshToken from '../../../../../../../refreshToken';
import adapter from '../../../../../../../adapter';
import { toast } from 'react-toastify';


const PeriodFilter = ({setStatsDate, setShowStats}) => {
    const storedData = JSON.parse(localStorage.getItem('dashboardData'));
    const dashboardData = storedData ? storedData: null;
    const [botsStat, setBotsStat] = useState(null);
    const[startDate, setStartDate] = useState('');
    const[endDate, setEndDate] = useState('');

    const handleStartDateChange = (date) => {
        setStartDate(date);
        setFilterDate({
            ...filterDate,
            from: date
        });;
      };
    
    const handleEndDateChange = (date) => {
        setEndDate(date);
        setFilterDate({
            ...filterDate,
            to: date
        });
    };

    const [periodSelect, setPeriodSelect] = useState(false);
    const [betweenDate, setBetweenDate] = useState(false);
    const [selectedPeriod, setSelectedPeriod] = useState(null);

    const [filterDate, setFilterDate] = useState({
        from: null,
        to: null
    });
    const handlePeriodDateSelect = (period) => {
        setSelectedPeriod(period);
        const currentDate = new Date();
        let lastDay;
        lastDay = new Date(currentDate);
        lastDay.setDate(currentDate.getDate() - 1);
        switch (period) {
            case 'today':
                if (betweenDate) {
                    setBetweenDate(false);
                }
                setFilterDate({
                    ...filterDate,
                    from: lastDay,
                    to: currentDate
                });
                break;
    
            case 'tomorrow':
                if (betweenDate) {
                    setBetweenDate(false);
                }
                lastDay = new Date(currentDate);
                lastDay.setDate(currentDate.getDate() + 1);
                setFilterDate({
                    ...filterDate,
                    from: currentDate,
                    to: lastDay
                });
                break;
    
            case 'lastSeven':
                if (betweenDate) {
                    setBetweenDate(false);
                }
                lastDay = new Date(currentDate);
                lastDay.setDate(currentDate.getDate() - 7);
                setFilterDate({
                    ...filterDate,
                    from: lastDay,
                    to: currentDate
                });
                break;
    
            case 'lastThirty':
                if (betweenDate) {
                    setBetweenDate(false);
                }
                lastDay = new Date(currentDate);
                lastDay.setDate(currentDate.getDate() - 30);
                setFilterDate({
                    ...filterDate,
                    from: lastDay,
                    to: currentDate
                });
                break;
    
            case 'lastMonth':
                if (betweenDate) {
                    setBetweenDate(false);
                }
                const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    
                setFilterDate({
                    from: firstDayOfCurrentMonth,
                    to: currentDate
                });
                break;
    
            case 'betweenDate':
                setBetweenDate(true);
                break;
    
            default:
                break;
        }
    };

    

    const handleCancelFilter = () =>{
        setPeriodSelect(false);
        setBetweenDate(false);
        setSelectedPeriod(null);
        setStartDate('');
        setEndDate('');
        setShowStats(false);
    }
    const handleConfirmFilter = () =>{
        if (!selectedPeriod) {
            toast.error('Выберете один из способов фильтрации!');
            return;
        }
        setStatsDate(filterDate);
        setShowStats(true);
    }


    return(
            <div className='form-elem period-filter-container'>
                    {!periodSelect && (
                        <div className='form-elem filter-check'>
                            <button className='period-filter-button' type='button' onClick={() => setPeriodSelect(true)}>Выбрать период</button>
                            <p>Выберите период для более подробного отображения статистики!</p>
                        </div>
                        )
                    }
                <div className='form-elem'>
                    {periodSelect && (
                        <>
                            <div className='period-btns'>
                                <button className='period-filter-button' type='button' 
                                onClick={() => handlePeriodDateSelect('today')}
                                disabled={selectedPeriod === 'today'}
                                >Сегодня</button>
                                <button className='period-filter-button' type='button' onClick={() => handlePeriodDateSelect('tomorrow')}
                                disabled={selectedPeriod === 'tomorrow'}
                                >Вчера</button>
                                <button className='period-filter-button' type='button' onClick={() => handlePeriodDateSelect('lastSeven')}
                                disabled={selectedPeriod === 'lastSeven'}
                                >Последние 7 дней</button>
                                <button className='period-filter-button' type='button' onClick={() => handlePeriodDateSelect('lastThirty')}
                                disabled={selectedPeriod === 'lastThirty'}
                                >Последние 30 дней</button>
                                <button className='period-filter-button' type='button' onClick={() => handlePeriodDateSelect('lastMonth')}
                                disabled={selectedPeriod === 'lastMonth'}
                                >Последний месяц</button>
                                <button className='period-filter-button' type='button' onClick={() => handlePeriodDateSelect('betweenDate')}
                                disabled={selectedPeriod === 'betweenDate'}
                                >Промежуток времени</button>
                                {betweenDate && (
                                    <div>
                                        <p><small>Выберите период:</small></p>
                                        <div className="between-date-container">
                                            <div className="react-datepicker-wrapper">
                                                <div className="react-datepicker__input-container">
                                                <span
                                                    role="alert"
                                                    aria-live="polite"
                                                    className="react-datepicker__aria-live"
                                                ></span>
                                                <DatePicker
                                                    dateFormat="yyyy/MM/dd"
                                                    className="datepicker-input"
                                                    placeholderText='От:'
                                                    onChange={handleStartDateChange}
                                                    selected={startDate}
                                                    locale={ru}
                                                
                                                />
                                                </div>
                                            </div>
                                        <div>
                                            <DatePicker
                                                dateFormat="yyyy/MM/dd"
                                                className="datepicker-input"
                                                placeholderText='До:'
                                                onChange={handleEndDateChange}
                                                selected={endDate}
                                                locale={ru}
                                            />
                                        </div>
                                        </div>
                                    </div>
                                    
                                )}
                            </div>
                            <div className='filter-control-btns'>
                                <button className='period-filter-button' type='button' onClick={() => handleConfirmFilter() }
                                
                                >Показать</button>
                                <button className='period-filter-button' type='button' onClick={() => handleCancelFilter()}>Отменить</button>
                            </div>
                            
                        
                     </>
                    )
                        
                    }
                </div>
            </div>
    );

}
    
    
    
    
export default PeriodFilter;