import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../../../../../index.css';
import './Auditory.css';
import refreshToken from '../../../../../../refreshToken';
import adapter from '../../../../../../adapter';
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});

const Auditory = ({selectedBotId}) => {
    const storedData = JSON.parse(localStorage.getItem('dashboardData'));
    const dashboardData = storedData ? storedData: null;

    const [leadsData, setLeadsData] = useState('');
    const navigate = useNavigate();

    const getLeads = async () => {
    try {
        const data = {
            route : 'getLeads',
            user_tg_id: dashboardData.user['telegram_id'],
            bot_id:selectedBotId,
            access_token:dashboardData.token
        };
        const response = await adapter.post('', data);
        if (response.data.result === 'success') {
            setLeadsData(response.data.array);
        }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
        try {
            const refreshTokenResult = await refreshToken();
        
            if (refreshTokenResult === 'logout') {
            navigate('/', { replace: true });
            }else{
            dashboardData.token = refreshTokenResult;
            const data = {
                route : 'getLeads',
                user_tg_id: dashboardData.user['telegram_id'],
                bot_id:selectedBotId,
                access_token:dashboardData.token
            };
            const response = await adapter.post('', data);
            
            if (response.data.result === 'success') {
                setLeadsData(response.data.array);
            }
            else {
                console.log(response.data);
            }
            }
        
        } catch (error) {
            console.error(error);
        }
        }else {
        console.log('Произошла ошибка: ' + response.data.text);
        }    
    } catch (error) {
        rollbar.error('Error during request:', error);
    }
    }
   

    useEffect(() => {
        getLeads();
    },[])

    return(
        <div className='table-body'>
        <table className="auditory-table">
            <thead>
                <tr className='auditory-table_header'>
                    <th>Данные пользователя</th>
                    <th>Роль</th>
                    <th>Последняя активность</th>
                    <th>Статус</th>
                </tr>
            </thead>
            <tbody>
                
                {leadsData ? (
                    leadsData.map((leads, index) => (
                        <tr key={index} className="auditory-table_row">
                            <td>
                                <div className="icon"></div>
                                <div>
                                    <strong>
                                        {leads.name}
                                    </strong>
                                    <p>@{leads.username}</p>
                                    <small>{leads.date}</small>
                                </div>
                            </td>
                            <td>
                                {leads.role}
                            </td>
                            <td>
                                {leads.date_active}
                            </td>
                            <td>
                                {leads.status}
                            </td>
                        </tr>
                        ))
                ) : (
                    <tr>
                        <td colSpan={6} className="auditory-table_row">У этого бота нет аудитории !</td>
                    </tr>
                   
                )}
            </tbody>
        </table>
        </div>
    );

}
     
    
export default Auditory;