import React from 'react';
import { Handle, Position } from 'reactflow';
import { handleStyleOutput, handleStyleInput } from '../flowStyle/styleHandler';
import styles from './CaptchaBlock.module.css';
import { BsRobot } from 'react-icons/bs';
import DeleteBlock from '../deleteBlock';
import CopyBlock from '../copyBlock';

function Captcha({ data, selected, id }) {
  return (
    <>
      <Handle
        type="target"
        id={`input-${id}`}
        position={Position.Left}
        style={handleStyleInput}
      />
      <div
        className={
          selected ? `${styles.block} ${styles.blockSelected}` : styles.block
        }
      >
        <div className={styles.title}>Капча</div>
        <div className={styles.contentBlock}>
          <BsRobot className={styles.robot} />
          <div className={styles.description}>
            Проверить что пользователь не бот
          </div>
        </div>
      </div>
      {selected && <DeleteBlock id={id} />}
      {selected && <CopyBlock id={id} />}

      <Handle
        type="source"
        position={Position.Right}
        id={`output-${id}`}
        style={handleStyleOutput}
      />
    </>
  );
}

export default Captcha;
