import React, { useEffect, useState } from "react";
import TypeText from "../../UI/MessageTypeText/typeText";
import TypePhoto from "../../UI/MessageTypePhoto/typePhoto";
import styles from "./MessageContent.module.css";
import TypeFile from "../../UI/MessageTypeFile/typeFile";
import MessageAddType from "../../UI/MessageAddType";
// import QuickAnswerAdd from '../popupUI/QuickAnswer/quickAnswerAdd';
// import QuickAnswerButtons from '../popupUI/QuickAnswer/quickAnswerButtons';
import TypePause from "../../UI/MessageTypePause/typePause";
import { counterInitialized } from "../../../functionForBlock";
function MessageContent({ addData, nodeID, nodes }) {
  const [data, setData] = useState(null);
  // const [quickAnswerData, setQuickAnswerData] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [counterElements, setCounterElements] = useState(null);
  // const [counterQuickAns, setCounterQuickAns] = useState(null);

  useEffect(() => {
    if (data && isInitialized) {
      addData(data, "elements", nodeID);
      // addData(quickAnswerData, 'quickAnswer', nodeID);
    }
  }, [data /*quickAnswerData*/]);

  useEffect(() => {
    setIsInitialized(false);
  }, [nodeID]);

  //иннициализация
  useEffect(() => {
    const dataNode = nodes.data.block;
    if (dataNode && !isInitialized) {
      // если данные есть
      setIsInitialized(true);
      setData(dataNode.elements);
      // setQuickAnswerData(dataNode.quickAnswer);
    }
  }, [nodes.data.block, isInitialized]);

  useEffect(() => {
    if (data && isInitialized && !counterElements) {
      const maxIDElement = counterInitialized(data);
      setCounterElements(maxIDElement);
      // const maxIDQuickAns = counterInitialized(quickAnswerData.buttons);
      // setCounterQuickAns(maxIDQuickAns);
    }
  }, [data]);

  // изменить элемент
  function blockElementHandler(dataBlock, index) {
    if (data[0]) {
      setData((previous) => {
        previous[index] = dataBlock;
        return [...previous];
      });
    }
  }

  // добавить елемент
  function blockElementAdd(elementObj) {
    setData((previous) => {
      //добавляем ид

      const addIdObject = { ...elementObj, id: counterElements };
      return [...previous, addIdObject];
    });
    setCounterElements(counterElements + 1);
  }

  // передвинуть елемент
  function moveBlock(index, direction) {
    const itemsArray = [...data];
    const targetIndex = index + direction;
    // проверка границ массива
    if (targetIndex < 0 || targetIndex > itemsArray.length - 1) return;

    // меняем местами элементы
    const temp = itemsArray[targetIndex];
    itemsArray[targetIndex] = itemsArray[index];
    itemsArray[index] = temp;

    setData(itemsArray);
  }

  // удалить елемент
  function deleteBlock(index) {
    setData((previous) => {
      const newArray = previous.filter((_, i) => i !== index);
      return [...newArray];
    });
  }

  // определить тип компонента
  function blockDeterminant(type) {
    if (type === "message text") {
      return TypeText;
    }
    if (type === "message image") {
      return TypePhoto;
    }
    if (type === "message file") {
      return TypeFile;
    }
    if (type === "message pause") {
      return TypePause;
    }
    return null;
  }
  // // добавить быстрый ответ
  // function addQuickAnswer(obj) {
  //   const addID = { ...obj, id: counterQuickAns };
  //   setQuickAnswerData((previous) => {
  //     // раскрываем массив и добавляем новый объект
  //     const newArrayButtons = [...previous.buttons, addID];

  //     // обновляем свойсвто buttons
  //     return { ...previous, buttons: newArrayButtons };
  //   });
  //   setCounterQuickAns(counterQuickAns + 1);
  // }

  // // удалить быстрый ответ
  // function deleteQuickAnswer(index) {
  //   setQuickAnswerData((previous) => {
  //     const newArrayButtons = previous.buttons.filter((_, i) => i !== index);
  //     return { ...previous, buttons: newArrayButtons };
  //   });
  // }

  // // количество кнопок в строку
  // function selectHandler(value) {
  //   setQuickAnswerData((previous) => {
  //     const newSelect = { ...previous, maxBtnRow: value };
  //     return { ...newSelect };
  //   });
  // }

  return (
    <>
      {data &&
        data.map((blockElement, index) => {
          const BlockType = blockDeterminant(blockElement.type);
          if (!BlockType) {
            return <p>error</p>;
          }
          return (
            <BlockType
              typeContentInit={blockElement}
              blockElementHandler={blockElementHandler}
              key={blockElement.id}
              moveUp={() => moveBlock(index, -1)}
              moveDown={() => moveBlock(index, 1)}
              deleteBlock={() => deleteBlock(index)}
              index={index}
              length={data.length}
            />
          );
        })}

      {/* {quickAnswerData && (
        <QuickAnswerButtons
          select={quickAnswerData.maxBtnRow}
          selectHandler={selectHandler}
          buttons={quickAnswerData.buttons}
          deleteQuickAnswer={deleteQuickAnswer}
        />
      )} */}

      <div className={styles.addBlock}>
        <div>
          {data?.length < 11 && (
            <MessageAddType blockElementAdd={blockElementAdd} />
          )}
        </div>
        {/* <QuickAnswerAdd
          btnLength={quickAnswerData?.buttons?.length}
          addQuickAnswer={addQuickAnswer}
        /> */}
      </div>
    </>
  );
}

export default MessageContent;
