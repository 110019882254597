import React from "react";
import { Handle, Position } from "reactflow";
import {
  handleStyleInput,
  handleStyleOutput,
  handleStyleOutputApiBlock,
  handleStyleOutputErrorApiBlock,
  handleStyleOutputFilterNot,
} from "../flowStyle/styleHandler";

import styles from "./Chain.module.css";
import DeleteBlock from "../deleteBlock";
import CopyBlock from "../copyBlock";
import BlockButton from "../messageBlock/blockButton";
function ApiBlock({ data, selected, id }) {
  const block = data.block;
  console.log(block);
  return (
    <>
      <Handle
        type="target"
        id={`input-${id}`}
        position={Position.Left}
        style={handleStyleInput}
      />
      <div
        className={
          selected ? `${styles.block} ${styles.blockSelected}` : styles.block
        }
      >
        <div className={styles.title}>Запрос API</div>

        <div className={styles.contentBlock}>Получить данные из URL</div>
        {block.requestUrl && (
          <p className={styles.subtitle}>{block.requestUrl}</p>
        )}
        {block.sendMessage.arrayAnchor && (
          <div className={styles.dataBox}>
            {block.sendMessage.imageUrl && (
              <div className={styles.imageBox}>
                {block.sendMessage.imageUrl}
              </div>
            )}
            <div className={styles.textBox}>
              {block.sendMessage.text && (
                <p className={styles.text}>{block.sendMessage.text}</p>
              )}
              {block.sendMessage.description && (
                <p className={styles.description}>
                  {block.sendMessage.description}
                </p>
              )}
              {block.sendMessage.buttons.map((btn) => (
                <BlockButton
                  key={btn.id}
                  id={999}
                  text={btn.url || btn.text}
                  type={"link"}
                />
              ))}
            </div>
          </div>
        )}
        {!!block.storeValues.length && (
          <div className={styles.storeValuesBox}>
            {block.storeValues.map((val) => (
              <div key={val.id} className={styles.storeValuesBox}>
                {val.apiPath && (
                  <code
                    style={!val.value ? { borderRadius: "6px" } : {}}
                    className={`${styles.storeValue} ${styles.valuePath}`}
                  >
                    {val.apiPath}
                  </code>
                )}
                {val.value && (
                  <code
                    style={!val.apiPath ? { borderRadius: "6px" } : {}}
                    className={`${styles.storeValue} ${styles.valueConstant}`}
                  >
                    {val.value}
                  </code>
                )}
              </div>
            ))}
          </div>
        )}
        {!!block.reguestUrl && (
          <div className={styles.outputBox}>
            <p className={styles.output} style={{ color: "#00b175" }}>
              Выполнено
            </p>
            <p className={styles.output} style={{ color: "#91a4a5" }}>
              Ошибка
            </p>
          </div>
        )}
      </div>
      {!!block.reguestUrl && (
        <>
          <Handle
            type="source"
            id={`output-${id}`}
            position={Position.Right}
            style={handleStyleOutputApiBlock}
          />
          <Handle
            type="source"
            id={`api-error-${id}`}
            position={Position.Right}
            style={handleStyleOutputErrorApiBlock}
          />
        </>
      )}
      {selected && <DeleteBlock id={id} />}
      {selected && <CopyBlock id={id} />}
    </>
  );
}

export default ApiBlock;
