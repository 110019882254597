import adapter from './adapter';
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});
const refreshToken = async () => {
  const storedData = JSON.parse(localStorage.getItem('dashboardData'));
  const dashboardData = storedData ? storedData: null;
  
  try {
    const refreshData = {
      user_id: dashboardData.user['telegram_id'],
      refresh_token: dashboardData.refresh,
      ip: localStorage.ip,
      route: 'getAccessToken'
    };
    const refreshResponse = await adapter.post('', refreshData);
    if (refreshResponse.data.result === 'success') {
      const accessTokenNew = refreshResponse.data.token;
      localStorage.setItem('dashboardData', JSON.stringify({ ...dashboardData, token: accessTokenNew }));
      console.log('Токен обновлён');
      return accessTokenNew;
    } else {
      localStorage.setItem('authenticated', false);
      return 'logout';
    }
  } catch (error) {
    console.error(error);
    rollbar.error('Error during request:', error);
  }
};

export default refreshToken;