import React, { useEffect, useState } from "react";
import styles from "./Giveaway.module.css";
function Giveaway({ data, handler }) {
  const [radio, setRadio] = useState("");
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (data.value && !init) {
      setRadio(data.value);
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      handler({ ...data, ...(radio && { value: radio }) });
    }
  }, [radio, init]);

  return (
    <div className={styles.box}>
      <div
        onClick={() => setRadio("add")}
        className={`${styles.btn} ${
          radio === "add" ? styles.add : styles.unactive
        }`}>
        Добавить
      </div>
      <div
        onClick={() => setRadio("remove")}
        className={`${styles.btn} ${
          radio === "remove" ? styles.delete : styles.unactive
        }`}>
        Удалить
      </div>
    </div>
  );
}

export default Giveaway;
