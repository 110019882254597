import React from 'react';
import {
  getBezierPath,
  useStore,
  useReactFlow,
  EdgeLabelRenderer,
} from 'reactflow';
import styles from './EdgeLine.module.css';

const EdgeLine = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  selected,
}) => {
  const edgePath = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const { edges } = useStore();
  const { setEdges } = useReactFlow();

  function deleteEdge(event) {
    event.stopPropagation();
    const newArray = edges.filter((edg) => edg.id !== id);
    setEdges(newArray);
  }

  const edgeCenterX = (sourceX + targetX) / 2;
  const edgeCenterY = (sourceY + targetY) / 2;
  return (
    <>
      <path
        id={id}
        style={style}
        stroke="transparent"
        fill="none"
        d={edgePath[0]}
        strokeWidth={20}
      />
      <path
        fill="none"
        stroke="rgb(24, 108, 217)"
        strokeWidth={2}
        d={edgePath[0]}
      />

      {selected && id !== 'StartConnection' && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${edgeCenterX}px,${edgeCenterY}px)`,
              fontSize: 12,
              pointerEvents: 'all',
            }}
          >
            <div className={styles.edgebutton} onClick={deleteEdge}>
              <span className={styles.crossButton}>×</span>
            </div>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export default EdgeLine;
