import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../../../index.css';
import './BackupWindow.css';
import FlowDiagram from '../flowDiagram/FlowDiagram';
import { AppContext } from '../../../../../../App'; 

// import refreshToken from '../../../../../refreshToken';
// import adapter from '../../../../../adapter';

const BackupWindow = ({ currBotID }) => {
    const storedData = JSON.parse(localStorage.getItem('dashboardData'));
    const dashboardData = storedData ? storedData: null;

    const [lock, setLock] = useState(true);
    const [data, setData] = useState({
        edges: [],
        nodes: [],
        diagram: []
      });
    const [botId, setBotId] = useState(null);
    const [botName, setBotName] = useState(null);
    const [chainDateSave, setChainDateSave] = useState('');
    const [chainId, setChainId] = useState('');
    const [selectedChainId, setSelectedChainId] = useState('');
    const auth = {
        access_token: dashboardData.token,
        user_tg_id: dashboardData.user['telegram_id'],
        tg_id: dashboardData.user['id'],
        bot_id: botId
      };
    const restoredData = JSON.parse(localStorage.getItem('savedData')) || [];

    const currData = restoredData.filter(bot => bot.botId === currBotID);
  

    const handleSelectChain = (chain, chainId) => {
        setBotId(chain.botId);
        setChainDateSave(chain.timestamp);
       
        setData({
            edges: chain.edges,
            nodes: chain.nodes,
            diagram: chain.diagram
          });
        setChainId(chainId);
        setSelectedChainId(chainId);
        setBotId(chain.botId);
        setBotName(chain.botName);
    } 

    const { restoreChain } = useContext(AppContext);
    const handleRestoreChain = () => {
        restoreChain(botId, botName, data);
    };


    return(
        <>
        
            <div className='card'>
                {currData.length !== 0 ?  
                <p className='restored-data-title'>Выберите подходящие данные по дате:</p>
                : null }
                <div id='chainsFrame' className='table-body'>
                    <p>Выберите подходящие данные по дате:</p>
                    {currData.length !== 0 ? currData.map((item, index) => (
                 
                        <button key={index + 1} class={`template-dilogs-btn ${selectedChainId === index + 1 ? 'template-dilogs-btn__active' : ''}`} onClick={() => handleSelectChain(item, index + 1)}
                        dataindex={index + 1}>{item.autosave? 'Автосохранение' : 'Цепочка'} от: <br></br>{item.timestamp}</button>
                    )):(
                        <>
                        Нет данных для восстановления!
                        </>
                    )}
                </div>
            
            </div>
           
            <div id="diagram" className='card'>
            {selectedChainId  ? (
                  <>
                <div class="render-content-title">
                    <p>Цепочка от: {chainDateSave}</p>
                    <button class="btn_editchain" onClick={handleRestoreChain}>Восстановить цепочку</button>
                </div>
                
                <div class="form-elem date-create-and-update"><small>Выберите нужную цепочку и нажмите восстановить</small></div>
                    
                            
                <FlowDiagram
                key={chainId} 
                auth={auth}
                data={data}
                lock={lock}
                // onDeleteChain={onDeleteChain}
                // onDeleteStatus={deleteChainResponse}
                />
               </>   
                 ): (
                    <>
                      
                        {currData.length === 0 ? (<p>В этой сессии не было сохраний цепочек для данного бота!</p>) : (<p>Выберите цепочку для восстановления!</p>)}

                    </>
                  
                 )
                 } 
                    
                </div>
        </>
    );

}
     
    
export default BackupWindow;