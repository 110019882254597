import axios from "axios";
const https = require('https');


const agent = new https.Agent();
// rejectUnauthorized: false
const adapter = axios.create({ 
    baseURL: 'https://api.bot4crypto.com/',
    responseType: 'json',
    withCredentials: false,
    httpsAgent: agent,
});


export default adapter;