import React from 'react';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { TiDeleteOutline } from 'react-icons/ti';

import styles from './FileBlock.module.css';

function FileBlock({ data, documentHandler }) {
  function formatNumberWithSpaces(bytes) {
    const kilobytes = Math.round(bytes / 1024);
    return kilobytes.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  }
  function deleteFile() {
    documentHandler({
      fileName: '',
      fileType: '',
      fileSize: '',
      fileValue: '',
    });
  }
  const sizeSplit = formatNumberWithSpaces(data.fileSize);
  return (
    <div className={styles.blockFile}>
      <div className={styles.item}>
        <TiDeleteOutline className={styles.iconDelete} onClick={deleteFile} />
        <AiOutlinePaperClip className={styles.iconFile} />
        <div className={styles.size}>
          <span className={styles.title}>{sizeSplit}</span> kB
        </div>
        <div className={styles.name}>{data.fileName}</div>
      </div>
    </div>
  );
}

export default FileBlock;
