export const actionBlocks = [
  {
    name: "unsubscribe",
    title: "Отписаться от бота",
    description: "Отключить подписчика от массовых и автоматических рассылок ",
  },
  { name: "block_user", title: "Заблокировать в группе или канале" },
  { name: "unblock_user", title: "Разблокировать в группе или канале" },
  {
    name: "add_tags",
    title: "Добавить тег",
    description: "Задать тег подписчику",
  },
  { name: "remove_tags", title: "Удалить тег" },
  {
    name: "giveaway",
    title: "Розыгрыш",
    description: "Добавить или Удалить в таблицу розыгрыша пользователя",
  },
  {
    name: "set_constant",
    title: "Установить переменную",
    description: "Задать переменную подписчику",
  },
  { name: "remove_constant", title: "Удалить переменную" },
  {
    name: "send_webhook",
    title: "Отправить вебхук",
    description: "Отправить POST запрос с данными на URL",
  },
  {
    name: "alert",
    title: "Известить меня",
    description: "Сообщение в Telegram",
  },
];

export const dataActionBlock = {
  unsubscribe: {
    type: "unsubscribe",
    unsubscribe: false,
  },

  block_user: {
    type: "block_user",
    channel: "",
  },

  unblock_user: {
    type: "unblock_user",
    channel: "",
  },

  add_tags: {
    type: "add_tags",
    tags: [],
  },

  remove_tags: {
    type: "remove_tags",
    tags: [],
  },

  set_constant: {
    type: "set_constant",
    name: "",
    constant_process: {
      type: "",
      act: false,
      value: "",
    },
  },

  remove_constant: {
    type: "remove_constant",
    name: "",
  },

  send_webhook: {
    type: "send_webhook",
    title: "",
    link: "",
  },

  alert: {
    type: "alert",
    text: "",
  },

  giveaway: {
    type: "giveaway",
    value: "",
  },
};
