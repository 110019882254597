import React from 'react';
import styles from './ButtonRowSelect.module.css';

function ButtonRowSelect({ selectedValue, handleSelect }) {
  return (
    <div className={styles.blockSelect}>
      <span className={styles.titleRow}>Максимум кнопок в ряд:</span>
      <select
        className={styles.select}
        value={selectedValue}
        onChange={handleSelect}
      >
        <option value="1">1</option>
        <option value="2">2</option>
        <option value="3">3</option>
        <option value="4">4</option>
      </select>
    </div>
  );
}

export default ButtonRowSelect;
