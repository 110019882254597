import React from 'react';
import { Handle, Position } from 'reactflow';
import { handleStyleOutputButton } from '../../flowStyle/styleHandler';
import { FiLink } from 'react-icons/fi';
import styles from './BlockButton.module.css';

function BlockButton({ id, text, type }) {
  return (
    <div className={styles.button}>
      {text}

      {type === 'text' ? (
        <Handle
          id={id}
          type="source"
          position={Position.Right}
          style={handleStyleOutputButton}
        />
      ) : (
        <FiLink className={styles.link} />
      )}
    </div>
  );
}

export default BlockButton;
