import React, { useState, useEffect } from 'react';
import SelectFile from '../../MessageTypePhoto/selectFile';
import DropZoneFileUpload from '../dropZoneFileUpload';

import styles from './DropZoneFile.module.css';
import DropZoneURL from '../../MessageTypePhoto/dropZoneURL';
import FileBlock from '../fileBlock';

function DropZoneFile({ initFileData, fileHandler }) {
  const [select, setSelect] = useState('file');
  const [selectStatus, setSelectStatus] = useState(false);
  const [fileData, setFileData] = useState({
    typeFile: 'file',
    fileValue: '',
    fileName: '',
    fileSize: '',
    fileType: '',
    textValue: '',
    hash: '',
  });
  const [isInitialized, setIsInitialized] = useState(false);

  //инициализация данных
  useEffect(() => {
    if (!isInitialized && initFileData?.file?.fileValue) {
      setFileData(initFileData.file);
      setSelect(initFileData.file.typeFile);
      setIsInitialized(true);
    }
  }, [initFileData]);

  // для скрытия дропа когда указали файл
  useEffect(() => {
    if (fileData.fileValue) {
      setSelectStatus(true);
    }
  }, [fileData.fileValue]);

  // для выбора типа загрузки
  function handleSelect(type) {
    setSelect(type);
  }

  function documentHandler(fileObj) {
    setFileData((previous) => {
      const newFileData = {
        ...previous,
        ...fileObj,
        typeFile: select,
      };
      fileHandler(newFileData);
      return { ...newFileData };
    });
    if (!fileObj.fileValue) {
      setSelectStatus(false);
    }
  }
  function urlHandler(link) {
    setFileData((previous) => {
      const newFileData = {
        ...previous,
        fileValue: link,
        typeFile: select,
      };
      fileHandler(newFileData);
      return { ...newFileData };
    });
    if (!link) {
      setSelectStatus(false);
    }
  }
  return (
    <>
      <div className={styles.dropZoneBlock}>
        {/* <ErrorUploadFile /> */}
        {!selectStatus && (
          <SelectFile handleSelect={handleSelect} selectData={select} />
        )}
      </div>

      {select === 'file' ? (
        !fileData.fileValue && (
          <DropZoneFileUpload documentHandler={documentHandler} />
        )
      ) : (
        <DropZoneURL
          handler={urlHandler}
          initUrl={fileData.typeFile === 'link' ? fileData.fileValue : false}
          plcHldr={'https://example.com/file.pdf'}
        />
      )}
      {fileData.fileValue && fileData.typeFile === 'file' && (
        <FileBlock data={fileData} documentHandler={documentHandler} />
      )}
    </>
  );
}

export default DropZoneFile;
