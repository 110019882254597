import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';

// Вставьте сконфигурированный объект Firebase здесь
const firebaseConfig = {
  apiKey: "AIzaSyDbJdYzY6JcAD18WklhG_kW0XGt2zf4rFw",
  authDomain: "filestoragefordiagram.firebaseapp.com",
  projectId: "filestoragefordiagram",
  storageBucket: "filestoragefordiagram.appspot.com",
  messagingSenderId: "393457652480",
  appId: "1:393457652480:web:27369ef753435bbcae19e5"
};

// Инициализируйте Firebase
firebase.initializeApp(firebaseConfig);

// Получите ссылку на хранилище
const storage = firebase.storage();
const storageRef = storage.ref();

export default storageRef;

