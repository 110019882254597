import React from 'react';
import { Handle, Position } from 'reactflow';
import { handleStyleInput } from '../../flowStyle/styleHandler';

import styles from './Chain.module.css';
import DeleteBlock from '../../deleteBlock';
import CopyBlock from '../../copyBlock';
function Chain({ data, selected, id }) {
  return (
    <>
      <Handle
        type="target"
        id={`input-${id}`}
        position={Position.Left}
        style={handleStyleInput}
      />
      <div
        className={
          selected ? `${styles.block} ${styles.blockSelected}` : styles.block
        }
      >
        <div className={styles.title}>Цепочка</div>
        {!data.block.select ? (
          <div className={styles.contentBlock}>Выберите цепочку</div>
        ) : (
          <div className={styles.blockChain}>
            <h5 className={styles.subtitle}>Запустить другую цепочку</h5>
            <b className={styles.chain}>{data.block.select.name}</b>
          </div>
        )}
      </div>
      {selected && <DeleteBlock id={id} />}
      {selected && <CopyBlock id={id} />}
    </>
  );
}

export default Chain;
