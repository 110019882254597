export const initialValue = [
  { label: 'Имя', action: '{{first_name}}' },
  { label: 'Полное имя', action: '{{full_name}}' },
  { label: 'Username', action: '{{username}}' },
  { label: 'Contact ID', action: '{{contact_id}}' },
  { label: 'Реферальная ссылка', action: '{{referal_link}}' },
  { label: 'Количество рефералов', action: '{{ref_count}}' },
  { label: 'Таблица статистики', action: '{{table_stat}}' },
  { label: 'Ссылка', action: '[link](text)' },
  { label: 'Сегодня', action: '{{today}}' },
  { label: 'Завтра', action: '{{tomorrow}}' },
];
