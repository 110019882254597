import React, { useState, useEffect, useRef} from 'react';
import axios from 'axios';
import '../../index.css';
import './LoginPage.css';
import adapter from '../../adapter';
import { useNavigate } from 'react-router-dom';
import Preloader from '../../Preloader';
import tgIcon from '../../assets/img-01.png';
import { toast } from 'react-toastify';
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});
const LoginPage = () => {


  let storedAppState = localStorage.getItem('storedAppState');
    storedAppState = storedAppState? JSON.parse(storedAppState) : null;


  useEffect(() => {
    const fetchUserIP = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const userIP = response.data.ip;
        setFormData({
          ...formData,
          ip: userIP || '',
        });
      } catch (error) {
        rollbar.error('Error during request:', error);
        console.error('Error fetching IP address:', error);
      }
    };

    fetchUserIP();
  }, []);

  const [formData, setFormData] = useState({
    username: '',
    pass: '',
    ip: '',
    route: 'login',
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!isFormValid()) {
      return;
    }
    try {
      setIsLoading(true);
      const response = await adapter.post('', formData);
      if (response.data.result === 'success') {
        localStorage.setItem('dashboardData', JSON.stringify(response.data));
        localStorage.setItem('authenticated', true);
        localStorage.setItem('ip', formData.ip);
        localStorage.setItem('storedAppState', JSON.stringify({
          ...storedAppState,
          page: 'AdminPanel'
        }));
        toast.success('Вход выполнен!');
        setTimeout(() => {
          navigate('/');
          setIsLoading(false);
        }, 1000);
      } else {
        console.log(response.data.text);
        toast.error(response.data.text);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error);
      console.error(error);
      setIsLoading(false);
      rollbar.error('Error during request:', error);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const isFormValid = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.username) {
      newErrors.username = 'Пожалуйста, введите ваш username.';
      isValid = false;
    }

    if (!formData.pass) {
      newErrors.pass = 'Пожалуйста, введите ваш пароль.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleRegAcc = () => {
    navigate('/reg');
  };


  const telegramWidgetContainerRef = useRef(null);


  useEffect(() => {
    const container = telegramWidgetContainerRef.current;

    if (container) {
      const script = document.createElement('script');
      script.src = 'https://telegram.org/js/telegram-widget.js?22';
      script.async = true;
      script.setAttribute('data-telegram-login', 'MailingAdminPanel_bot');
      script.setAttribute('data-size', 'medium');
      script.setAttribute('data-onauth', 'onTelegramAuth(user)');
      script.setAttribute('data-request-access', 'write');

      container.appendChild(script);

      window.onTelegramAuth = (user) => {
        alert(`Logged in as ${user.first_name} ${user.last_name} (${user.id}${user.username ? `, @${user.username}` : ''})`);
      };

      return () => {
        if (container && document.contains(container)) {
          container.removeChild(script);
        }
        delete window.onTelegramAuth;
      };
    }
  }, [telegramWidgetContainerRef]);


  const phrases = ['Projects', 'Bloggers'];
  const [displayedText, setDisplayedText] = useState('');
  const [showCursor, setShowCursor] = useState(true);
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isDeleting && currentTextIndex < phrases[currentPhraseIndex].length) {
        setDisplayedText(prevText => prevText + phrases[currentPhraseIndex][currentTextIndex]);
        setCurrentTextIndex(prevIndex => prevIndex + 1);
      } else if (!isDeleting && currentTextIndex === phrases[currentPhraseIndex].length) {
        setIsDeleting(true);
      } else if (isDeleting && currentTextIndex > 0) {
        setDisplayedText(prevText => prevText.slice(0, -1));
        setCurrentTextIndex(prevIndex => prevIndex - 1);
      } else {
        setIsDeleting(false);
        setCurrentTextIndex(0);
        setCurrentPhraseIndex(prevIndex => (prevIndex + 1) % phrases.length);
      }
    }, 250); 

    const cursorInterval = setInterval(() => {
      setShowCursor(prevShowCursor => !prevShowCursor);
    }, 500);

    return () => {
      clearInterval(interval);
      clearInterval(cursorInterval);
    };
  }, [currentTextIndex, currentPhraseIndex, isDeleting]);
  return (
    <div id='authPage' className="auth-page-inner">
      {isLoading ? (
        <>
          <p className="preloader-title">Выполняем вход</p>
          <Preloader />
        </>
      ) : (
        <>
          <div className='auth-page__img'></div>
          <div className='auth-page__content'>
            <h1 className="main-title">Bot4Crypto {displayedText}
            {showCursor && <span style={{ opacity: 0.5 }}>|</span>}</h1>
            <div className="card">
              <h4 className='card-title'>Вход в аккаунт</h4>
              <form className="" onSubmit={handleLogin}>
                <div className="form-elem">
                  <input
                    type="text"
                    value={formData.username}
                    onChange={handleChange}
                    className="input"
                    name="username"
                    placeholder='Имя аккаунта'
                  />
                  {errors.username && (
                    <div className={`error ${errors.username && 'fade-in'}`}>{errors.username}</div>
                  )}
                </div>
                <div className="form-elem">
                  <input
                    type="password"
                    name="pass"
                    value={formData.pass}
                    onChange={handleChange}
                    className="input"
                    placeholder='Пароль'
                  />
                  {errors.pass && (
                    <div className={`error ${errors.pass && 'fade-in'}`}>{errors.pass}</div>
                  )}
                </div>

                <div className="form-elem">
                  <button type="submit" className="auth-btn">
                    Вход
                  </button>
                </div>
              </form>
              
            
            <div className='btns'>
              <button className="reg-acc-btn" onClick={handleRegAcc}>
              Зарегистрировать аккаунт
              </button>
              <a className='auth-tg-btn' href='tg://resolve?domain=testbot4crypto_bot&start=start'>Войти с помощью Telegram</a>
              </div>
          </div>
          </div>
        </>
        
      )}
    </div>
  );
};

export default LoginPage;