import React, { useContext, useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../App';
import '../../../index.css';
import './Sidebar.css';
import { FaBezierCurve,FaTelegramPlane,FaChartArea ,FaUserCheck,FaSignInAlt,FaArrowRight ,FaArrowLeft } from 'react-icons/fa';
import pen from '../../../../public/pen.png';
import mail from '../../../../public/mail.png';
import home from '../../../../public/home.png';
import stats from '../../../../public/stats.png';
import mailW from '../../../../public/mail-w.png';
import homeW from '../../../../public/home-w.png';
import exit from '../../../../public/exit-w.png';
import statsW from '../../../../public/stats-w.png';
import penW from '../../../../public/pen-w.png';
import { toast } from 'react-toastify';

const Sidebar = ({setCurrComponent, handleRefreshState}) => {
  const storedData = JSON.parse(localStorage.getItem('dashboardData'));
  const dashboardData = storedData ? storedData: null;

  let storedAppState = localStorage.getItem('storedAppState');
  if (!storedAppState) {
    storedAppState = {
      page: 'AdminPanel',
    };
  } else {
    storedAppState = JSON.parse(storedAppState);
  }
  const [activeComponent, setActiveComponent] = useState('');
  const {handleExit } = useContext(AppContext);
  const [username, setUserName] = useState('');
  const [disabledSidebarBtns, setDisabledSidebarBtns] = useState(false);


  const navigate = useNavigate();
  
  useEffect(() => {
    if (activeComponent) {
      if(activeComponent === 'tieAcc'){
        setDisabledSidebarBtns(true);
      }
      if ((activeComponent !== 'AdminPanel' && activeComponent !== 'BotStats') && storedAppState.state && storedAppState.botData) {
       
        delete storedAppState.state;
        delete storedAppState.botData;
      }
      if(!storedData){
        toast.error('Срок действия данных сессии истек!Выполните повторный вход')
        localStorage.clear(); 
        sessionStorage.clear();
        navigate('/dashboard');
      }
      localStorage.setItem('storedAppState', JSON.stringify({
        ...storedAppState,
        page: activeComponent
      }));
      setCurrComponent(activeComponent);
    }
  
  }, [activeComponent]);

  useEffect(() => {
    const userData = dashboardData.user;
    setUserName(dashboardData.user['name'] );
    if(!userData.telegram_id){
      setActiveComponent('tieAcc');
      setCurrComponent('tieAcc');
      localStorage.setItem('storedAppState', JSON.stringify({
        ...storedAppState,
        page: activeComponent
      }));
    }else if (userData.telegram_id && storedAppState?.page) {
        setActiveComponent(storedAppState.page);
        setCurrComponent(storedAppState.page);
    }
  }, []);

  const [sidebarVisible, setSidebarVisible] = useState(true);

  const handleWindowSizeChange = () => {
    if (window.innerWidth <= 992) {
      setSidebarVisible(false);
    }else{
      setSidebarVisible(true);
    }
    
  };

  const toggleSidebar = () => {
    if (window.innerWidth > 992) return;

    setSidebarVisible(!sidebarVisible);
  
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);

    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  

  return (
    <>
      <div className={`sidebar ${sidebarVisible ? 'visible' : 'hidden'}`}>
        <p className='sidebar-title'>Bot4Crypto Projects</p>
        <ul className={`sidebar__menu ${!sidebarVisible ? 'sidebar__menu-close' : ''}`}> 
          {/* <li className="sidebar__menu-item">
            <small>Добро пожаловать! Вы вошли как: </small>
            <p className='username'>{username}</p>  
          </li> */}
        
          <li className="sidebar__menu-item">
            <div className={`item-icon ${activeComponent === 'AdminPanel' || activeComponent === null ?'item-icon--active' : ''}`}>
              <img src={activeComponent === 'AdminPanel'  ? pen : penW}/> 
            </div>
            <button
              className={`sidebar__item-link ${activeComponent === 'AdminPanel' || activeComponent === null ?'sidebar__item-link--active' : ''}`}
              onClick={() => {
                setActiveComponent('AdminPanel');
                handleRefreshState(true);
                handleWindowSizeChange();
              }}
              disabled={disabledSidebarBtns ? true : false}
            >
              Управление ботами
             
            </button>
          </li>
          <li className="sidebar__menu-item">
          <div className={`item-icon ${activeComponent === 'MailingPage' || activeComponent === null ?'item-icon--active' : ''}`}>
          
          <img src={activeComponent === 'MailingPage'  ? mail : mailW}/> 
          </div>
            <button
              className={`sidebar__item-link ${activeComponent === 'MailingPage' ? 'sidebar__item-link--active' : ''}`}
              onClick={() => {
                setActiveComponent('MailingPage');
                handleRefreshState(true);
                handleWindowSizeChange();
              }}
              disabled={disabledSidebarBtns ? true : false}
            >
              Мои рассылки
             
            </button>
          </li>
          <li className="sidebar__menu-item">
          <div className={`item-icon ${activeComponent === 'BotStats' || activeComponent === null ?'item-icon--active' : ''}`}>
            <img src={activeComponent === 'BotStats'  ? stats : statsW}/>
          </div> 
            <button
              className={`sidebar__item-link ${activeComponent === 'BotStats' ? 'sidebar__item-link--active' : ''}`}
              onClick={() => {
                setActiveComponent('BotStats');
                handleRefreshState(true);
                handleWindowSizeChange();
              }}
              disabled={disabledSidebarBtns ? true : false}
            >
              Статистика
             
            </button>
          </li>
          <li className="sidebar__menu-item">
          <div className={`item-icon ${activeComponent === 'accountSettings' || activeComponent === null ?'item-icon--active' : ''}`}>
            <img src={activeComponent === 'accountSettings'  ? home : homeW}/> 
          </div>
            <button
              className={`sidebar__item-link ${activeComponent === 'accountSettings' ? 'sidebar__item-link--active' : ''}`}
              onClick={() => {
                setActiveComponent('accountSettings');
                handleRefreshState(true);
                handleWindowSizeChange();
              }}
              disabled={disabledSidebarBtns ? true : false}
            >
              Личный кабинет
             
            </button>
          </li>
        <li className="sidebar__menu-item">
        <div className='item-icon'>
          <img src={exit}/> 
        </div>
          <button
            className={`sidebar__item-link`}
            onClick={handleExit}
          >
            Выход
          </button>
        </li>
        </ul>
      </div>
      <button type='button' onClick={toggleSidebar} className={`toggle-btn ${!sidebarVisible ? 'toggle-btn-hide' : ''}`}>
      {sidebarVisible ? (
        <>
         <p>Скрыть меню </p> <FaArrowLeft/> 
        </>
      ): (
        <>
          <p>Показать меню </p><FaArrowRight/> 
        </>
      )}
      </button>
    </>
     
  );
};
  

export default Sidebar;