import React from 'react';
import { Handle, Position } from 'reactflow';
import { handleStyleInput } from '../../flowStyle/styleHandler';

import styles from './Filter.module.css';
import Condition from '../condition';
import NotBlock from '../notBlock';
import DeleteBlock from '../../deleteBlock';
import CopyBlock from '../../copyBlock';
function Filter({ data, selected, id }) {
  return (
    <>
      <Handle
        type="target"
        id={`input-${id}`}
        position={Position.Left}
        style={handleStyleInput}
      />
      <div
        className={
          selected ? `${styles.block} ${styles.blockSelected}` : styles.block
        }
      >
        <div className={styles.title}>Фильтр</div>
        <div className={styles.content}>
          {data.block.conditions &&
            data.block.conditions.map((cnd, i) => (
              <Condition key={cnd.id} data={cnd} />
            ))}
          <NotBlock />
        </div>
      </div>
      {selected && <DeleteBlock id={id} />}
      {selected && <CopyBlock id={id} />}
    </>
  );
}

export default Filter;
