import React from 'react';
import { Handle, Position } from 'reactflow';
import { handleStyleOutputButton } from '../../flowStyle/styleHandler';
import styles from './BlockQuickAnswer.module.css';

function QuickAnswer({ text, id }) {
  return (
    <div className={styles.button}>
      {text}
      <Handle
        id={id}
        type="source"
        position={Position.Right}
        style={handleStyleOutputButton}
      />
    </div>
  );
}

export default QuickAnswer;
