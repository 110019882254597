import React, { useState } from "react";
import JsonInputUI from "../inputUI/JsonInputUI";
import { useDetectClickOutside } from "react-detect-click-outside";

import styles from "./SaveValue.module.css";

const checkValue = ["arrayAnchor", "imageUrl", "text", "description"];

function SaveValue({
  changeValue,
  keyValue,
  value,
  json,
  label,
  placeholder,
  union,
  hideIndex,
}) {
  const [popup, setPopup] = useState(false);
  const close = () => setPopup(false);
  const handlerFocus = () => setPopup(true);

  const dropDownRef = useDetectClickOutside({
    onTriggered: (e) => {
      // из-за сложности вложености было сделано исключения для элементов которые содержат в своем класе jv
      if (typeof e.target.className === "object") {
        if (e.target.className.animVal.includes("jv")) return;
      } else {
        if (e.target.className.includes("jv")) return;
      }
      setPopup(false);
    },
  });

  return (
    <div ref={dropDownRef} className={!union ? styles.box : styles.union}>
      {label && <label className={styles.label}>{label}</label>}
      <JsonInputUI
        popup={popup}
        close={close}
        handlerFocus={handlerFocus}
        handler={changeValue}
        type={keyValue}
        json={json}
        placeholder={placeholder}
        text={value}
        hideIndex={checkValue.includes(keyValue) || hideIndex}
      />
    </div>
  );
}

export default SaveValue;
