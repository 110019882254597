import { initializeApp } from 'firebase/app'; 

// ключи для всего приложения
const firebaseConfigForWeb = {
  apiKey: 'AIzaSyA5P8dUzZYbMvcPNrcrzkNG_ZWS909n1yU',
  authDomain: 'some-admin-5848a.firebaseapp.com',
  projectId: 'some-admin-5848a',
  storageBucket: 'some-admin-5848a.appspot.com',
  messagingSenderId: '627085153131',
  appId: '1:627085153131:web:aebe508e5bbe5160d4322e',
  measurementId: 'G-3MGNJ5CLYL',
};

// ключи для блок-схемы
const firebaseConfigForDiagram = {
  apiKey: 'AIzaSyDbJdYzY6JcAD18WklhG_kW0XGt2zf4rFw',
  authDomain: 'filestoragefordiagram.firebaseapp.com',
  projectId: 'filestoragefordiagram',
  storageBucket: 'filestoragefordiagram.appspot.com',
  messagingSenderId: '393457652480',
  appId: '1:393457652480:web:7e8d3ae28f238836ae19e5',
};

// Инициализируем два приложения Firebase
export const webApp = initializeApp(firebaseConfigForWeb, 'webApp'); // для всего приложения (можешь изменить названия этой переменной)

export const diagramFBInit = initializeApp(
  firebaseConfigForDiagram,
  'diagramFBInit'
); // для блок схемы
