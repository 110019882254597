import React, { useEffect, useState } from 'react';
import '../../../../../../index.css';
import styles from './CreateTriggerWindow.module.css';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import refreshToken from '../../../../../../refreshToken';
import adapter from '../../../../../../adapter';
import { toast } from 'react-toastify';
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});
const ignoreTimeOptions = [
  { value: 'second', label: 'Секунд' },
  { value: 'minute', label: 'Минут' },
  { value: 'hour', label: 'Часов' },
  { value: 'day', label: 'Дней' },
];


const CreateTriggerWindow = ({ selectedBotId, returnToActiveContent, setRefreshTriggersData, editTriggerData, editTriggerState, resetTriggerState,setTriggerMenuLock }) => {
  
  const storedData = JSON.parse(localStorage.getItem('dashboardData'));
  const dashboardData = storedData ? storedData: null;
  const navigate = useNavigate();
  const [selectedTriggerType, setSelectedTriggerType] = useState('keywordRadio');
  const [incMess, setIncMess] = useState('');
  const [triggerName, setTriggerName] = useState('');
  const [triggerDesc, setTriggerDesc] = useState('');
  const [triggerId, setTriggerId] = useState('');
  const [keywords, setKeywords] = useState([]);
  const [ignoreCount, setIgnoreCount] = useState('');
  const [selectTimeType, setSelectTimeType] = useState(null);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isIgnoreCountEnabled, setIsIgnoreCountEnabled] = useState(false);
  const [isSelectTimeTypeEnabled, setIsSelectTimeTypeEnabled] = useState(false);
  const handleTriggerSelect = (e) => {
    const selectedTriggerType = e.target.value;
    setSelectedTriggerType(selectedTriggerType);
  };
  const handleIncMessSelect = (e) => {
    const incMess = e.target.value;
    setIncMess(incMess);
  };

  const inputsReset = () => {
    setTriggerName('');
    setTriggerId('');
    setTriggerDesc('');
    setIgnoreCount('');
    setSelectTimeType('');
    setKeywords([]);
    setIncMess('');
  };

  const handleSaveTrigger = async (e) => {
    if (!triggerName) {
      toast.error('Пожалуйста, введите название');
      setIsFormValid(false);
      return;
    }

    if (keywords.length === 0) {
      toast.error('Пожалуйста, добавьте ключевые слова');
      setIsFormValid(false);
      return;
    }

    if (!incMess) {
      toast.error('Пожалуйста, выберите тип входящего сообщения');
      setIsFormValid(false);
      return;
    }

    if (isIgnoreCountEnabled && !ignoreCount) {
      toast.error('Пожалуйста, выберите количество времени для игнорирования повторений');
      setIsFormValid(false);
      return;
    }

    if (isSelectTimeTypeEnabled && !selectTimeType) {
      toast.error('Пожалуйста, выберите тип времени для игнорирования повторений');
      setIsFormValid(false);
      return;
    }

    setIsFormValid(true);

    if(editTriggerState){
      const formattedKeywords = keywords.map((keyword) => `${keyword};`).join('');
      const data = {
        route: 'updateTrigerSettings',
        bot_id: selectedBotId,
        access_token: dashboardData.token,
        keywords: formattedKeywords,
        dialog_name: triggerName,
        dialog_description: triggerDesc,
        keyword_condition: incMess,
        time_ignore: ignoreCount + ' ' + selectTimeType,
        triger_id: triggerId,
      };
    
      const response = await adapter.post('', data);
      
      if (response.data.result === 'success') {
        toast.success('Триггер отредактирован');
        setRefreshTriggersData(true);
        setTriggerMenuLock(false);
        inputsReset();
        returnToActiveContent('Triggers');
      } else if (response.data.result === 'error' && response.data.text === 'Token is expired') {
        try {
          const refreshTokenResult = await refreshToken();
          if (refreshTokenResult === 'logout') {
            navigate('/', { replace: true });
          } else {
            dashboardData.token = refreshTokenResult;
            const formattedKeywords = keywords.map((keyword) => `${keyword};`).join('');
            const data = {
              route: 'updateTrigerSettings',
              bot_id: selectedBotId,
              access_token: dashboardData.token,
              keywords: formattedKeywords,
              dialog_name: triggerName,
              dialog_description: triggerDesc,
              keyword_condition: incMess,
              time_ignore: ignoreCount + ' ' + selectTimeType,
              triger_id: triggerId,
            };
            const response = await adapter.post('', data);
            if (response.data.result === 'success') {
              toast.success('Триггер отредактирован');
              setRefreshTriggersData(true);
              inputsReset();
              returnToActiveContent('Triggers');
            } else {
              console.log(response.data);
            }
          }
        } catch (error) {
          console.error(error);
          rollbar.error('Error during request:', error);
        }
      } else {
        console.log(response.data);
      }
    }else{
      const formattedKeywords = keywords.map((keyword) => `${keyword};`).join('');
      const data = {
        route: 'setTriger',
        user_tg_id: dashboardData.user['telegram_id'],
        bot_id: selectedBotId,
        access_token: dashboardData.token,
        keywords: formattedKeywords,
        dialog: '',
        dialog_name: triggerName,
        dialog_description: triggerDesc,
        keyword_condition: incMess,
        time_ignore: ignoreCount + ' ' + selectTimeType,
      };
      try {
      const response = await adapter.post('', data);
      
      if (response.data.result === 'success') {
        toast.success('Триггер добавлен');
        inputsReset();
        returnToActiveContent('Triggers');
        setRefreshTriggersData(true);
      } else if (response.data.result === 'error' && response.data.text === 'Token is expired') {
        try {
          const refreshTokenResult = await refreshToken();
          if (refreshTokenResult === 'logout') {
            
            navigate('/', { replace: true });
          } else {
            dashboardData.token = refreshTokenResult;
            const formattedKeywords = keywords.map((keyword) => `${keyword};`).join('');
            const data = {
              route: 'setTriger',
              user_tg_id: dashboardData.user['telegram_id'],
              bot_id: selectedBotId,
              access_token: dashboardData.token,
              keywords: formattedKeywords,
              dialog: '',
              dialog_name: triggerName,
              dialog_description: triggerDesc,
              keyword_condition: incMess,
              time_ignore: ignoreCount + ' ' + selectTimeType,
            };
            const response = await adapter.post('', data);
            if (response.data.result === 'success') {
              toast.success('Триггер добавлен');
              inputsReset();
              returnToActiveContent('Triggers');
            } else {
              console.log(response.data);
            }
          }
        } catch (error) {
          console.error(error);
          toast.error(response.data.text);
          rollbar.error('Error during request:', error);
        }
      } else {
        toast.error(response.data.text);
        console.log(response.data);
      }
        
    } catch (error) {
      console.error(error);
      rollbar.error('Error during request:', error);
    }
    }
  };

  const handleReturnToScript = (e) => {
    setTriggerMenuLock(false);
    inputsReset();
    returnToActiveContent('Triggers');
    resetTriggerState();
  };

  const [inputValue, setInputValue] = useState('');

  const handleChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleAddTrigger = () => {
    if (inputValue) {
      setKeywords([...keywords, inputValue]);
      setInputValue('');
    }
  };

  const handleDelete = (index) => {
    const newValues = [...keywords];
    newValues.splice(index, 1);
    setKeywords(newValues);
  };

  const handleSelectIgnoreCount = (e) => {
    const value = parseInt(e.target.value);
    if (isNaN(value) || value < 0) {
      setIgnoreCount(0);
    } else {
      setIgnoreCount(value);
    }
  };

  const handleSelectTimeType = (selectedOption) => {
    setSelectTimeType(selectedOption);
  };

  const handleSetTriggerName = (e) => {
    setTriggerName(e.target.value);
  };

  const handleSetTriggerDesc = (e) => {
    setTriggerDesc(e.target.value);
  };

  const handleIgnoreCountCheckboxChange = (e) => {
    setIsIgnoreCountEnabled(e.target.checked);
  };

  const handleSelectTimeTypeCheckboxChange = (e) => {
    setIsSelectTimeTypeEnabled(e.target.checked);
  };
  useEffect(() => {
    if (editTriggerData.type) {
      // setIsSelectTimeTypeEnabled(true); 
      const parsedType = JSON.parse(editTriggerData.type);
      setTriggerId(editTriggerData.id);
      setTriggerName(editTriggerData.name);
      setTriggerDesc(editTriggerData.description);
      if (parsedType.keywords) {
          const keywordsArray = parsedType.keywords.split(';').filter(keyword => keyword.trim() !== '');
          setKeywords(keywordsArray);
      }
      setIncMess(parsedType.keyword_condition);
      if(editTriggerData.time_ignore !== ''){
        setIsIgnoreCountEnabled(false);
      
      }else{
        setIsIgnoreCountEnabled(true);
        setIgnoreCount(parsedType.time_ignore);
        setSelectTimeType();
      }
    }
}, [editTriggerData]);




  return (
    <>
      {editTriggerState ? (
        <>
        <h3 className="main-title">
          <p>Редактирование триггера</p>
        </h3>
        <div className="form-elem">
          <p>Дайте название и краткое описание для триггера:</p>
          <div className="d-flex">
            <input
            className='form-elem'
              type="text"
              placeholder="Введите название"
              onChange={handleSetTriggerName}
              value={triggerName}
              required
            />
  
            <input
            className='form-elem'
              type="text"
              placeholder="Добавьте описание"
              onChange={handleSetTriggerDesc}
              value={triggerDesc}
              required
            />
          </div>
         
        </div>
        <div className="form-elem">
          <p>Выберите тип триггера:</p>
          <div className="radio-group form-elem">
            <label htmlFor="keywordRadio" className="radio">
              <input
                className="radio-input"
                type="radio"
                id="keywordRadio"
                name="keywordRadio"
                value="keywordRadio"
                checked={selectedTriggerType === 'keywordRadio'}
                onChange={handleTriggerSelect}
              />
              <div className="radio-circle">
                <div className="radio-mark"></div>
              </div>
              <small>Ключевое слово</small>
            </label>
          </div>
        </div>
  
        {selectedTriggerType === 'keywordRadio' && (
          <>
            <div className="form-elem form-elem-margin">
              <strong>Входящее сообщение:</strong>
              <div className="radio-group form-elem">
                <label htmlFor="equalMessageRadio" className="radio">
                  <input
                    className="radio-input"
                    type="radio"
                    id="equalMessageRadio"
                    name="equalMessageRadio"
                    value="equal"
                    checked={incMess === 'equal'}
                    onChange={handleIncMessSelect}
                  />
                  <div className="radio-circle">
                    <div className="radio-mark"></div>
                  </div>
                  <small>Совпадает</small>
                </label>
                <label htmlFor="containsMessageRadio" className="radio">
                  <input
                    className="radio-input"
                    type="radio"
                    id="containsMessageRadio"
                    name="containsMessageRadio"
                    value="contain"
                    checked={incMess === 'contain'}
                    onChange={handleIncMessSelect}
                  />
                  <div className="radio-circle">
                    <div className="radio-mark"></div>
                  </div>
                  <small>Содержит</small>
                </label>
              </div>
            </div>
            <div className="form-elem">
              <small>
                <p>
                  Слова или фразы без учёта регистра. Например: <span className="trigger-words__elem-example">привет</span>,{' '}
                  <span className="trigger-words__elem-example">какая цена</span>,{' '}
                  <span className="trigger-words__elem-example">поздравляю</span>
                </p>
              </small>
            </div>
  
            <div className="form-elem">
              <div className="d-flex">
                <input type="text" value={inputValue} onChange={handleChange} />
                <button className="action-btn btn_connect" onClick={handleAddTrigger}>
                  Добавить
                </button>
              </div>
              <div className="trigger-words">
                {keywords.map((value, index) => (
                  <div className="trigger-words__elem" key={index}>
                    <span>{value}</span>
                    <button className="delete-link-btn" onClick={() => handleDelete(index)}></button>
                  </div>
                ))}
              </div>
            </div>
  
            <div className="checkbox-group form-elem">
              <label htmlFor="ignoreCountCheckbox" className="checkbox">
                <input
                  className="checkbox-input"
                  type="checkbox"
                  id="ignoreCountCheckbox"
                  checked={isIgnoreCountEnabled}
                  onChange={handleIgnoreCountCheckboxChange}
                />
                <div className="checkbox-box">
                  <div className="checkbox-check"></div>
                </div>
                <strong className='form-elem'>Игнорировать повторения</strong>
              </label>
            </div>
  
            {isIgnoreCountEnabled && (
              <div className="form-elem d-flex">
                <input
                  className="form-elem time-select"
                  type="number"
                  name="ignoreCount"
                  id="ignoreCount"
                  value={ignoreCount}
                  onChange={handleSelectIgnoreCount}
                />
                <Select
                  className="select-focus form-elem"
                  options={ignoreTimeOptions}
                  onChange={handleSelectTimeType}
                  placeholder="секунд"
                  value={selectTimeType}
                />
              </div>
            )}
  
            <div className="control-btns">
              <div className="form-elem">
                <button className="action-btn btn_connect" type="button" onClick={handleSaveTrigger}>
                  Сохранить триггер
                </button>
              </div>
              <div className="d-flex">
                <div className="form-elem">
                  <button className="action-btn btn_scenario" type="button" onClick={handleReturnToScript}>
                    Отмена
                  </button>
                </div>
                <div className="form-elem"></div>
              </div>
            </div>
          </>
        )}
        </>)
      :(<>
        <h3 className="main-title">
          <p>Создание триггера</p>
        </h3>
        <div className="form-elem">
          <p>Дайте название и краткое описание для триггера:</p>
          <div className="d-flex">
            <input
            className='form-elem'
              type="text"
              placeholder="Введите название"
              onChange={handleSetTriggerName}
              value={triggerName}
              required
            />
  
            <input
            className='form-elem'
              type="text"
              placeholder="Добавьте описание"
              onChange={handleSetTriggerDesc}
              value={triggerDesc}
              required
            />
          </div>
         
        </div>
        <div className="form-elem">
          <p>Выберите тип триггера:</p>
          <div className="radio-group form-elem">
            <label htmlFor="keywordRadio" className="radio">
              <input
                className="radio-input"
                type="radio"
                id="keywordRadio"
                name="keywordRadio"
                value="keywordRadio"
                checked={selectedTriggerType === 'keywordRadio'}
                onChange={handleTriggerSelect}
              />
              <div className="radio-circle">
                <div className="radio-mark"></div>
              </div>
              <small>Ключевое слово</small>
            </label>
          </div>
        </div>
  
        {selectedTriggerType === 'keywordRadio' && (
          <>
            <div className="form-elem form-elem-margin">
              <strong>Входящее сообщение:</strong>
              <div className="radio-group form-elem">
                <label htmlFor="equalMessageRadio" className="radio">
                  <input
                    className="radio-input"
                    type="radio"
                    id="equalMessageRadio"
                    name="equalMessageRadio"
                    value="equal"
                    checked={incMess === 'equal'}
                    onChange={handleIncMessSelect}
                  />
                  <div className="radio-circle">
                    <div className="radio-mark"></div>
                  </div>
                  <small>Совпадает</small>
                </label>
                <label htmlFor="containsMessageRadio" className="radio">
                  <input
                    className="radio-input"
                    type="radio"
                    id="containsMessageRadio"
                    name="containsMessageRadio"
                    value="contain"
                    checked={incMess === 'contain'}
                    onChange={handleIncMessSelect}
                  />
                  <div className="radio-circle">
                    <div className="radio-mark"></div>
                  </div>
                  <small>Содержит</small>
                </label>
              </div>
            </div>
            <div className="form-elem">
              <small>
                <p>
                  Слова или фразы без учёта регистра. Например: <span className="trigger-words__elem-example">привет</span>,{' '}
                  <span className="trigger-words__elem-example">какая цена</span>,{' '}
                  <span className="trigger-words__elem-example">поздравляю</span>
                </p>
              </small>
            </div>
  
            <div className="form-elem">
              <div className="d-flex">
                <input type="text" value={inputValue} onChange={handleChange} />
                <button className="action-btn btn_connect" onClick={handleAddTrigger}>
                  Добавить
                </button>
              </div>
              <div className="trigger-words">
                {keywords.map((value, index) => (
                  <div className="trigger-words__elem" key={index}>
                    <span>{value}</span>
                    <button className="delete-link-btn" onClick={() => handleDelete(index)}></button>
                  </div>
                ))}
              </div>
            </div>
  
            <div className="checkbox-group form-elem">
              <label htmlFor="ignoreCountCheckbox" className="checkbox ignore-checkbox">
                <input
                  className="checkbox-input"
                  type="checkbox"
                  id="ignoreCountCheckbox"
                  checked={isIgnoreCountEnabled}
                  onChange={handleIgnoreCountCheckboxChange}
                />
                <div className="checkbox-box">
                  <div className="checkbox-check"></div>
                </div>
                <strong className='form-elem'>Игнорировать повторения</strong>
              </label>
            </div>
  
            {isIgnoreCountEnabled && (
              <div className="form-elem d-flex">
                <input
                  className="form-elem time-select"
                  type="number"
                  name="ignoreCount"
                  id="ignoreCount"
                  value={ignoreCount}
                  onChange={handleSelectIgnoreCount}
                />
                <Select
                  className="select-focus form-elem"
                  options={ignoreTimeOptions}
                  onChange={handleSelectTimeType}
                  placeholder="секунд"
                  value={selectTimeType}
                />
              </div>
            )}
  
            <div className="control-btns">
              <div className="form-elem">
                <button className="action-btn btn_connect" type="button" onClick={handleSaveTrigger}>
                  Сохранить триггер
                </button>
              </div>
              <div className="d-flex">
                <div className="form-elem">
                  <button className="action-btn btn_scenario" type="button" onClick={handleReturnToScript}>
                    Отмена
                  </button>
                </div>
                <div className="form-elem"></div>
              </div>
            </div>
          </>
        )}
        </>)}
    </>
  );
};

export default CreateTriggerWindow;