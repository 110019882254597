import axios from "axios";

export const apiRequest = async (method, url, headers, body) => {
  try {
    const result = await axios({
      method,
      url,
      headers: {
        ...headers,
      },
      data: body,
    });
    console.log("request", result);
    return result.data;
  } catch (error) {
    console.error(error);
  }
};
