import React, { useState, useEffect } from 'react';
import { RiErrorWarningLine } from 'react-icons/ri';
import styles from './TypePause.module.css';
import MessageMoveType from '../../MessageMoveType';

function TypePause({
  typeContentInit,
  blockElementHandler,
  moveUp,
  moveDown,
  deleteBlock,
  index,
  length,
}) {
  const [number, setNumber] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  // для стрелок перемещения
  const [blockStatus, setBlockStatus] = useState(false);
  const lastElemet = index === length - 1;

  const handleInputChange = (e) => {
    let inputValue = e.target.value[e.target.value.length - 1];
    if (!inputValue) inputValue = 0;
    const pattern = /^[0-5]$/; // Регулярное выражение: только цифры 0, 1, 2, 3, 4 или 5

    if (pattern.test(inputValue)) {
      setNumber(inputValue);
    }
  };

  useEffect(() => {
    if (!isInitialized && typeContentInit) {
      setNumber(typeContentInit.pauseValue);
      setIsInitialized(true);
    }
  }, [typeContentInit]);

  // Обновляем информацию
  useEffect(() => {
    const newObject = {
      type: 'message pause',
      pauseValue: number,
      pauseType: 'second',
      id: typeContentInit.id,
    };
    if (!(number === false)) {
      blockElementHandler(newObject, index);
    }
  }, [number]);

  function blockSatusHandler(boolen) {
    if (blockStatus !== boolen) {
      setBlockStatus(boolen);
    }
  }
  return (
    <div
      className={`${styles.blockPause} ${
        lastElemet ? styles.blockPauseLast : ''
      }`}
      onMouseEnter={() => blockSatusHandler(true)}
      onMouseLeave={() => blockSatusHandler(false)}
    >
      <MessageMoveType
        moveUp={moveUp}
        moveDown={moveDown}
        blockStatus={blockStatus}
        deleteBlock={deleteBlock}
        index={index}
        length={length}
      />
      <div className={styles.title}>Показывать индикатор ввода</div>
      <div className={styles.inputBlock}>
        {number === false ? (
          false
        ) : (
          <input
            className={styles.number}
            type="number"
            value={number}
            onChange={handleInputChange}
            disabled={lastElemet}
          />
        )}
        <span className={styles.cell}>сек</span>
      </div>
      {lastElemet && (
        <span className={styles.warning}>
          <RiErrorWarningLine className={styles.iconWarning} />
          Блок задержки не может быть последним элементом
        </span>
      )}
    </div>
  );
}

export default TypePause;
