import React from "react";
import styles from "./ActionBlock.module.css";
import { Handle, Position, useStore } from "reactflow";
import { handleStyleOutput, handleStyleInput } from "../flowStyle/styleHandler";
import { BsRobot } from "react-icons/bs";
import DeleteBlock from "../deleteBlock";
import CopyBlock from "../copyBlock";
import Group from "../../icon/group.svg";

const UserBlock = ({ title, apiInfo, block }) => {

  return (
    <>
      <div className={styles.descriptionMain}>{title}</div>
      <div className={styles.gray}>
        <img src={Group} alt="circleAnd" />
        {block.channel
          ? `${
              apiInfo.channel.find((el) => el.id === block.channel)?.name ||
              "Выберите..."
            }`
          : "Выберите..."}
      </div>
    </>
  );
};

const Tags = ({ title, tags, action }) => {
  return (
    <>
      {tags.length === 0 ? (
        <div className={styles.secondaryText}>{title}</div>
      ) : (
        <>
          <div className={styles.descriptionMain}>{action}</div>{" "}
          <div className={styles.tags}>
            {tags.map((t) => (
              <span className={styles.tag}>{t}</span>
            ))}
          </div>
        </>
      )}
    </>
  );
};

const showInfo = (block, apiInfo) => {
  switch (block.type) {
    case "unsubscribe":
      return <div className={styles.description}>Отписаться от бота</div>;
    case "block_user":
      return (
        <UserBlock
          title="Заблокировать в группе или канале"
          apiInfo={apiInfo}
          block={block}
        />
      );

    case "unblock_user":
      return (
        <UserBlock
          title="Разблокировать в группе или канале"
          apiInfo={apiInfo}
          block={block}
        />
      );
    case "add_tags":
      return (
        <Tags
          title="Необходимо добавить хотя бы один тег"
          action={"Добавить тег"}
          tags={block.tags}
        />
      );
    case "remove_tags":
      return (
        <Tags
          title="Необходимо добавить хотя бы один тег"
          action={"Удалить тег"}
          tags={block.tags}
        />
      );
    case "giveaway":
      return (
        <>
          {block.value ? (
            <div className={styles.descriptionMain}>
              {block.value === "add" ? "Добавить" : "Удалить"} пользователя{" "}
              {block.value === "add" ? "в " : "из "}
              таблиц{block.value === "add" ? "у" : "ы"}
            </div>
          ) : (
            <div className={styles.secondaryText}>
              Необходимо выбрать действие
            </div>
          )}
        </>
      );
    case "set_constant":
      return (
        <>
          {block.name && block.constant_process?.value ? (
            <>
              <div className={styles.secondaryText}>
                Установить значение{" "}
                <span className={styles.tag}>
                  {block.constant_process?.value}
                </span>
                <br />
                для переменной <span className={styles.tag}>
                  {block.name}
                </span>{" "}
              </div>
            </>
          ) : (
            <div className={styles.secondaryText}>
              Необходимо указать переменную и ее значение
            </div>
          )}
        </>
      );
    case "remove_constant":
      return (
        <>
          {block.name ? (
            <>
              <div className={styles.secondaryText}>
                Удалить переменную
                <br />
                <span className={styles.tag}>{block.name}</span>
              </div>
            </>
          ) : (
            <div className={styles.secondaryText}>
              Необходимо указать переменную
            </div>
          )}
        </>
      );
    case "send_webhook":
      return (
        <>
          {" "}
          {block.link && block.title ? (
            <>
              <div className={styles.secondaryText}>
                <h4>Отправить вебхук</h4>
                <span className={styles.tag}>{block.title}</span>
                <br />
                на
                <br />
                <span className={styles.tag}>{block.link}</span>
              </div>
            </>
          ) : (
            <div className={styles.secondaryText}>
              Необходимо указать ссылку и тег
            </div>
          )}
        </>
      );
    case "alert":
      return (
        <>
          <div className={styles.secondaryText}>
            <h4>Известить меня</h4>
          </div>
          <div className={styles.gray} style={{ textAlign: "center" }}>
            {block.text ? block.text : "🔴 Введите текст сообщения"}
          </div>
        </>
      );
    default:
      return <div className={styles.description}>Выберите действие</div>;
  }
};

function ActionBlock({ data, selected, id }) {
  const block = data.block;
  const { apiInfo } = useStore();
  return (
    <>
      <Handle
        type="target"
        id={`input-${id}`}
        position={Position.Left}
        style={handleStyleInput}
      />
      <div
        className={
          selected ? `${styles.block} ${styles.blockSelected}` : styles.block
        }>
        <div className={styles.title}>Дeйствие</div>
        <div className={styles.contentBlock}>{showInfo(block, apiInfo)}</div>
      </div>
      {selected && <DeleteBlock id={id} />}
      {selected && <CopyBlock id={id} />}

      <Handle
        type="source"
        position={Position.Right}
        id={`output-${id}`}
        style={handleStyleOutput}
      />
    </>
  );
}

export default ActionBlock;
