import { ReactFlowProvider } from 'reactflow';
import React, {
  useEffect,
} from 'react';
import App from './flow';
// Буферная зона которая потом мне будет нужна *возможно*

function FlowDiagram({ auth, data, lock, dataHandler, errorHandler, logApi, isLoading, isEdit,getBack,setShowRecoverButton,handleRecoverDiagram, saveDraft, dialogNameDefault, editedDialogName , undoDiagramChanges,redoDiagramChanges, dialogId}) {

  return (
    <ReactFlowProvider>
       {isLoading ? (
                <>
                 {isEdit === false ? (
                  <>
                    <p className='preloader-title'>Сохраняем цепочку</p>
                    <Preloader></Preloader>
                  </>
                ) : (
                  <>
                    <p className='preloader-title'>Редактируем цепочку</p>
                    <Preloader></Preloader>
                  </>
                )}
                </>
            ) : (
          <>
            <App
             
              auth={auth}
              data={data}
              lock={lock}
              dialogId={dialogId}
              dataHandler={dataHandler}
              errorHandler={errorHandler}
              logApi={logApi}
              getBack={getBack}
              dialogNameDefault={dialogNameDefault}
              editedDialogName={editedDialogName}
              saveDraft={saveDraft}
              setShowRecoverButton={setShowRecoverButton}
              handleRecoverDiagram={handleRecoverDiagram}
              undoDiagramChanges={undoDiagramChanges}
              redoDiagramChanges={redoDiagramChanges}
            />
          </>
        )}
    </ReactFlowProvider>
  );
}

export default FlowDiagram;
