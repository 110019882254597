import React from 'react';
import styles from './Condition.module.css';
import { Handle, Position } from 'reactflow';
import SubCondition from '../subCondition';
import { handleStyleOutputFilter } from '../../flowStyle/styleHandler';
function Condition({ data }) {
  const voidForm = handVoid();

  // для ещё не полностью определенных условий
  function handVoid(number = -1) {
    if (!data.subConditions) return false;
    if (number > -1) {
      if (!data.subConditions[number].value) return false; // отсутсвует значения по указаному индексу
      if (data.subConditions[number].value) return true; // присутвует значения по указаному индексу
    }
    for (let i = 0; i < data.subConditions.length; i++) {
      const element = data.subConditions[i];
      if (element.value) {
        return true;
      }
    }
    return false;
  }

  return (
    <div className={styles.block}>
      <Handle
        type="source"
        id={`filter-${data.id}`}
        position={Position.Right}
        style={handleStyleOutputFilter}
      />
      {data.subConditions?.length > 1 && voidForm && (
        <div className={styles.text}>
          {data.typeLogic === 'or'
            ? 'любому из условий:'
            : 'каждому из условий:'}
        </div>
      )}
      {voidForm ? (
        data.subConditions &&
        data.subConditions.map((sCnd, i) => {
          const length = data.subConditions?.length;
          const smartСrutch = length === i + 1 ? i : i + 1;
          const valueVoid = handVoid(smartСrutch);

          return (
            <SubCondition
              key={`${i}${sCnd.name}`}
              data={sCnd}
              line={length !== i + 1 && valueVoid}
            />
          );
        })
      ) : (
        <div className={styles.void}>Все</div>
      )}
    </div>
  );
}

export default Condition;
