import React, {
  useCallback,
  useState,
  useRef,
  useMemo,
  useEffect,
} from "react";
import ReactFlow, {
  useStoreApi,
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  isEdge,
} from "reactflow";
import "reactflow/dist/style.css";
import Message from "../components/messageBlock/message";
import Chain from "../components/chainBlock/chain";
import Filter from "../components/filterBlock/filter";
import PauseBlock from "../components/pauseBlock/pause";
import Popup from "../components/popup/root";
import ApiBlock from "../components/apiBlock";
import ConnectionLine from "../components/flowStyle/ConnectionLine";
import {
  blockDataCreate,
  capitalize,
  counterInitialized,
  edgesDefault,
  nodesDefault,
  createDiagram
} from "../components/functionForBlock";
import StartBlock from "../components/startBlock";
import Captcha from "../components/captchaBlock";
import Action from "../components/actionBlock";
import SideBar from "../components/sideBar";
import EdgeLine from "../components/flowStyle/EdgeLine";
import MenuBar from "../components/menu";
import { fetchDataDiagram } from "../api/diagramApi";
import "./flowClass.css";
import { deleteFiles, downloadTable, uploadTable } from "../api/firestoreApi";
import Loader from "../components/loader";
import {
  searchFileBlock,
  toggleCountFiles,
} from "../components/functionForFireStore";

import CreateConstant from "../components/modal/createConstant";
import adapter from "../../../adapter";
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});
const proOptions = { hideAttribution: true };

function App({
  auth,
  data,
  lock,
  dataHandler,
  errorHandler,
  logApi,
  deleteStatus,
  deleteTrigger,
  getBack,
  saveDraft,
  setShowRecoverButton,
  handleRecoverDiagram,
  undoDiagramChanges,
  redoDiagramChanges,
  dialogNameDefault,
  editedDialogName,
  dialogId,
  getBackupData
}) {
  const [nodes, setNodes] = useState(false);
  const [edges, setEdges] = useState(false);

  const [nodeID, setNodeID] = useState(null);
  const [prevNodeID, setPrevNodeID] = useState(null);

  const [popupShow, setPopupShow] = useState(false);
  const [counterNode, setCounterNode] = useState(0);

  const reactFlowWrapper = useRef(null);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);

  const [isInitialized, setIsInitialized] = useState(false);
  const [isInitializedApi, setIsInitializedApi] = useState(false);

  // работа с апи
  const [error, setError] = useState(false);
  const [modalStatus, setModalStatus] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  function loaderHandelr(bool) {
    setIsLoader(bool);
  }

  const store = useStoreApi();

  const openModal = () => setModalStatus(true);
  const closeModal = () => setModalStatus(false);

  // modal window
  useEffect(() => {
    store.setState({ ...store.getState(), openModal });
  }, []);
  useEffect(() => {
    if (!(auth.bot_id || auth.user_tg_id || auth.access_token || auth.tg_id)) {
      console.error("Отсутствуют некоторые данные api");
      return;
    }
    setIsLoader(true);
    fetchDataDiagram(auth)
    .then((dataAPIobj) => {
      if (dataAPIobj) {
        const newState = { ...store.getState(), apiInfo: dataAPIobj, auth }; // Добавить новое свойство 'apiInfo'
        store.setState(newState);
        if (logApi) {
          console.log(dataAPIobj);
        }
        setIsLoader(false);
        setIsInitializedApi(true);
      }
    })
    .catch((err) => {
      errorHandler(err.toString());
      setIsLoader(false);
      setIsInitializedApi(true);
    });
    getBotVariables(auth.bot_id, auth.access_token)
    .then(variables => {
      store.setState({ botVariables: variables });
    })
    .catch(error => {
      console.error(error);
    });
   

  }, [auth]);

  const getBotVariables =  async (botId, token) => {
    try {
      const data = {
          route : 'getVariables',
          access_token:token,
          bot_id: botId
      }

      const response = await adapter.post('', data);
      if (response.data.result === 'success') {
        return  response.data.array;
      }
      else {
        console.log(response.data);
        return null;
      }
    } catch (error) {
      rollbar.error('Error during request:', error);
    }
}





  //
  //firestore
  useEffect(() => {
    if (!auth.user_tg_id) return;
    setIsLoader(true);

    // все следующее операции с работай firebase user_id должен быть в формате строки
    const userID = auth.user_tg_id.toString();
   
    // Запрос на таблицу
    downloadTable(userID)
      .then((document) => {
        // добавляем ид
        const FBTable = {
          table: { ...document },
          id: userID,
        };
        const newState = { ...store.getState(), FBTable }; // Добавить новое свойство 'FBTable'
        store.setState(newState);
        setIsLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setIsLoader(false);
      });
  }, []);
  // режим просмотра
  useEffect(() => {
    const newState = { ...store.getState(), lock }; // Добавить новое свойство 'lock'+
    store.setState(newState);
  }, [lock]);

  // режим просмотра
  useEffect(() => {
    const newState = {
      ...store.getState(),
      close: closePopup,
    }; // Добавить новое свойство 'lock'+
    store.setState(newState);
  }, []);

  // для копирования нода
  useEffect(() => {
    const newState = { ...store.getState(), handleCounterIdNode, counterNode }; // Добавить новое свойство 'lock'+
    store.setState(newState);
  }, [counterNode]);
  function handleCounterIdNode() {
    setCounterNode((prev) => prev + 1);
  }

  // инициализация
  useEffect(() => {
    if (!isInitialized && isInitializedApi) {
      if (data) {
        //если нету информации
        if (!data?.nodes[0]) {
          setEdges(edgesDefault());
          setNodes(nodesDefault());
          setCounterNode(3);
          setIsInitialized(true);
          setFirstRender(true);
          //если есть информация
        } else {
          setNodes(data.nodes);
          setEdges(data.edges);
          const maxIdNode = counterInitialized(data.nodes);
          setCounterNode(maxIdNode);
          setIsInitialized(true);
          setFirstRender(true);
        }
      }
    }else if(isInitialized && isInitializedApi){
      if (data?.nodes[0]) {
        setNodes(data.nodes);
        setEdges(data.edges);
        const maxIdNode = counterInitialized(data.nodes);
        setCounterNode(maxIdNode);
        setIsInitialized(true);
      }
     
    }

  }, [data, isInitializedApi]);
  
  const [firstRender, setFirstRender] = useState(false);

  // подготовка цепочки к удалению
  useEffect(() => {
    if (deleteTrigger && !isLoader) {
      const { FBTable } = store.getState();
      searchFileBlock(nodes, FBTable.table, toggleCountFiles);
      deleteFiles(FBTable.table)
        .then(() =>
          uploadTable(FBTable.id, FBTable.table)
            .then(() => {
              setEdges(edgesDefault());
              setNodes(nodesDefault());
            })
            .then(() => deleteStatus("success"))
            .catch((err) => deleteStatus(err.toString()))
        )
        .catch((err) => deleteStatus(err.toString()));
    }
  }, [deleteTrigger, isLoader]);

  // тип узла сообщение
  const nodeTypes = useMemo(
    () => ({
      Message: Message,
      Chain: Chain,
      Filter: Filter,
      Pause: PauseBlock,
      Captcha: Captcha,
      Start: StartBlock,
      Api: ApiBlock,
      Action,
    }),
    []
  );

  // тип конекта
  const edgeTypes = useMemo(
    () => ({
      EdgeLine: EdgeLine,
    }),
    []
  );

  // изменения узла в позиции
  const onNodesChange = useCallback(
    (changes) => {
      setNodes((nds) => applyNodeChanges(changes, nds));
    },
    [setNodes]
  );

  // изменения ребра в позиции
  const onEdgesChange = useCallback(
    (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    [setEdges]
  );

  // обновляет узлы и новые делает
  const onConnect = useCallback(
    (connection) => {
      if (connection.source === connection.target) {
        return;
      }

      // если handler св'язывается с новым узлом, то старый конект удаляется
      const newEdges = edges.filter(
        (e) =>
          !(
            isEdge(e) &&
            e.source === connection.source &&
            e.sourceHandle === connection.sourceHandle
          )
      );
      setEdges(addEdge(connection, newEdges));
    },
    [edges, setEdges]
  );

  //передает инфу по клику блоку
  function clickNode(params, node) {
    if (node.data.role === "captcha" || node.type === "Start") return;
    setPrevNodeID(nodeID);
    setNodeID(node.id);
    setPopupShow(true);
  }

  function closePopup() {
    setPopupShow(false);
    setPrevNodeID(nodeID);
    setNodeID(null);
  }

  // обновить данные в узле(node)
  function addData(dataProps, nameProps, idNode) {
    setNodes((previous) => {
      const newArray = previous.map((node) => {
        if (node.id === idNode) {
          if (nameProps) {
            node.data.block[nameProps] = dataProps;
          }
          if (!nameProps) {
            node.data.block = dataProps;
          }
        }
        return node;
      });
      return newArray;
    });
  }

  ////////////////////////////////////////
  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const [blockOnClick, setBlockOnClick] = useState(false);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");


      // check if the dropped element is valid
      if (typeof type === "undefined" || !type) {
        return;
      }
      if (nodes.length > 24) return;

      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      const id = `${counterNode}`; // generate unique id for the new node
      const newNode = addNode(type, position, id);

      setNodes((nds) => nds.concat(newNode));
      setCounterNode(counterNode + 1);
      setBlockOnClick(true);
    },
    [reactFlowInstance, counterNode, nodes.length]
  );
  ////////////////////////////////////////

  //добавляем узел
  function addNode(text, position, id) {
    const dataObj = blockDataCreate(text);
    const typeCapitalize = capitalize(text);
    const newNode = {
      id,
      position,
      data: dataObj,
      type: typeCapitalize,
    };

    return newNode;
    // setNodes((nodes) => nodes.concat(newNode));
  }

  const { isDeleted, isConfirmed } = store.getState();

  function clickReactFlow() {
    if (popupShow) setPopupShow(false);
    setPrevNodeID(nodeID);
    setNodeID(null);
  }



  useEffect(() => {
    if (firstRender) {
      const diagram = createDiagram(edges, nodes);
      const data = {
        edges:edges,
        nodes:nodes,
        diagram:diagram,
      };
      getBackupData(data);
    }
    setFirstRender(false);
  }, [firstRender]);

 

  useEffect(() => {
    if (blockOnClick || isDeleted || isConfirmed ) {
      
      const diagram = createDiagram(edges, nodes);
      const data = {
        edges:edges,
        nodes:nodes,
        diagram:diagram,
      };
      if(!firstRender){
        store.setState({ isUndo: true });
      }
      getBackupData(data);
    }
    if (isDeleted) {
      store.setState({ isDeleted: false });
    }else if(isConfirmed){
      store.setState({ isConfirmed: false });
    }else if(blockOnClick){
      setBlockOnClick(false);
    }
    
  }, [blockOnClick, isDeleted, isConfirmed]);

  useEffect(() => {
    if (dialogId) {
      store.setState({ currentChain: dialogId });
    }
    
  }, [dialogId]);

  return (
    <>
      {isLoader && <Loader />}

      {nodes && edges && (
        <>
          {!lock && (
            <MenuBar
            loaderHandelr={loaderHandelr}
            dataHandler={dataHandler}
            edges={edges}
            nodes={nodes}
            getBack={getBack}
            saveDraft={saveDraft}
            editedDialogName={editedDialogName}
            dialogNameDefault={dialogNameDefault}
            setShowRecoverButton={setShowRecoverButton}
            handleRecoverDiagram={handleRecoverDiagram}
            undoDiagramChanges={undoDiagramChanges}
            redoDiagramChanges={redoDiagramChanges}
          />
          )}
          <div
            className="WrapperFlow"
            style={lock ? { height: "100vh" } : null}>
            {!lock && <SideBar addNode={addNode} popupShow={popupShow} />}
            <div className="reactFlowWrapper" ref={reactFlowWrapper}>
              <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={lock ? undefined : onNodesChange}
                onEdgesChange={lock ? undefined : onEdgesChange}
                onConnect={lock ? undefined : onConnect}
                onNodeClick={lock ? undefined : clickNode}
                nodeTypes={nodeTypes}
                connectionLineComponent={ConnectionLine}
                onInit={setReactFlowInstance}
                defaultEdgeOptions={{ type: "EdgeLine" }}
                fitView={false}
                deleteKeyCode={null}
                maxZoom={1.5}
                minZoom={0.5}
                onDrop={onDrop}
                edgeTypes={edgeTypes}
                onDragOver={onDragOver}
                onPaneClick={clickReactFlow}
                proOptions={proOptions}
                translateExtent={[
                  [-1000, -1000],
                  [4250, 3250],
                ]}
                nodeExtent={[
                  [0, 0],
                  [3020, 2000],
                ]}></ReactFlow>
            </div>
            {popupShow && (
              <Popup
                prevNodeID={prevNodeID}
                key={nodeID}
                nodeID={nodeID}
                nodes={nodes.filter((node) => node.id === nodeID)[0]}
                addData={addData}
                close={closePopup}
                isDeleted={isDeleted}
              />
            )}
            {modalStatus && <CreateConstant close={closeModal} />}
          </div>
        </>
      )}
    </>
  );
}

export default App;
