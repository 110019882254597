import React, {useState, useEffect} from 'react';
import { saveAs } from 'file-saver';
import { createDiagram } from '../functionForBlock';
import styles from './Menu.module.css';
import { preparationFile, searchFileBlock } from '../functionForFireStore';
import { useStore } from 'reactflow';
import { deleteFiles, uploadTable } from '../firestoreApi';
import { FaPencilAlt } from "react-icons/fa";
import { toast } from 'react-toastify';
import { FaUndoAlt, FaRedoAlt } from 'react-icons/fa';

function MenuBar({ edges, nodes, dataHandler, loaderHandelr, getBack, saveDraft, editedDialogName,dialogNameDefault, undoDiagramChanges, redoDiagramChanges}) {
  
  const store = useStore();
  const { FBTable, isUndo } = store;

  async function dataSaveFile() {
    try {
      if (!FBTable) {
        console.error('ошибка подключения firebase files');
        return;
      }
      loaderHandelr(true);

      const arrayFile = searchFileBlock(nodes, FBTable.table);
      if (arrayFile[0]) {
        await preparationFile(arrayFile, FBTable.id, FBTable.table);
      }

      await deleteFiles(FBTable.table);

      await uploadTable(FBTable.id, FBTable.table);
      const diagram = createDiagram(edges, nodes);
      const data = {
        edges,
        nodes,
        diagram,
      };
      if (dataHandler) {
      // Проверяем на пустоту сообщения, если есть подсвечиваем
      let hasEmptyText = false;
        nodes.forEach(item => {
          if (item.data && item.data.role === 'message') {
             
              item.data.block.elements.forEach(element => {
                  if (element.type === "message text" && element.textValue === "") {
                      hasEmptyText = true;
                  }
              });
      
              if (hasEmptyText) {
                  item.data.isEmpty = true;
                  
              } else {
                  delete item.data.isEmpty;
              }
          }
        });
      if (!hasEmptyText) {
        dataHandler(data);
      }else{
        toast.error('Найдены пустые сообщения! Проверьте подсвеченные блоки');
      }
    
      } else {
        console.error('Ненайден обработчик для data ');
      }
      loaderHandelr(false);
    } catch (error) {
      loaderHandelr(false);
      console.error('Ошибка отправки данных', error.toString());
      return;
    }
  }
   const handleSaveDraft = () => {
    const diagram = createDiagram(edges, nodes);
    const data = {
      edges,
      nodes,
      diagram,
    };
    saveDraft(data, null);
   }


useEffect(() => {

  const intervalId = setInterval(() => {

    const diagram = createDiagram(edges, nodes);
    const data = {
      edges,
      nodes,
      diagram,
    };
    saveDraft(null,data)


  },30000);

  return () => {
    clearInterval(intervalId);
  };
}, []);




const [dialogName, setDialogName] = useState('');

useEffect(() => {
  if (dialogNameDefault !== '') {
    setDialogName(dialogNameDefault);
  }else{

  }
}, []); 

const [nameEdit, setNameEdit] = useState(false);

const handleInputChange = (event) => {
  setDialogName(event.target.value);
};

const handleSave = () => {
  if (!dialogName) {
    toast.error('Дайте название цепочки');
    return;
  }
  editedDialogName(dialogName);
  setNameEdit(false);
};



const [adaptiveButtons, setAdaptiveButtons] = useState(false);
const handleWindowSizeChange = () => {
  if (window.innerWidth <= 1240) {
    setAdaptiveButtons(true);
  }else{
    setAdaptiveButtons(false);
  }
  
};

useEffect(() => {
  window.addEventListener('resize', handleWindowSizeChange);

  return () => {
    window.removeEventListener('resize', handleWindowSizeChange);
  };
}, []);

const [isMenuOpen, setMenuOpen] = useState(false);

const toggleMenu = () => {
  setMenuOpen(!isMenuOpen);
};

const [isRedo, setIsRedo] = useState(false);

return (
  <div className={styles.menu}>
    <div className={styles['chain-name-container']}>
      {dialogName && dialogNameDefault !== '' ? (
        <>
          {!nameEdit ? (
            <>
              <p>{dialogName}</p>
              <>
                <FaPencilAlt onClick={() => setNameEdit(true)} className={styles['button-edit']}  title='Редактировать'/>
              </>
            </>
          ):(
            <>
              <input placeholder='Введите имя цепочки'  className={styles['chain-name-input']} type="text" value={dialogName} onChange={handleInputChange} />
              <button className={styles['button-menu']} onClick={handleSave}>
                Сохранить
              </button>
            </>
          )}
        </>
      ):(
        <>
            <input placeholder='Введите имя цепочки'  className={styles['chain-name-input']} type="text" value={dialogName} onChange={handleInputChange} />
            <button className={styles['button-menu']} onClick={handleSave}>
              Сохранить
            </button>
        </>
      )}
    </div>
    {adaptiveButtons ? (
      <div className={styles['adaptive-buttons-container']}>
      
      <div className={`${styles['hamburger-menu']} ${isMenuOpen ? styles['open'] : ''}`} onClick={toggleMenu}>
        <span></span>
      </div>

      <div className={`${styles['slide-menu']} ${isMenuOpen ? styles['open'] : ''}`}>
        <p className={styles['adaptive-buttons__title']}>Выберите действие:</p>
        <div className={styles['button-menu']}  
          onClick={
            () => {
              if (isUndo) {
                setIsRedo(true);
                undoDiagramChanges();
              }
            } 
          }
        >
          Отменить последнее изменение
        </div>
        <div className={styles['button-menu']} 
         onClick={
          () => {
            if (isUndo) {
              setIsRedo(true);
              undoDiagramChanges();
            }
          } 
        }>
          Вернуть изменение изменение
        </div>
        <div className={styles['button-menu']} onClick={dataSaveFile}>
          Сохранить
        </div>
       
        <div className={styles['button-menu']} onClick={getBack}>
          Вернуться назад
        </div>
        <div className={styles['button-menu']} onClick={handleSaveDraft}>
          Копировать в хранилище
        </div>
      </div>
    </div>
    ) : (
      <>
        <div className={styles['button-container']}>
        <div  className={`${styles['button-menu']} ${!isUndo ? styles['button-disabled'] : ''}`} 
          onClick={
            () => {
              if (isUndo) {
                setIsRedo(true);
                undoDiagramChanges();
              }
            } 
          }>
          <FaUndoAlt className='fa-arrow'></FaUndoAlt >
        </div>
        <div className={`${styles['button-menu']} ${!isRedo ? styles['button-disabled'] : ''}`}
        onClick={
          () => {
            if (isRedo) {
              redoDiagramChanges();
            }
          } 
        }>
          <FaRedoAlt className='fa-arrow'></FaRedoAlt  >
        </div>
        <div className={styles['button-menu']} onClick={handleSaveDraft}>
          Копировать в хранилище
        </div>
        <div className={styles['button-menu']} onClick={getBack}>
          Вернуться назад
        </div>
        <div className={styles['button-menu']} onClick={dataSaveFile}>
          Сохранить
        </div>
      </div>
      </>
    )}
   
  
  </div>
);
}

export default MenuBar;