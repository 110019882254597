import React, { useEffect, useState } from "react";
import styles from "./Alert.module.css";

import TextDescription from "../../MessageTypeText/textDescription";

function Alert({ data, handler }) {
  const [value, setValue] = useState("");
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (data.text && !init) {
      setValue(data.name);
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      handler({
        ...data,
        ...(value && {
          text: value,
        }),
      });
    }
  }, [value, init]);
  return (
    <div className={styles.box}>
      <TextDescription
        textData={(newValue) => setValue(newValue)}
        initTextData={value}
        sx={{ borderRadius: 10 }}
      />
    </div>
  );
}

export default Alert;
