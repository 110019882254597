import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRegPlusSquare, FaRegMinusSquare} from 'react-icons/fa';
import ReactModal from 'react-modal';
import './DrawPage.css';
import adapter from '../../../../adapter';
import refreshToken from '../../../../refreshToken';
import Preloader from '../../../../Preloader';
import backIcon  from '../../../../assets/get_back.png';
import { toast } from 'react-toastify';
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});

ReactModal.setAppElement('#root');

function DrawPage ({drawBotsData, handleReturnToPanel}) {
    
    const navigate = useNavigate();
    const storedData = JSON.parse(localStorage.getItem('dashboardData'));
    const dashboardData = storedData ? storedData: null;

    const [isLoading, setIsLoading] = useState(false);
    const [drawBotsInfo, setDrawBotsInfo] = useState([]);
    const [currBot, setCurrBot] = useState('');
    
    const [modalOpen, setModalOpen] = useState(false);

    const [isDataUpdate, setIsDataUpdate] = useState(false);

    const handleFinishDraw = async (botId, drawId) => {
        setIsLoading(true);
        try {
            const formData = {
              bot_id : botId,
              draw_id: drawId,
              access_token : dashboardData.token,
              route : 'finishDraw',
            };
            const response = await adapter.post('', formData);
            if (response.data.result === 'success') {
              setIsDataUpdate(true);
              toast.success('Розыгрыш закончен!');
             
            }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
              try {
                const refreshTokenResult = await refreshToken();
                if (refreshTokenResult === 'logout') {
                  navigate('/', { replace: true });
                }else{
                  dashboardData.token = refreshTokenResult;
                  const formData = {
                    bot_id : botId,
                    draw_id: drawId,
                    access_token : dashboardData.token,
                    route : 'finishDraw',
                  };
                  const response = await adapter.post('', formData);
                  if (response.data.result === 'success') {
                    setIsDataUpdate(true);
                    toast.success('Розыгрыш закончен!');
                  }
                }
              } catch (error) {
                toast.error(error);
              }
            }else {
              toast.error(response.data.text);
            }
          } catch (error) {
            toast.error(error);
            rollbar.error('Error during request:', error);
          }finally{
            setIsLoading(false);
          }
    }

    const [winnersId, setWinnersId] = useState(['']);
    const addNumberInput = () => {
       
        setWinnersId([...winnersId, '']);
      };
    
      const removeNumberInput = (index) => {
        const newInputs = [...winnersId];
        newInputs.splice(index, 1);
        setWinnersId(newInputs);
      };
      const handleNumberInputChange = (index, event) => {
        const newInputs = [...winnersId];
        newInputs[index] = event.target.value;
        setWinnersId(newInputs);
      };

      const [winnersBotId, setWinnersBotId] = useState('');
      const [winnersDrawId, setWinnersDrawId] = useState('');

    const handleNotifyWinners = async (botId, drawId) => {
        setWinnersDrawId(drawId);
        setWinnersBotId(botId);
        setModalOpen(true);
       
    }
    const [message, setMessage] = useState('');
    const handleTextareaChange = (e) => {
        setMessage(e.target.value);
      
      };
    const [winnersNotification, setWinnersNotification] = useState(false);
    const handleCloseModal = async () => {
        setIsLoading(true);
        try {
            const formData = {
              bot_id : winnersBotId,
              draw_id: winnersDrawId,
              winners: winnersId,
              message:message,
              access_token : dashboardData.token,
              route : 'notifyWinners',
              
            };
            setModalOpen(false);
            setWinnersNotification(true);
           
            const response = await adapter.post('', formData);
      
            if (response.data.result === 'success') {
                setIsDataUpdate(true);
                setMessage('');
                setWinnersId(['']);
                setWinnersBotId('');
                setWinnersDrawId('');
                toast.success('Победители объявлены!');
             
            }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
              try {
                const refreshTokenResult = await refreshToken();
              
                if (refreshTokenResult === 'logout') {
                  navigate('/', { replace: true });
                }else{
                  dashboardData.token = refreshTokenResult;
                  const formData = {
                    bot_id : winnersBotId,
                    draw_id: winnersDrawId,
                    winners: winnersId,
                    message:message,
                    access_token : dashboardData.token,
                    route : 'notifyWinners',
                    
                  };
                  setModalOpen(false);
                  setWinnersNotification(true);
                 
                  const response = await adapter.post('', formData);
            
                  if (response.data.result === 'success') {
                      setIsDataUpdate(true);
                      setMessage('');
                      setWinnersId(['']);
                      setWinnersBotId('');
                      setWinnersDrawId('');
                      toast.success('Победители объявлены!');
                   
                  }
                }
              
              } catch (error) {
                console.error(error);
                toast.error(error)
              }
            }else {
              toast.error(response.data.text);
            }
          } catch (error) {
            console.error(error);
            toast.error(error);
            rollbar.error('Error during request:', error);
          }finally{
            setIsLoading(false);
            setWinnersNotification(false);
          }
    };

    const cancelNotifyWinners = async() => {
        setModalOpen(false);
        setMessage('');
        setWinnersId(['']);
        setWinnersBotId('');
        setWinnersDrawId('');
    }


    const getDrawsInfo = async () =>{
        try {
            const formData = {
              bot_id : drawBotsData.id,
              access_token : dashboardData.token,
              route : 'getDrawsInfo',
              
            };
            const response = await adapter.post('', formData);
      
            if (response.data.result === 'success') {

              const sortedInfo = response.data.array.sort((a,b)=> b.id -a.id);
              setDrawBotsInfo(sortedInfo);
             
            }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
              try {
                const refreshTokenResult = await refreshToken();
                if (refreshTokenResult === 'logout') {
                  navigate('/', { replace: true });
                }else{
                  dashboardData.token = refreshTokenResult;
                  const formData = {
                    bot_id : drawBotsData.id,
                    access_token : dashboardData.token,
                    route : 'getDrawsInfo',
                    
                  };
                  const response = await adapter.post('', formData);
            
                  if (response.data.result === 'success') {
                    const sortedInfo = response.data.array.sort((a,b)=> b.id -a.id);
                    setDrawBotsInfo(sortedInfo);
                  }
                }
              } catch (error) {
                console.error(error);
                toast.error(error);
              }
            }else {
              toast.error(response.data.text);
            }
          } catch (error) {
            toast.error(error);
            rollbar.error('Error during request:', error);
          }
    }

    useEffect(() => {
        
        setCurrBot(drawBotsData.name);
        getDrawsInfo();
        if (isDataUpdate) {
            setIsDataUpdate(false);
        }
       
    }, [drawBotsData,isDataUpdate]);
    return ( 
        <>
            <div className="card ">
                <h1 className='main-title'>
                    <p>История розыгрышей</p>
                    {/* <img className='icon' src={gearsIcon}/> */}
                </h1>
                <div className='d-flex space-between form-elem'>
                   
                    <div>
                        <p className='draw-title'>Вы просматриваете историю бота: <strong>{currBot}</strong></p>
                    </div>
                    <div>
                        <a className="breadcrumbs d-flex" onClick={handleReturnToPanel}>
                            Вернуться к статистике
                            <img className='icon' src={backIcon}/>
                        </a>
                    </div>
                </div>
                
                {isLoading ? (
                    <>
                        
                        {winnersNotification ? ( 
                            <p className='preloader-title'>Объявляем победителей, подождите</p>)
                            : ( <p className='preloader-title'>Идёт завершение розыгрыша, подождите</p>
                        )}
                       
                        <Preloader></Preloader>
                    </>
                ) : (
                <>
                  
                <div className='table-body'>
                  <table id='drawTable' className='bot-management '>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Название розыгрыша</th>
                                <th>ID</th>
                                <th>Дата начала</th>
                                <th>Дата конца</th>
                                <th>Статистика</th>
                                {/* <th>Количество победителей</th> */}
                                <th>Таблица для розыгрыша</th>
                                <th>Действие</th>
                            </tr>
                        </thead>
                        <tbody>
                            {drawBotsInfo.map((item, index) => {
                                const dateStr = item.date;
                                const date = new Date(dateStr);
                                date.setMilliseconds(Math.round(date.getMilliseconds() / 1000) * 1000);
                            
                                const formattedDate = date.toISOString().slice(0, 19).replace("T", " "); 
                                return (
                                  
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.name}</td>
                                    <td>{item.id}</td>
                                    <td>{formattedDate}</td>
                                    <td>{item.date_end ? item.date_end : 'Ещё не окончен'}</td>
                                    <td>
                                        {item.table_id ? (<a target='blank_' href={`https://docs.google.com/spreadsheets/d/${item.table_id}`}>Просмотреть</a>):(<p>Нет данных</p>)}
                                        
                                    </td>
                                    {/* <td></td> */}
                                    <td>
                                        {item.table_id_final ? (<a target='blank_' href={`https://docs.google.com/spreadsheets/d/${item.table_id_final}`}>Просмотреть</a>):(<p>Ещё нет победителей</p>)}
                                    </td>
                                    <td>
                                        <button
                                            className='action-btn btn_end-draw'
                                            onClick={() => handleFinishDraw(item.bot_id, item.id)}
                                            disabled={item.table_id_final || item.status === 'finished' ? true : false}
                                        >
                                            Закончить розыгрыш
                                        </button>
                                        <button
                                            className='action-btn  btn_success btn-notify-winners'
                                            onClick={() => handleNotifyWinners(item.bot_id, item.id)}
                                            disabled={(item.status !== 'finished' && item.table_id_final === '') || item.winners !== '' ? true : false}
                                            
                                        >Объявить победителей
                                        </button>
                                    </td>
                                </tr>
                                );
                            })}
                        </tbody>
                  </table>
                  
                </div>
                    
                        
                </>
                )}
            </div>
            {modalOpen && (
            <ReactModal
            isOpen={modalOpen}
            onRequestClose={handleCloseModal}
            className="modal-content"
            overlayclassName="modal-overlay"
            contentLabel="Модальное окно"
            >
                <div className="modal-header">
                    <div className="">
                        <h2 className="main-title"><b>Закончить розыгрыш</b></h2>
                       
                    </div>       
                </div>
                <div className="modal-body">  
                    
                <form>
                    <div className='form-elem'>
                        <p className="form-elem">
                            Добавьте одного или нескольких победителей!
                        </p>
                        <div className='winners-container'>
                            {winnersId.map((winnerId, index) => (
                            <div className='form-elem winners-input' key={index}>
                                <p>Победитель номер: {index + 1}</p>
                                <input
                                type='text'
                                value={winnerId}
                                onChange={(event) => handleNumberInputChange(index, event)}
                                className='draw-input'
                                placeholder='Введите id победителя'
                                />
                                {index >= 1 ? (
                                     <button
                                     type='button'
                                     className='action-btn btn_disconnect'
                                     onClick={() => removeNumberInput(index)}
                                     >
                                         <FaRegMinusSquare className="fa"></FaRegMinusSquare>Удалить
                                     </button> 
                                ): null}
                               
                            </div>
                            )).reverse()}
                        </div>
                            <div>
                                <button
                                type='button'
                                className='action-btn btn_success add-winner-btn'
                                onClick={addNumberInput}
                                >
                                <FaRegPlusSquare className="fa"></FaRegPlusSquare>
                                Добавить ещё
                                </button>
                            </div>
                            
                            <textarea value={message} onChange={handleTextareaChange} className='draw-textarea' placeholder='Сообщение для победителей' ></textarea>
                            <p className="form-elem">
                                Для того, чтобы закончить розыгрыш, добавьте сообщение, которое получат победители!
                            </p>
                            <div className='winners-buttons'>
                                <button 
                                // disabled={buttonReady ? false:true}  
                                type='button' className='main-btn' onClick={handleCloseModal}>Подтвердить</button>
                                <button  type='button' className='main-btn btn_disconnect' onClick={cancelNotifyWinners}>Отмена</button>
                            </div>
                           
                    </div>
                </form>
                                    
                </div>
            </ReactModal>
            )}
        </>
    );
}

export default DrawPage ;