import React, { useState, useEffect } from 'react';
import DropZoneFile from '../dropZoneFile';

import styles from './TypeFile.module.css';
import TextDescription from '../../MessageTypeText/textDescription';
import AddButton from '../../MessageTypeText/addButton';
import MessageMoveType from '../../MessageMoveType';

function TypeFile({
  blockElementHandler,
  typeContentInit,
  moveUp,
  moveDown,
  deleteBlock,
  index,
  length,
}) {
  const [fileData, setFileData] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // для стрелок перемещения
  const [blockStatus, setBlockStatus] = useState(false);

  useEffect(() => {
    if (!isInitialized && typeContentInit) {
      setFileData(typeContentInit);
      setIsInitialized(true);
    }
  }, [typeContentInit]);

  // Обновляем информацию
  useEffect(() => {
    if (fileData) {
      blockElementHandler(fileData, index);
    }
  }, [fileData]);

  function blockSatusHandler(boolen) {
    if (blockStatus !== boolen) {
      setBlockStatus(boolen);
    }
  }

  // для фото
  function fileDataHandler(fileData) {
    setFileData((previous) => {
      const newImageData = {
        ...previous,
        file: fileData,
      };
      return { ...newImageData };
    });
  }

  // для кнопок
  function buttonsData(buttonsArr) {
    setFileData((previous) => {
      const newFileData = { ...previous, buttons: buttonsArr };
      return { ...newFileData };
    });
  }
  // для выбора кнопок в ряд
  function selectData(value) {
    setFileData((previous) => {
      const newFileData = { ...previous, maxBtnRow: value };
      return { ...newFileData };
    });
  }

  return (
    <>
      {fileData && (
        <div
          className={styles.fileBlock}
          onMouseEnter={() => blockSatusHandler(true)}
          onMouseLeave={() => blockSatusHandler(false)}
        >
          <MessageMoveType
            moveUp={moveUp}
            moveDown={moveDown}
            blockStatus={blockStatus}
            deleteBlock={deleteBlock}
            index={index}
            length={length}
          />
          <DropZoneFile initFileData={fileData} fileHandler={fileDataHandler} />
          <AddButton
            buttonsData={buttonsData}
            initButtonsData={fileData.buttons}
            selectData={selectData}
            initSelectData={fileData.maxBtnRow}
          />
        </div>
      )}
    </>
  );
}

export default TypeFile;
