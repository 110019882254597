import React, { useEffect, useState, useRef } from 'react';

import '../../../../../index.css';
import './MailingSchedule.css';
import ScheduleMailingConstructor from './scheduleMailingConstructor/ScheduleMailingConstructor'
import Select from 'react-select';
import backIcon  from '../../../../../assets/get_back.png';


const MailingSchedule = ({ botsData, spamsSchaludeData, refreshSpamsData}) => {


    const botsOptions = botsData.map(item => ({
        value: item.id,
        label: item.bot_name
    }));
    const [selectedBot, setSelectedBot] = useState(null);
    const [scheduleData, setScheduleData] = useState(null);
    const [scheduleMailing, setScheduleMailing] = useState(false);
    const [spamsData, setSpamsData]= useState([]);

    const handleSetCurrentBot = (botId) => {
        setSelectedBot(botId);
       
        setSpamsData(spamsSchaludeData.filter(item => item.bot_id.includes(botId)));
       
        setScheduleMailing(false);
    };
    const handleSelectMailing = (id) =>{
        setSelectedBot(null);
        setScheduleMailing(true);
        setScheduleData(spamsSchaludeData.find(item => item.id === id));
        
    }

   
    
    const [returnToSchedule, setReturnToSchedule] = useState(false);

    const resetScheduleData = (reset) =>{
        if (reset) {
            setScheduleMailing(false);
            setSpamsData([]);
            setScheduleData(null);
            setReturnToSchedule(false);
        }
    }

    useEffect(() => {
        if (scheduleData) {
            setReturnToSchedule(false);
        }
    }, [scheduleData]);
    
    
        return (
            <form className="form-card">
                <h2 className='card-title'>
                    <p>{!scheduleData ? 'Отложенные рассылки' : 'Редактировать отложенную рассылку'}</p>
                   
                </h2>
               
                    
                <div className='card'>
                    <div className="form-elem ">
                        {!scheduleData && (
                            <> 
                                <Select
                                    name="botId"
                                    placeholder="Выберите бота"
                                    options={botsOptions}
                                    value={selectedBot ? botsOptions.find(option => option.value === selectedBot) : null}
                                    onChange={(e) => {
                                        handleSetCurrentBot(e.value);
                                    }}
                                />
                            </>
                        )}
                        {selectedBot && spamsData && spamsData.length > 0 ? (
                            <>
                                <p className='form-elem'><p>Выберите рассылку из списка ниже для редактирования:</p></p>

                                <div className='table-body'>
                                    <div className='schalude-mail-container'>
                                                    <p>
                                                        <strong>Название рассылки:</strong>  
                                                    </p>
                                                    <p>
                                                        <strong>Дата отправки:</strong>
                                                    </p>
                                                    <p>
                                                        <strong>Количество получателей:</strong>
                                                    </p>
                                    </div>
                                
                                    {spamsData.map(item => (

                            
                                        
                                            <div className='schalude-mail-container' onClick={()=>handleSelectMailing(item.id)}>
                                                <p>
                                                    {item.name}  
                                                </p>
                                                <p>
                                                    {item.time_send}  
                                                </p>
                                                <p>
                                                    {item.receivers}  
                                                </p>
                                                
                                                
                                            </div>
                                    )).reverse()
                                    }

                                </div>


                                
                            </>
                        ) : (
                            <>
                                {selectedBot && spamsData.length === 0 ? (
                                    <p className='form-elem'><p>У выбраного вами бота нет отложенных рассылок !</p></p>
                                ):null}
                            </>
                            
                        ) }
                        {scheduleMailing && (
                            <>
                            
                                {!returnToSchedule && (
                                    <a className="breadcrumbs d-flex" onClick={resetScheduleData}>
                                        Вернуться к выбору рассылок
                                        <img className='icon' src={backIcon}/>
                                    </a>
                                )}
                                <ScheduleMailingConstructor botsData={botsData} scheduleData={scheduleData} returnToSchedule={returnToSchedule} setResetScheduleData={resetScheduleData} refreshSpamsData={refreshSpamsData}></ScheduleMailingConstructor>
                            </>
                           
                        )
                        }
                    </div>
                </div>
            </form>      

        );
};

export default MailingSchedule;