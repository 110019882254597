import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Sidebar from './sidebar/Sidebar';
import Workspace from './workSpace/WorkSpace';
import '../../index.css';


const DashboardPage = ({ routeToBotScript, botScriptId, botScriptName}) => {
  
  let storedAppState = localStorage.getItem('storedAppState');
  storedAppState = storedAppState? JSON.parse(storedAppState) : null;

  
    const [selectedComponent, setSelectedComponent] = useState(null);
    const [refreshPage, setRefreshPage] = useState(false);

    const setCurrComponent = (e) => {
      setSelectedComponent(e);
    }
    const handleRefreshState = (e) => {
      setRefreshPage(prevRefreshPage => !prevRefreshPage);
 
    }

   
   

    return (
      <div className="dashboard-wrapper">
        <Sidebar setCurrComponent={setCurrComponent} handleRefreshState={handleRefreshState}/>
        <Workspace botScriptId={botScriptId} botScriptName={botScriptName} selectedComponent={selectedComponent} refreshPage={refreshPage}/>
      </div>
    );
  }

export default DashboardPage;