import React from 'react';
import { Handle, Position } from 'reactflow';
import {
  handleStyleInput,
  handleStyleOutput,
} from '../../flowStyle/styleHandler';
import styles from './Pause.module.css';
import DeleteBlock from '../../deleteBlock';
import CopyBlock from '../../copyBlock';

const timeList = {
  second: 'секунд',
  minute: 'минут',
  hour: 'часов',
  day: 'дней',
  week: 'недель',
};

function PauseBlock({ data, selected, id }) {
  const pause = data.block.pause;
  let duration;
  if (pause.duration) {
    duration = pause.duration.split(' ');
  }
  return (
    <>
      <Handle
        type="target"
        id={`input-${id}`}
        position={Position.Left}
        style={handleStyleInput}
      />
      <div
        className={
          selected ? `${styles.block} ${styles.blockSelected}` : styles.block
        }
      >
        <div className={styles.title}>Пауза</div>
        <div className={styles.contentBlock}>
          {!pause.duration && 'Введите значение задержки'}
          {pause.duration && (
            <div>
              {pause.pause_type === 'time' ? 'Задержка' : 'Задержка до '}

              {pause.pause_type === 'time' ? (
                <span className={styles.time}>
                  {duration[0]} {timeList[duration[1]]}
                </span>
              ) : (
                <>
                  <span className={styles.time}>{duration[0]}</span>
                  <span className={styles.time}>{duration[1]}</span>
                </>
                // <span className={styles.time}>{pause.duration}</span>
              )}
            </div>
          )}
          {pause.duration && (
            <small className={styles.small}>
              Продолжить цепочку:{' '}
              {pause.active_type === 'passive' ? 'При неактивности' : 'Всегда'}
            </small>
          )}
        </div>
      </div>
      {selected && <DeleteBlock id={id} />}
      {selected && <CopyBlock id={id} />}
      <Handle
        type="source"
        position={Position.Right}
        id={`output-${id}`}
        style={handleStyleOutput}
      />
    </>
  );
}

export default PauseBlock;
