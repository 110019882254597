import React from 'react';
import styles from './StartBlock.module.css';
import { Handle, Position } from 'reactflow';
import { start } from '../flowStyle/styleHandler';

function StartBlock() {
  return (
    <>
      <div className={styles.block}>
        <div className={styles.title}>Start</div>
      </div>
      <Handle
        type="source"
        id={`output-0`}
        position={Position.Right}
        style={start}
      />
    </>
  );
}

export default StartBlock;
