import React from 'react';
import { BiTimer } from 'react-icons/bi';

import styles from './BlockPause.module.css';

function BlockPause({ data }) {
  return (
    <div className={styles.block}>
      <span className={styles.load}>•••</span>
      <span className={styles.second}>
        <BiTimer className={styles.icon} />
        {data.pauseValue} сек.
      </span>
    </div>
  );
}

export default BlockPause;
