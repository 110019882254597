import React, { useState, useEffect } from 'react';
import DropZone from '../dropZone';
import AddButton from '../../MessageTypeText/addButton';
import TextDescription from '../../MessageTypeText/textDescription';
import styles from './TypePhoto.module.css';
import MessageMoveType from '../../MessageMoveType';

const areaTxtLimit = 1024;

function TypePhoto({
  blockElementHandler,
  typeContentInit,
  moveUp,
  moveDown,
  deleteBlock,
  index,
  length,
}) {
  const [imageData, setImageData] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  // для стрелок перемещения
  const [blockStatus, setBlockStatus] = useState(false);

  function blockSatusHandler(boolen) {
    if (blockStatus !== boolen) {
      setBlockStatus(boolen);
    }
  }
  useEffect(() => {
    if (!isInitialized && typeContentInit) {
      setImageData(typeContentInit);
      setIsInitialized(true);
    }
  }, [typeContentInit]);

  // Обновляем информацию
  useEffect(() => {
    if (imageData) {
      blockElementHandler(imageData, index);
    }
  }, [imageData]);

  // для фото
  function fileHandler(fileData) {
    setImageData((previous) => {
      const newImageData = {
        ...previous,
        image: fileData,
      };
      return { ...newImageData };
    });
  }

  // для кнопок
  function buttonsData(buttonsArr) {
    setImageData((previous) => {
      const newFileData = { ...previous, buttons: buttonsArr };
      return { ...newFileData };
    });
  }
  // для выбора кнопок в ряд
  function selectData(value) {
    setImageData((previous) => {
      const newFileData = { ...previous, maxBtnRow: value };
      return { ...newFileData };
    });
  }
  // для текста
  function textData(txt) {
    setImageData((previous) => {
      const newImageData = {
        ...previous,
        image: { ...previous.image, textValue: txt },
      };
      return { ...newImageData };
    });
  }

  return (
    <>
      {imageData && (
        <div
          className={styles.photoBlock}
          onMouseEnter={() => blockSatusHandler(true)}
          onMouseLeave={() => blockSatusHandler(false)}
        >
          <MessageMoveType
            moveUp={moveUp}
            moveDown={moveDown}
            blockStatus={blockStatus}
            deleteBlock={deleteBlock}
            index={index}
            length={length}
          />
          <DropZone fileHandler={fileHandler} initFileData={imageData.image} />
          {imageData.image.imageValue && (
            <div className={styles.textBlock}>
              <div className={styles.textContent}>
                <TextDescription
                  textData={textData}
                  limit={areaTxtLimit}
                  initTextData={imageData.image.textValue}
                  placeholder={'Введите заголовок'}
                />
              </div>
            </div>
          )}
          <AddButton
            buttonsData={buttonsData}
            initButtonsData={imageData.buttons}
            selectData={selectData}
            initSelectData={imageData.maxBtnRow}
          />
        </div>
      )}
    </>
  );
}

export default TypePhoto;
