import React, { useState, useEffect } from "react";
import Condition from "../../UI/FilterCondition";
import ConditionsGroup from "../../UI/FilterConditionsGroup";
import IfNotBlock from "../../UI/FilterIfNotBlock";
import { counterInitialized } from "../../../functionForBlock";

import styles from "./PopupFilterContent.module.css";

function FilterContent({ addData, nodeID, nodes }) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [counterElements, setCounterElements] = useState(null);

  const [data, setData] = useState(null);

  useEffect(() => {
    if (data && isInitialized) {
      addData(data, "conditions", nodeID);
    }
  }, [data]);

  //иннициализация
  useEffect(() => {
    const dataNode = nodes.data.block;
    if (dataNode && !isInitialized) {
      // если данные есть
      setIsInitialized(true);
      setData(dataNode.conditions);
    }
  }, [nodes.data.block]);

  function createCndGroup() {
    const newObj = {
      id: counterElements,
      typeLogic: "and",
      subConditions: [
        {
          name: "",
          condition: "",
          value: "",
        },
      ],
    };

    setData((previous) => [...previous, newObj]);

    setCounterElements(counterElements + 1);
  }

  function changeCndGroup(obj, id) {
    setData((previous) => {
      const newArray = [...previous];
      for (let i = 0; i < newArray.length; i++) {
        const element = newArray[i];
        if (element.id === id) {
          newArray.splice(i, 1, obj);
          break;
        }
      }
      return newArray;
    });
  }

  useEffect(() => {
    if (data && isInitialized && !counterElements) {
      const maxIDElement = counterInitialized(data);
      setCounterElements(maxIDElement);
    }
  }, [data]);

  function deleteCndGroup(id) {
    setData((previous) => {
      const newArray = [...previous].filter((cndGroup) => cndGroup.id !== id);
      return [...newArray];
    });
  }
  return (
    <div>
      <div>
        {data &&
          data.map((cnd, i) => (
            <div key={`block-${cnd.id}`}>
              {i > 0 && <IfNotBlock />}
              <ConditionsGroup
                select={cnd.typeLogic}
                changeCndGroup={changeCndGroup}
                deleteCndGroup={deleteCndGroup}
                length={data.length}
                data={cnd}
              />
            </div>
          ))}
      </div>
      <IfNotBlock />
      <div className={styles.wayNot}>
        Выполнить путь:{" "}
        <span className={styles.wordNo}>
          НЕТ <span className={styles.circle}> </span>
        </span>
      </div>
      <div className={styles.addCnd} onClick={createCndGroup}>
        Задать дополнительное условие
      </div>
    </div>
  );
}
export default FilterContent;
// подписка на канал
// дата подписки
// получено спам-сообщение
// участие в  цепочке
