import sha256 from "crypto-js/sha256";
import { uploadFile } from "../api/firestoreApi";
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});
// ищет в блоках элемент message под элементы image and file
export function searchFileBlock(nodes, table, functAct) {
  // functAct аргумент который должен быть функцией, если его нет то происходит обычный поиск
  const elementsHasFiles = [];
  for (let i = 0; i < nodes.length; i++) {
    const element = nodes[i];

    // если сработало то запустить и перейти сразу к следующей итерации
    if (typeof functAct === "function") {
      functAct(element, table, "decrement");
      continue;
    }
    if (element.type === "Message") {
      //Двойная итерация для того чтобы добраться до файлов
      const arrElements = [...element.data.block.elements];
      for (let index = 0; index < arrElements.length; index++) {
        const elem = arrElements[index];
        if (elem.type === "message image" || elem.type === "message file") {
          const elementHasFile = searchFile(elem, table);
          if (elementHasFile) {
            elementsHasFiles.push(elementHasFile);
          }
        }
      }
    }
  }
  return elementsHasFiles;
}

// ищет файлы для загрузки если есть возвращает объект
function searchFile(elem, table) {
  // определяем тип элемента image or file
  const typeObj = elem.type.split(" ")[1];
  // заходит в сам файл где хранятся данные о нем
  const openFile = elem[typeObj];
  // console.log(`typeObj, openFile`, typeObj, openFile);
  // console.log(determineValueType(typeObj, openFile));
  // являеться ли файл base64
  if (determineValueType(typeObj, openFile) === "base64") {
    // получаем его хеш и сверяем с базой данных
    const hash = sha256(openFile[`${typeObj}Value`]).toString(); // пример elem.image.imageValue
    const isHashObj = isMatch(typeObj, hash, table, "increment");
    if (isHashObj) {
      console.log("yes");
      // если совпадения есть, значит файл есть и в базе по этому заменяем value на ссылку из базы
      openFile.hash = isHashObj.hash;
      openFile[`${typeObj}Value`] = isHashObj.link;
      openFile[`${typeObj}Name`] = nameFileFromPath(isHashObj.path);
    }

    if (!isHashObj) {
      // openFile.hash = hash;
      openFile.hash = hash;
      return openFile;
    }
  }
}

// функция которая нужна при удалении или дублирование блока
export function toggleCountFiles(element, table, act) {
  if (element.type === "Message") {
    const arrElements = [...element.data.block.elements];
    for (let index = 0; index < arrElements.length; index++) {
      const elem = arrElements[index];
      if (elem.type === "message image" || elem.type === "message file") {
        // определяем тип элемента image or file
        const typeObj = elem.type.split(" ")[1];
        // заходит в сам файл где хранятся данные о нем
        const openFile = elem[typeObj];
        // являеться ли файл из firestore
        if (determineValueType(typeObj, openFile) === "hash") {
          // изменения count
          isMatch(typeObj, openFile.hash, table, act);
        }
      }
    }
  }
}

// делает новый объект, а также загружает файл на серв (можна оптимизировать)
export async function preparationFile(arr, id, table) {
  // для удобноства сокрщаем обращения к объекту
  try {
    const objCluster = clusterByProperty(arr, "hash");
    for (const key in objCluster) {
      if (Object.hasOwnProperty.call(objCluster, key)) {
        console.log(table);
        const element = objCluster[key];
        console.log(element);
        const firstElement = element[0];
        // firstElement.imageValue = '123';
        const { value, name, type, isName, isFormat } =
          processElement(firstElement);

        if (!isFormat) return false; // значит что операция не пройшла проверку наличия формата файла

        const path = `${id}/${type}s/${
          name ? isName : `${isName}.${isFormat}`
        }`;

        // загрузка файла в бд и возвращает на него ссылку
        const link = await uploadFile(path, value);

        // скелет новой сущности в таблице
        const newRow = {
          hash: firstElement.hash,
          count: element.length,
          link,
          path,
        };
        table[`${type}s`].push(newRow);

        for (let i = 0; i < element.length; i++) {
          const elem = element[i];
          const { type } = processElement(elem);

          elem[`${type}Value`] = link;
          elem[`${type}Name`] = nameFileFromPath(path);
        }
      }
    }
  } catch (error) {
    rollbar.error('Error during request:', error);
    return console.log(error.toString());
  }
}
function nameFileFromPath(path) {
  return path.substring(path.lastIndexOf("/") + 1, path.length);
}

function processElement(obj) {
  const type = "imageValue" in obj ? "image" : "file";

  // только для чтения (не имеет иммутабельность)
  const value = obj[`${type}Value`];
  const name = obj[`${type}Name`];
  const format = obj[`${type}Type`];

  const isName = `${generateCode(16)}${name}`;
  const isFormat = format ? format : extractTypeFileFromBase64(value);

  return { value, name, format, type, isName, isFormat };
}

function clusterByProperty(arr, prop) {
  const clusters = {};
  for (const item of arr) {
    const key = item[prop];
    if (!clusters[key]) {
      clusters[key] = [];
    }
    clusters[key].push(item);
  }
  console.log(clusters);
  return clusters;
}

// определяет тип файла и как загружен
function determineValueType(variant, object) {
  const value = `${variant}Value`;
  if (object[value]) {
    // если способ загрузки file то он имеет base
    if (object.typeFile === "file") {
      if (isValidBase64URL(object[value])) {
        return "base64";
      }
    }
    // проверка файла на наличии хеша
    if (object.hash) {
      return "hash";
    }
    // если в поле для ссылке валидный base64 то воспринимать файл как base64
    if (object.typeFile === "link") {
      if (isValidBase64URL(object[value])) {
        return "base64";
      } else {
        return false;
      }
    }
  } else {
    // если значения файла пустое
    return false;
  }
}

// ищет совпадения
function isMatch(type, hash, table, act) {
  console.log("yes");
  // если type image то будет images, потому что папки имеет окончания 's' (images, files)
  const list = table[type + "s"];
  console.log("heelo");
  console.log(
    `${type + "s"}`,
    table,
    table.images["0"]?.hash,
    table.images.length,
    typeof table.images
  );
  if (!list[0]) return false; // если список пустой
  for (let i = 0; i < list.length; i++) {
    const element = list[i];
    if (element.hash === hash) {
      if (act) {
        // так как count являеться количеством подключений к файлу мы изменяем его значения
        if (act === "decrement") {
          element.count--;
        }
        if (act === "increment") {
          element.count++;
        }
      }
      return element;
    }
  }
  return false;
}

// проверить валидность base64
function isValidBase64URL(str) {
  const base64URLPattern =
    /^data:(image\/(jpeg|png|gif)|application\/zip|application\/pdf);base64,([A-Za-z0-9+/=])+$/;
  return base64URLPattern.test(str);
}

//
function extractTypeFileFromBase64(base64String) {
  try {
    // const base64String =
    const startIndex = base64String.indexOf("/") + 1; // Индекс первого символа после "/"
    const endIndex = base64String.indexOf(";"); // Индекс символа ";"

    if (startIndex === 0 || endIndex === -1) {
      // Если символы не найдены, возвращаем null или бросаем исключение
      return null;
    }

    const extractedData = base64String.substring(startIndex, endIndex);
    return extractedData;
  } catch (error) {
    rollbar.error('Error during request:', error);
    return null;
  }
}

function generateCode(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let code = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    code += characters.charAt(randomIndex);
  }
  // const id = code.replace(/(.{4})(?!$)/g, '$1-');
  return code;
}
