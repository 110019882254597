import React, { useState } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";

import SelectValueUI from "../inputUI/SelectValueUI";
import styles from "./FieldAnswer.module.css";
import JsonInputUI from "../inputUI/JsonInputUI";
import ButtonDeleteUI from "../inputUI/ButtonsUI/ButtonDeleteUI";
function FieldAnswer({ data, change, json, deleteValue }) {
  const [popup, setPopup] = useState(false);

  const handler = (text, type) => {
    const newObj = { ...data };
    newObj[type] = text;
    console.log(newObj);
    change(newObj);
  };

  const dropDownRef = useDetectClickOutside({
    onTriggered: (e) => {
      // из-за сложности вложености было сделано исколючения для элементов которые содержат в своем класе jv
      if (typeof e.target.className === "object") {
        if (e.target.className.animVal.includes("jv")) return;
      } else {
        if (e.target.className.includes("jv")) return;
      }
      setPopup(false);
    },
  });

  const close = () => setPopup(false);
  const handlerFocus = () => setPopup(true);
  return (
    <div className={styles.box} ref={dropDownRef}>
      <JsonInputUI
        close={close}
        handler={handler}
        type={"apiPath"}
        json={json}
        placeholder={"$[somekey]"}
        popup={popup}
        text={data.apiPath}
        handlerFocus={handlerFocus}
      />
      <SelectValueUI
        value={data.value}
        handler={(text) => handler(text, "value")}
      />
      <ButtonDeleteUI size={15} deleteHandler={() => deleteValue(data.id)} />
    </div>
  );
}

export default FieldAnswer;
