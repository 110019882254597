import React, { useEffect, useState } from 'react';
import Sidebar from '../dashboardPage/sidebar/Sidebar';
import Workspace from '../dashboardPage/workSpace/WorkSpace';
import '../../index.css';
import './ConfirmAccPage.css';
import DashboardPage from '../dashboardPage/DashboardPage';

const ConfirmAccPage = () => {

  const [confirmUser, setconfirmUser] = useState(false);

  return (
      <DashboardPage confirmUser={confirmUser}/>
  );
  
};

export default ConfirmAccPage;