import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { AiOutlinePaperClip } from 'react-icons/ai';
import { RiErrorWarningLine } from 'react-icons/ri';

import styles from './DropZoneFileUpload.module.css';

const MAX_FILE_SIZE = 5 * 1024 * 1024;

function DropZoneFileUpload({ documentHandler }) {
  const [isDragActive, setIsDragActive] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(false);

  //Обработка ошибок
  const onDropRejected = useCallback((fileRejections) => {
    console.error(fileRejections[0].errors);
    setIsDragActive(false);
  }, []);

  //Обработка файла и сохранения в стейте
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      if (file.name.length > 40) {
        // Handle file name exceeding 40 characters
        return;
      }
      reader.onload = () => {
        documentHandler({
          fileName: file.name,
          fileType: file.type,
          fileSize: file.size,
          fileValue: reader.result,
        });
        setIsDragActive(false);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: MAX_FILE_SIZE,
    accept: {
      'application/pdf': [],
      'application/zip': [],
      'image/gif': [],
    },
    multiple: false,
    onDropRejected,
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
  });

  return (
    <div
      className={`${styles.dropZone} ${
        isDragActive ? styles.dropZoneDrag : ''
      }`}
      {...getRootProps()}
    >
      <AiOutlinePaperClip className={styles.iconPhoto} />
      <div className={styles.chooseFile}>Загрузите с диска</div>
      <span className={styles.title}>
        или перетащите сюда файл (zip, pdf, gif • 5MB)
      </span>
      <span className={styles.warning}>
        <RiErrorWarningLine className={styles.iconWarning} />
        Название файла не более 40 символов
      </span>
      <input {...getInputProps()} />
    </div>
  );
}

export default DropZoneFileUpload;
