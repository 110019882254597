import React, { createContext, useEffect, useState} from 'react';
import {  Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import LoginPage from './components/loginPage/LoginPage';
import RegPage from './components/regPage/RegPage';
import NotFoundPage from './NotFoundPage'; 
import DashboardPage from './components/dashboardPage/DashboardPage';
import WebFont from 'webfontloader';
import refreshToken from './refreshToken';
import adapter from './adapter';
import FlowDiagram from './components/flowDiagram/FlowDiagram';
import TgLoginPage from './components/tgLoginPage/TgLoginPage';
import ConfirmAccPage from './components/confirmAccPage/ConfirmAccPage';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuidv4 } from 'uuid'
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});



export const AppContext = createContext();



function App() {


  const storedData = JSON.parse(localStorage.getItem('dashboardData'));
  const dashboardData = storedData ? storedData: null;

  const [data, setData] = useState({
    edges: [],
    nodes: [],
    diagram: []
  });
  const [botId, setBotId] = useState('');
  const [botName, setBotName] = useState('');
  const [lock, setLock] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [routeToBotScript, setRouteToBotScript] = useState('');
  const [botScriptId, setBotScriptId] = useState(null);
  const [botScriptName, setBotScriptName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const auth = dashboardData ? {
    access_token: dashboardData.token,
    user_tg_id: dashboardData.user ? dashboardData.user['telegram_id'] : '',
    tg_id: dashboardData.user ? dashboardData.user['id'] : '',
    bot_id: botId
  } : {}; 

  const [dialogName, setDialogName] = useState('');
  
 

  const errorHandler = (err) => {
    console.error(err);
  };

  const [recoverDiagram, setRecoverDiagram] = useState(false);




  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }
  
  function saveDataToStorage(data) {
    const currentDate = new Date();
    const formattedDate = formatDate(currentDate);
  
    const savedData = JSON.parse(localStorage.getItem('savedData')) || [];
  
    data.timestamp = formattedDate;
    data.botId = botId;
    data.botName = botName;
  
    const autosaveData = savedData.filter(item => item.autosave === true);
    const nonAutosaveData = savedData.filter(item => item.autosave !== true);
  
    if (data.autosave === true) {
      autosaveData.unshift(data);
      if (autosaveData.length > 3) {
        autosaveData.pop();
      }
    } else {
      nonAutosaveData.unshift(data);
      if (nonAutosaveData.length > 5) {
        nonAutosaveData.pop();
      }
    }
  
    const updatedData = autosaveData.concat(nonAutosaveData);
  
    localStorage.setItem('savedData', JSON.stringify(updatedData));
  }


  const saveDraft = (draftData, autoSaveDraftData) => {
  
   
    // setDialogName('');
    // setDialogNameDefault('');
    if (autoSaveDraftData) {
      autoSaveDraftData.autosave = true;
      saveDataToStorage(autoSaveDraftData);
    }

    if (draftData) {
      if (!dialogName) {
        toast.error('Дайте название для цепочки!');
        return;
      }
      setData(draftData);
      saveDataToStorage(draftData);
      toast.success('Цепочка доступна для восстановления!');
    }
  };


 


  const dataHandler = async (data) => {
    if (!dialogName) {
      toast.error('Дайте название для цепочки!');
      return;
    }
    setIsLoading(true);
    if (isEdit === false) {
      try {
        const dialodData = {
          user_tg_id: dashboardData.user['telegram_id'],
          bot_id: botId,
          access_token: dashboardData.token,
          dialog_name: dialogName,
          dialog: data,
          route: 'saveDialog',
          description: ''
        };
        
        const response = await adapter.post('', dialodData);
        if (response.data.result === 'success') {
          toast.success('Цепочка успешно сохранена!');
          setTimeout(() => {
            navigate('/dashboard');
            setRouteToBotScript('BotScript');
            setBotScriptId(botId);
            setBotScriptName(botName);
            setIsLoading(false);
          }, 2000);
        } else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
          try {
            const refreshTokenResult = await refreshToken();
          
            if (refreshTokenResult === 'logout') {
              navigate('/', { replace: true });
            }else{
              dashboardData.token = refreshTokenResult;
              const dialodData = {
                user_tg_id: dashboardData.user['telegram_id'],
                bot_id: botId,
                access_token: dashboardData.token,
                dialog_name: dialogName,
                dialog: data,
                route: 'saveDialog',
                description: ''
              };
              
              const response = await adapter.post('', dialodData);
              if (response.data.result === 'success') {
                toast.success('Цепочка успешно сохранена!');
                setTimeout(() => {
                  navigate('/dashboard');
                  setRouteToBotScript('BotScript');
                  setBotScriptId(botId);
                  setBotScriptName(botName);
                  setIsLoading(false);
                }, 2000);
              }
              else {
                toast.error(response.data.text);
                console.log(response.data);
                setIsLoading(false);
              }
            }
          
          } catch (error) {
            console.error(error);
            toast.error(error);
            setIsLoading(false);
          }
        } else {
          toast.error(response.data.text);
          setIsLoading(false);
        }
      } catch (error) {
        toast.error(error);
        console.error(error);
        setIsLoading(false);
        rollbar.error('Error during request:', error);
      }finally{
        setDialogName('');
        setDialogNameDefault('');
      }
    
    }else{  
      try {
       
        const dialodData = {
          dialog_id: dialogId,
          dialog_name: dialogName,
          access_token: dashboardData.token,
          dialog: data,
          bot_id: botId,
          route: 'updateDialog'
        };
        const response = await adapter.post('', dialodData);
        if (response.data.result === 'success') {
          toast.success('Цепочка успешно отредактирована!');
          setTimeout(() => {
            navigate('/dashboard');
            setRouteToBotScript('BotScript');
            setBotScriptId(botId);
            setBotScriptName(botName);
            setIsLoading(false);
          }, 2000);
        } else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
          try {
            const refreshTokenResult = await refreshToken();
          
            if (refreshTokenResult === 'logout') {
              navigate('/', { replace: true });
            }else{
              dashboardData.token = refreshTokenResult;
              const dialodData = {
                dialog_id: dialogId,
                access_token: dashboardData.token,
                dialog: data,
                route: 'updateDialog'
              };
              const response = await adapter.post('', dialodData);
              if (response.data.result === 'success') {
                toast.success('Цепочка успешно отредактирована!');
                setTimeout(() => {
                  navigate('/dashboard');
                  setRouteToBotScript('BotScript');
                  setBotScriptId(botId);
                  setBotScriptName(botName);
                  setIsLoading(false);
                }, 2000);
              }
              else {
                toast.error(response.data.text);
                console.log(response.data);
                setIsLoading(false);
              }
            }
          
          } catch (error) {
            console.error(error);
            toast.error(error);
            setIsLoading(false);
          }
        } else {
          toast.error(response.data.text);
          setIsLoading(false);
        }
      } catch (error) {
        toast.error(error);
        console.error(error);
        setIsLoading(false);
        rollbar.error('Error during request:', error);
      }finally{
        setDialogName('');
        setDialogNameDefault('');
      }
        
    }
    

  };
  const [dialogId, setDialogId] = useState('');

  useEffect(() => {
    
    if (window.location.pathname === '/flowdiagram') {
      setIsEdit(false);
    }else if(window.location.pathname === '/flowdiagram_edit')
    {
      setIsEdit(true);
    }
  }, [window.location.pathname]);

  const [version, setVersion] = useState(null);

  const createNewChain = (botId, botName) => {
    const uniqueKey = uuidv4();
    setVersion(uniqueKey);
    setBotId(botId);
    setData({
      edges: [],
      nodes: [],
      diagram: []
    });
    setBotName(botName);
    setIsEdit(false);
    navigate('/flowdiagram');
  };
  const restoreChain = (botId, botName, data) => {
    setBotId(botId);
    setData(data);
    setBotName(botName);
    setIsEdit(false);
    navigate('/flowdiagram');
  };



  const [dialogNameDefault, setDialogNameDefault] = useState('');
  useEffect(() => {
    setDialogNameDefault('');
  }, []);

  const editTrigger = (botId, data, dialogId, botName, dialogName) => {
    const uniqueKey = uuidv4();
    setVersion(uniqueKey);
    setBotId(botId);
    setData(data);
    setDialogId(dialogId);
    setDialogName(dialogName);
    setDialogNameDefault(dialogName);
    setBotName(botName);
    setIsEdit(true);
    navigate('/flowdiagram_edit');
  };
  const navigate = useNavigate();

  

  const handleExit = () => {
    localStorage.clear(); 
    sessionStorage.clear();
    window.location.reload();
  };

  const getBack = ()=> {
    navigate('/dashboard');
    setRouteToBotScript('BotScript');
    setBotScriptId(botId);
    setBotScriptName(botName);
    setDialogName('');
    setDialogNameDefault('');
  }
  const [userTgId, setUserTgId] = useState(null);

  useEffect(() => {
    if (dashboardData) {
      const storedUser = dashboardData.user;
      setUserTgId(storedUser.telegram_id);
    }
    
  }, []);

  const handleDialogNameEdited = (name) => {
    setDialogName(name);
    setDialogNameDefault(name);
  }



  const saveDiagramHistory = async (data)  => {
    try {
       
      const dialodData = {
        bot_id: botId,
        user_tg_id: userTgId,
        access_token: dashboardData.token,
        dialog_id: isEdit? dialogId : 0,
        dialog: data,
        version: version,
        route: 'saveDiagramHistory'
      };
      const response = await adapter.post('', dialodData);
      if (response.data.result === 'success') {
      } else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
        try {
          const refreshTokenResult = await refreshToken();
        
          if (refreshTokenResult === 'logout') {
            navigate('/', { replace: true });
          }else{
            dashboardData.token = refreshTokenResult;
            const dialodData = {
              bot_id: botId,
              user_tg_id: userTgId,
              access_token: dashboardData.token,
              dialog_id: isEdit? dialogId : 0,
              dialog: data,
              version: version,
              route: 'saveDiagramHistory'
            };
            const response = await adapter.post('', dialodData);
            if (response.data.result === 'success') {
            }
           
            else {
              toast.error(response.data.text);
              console.log(response.data);
            }
          }
        
        } catch (error) {
          console.error(error);
          toast.error(error);
        }
      } else {
        toast.error(response.data.text);
      }
    } catch (error) {
      toast.error(error);
      console.error(error);
      rollbar.error('Error during request:', error);
    }
  }





  const getBackupData = (data) =>{
    saveDiagramHistory(data);
  }

  const undoDiagramChanges = async () => {
    try {
       
      const dialodData = {
        bot_id: botId,
        user_tg_id: userTgId,
        access_token: dashboardData.token,
        dialog_id: isEdit ? dialogId : 0,
        version: version,
        state: 'next',
        route: 'getDiagramHistory'
      };
      const response = await adapter.post('', dialodData);
      if (response.data.result === 'success') {
        const historyData = response.data.array;
        const dialog = JSON.parse(historyData.dialog);
        setData(dialog);
      } else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
        try {
          const refreshTokenResult = await refreshToken();
        
          if (refreshTokenResult === 'logout') {
            navigate('/', { replace: true });
          }else{
            dashboardData.token = refreshTokenResult;
            const dialodData = {
              bot_id: botId,
              user_tg_id: userTgId,
              access_token: dashboardData.token,
              dialog_id: isEdit ? dialogId : 0,
              dialog: data,
              version: version,
              route: 'saveDiagramHistory'
            };
            const response = await adapter.post('', dialodData);
            if (response.data.result === 'success') {
             console.log(response.data);
            }
           
            else {
              toast.error(response.data.text);
              console.log(response.data);
            }
          }
        
        } catch (error) {
          console.error(error);
          toast.error(error);
        }
      } else {
        toast.error(response.data.text);
      }
    } catch (error) {
      toast.error(error);
      console.error(error);
      rollbar.error('Error during request:', error);
    }
  };
  const redoDiagramChanges = async () => {
    try {
       
      const dialodData = {
        bot_id: botId,
        user_tg_id: userTgId,
        access_token: dashboardData.token,
        dialog_id: isEdit ? dialogId : 0,
        version: version,
        state: 'prev',
        route: 'getDiagramHistory'
      };
      const response = await adapter.post('', dialodData);
      if (response.data.result === 'success') {
        const historyData = response.data.array;
        const dialog = JSON.parse(historyData.dialog);
        setData(dialog);
      } else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
        try {
          const refreshTokenResult = await refreshToken();
        
          if (refreshTokenResult === 'logout') {
            navigate('/', { replace: true });
          }else{
            dashboardData.token = refreshTokenResult;
            const dialodData = {
              bot_id: botId,
              user_tg_id: userTgId,
              access_token: dashboardData.token,
              dialog_id: isEdit ? dialogId : 0,
              dialog: data,
              version: version,
              route: 'saveDiagramHistory'
            };
            const response = await adapter.post('', dialodData);
            if (response.data.result === 'success') {
             console.log(response.data);
            }
           
            else {
              toast.error(response.data.text);
              console.log(response.data);
            }
          }
        
        } catch (error) {
          console.error(error);
          toast.error(error);
        }
      } else {
        toast.error(response.data.text);
      }
    } catch (error) {
      toast.error(error);
      console.error(error);
      rollbar.error('Error during request:', error);
    }
  };

 
  return (
      <AppContext.Provider value={{ createNewChain,restoreChain, handleExit, editTrigger}}>
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route
            path="/"
            element={
              localStorage.getItem('authenticated') === 'true' && userTgId !== null && dashboardData ? ( 
                <Navigate to="/dashboard" replace />
              ): 
              localStorage.getItem('authenticated') === 'true' && userTgId === null && dashboardData ? (
                <ConfirmAccPage />
              ):(
                <LoginPage />
              )
            }
          />
          <Route path="/login_tg/*" element={<TgLoginPage />} />
          <Route
            path="/dashboard"
            element={
              localStorage.getItem('authenticated') === 'true' && userTgId !== null && dashboardData ? (
                <DashboardPage routeToBotScript={routeToBotScript} botScriptId={botScriptId} botScriptName={botScriptName } />
              ) : (
                <Navigate to="/" replace state={{ from: '/dashboard' }} />
              )
            }
          />
          <Route path="/reg" element={<RegPage />} />
          <Route
            path="/flowdiagram"
            element={
              localStorage.getItem('authenticated') === 'true' && botId ? (
                <>
                  <FlowDiagram
                    auth={auth}
                    data={data}
                    lock={lock}
                    isEdit={false}
                    isLoading={isLoading}
                    dataHandler={dataHandler}
                    errorHandler={errorHandler}
                    getBack={getBack}
                    editedDialogName={handleDialogNameEdited}
                    dialogNameDefault={dialogNameDefault}
                    saveDraft={saveDraft}
                    recoverDiagram={recoverDiagram}
                    getBackupData={getBackupData}
                    undoDiagramChanges={undoDiagramChanges}
                    redoDiagramChanges={redoDiagramChanges}
                  />
                </>
              ) : botId === '' ? (
                <Navigate to="/dashboard" replace state={{ selectedComponent: 'BotScript' }} />
              ) : (
                <Navigate to="/" replace state={{ from: '/flowdiagram' }} />
              )
            }
          />
          <Route
            path="/flowdiagram_edit"
            element={
              localStorage.getItem('authenticated') === 'true' && botId ? (
                <>
                  <FlowDiagram
                    auth={auth}
                    data={data}
                    lock={lock}
                    isEdit={true}
                    dialogId={dialogId}
                    isLoading={isLoading}
                    dataHandler={dataHandler}
                    errorHandler={errorHandler}
                    getBack={getBack}
                    editedDialogName={handleDialogNameEdited}
                    dialogNameDefault={dialogNameDefault}
                    saveDraft={saveDraft}
                    recoverDiagram={recoverDiagram}
                    undoDiagramChanges={undoDiagramChanges}
                    redoDiagramChanges={redoDiagramChanges}
                    getBackupData={getBackupData}
                  />
                 
                </>
              ) : botId === '' ? (
                <Navigate to="/dashboard" replace state={{ selectedComponent: 'BotScript' }} />
              ) : (
                <Navigate to="/" replace state={{ from: '/flowdiagram' }} />
              )
            }
          />
          
        </Routes>
        <ToastContainer  autoClose={2500} position="bottom-right"  theme="dark"/>
      </AppContext.Provider>
  );
}

export default App;
