import React from 'react';
import styles from './BlockImage.module.css';
import BlockButton from '../blockButton';

function BlockImage({ data, idNode }) {
  return (
    <div className={styles.content}>
      {!data.image.imageValue ? (
        <div className={styles.block}>Выберите картинку...</div>
      ) : (
        <div className={styles.blockImage}>
          <img className={styles.image} src={`${data.image.imageValue}`} />

          <div className={styles.text} style={{ whiteSpace: 'pre-wrap' }}>
            {data.image.textValue}
          </div>
        </div>
      )}
      {data?.buttons[0]
        ? data.buttons.map((button) => {
            return (
              <BlockButton
                id={`button-${data.id}-${button.id}`}
                key={button.id}
                text={button.textValue}
                type={button.type}
              />
            );
          })
        : null}
    </div>
  );
}

export default BlockImage;
