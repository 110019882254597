import React, { useEffect, useState } from "react";
import TextUI from "../../UI/inputUI/TextUI";
import SelectUI from "../../UI/inputUI/SelectUI";
import { apiRequest } from "../../../../utils/apiRequest";
import ButtonActionUI from "../../UI/inputUI/ButtonsUI/ButtonActionUI";
import ButtonDashedUI from "../../UI/inputUI/ButtonsUI/ButtonDashedUI";
import BodyJsonUI from "../../UI/BodyJsonUI";
import HeadersJsonUI from "../../UI/HeadersJsonUI";
import RadioMenuUI from "../../UI/inputUI/RadioUI/RadioMenuUI";
import AddHeadersUI from "../../UI/addHeadersUI";
import SaveValuesTab from "../../UI/SaveValuesTab";
import SendMessageTab from "../../UI/SendMessageTab";
import RawValidator from "../../UI/inputUI/RawValidator";
import { getHeaders } from "../../../../api/api";
import styles from "./PopupApiContent.module.css";
import { apiBlockSendMessageInitial } from "../../../../config/initialValueApiBlock";
import { isURL } from "../../../../utils/validURL";

const ByteSize = 50000;

const requestMethods = [
  { label: "GET", value: "get" },
  { label: "POST", value: "post" },
  { label: "PUT", value: "put" },
  { label: "PATCH", value: "patch" },
  { label: "DELETE", value: "delete" },
];
const initHeader = { value: "", key: "" };

const initStoreValue = {
  apiPath: "",
  value: "",
};
const allowRaw = ["post", "put", "patch"];

function ApiContent({ addData, nodes, nodeID }) {
  const [method, setMethod] = useState("get");
  const [url, setUrl] = useState("");
  const [headers, setHeaders] = useState([]);

  const [bodySelect, setBodySelect] = useState(null);
  const [headersSelect, setHeadersSelect] = useState(null);

  const [saveValues, setSaveValues] = useState([]);
  const [sendMessage, setSendMessage] = useState(apiBlockSendMessageInitial);

  const [tab, setTab] = useState("save"); // save | send
  const [radio, setRadio] = useState("body"); // body | headers

  const [rawValue, setRawValue] = useState("");

  const [errorUrl, setErrorUrl] = useState(false);

  const [errorMessage, setErrorMessage] = useState(false);

  const methodHandler = (newMethod) => setMethod(newMethod);
  const urlHandler = (newText) => setUrl(newText);

  const handlerSendMessage = (data) => {
    setSendMessage(data);
  };
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const dataNode = nodes.data.block;
    if (dataNode && !isInitialized) {
      setIsInitialized(true);

      const {
        requestType,
        reguestUrl,
        headers,
        body,
        storeValues,
        sendMessage,
      } = dataNode;
      setMethod(requestType);
      setUrl(reguestUrl);
      setHeaders(headers);
      setRawValue(body);
      setSaveValues(storeValues);
      setSendMessage(sendMessage);
    }
  }, [nodes.data.block, isInitialized]);

  useEffect(() => {
    if (isInitialized) {
      const prepareData = {
        requestType: method,
        requestUrl: url,
        headers: headers,
        body: rawValue,
        storeValues: saveValues,
        sendMessage: sendMessage,
      };

      addData(prepareData, "", nodeID);
    }
  }, [method, url, headers, rawValue, sendMessage, saveValues]);

  const rawHandler = (text) => setRawValue(text);

  const checkURL = async () => {
    const validUrl = isURL(url);
    if (!validUrl) {
      setErrorUrl(true);
      setBodySelect(null);
      setHeadersSelect(null);
      return;
    }

    const body = ["get", "delete"].includes(method) ? "" : JSON.parse(rawValue);
    const bodyForAnswer = body ? JSON.stringify(body) : "";
    // cut empty or half empty object in array and destructuring arr objcts to one obj
    const prepareHeaders = headers
      .filter((head) => head.key && head.value)
      .reduce((acc, cur) => ({ ...acc, ...{ [cur.key]: `${cur.value}` } }), {});

    try {
      const bodyAnswer = await apiRequest(
        method,
        validUrl,
        prepareHeaders,
        body
      );
      const bodyToString = JSON.stringify(bodyAnswer);
      const encoder = new TextEncoder();
      const byteLength = encoder.encode(bodyToString).length;

      console.log(`body have ${byteLength}kb`);
      if (byteLength > ByteSize) {
        setErrorUrl(true);
        setErrorMessage(true);
        return;
      }

      const headersAnswer = await getHeaders(
        method,
        validUrl,
        headers,
        bodyForAnswer
      );

      setHeadersSelect(headersAnswer.filter((header) => header.length === 2));
      setBodySelect(bodyAnswer);
    } catch (err) {
      console.error(err);
    }
  };

  const toggleRadio = (action) => {
    setRadio(action);
  };

  // user custom header values
  const addHeader = () => {
    const newHeader = { ...initHeader, id: Date.now() };
    setHeaders([...headers, newHeader]);
  };

  const changeHeader = (newValue) => {
    const newArr = headers.map((header) => {
      if (header.id === newValue.id) {
        return { ...header, ...newValue };
      }
      return header;
    });
    setHeaders(newArr);
  };
  const deleteHeader = (id) => {
    setHeaders(headers.filter((header) => header.id !== id));
  };

  // user save request answer in values
  const addSaveValue = () => {
    const newSaveValue = { ...initStoreValue, id: Date.now() };
    setSaveValues([...saveValues, newSaveValue]);
  };

  const deleteSaveValue = (id) => {
    setSaveValues(saveValues.filter((value) => value.id !== id));
  };

  const changeSaveValue = (newValue) => {
    const newArr = saveValues.map((value) => {
      if (value.id === newValue.id) {
        return { ...value, ...newValue };
      }
      return value;
    });
    setSaveValues(newArr);
  };

  return (
    <div>
      <div className={styles.boxUrl}>
        <p className={styles.label}>Получить данные по URL</p>
        <div style={{ display: "flex", columnGap: 10 }}>
          <SelectUI options={requestMethods} handler={methodHandler} />
          <TextUI
            placeholder={"URL"}
            storeValues={true}
            sx={{ flex: "1 1 100px" }}
            text={url}
            textHandler={urlHandler}
            errorStatus={errorUrl}
            handlerFocus={() => {
              setErrorUrl(false);
              setErrorMessage(false);
            }}
          />
        </div>
        {errorMessage && (
          <p
            style={{
              color: "red",
              fontSize: "15px",
              BackgroundColor: "#aa1111",
            }}>
            Размер ответа не может превышать 50 килобайт
          </p>
        )}
        {!headers[0] && (
          <ButtonDashedUI
            size={"small"}
            text="Добавить заголовок"
            clickHandler={addHeader}
          />
        )}
        {allowRaw.includes(method) && (
          <RawValidator text={rawValue} handler={rawHandler} />
        )}
        {headers[0] && (
          <AddHeadersUI
            headers={headers}
            changeHeader={changeHeader}
            addHeader={addHeader}
            deleteHeader={deleteHeader}
          />
        )}
        <div>
          <ButtonActionUI handler={checkURL} text="Проверить Запрос" />
        </div>
        {!!bodySelect && (
          <div className={styles.body}>
            <div className={styles.btnsBody}>
              <ButtonActionUI
                handler={() => toggleRadio("body")}
                active={radio === "body"}
                type="radio"
                text="Тело Ответа"
              />
              <ButtonActionUI
                handler={() => toggleRadio("headers")}
                active={radio === "headers"}
                type="radio"
                text="Заголовки"
              />
            </div>
            {radio === "body" && <BodyJsonUI data={bodySelect} />}
            {radio === "headers" && <HeadersJsonUI data={headersSelect} />}
          </div>
        )}
        <RadioMenuUI handler={(newTab) => setTab(newTab)} value={tab} />
        {tab === "save" && (
          <SaveValuesTab
            data={saveValues}
            change={changeSaveValue}
            deleteValue={deleteSaveValue}
            add={addSaveValue}
            json={bodySelect}
          />
        )}
        {tab === "send" && (
          <SendMessageTab
            dataHandler={handlerSendMessage}
            data={sendMessage}
            json={bodySelect}
          />
        )}
      </div>
    </div>
  );
}

export default ApiContent;
