import React, { useEffect, useState } from "react";
import styles from "./RemoveConstant.module.css";

import SelectValueUI from "../../inputUI/SelectValueUI";
import { removeBrackets } from "../../../../../utils/variablesForSelect";

function RemoveConstant({ data, handler }) {
  const [variable, setVariable] = useState("");
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (data.name && !init) {
      setVariable(data.name);
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      handler({
        ...data,
        ...(variable && {
          name: variable,
        }),
      });
    }
  }, [variable, init]);

  return (
    <>
      <div className={styles.box}>
        <div className={styles.selectTitle}>
          <p className={styles.title}>Выберите переменную</p>
        </div>
        <SelectValueUI
          value={variable}
          handler={(value) => {
            setVariable(removeBrackets(value));
          }}
        />
      </div>
    </>
  );
}

export default RemoveConstant;
