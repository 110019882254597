import React, { useState, useEffect } from 'react';
import styles from './StoreValue.module.css';
import StoreAutoComplete from '../StoreAutoComplete';
import { initialValue } from '../../../../config/initialStoreValue';
import { useStoreApi } from 'reactflow';

function StoreValues({
  popupStatus,
  dropDownRef,
  dropDownHandle,
  popupToggle,
  indent,
}) {
  const styleCaret = `${styles.caret}  ${
    popupStatus ? styles.caretAnimate : ''
  }`;

const store = useStoreApi();
const [variableOptions, setVariableOptions] = useState(initialValue);
const { botVariables } = store.getState();

  useEffect(() => {
    if (botVariables) {
      const newOptions = botVariables.map(item => ({
        label: item.name,
        action: `{{${item.name}}}`
      }));
      const updatedOptions = [
        ...newOptions,
        ...initialValue
        
      ];
      
    setVariableOptions(updatedOptions);
    }
    
  
  }, [botVariables]);


  return (
    <div
      className={styles.dropDownBlock}
      style={{ right: indent === 'small' ? '3px' : '20px' }}
      ref={dropDownRef}
    >
      <div className={styles.dropdownConstant} onClick={popupToggle}>
        {`{}`}
        <span className={styleCaret}></span>
      </div>
      {popupStatus && (
        <StoreAutoComplete
          dropDownHandle={dropDownHandle}
          initialValue={variableOptions}
          text="Выберите переменную"
        />
      )}
    </div>
  );
}

export default StoreValues;
