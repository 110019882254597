import React, { useEffect, useState, useContext } from 'react';
import '../../../../../index.css';
import './BotScript.css';
import BotStructure from './botStructure/BotStructure';
import BackupWindow from './BackupWindow/BackupWindow';
import CreateTriggerWindow from './createTriggerWindow/CreateTriggerWindow';
import FlowDiagram from './flowDiagram/FlowDiagram';
import { AppContext } from '../../../../../App'; 
import { useNavigate } from 'react-router-dom';
import refreshToken from '../../../../../refreshToken';
import adapter from '../../../../../adapter';
import Select from 'react-select';
import Preloader from '../../../../../Preloader';
import styles from '../styles.module.css';
import { toast } from 'react-toastify';
import { FaCalendarAlt,FaCalendarCheck } from 'react-icons/fa';




const BotScript = ({scenarioBotData, handleReturnToPanel}) => {
  const storedData = JSON.parse(localStorage.getItem('dashboardData'));
  const dashboardData = storedData ? storedData: null;
  let storedAppState = localStorage.getItem('storedAppState');
  storedAppState = storedAppState? JSON.parse(storedAppState) : null;
  const [lock, setLock] = useState(true);
  const [data, setData] = useState('');
  const [chainId, setChainId] = useState('');
  const [activeComponent, setActiveComponent] = useState('BotStructure');
  const [activeContent, setActiveContent] = useState('');
  const [selectedBotId, setSelectedBotId] = useState('');
  const [selectedBotName, setSelectedBotName] = useState('');
  const [activeTab, setActiveTab] = useState('');
  const [chainName, setChainName] = useState('');
  const [triggerName, setTriggerName] = useState('');
  const [tiedChainData, setTiedChainData] = useState({});
  const [triggerId, setTriggerId] = useState('');
  const [triggerData, setTriggerData] = useState('');
  const [triggerDateCreate, setTriggerDateCreate] = useState(null);
  const [triggerDateUpdate, setTriggerDateUpdate] = useState(null);
  const [editTriggerData, setEditTriggerData] = useState([]);
  const [editTriggerDataProps, setEditTriggerDataProps] = useState([]);
  const [editTriggerState, setEditTriggerState] = useState('');
  const [chainsDateCreate, setChainsDateCreate] = useState(null);
  const [chainsDateUpdate, setChainsDateUpdate] = useState(null);
  const [showCombineButton, setShowCombineButton] = useState(false);
  const [refreshChainsData, setRefreshChainsData] = useState(false);
  const [refreshTriggersData, setRefreshTriggersData] = useState(false);
  const [onDeleteChain, setOnDeleteChain] = useState(false);
  const [chainsOptions, setChainsOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);


  const { createNewChain, editTrigger } = useContext(AppContext);
  const navigate = useNavigate();
  const auth = {
    access_token: dashboardData.token,
    user_tg_id: dashboardData.user['telegram_id'],
    tg_id: dashboardData.user['id'],
    bot_id: scenarioBotData.id
  };

  const handleOpenCreateTriggerWindow = (e) => {
    setActiveContent(e);
  };

  const handleSetActiveTabContent = (e) => {
    setActiveContent(e);
  };

  const handleSelectedChainDialog = (dialog) => {
   
    const chainsDateCreate = dialog.date;
    const chainsDateUpdate = dialog.date_update;
    const createUpdateDateObject = new Date(chainsDateUpdate);
    const monthNames = [
      'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
      'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
    ];
    const createDateObject = new Date(chainsDateCreate);
    const dayCreateDate = createDateObject.getDate();
    const monthNumberCreateDate = createDateObject.getMonth();
    const monthNameCreateDate = monthNames[monthNumberCreateDate];
    const formattedCreateDate = `${dayCreateDate} ${monthNameCreateDate}`;
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - createUpdateDateObject.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    
    const daysAgo = diffDays + '  '+ getDaysAgoText(diffDays);
    
    if ( dialog.dialog === undefined || dialog.dialog ==='' ) {
  
      setChainName('');
      setChainsDateCreate('');
      setChainsDateUpdate('');
    } else {
      const parsedDialog = JSON.parse(dialog.dialog);
      setData(parsedDialog);
      setChainId(dialog.id);
      setChainName(dialog.name);
      setChainsDateCreate(formattedCreateDate);
      setChainsDateUpdate(daysAgo);
  
     
    }
  };
  function getDaysAgoText(days) {
    if (days % 10 === 1 && days % 100 !== 11) {
      return 'день назад';
    } else if ([2, 3, 4].includes(days % 10) && ![12, 13, 14].includes(days % 100)) {
      return 'дня назад';
    } else {
      return 'дней назад';
    }
  }
  const handleSelectedTrigger = (trigger) => {
  
    let tiedChainData = {
      id: '',
      name: '',
    };
    
    if (trigger && trigger.type) {
      try {
        const aboutTrigger = JSON.parse(trigger.type);
        tiedChainData = {
          ...tiedChainData,
          id: trigger.id,
          name: aboutTrigger.chain_name || null,
        };
      } catch (error) {
       
        tiedChainData = {
          ...tiedChainData,
          id: trigger.id,
          name: null,
        };
      }
    }
    const editTriggerData = {
      id: trigger.id ,
      botId: trigger.bot_id ,
      name: trigger.name,
      description: trigger.description ,
      type: trigger.type 
    
    };
    setEditTriggerData(editTriggerData);
    const triggerCreateDate = trigger.date;
    const triggerCreateUpdate = trigger.date_update;
    
    const createUpdateDateObject = new Date(triggerCreateUpdate);
    const monthNames = [
      'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
      'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
    ];
    

    const createDateObject = new Date(triggerCreateDate);
    const dayCreateDate = createDateObject.getDate();
    const monthNumberCreateDate = createDateObject.getMonth();
    const monthNameCreateDate = monthNames[monthNumberCreateDate];
    
    const formattedCreateDate = `${dayCreateDate} ${monthNameCreateDate}`;
    const currentDate = new Date();
    const timeDiff = Math.abs(currentDate.getTime() - createUpdateDateObject.getTime());
    const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    
    const daysAgo = diffDays + '  '+ getDaysAgoText(diffDays);
    if (trigger.dialog !== '') {
      setTriggerData(JSON.parse(trigger.dialog));
      
    }else{
      setTriggerData('');
    }
    setTriggerName(trigger.name);
    setTiedChainData(tiedChainData);
    setTriggerId(trigger.id);
    setTriggerDateCreate(formattedCreateDate);
    setTriggerDateUpdate(daysAgo);
  };
 
  const handleEditChain = () => {
    editTrigger(scenarioBotData.id, data , chainId, scenarioBotData.name, chainName);
  };

  const handleEditChainTrigger = () => {
    editTrigger(scenarioBotData.id, triggerData , triggerId, scenarioBotData.name,triggerName);
  };
  const handleReturnToDefault = (defaultTab) => {
    setActiveTab(defaultTab);
   
  }

  const handleSetDefaultTrigger = (trigger) => {
    let tiedChainData = {
      id: '',
      name: '',
    };
    if (trigger && trigger.type) {
      try {
        const aboutTrigger = JSON.parse(trigger.type);
        tiedChainData = {
          ...tiedChainData,
          id: trigger.id,
          name: aboutTrigger.chain_name || null,
        };
      } catch (error) {
        let tiedChainData = {
          id: '',
          name: '',
        };
        
        if (trigger && trigger.type) {
          try {
            const aboutTrigger = JSON.parse(trigger.type);
            tiedChainData = {
              ...tiedChainData,
              id: trigger.id,
              name: aboutTrigger.chain_name || null,
            };
          } catch (error) {
           
            tiedChainData = {
              ...tiedChainData,
              id: trigger.id,
              name: null,
            };
          }
        }
        tiedChainData = {
          ...tiedChainData,
          id: trigger.id,
          name: null,
        };
      }
    }
    if (trigger) {

      const editTriggerData = {
          id: trigger.id ,
          botId: trigger.bot_id ,
          name: trigger.name,
          description: trigger.description ,
          type: trigger.type 
        
        };
      setEditTriggerData(editTriggerData);
      function getDaysAgoText(days) {
        if (days % 10 === 1 && days % 100 !== 11) {
          return 'день назад';
        } else if ([2, 3, 4].includes(days % 10) && ![12, 13, 14].includes(days % 100)) {
          return 'дня назад';
        } else {
          return 'дней назад';
        }
      }
      
      const triggerCreateDate = trigger.date;
      const triggerCreateUpdate = trigger.date_update;
      
      const createUpdateDateObject = new Date(triggerCreateUpdate);
      const dayCreateUpdate = createUpdateDateObject.getDate();
      const monthNumberCreateUpdate = createUpdateDateObject.getMonth();
      const monthNames = [
        'января', 'февраля', 'марта', 'апреля', 'мая', 'июня',
        'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'
      ];
      const monthNameCreateUpdate = monthNames[monthNumberCreateUpdate];
      
      const formattedCreateUpdateDate = `${dayCreateUpdate} ${monthNameCreateUpdate}`;
      const createDateObject = new Date(triggerCreateDate);
      const dayCreateDate = createDateObject.getDate();
      const monthNumberCreateDate = createDateObject.getMonth();
      const monthNameCreateDate = monthNames[monthNumberCreateDate];
      
      const formattedCreateDate = `${dayCreateDate} ${monthNameCreateDate}`;
      const currentDate = new Date();
      const timeDiff = Math.abs(currentDate.getTime() - createUpdateDateObject.getTime());
      const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
      
      const daysAgo = diffDays + '  '+ getDaysAgoText(diffDays);
      if (trigger.dialog !== '') {
        let unescapedDialog;
        if (typeof trigger.dialog === 'string') {
          unescapedDialog = trigger.dialog.replace(/^"(.*)"$/, '$1').replace(/\\"/g, '"');
        } else {
          unescapedDialog = trigger.dialog; 
        }
        try {
          
          const dialogObject = JSON.parse(unescapedDialog);
          setTriggerData(dialogObject);
          setTriggerName(trigger.name);
          setTriggerId(trigger.id);
          setTriggerDateCreate(formattedCreateDate);
          setTriggerDateUpdate(daysAgo);
          setTiedChainData(tiedChainData);
        } catch (error) {
          console.error(error);
        }
      }  else {
        setTriggerData('');
        setTriggerName(trigger.name);
        setTriggerId(trigger.id);
        setTiedChainData(tiedChainData);
        setTriggerDateCreate(formattedCreateDate);
        setTriggerDateUpdate(daysAgo);
      }
    } else {
      setTriggerData(undefined);
      setTriggerName('');
      setTiedChainData('');
      setTriggerId('');
      setTriggerDateCreate('');
      setTriggerDateUpdate('');
    }
  };


  const [backupChainWindow, setBackupChainWindow] = useState(false);

  useEffect(() => {
    if (backupChainWindow) {
      setActiveComponent('backupWindow');
    }
    
  }, [backupChainWindow]);



  useEffect(() => {
    if (scenarioBotData !== null) {
      setActiveComponent('BotStructure');
    }
  }, [scenarioBotData]);


  const renderComponent = () => {
    switch (activeComponent) {
      case 'BotStructure':
        return (
            <BotStructure
            triggerMenuLock ={triggerMenuLock}
            scenarioBotData={scenarioBotData}
            openCreateTriggerWindow={handleOpenCreateTriggerWindow}
            setActiveTabContent={handleSetActiveTabContent}
            selectedChainDialog={handleSelectedChainDialog}
            setSelectedTrigger={handleSelectedTrigger}
            refreshChainsData={refreshChainsData}
            refreshTriggersData={refreshTriggersData}
            setDataState={handleSetDataState}
            setChainsOptions={setChainsOptions}
            setDefaultTrigger={handleSetDefaultTrigger}
            backupChainWindow={setBackupChainWindow}
          />
        );
     
      case 'backupWindow':
        return <BackupWindow currBotID={scenarioBotData.id} />;
      default:
        <>default</>
    }
  };
  const returnToActiveContent = (defaultTab) => {
 
      setActiveContent(defaultTab);
  };
  const handleSetDataState = (e) => {
    setRefreshChainsData(false);
    setRefreshTriggersData(false);
  };
  const handleDeleteChain  = async () => {
      try {
         const data = {
          route : 'deleteDialogBot',
          user_tg_id: dashboardData.user['telegram_id'],
          bot_id:scenarioBotData.id,
          access_token:dashboardData.token,
          dialog_id:chainId,
    
          };
          setIsLoadingDelete(true);
          const response = await adapter.post('', data);
          if (response.data.result === 'success') {
            toast.success('Цепочка удалена!');
            setRefreshChainsData(true);
             
            }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
              try {
                const refreshTokenResult = await refreshToken();
              
                if (refreshTokenResult === 'logout') {
                  navigate('/', { replace: true });
                }else{
                  dashboardData.token = refreshTokenResult;
                  const data = {
                    route : 'deleteDialogBot',
                    user_tg_id: dashboardData.user['telegram_id'],
                    bot_id:scenarioBotData.id,
                    access_token:dashboardData.token,
                    dialog_id:chainId,
          
                  };
                  setIsLoadingDelete(true);
                  const response = await adapter.post('', data);
                  if (response.data.result === 'success') {
                    toast.success('Цепочка удалена!');
                    setRefreshChainsData(true);
                  }
                  else {
                    toast.error('Ошибка при удалении цепочки!');
                    console.log(response.data.text);
                  }
                }
              
              } catch (error) {
                toast.error('Ошибка при удалении цепочки!');
                console.log(error);
              }
            }else {
              toast.error('Ошибка при удалении цепочки!');
              console.log(response.data.text);
            }
            setOnDeleteChain(false);
        } catch (error) {
          toast.error('Ошибка при удалении цепочки!');
          console.log(error);
        }finally{
          setIsLoadingDelete(false);
        }
    
  }
  const handleDeleteTrigger = async (e) => {
    const isConfirmed = window.confirm('Вы уверены что хотите удалить этот триггер?');

    if (!isConfirmed) {
        return; 
    }
    const data = {
        route: 'deleteTrigerBot',
        user_tg_id: dashboardData.user['telegram_id'],
        bot_id: scenarioBotData.id,
        access_token: dashboardData.token,
        triger_id: triggerId,
    };
    setIsLoadingDelete(true);

    try {
        const response = await adapter.post('', data);

        if (response.data.result === 'success') {
            toast.success('Триггер удален');
        } else if (response.data.result === 'error' && response.data.text === 'Token is expired') {
            const refreshTokenResult = await refreshToken();

            if (refreshTokenResult === 'logout') {
                navigate('/', { replace: true });
            } else {
                dashboardData.token = refreshTokenResult;
                setIsLoadingDelete(true);

                const response = await adapter.post('', data);

                if (response.data.result === 'success') {
                  toast.success('Триггер удален');
                } else {
                  toast.error(response.data.error);
                }
            }
        } else {
          toast.error(response.data.error);
        }
    } catch (error) {
        toast.error(error);
        console.error(error);
    }finally{
      setIsLoadingDelete(false);
      setRefreshTriggersData(true);
    }
};
  const handleCreateNewChain = () => {
    createNewChain(scenarioBotData.id, scenarioBotData.name);
  };
  const tieChain = async (selectedDialogId, triggerId) => {
    const data = {
      route: 'connectDialogToTriger',
      bot_id:scenarioBotData.id,
      dialog_id: selectedDialogId,
      triger_id: triggerId,
      access_token: dashboardData.token,
    };
    setIsLoading(true);
    setSelectedOption(null);
    setShowCombineButton(false);
    const response = await adapter.post('', data);
    if (response.data.result === 'success') {
      setRefreshChainsData(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
    }
    else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
      try {
        const refreshTokenResult = await refreshToken();
      
        if (refreshTokenResult === 'logout') {
          navigate('/', { replace: true });
        }else{
          const data = {
            route: 'connectDialogToTriger',
            bot_id:scenarioBotData.id,
            dialog_id: selectedDialogId,
            triger_id: triggerId,
            access_token: dashboardData.token,
          };
          setIsLoading(true);
          setSelectedOption(null);
          setShowCombineButton(false);
          const response = await adapter.post('', data);
          if (response.data.result === 'success') {
            setRefreshChainsData(true);
            setTimeout(() => {
              setIsLoading(false);
            }, 2000);
          }
          else {
            console.log(response.data);
          }
        }
      
      } catch (error) {
        console.error(error);
      }
    }
    else {
        console.error(response.data);
    }
  }
  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption.value);
    setShowCombineButton(true);
  }
  const handleCombineClick = () => {
    if (selectedOption) {
      tieChain(selectedOption, triggerId);
    }
  };
  const [triggerMenuLock, setTriggerMenuLock] = useState(false);
  const handleEditSelectedTrigger = () => {
    setActiveContent('createTriggerWindow');
    setEditTriggerState('editedTrigger');
    setEditTriggerDataProps(editTriggerData);
    setTriggerMenuLock(true);
  }
  const resetTriggerState = () => {
    setEditTriggerState('');
    setEditTriggerDataProps([]);
  }
  const disconnectDialogFromTriger = async (triggerId) => {
    const confirmChain = window.confirm("Вы уверены, что хотите отвязать цепочку?");
    if (confirmChain) {
      const data = {
        route: 'disconnectDialogFromTriger',
        triger_id: triggerId,
        access_token: dashboardData.token,
      };
      const response = await adapter.post('', data);
      if (response.data.result === 'success') {
        setRefreshTriggersData(true);
        toast.success('Успешно отвязана!')
      }
      else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
        try {
          const refreshTokenResult = await refreshToken();
        
          if (refreshTokenResult === 'logout') {
            navigate('/', { replace: true });
          }else{
          
            if (response.data.result === 'success') {
              setRefreshTriggersData(true);
              toast.success('Успешно отвязана!')
            }
            else {
              console.log(response.data);
            }
          }
        
        } catch (error) {
          console.error(error);
        }
      }
      else {
          console.error(response.data);
      }
    }
  }




  const renderComponentContent = () => {
    switch (activeContent) {
      case 'createTriggerWindow':
        return <CreateTriggerWindow
        setTriggerMenuLock={setTriggerMenuLock}
        selectedBotId={scenarioBotData.id}
        returnToActiveContent={returnToActiveContent}
        setRefreshTriggersData={setRefreshTriggersData}
        editTriggerData={editTriggerDataProps || {}}
        editTriggerState={editTriggerState}
        resetTriggerState={resetTriggerState}
      />;
      case 'Chains':
        return (
          <>
            {chainName ? (
              <div className='render-content-title'>
                <p>{chainName}</p>
                {data && isLoading === false ? (
                    <button className='btn_editchain' onClick={handleEditChain}>Редактировать цепочку</button>
                ) : null}
                <button className="action-btn btn_disconnect" type="button" onClick={handleDeleteChain}>Удалить цепочку</button>
              </div>
            ): (<p>Не найдено ни одной сохраненной цепочки!</p>)} 
            {data  && chainName ? (
              <>
              <div className='form-elem date-create-and-update'>
                <small><p>Cоздана:  {chainsDateCreate}<FaCalendarAlt className='fa'></FaCalendarAlt></p></small>
                <small><p>Обновлена:  {chainsDateUpdate}<FaCalendarCheck className='fa'></FaCalendarCheck></p></small>
              </div>
              <FlowDiagram
                key={chainId} 
                auth={auth}
                data={data}
                lock={lock}
                onDeleteChain={onDeleteChain}
              />
              </>
            ): null}
          </>
        );
      case 'Triggers':
        return (
          <>
            {triggerData === undefined ? (<p>Еще не создано ниодного триггера!</p>) : (
              <>
              <div className='render-content-title'>
                {triggerName ? (
                  <>
                    <p>Триггер: {triggerName}</p>
                   
                    <button className="action-btn btn_disconnect" type="button" onClick={handleDeleteTrigger}>Удалить триггер</button>
                    <button className="action-btn btn_edit" type="button" onClick={handleEditSelectedTrigger}>Редактировать триггер</button>
                  </>
                ) : null}
              </div>
              <div className='form-elem date-create-and-update'>
                <small><p>Cоздан:  {triggerDateCreate}<FaCalendarAlt className='fa'></FaCalendarAlt></p></small>
                <small><p>Обновлён:  {triggerDateUpdate}<FaCalendarCheck className='fa'></FaCalendarCheck></p></small>
               
                {tiedChainData.name && tiedChainData.id ? (
                  <>
                    <small>Привязана цепочка: {tiedChainData.name}</small>
                    <button className="action-btn btn_disconnect disconnect-trigger-btn" type="button" onClick={()=>disconnectDialogFromTriger(tiedChainData.id)}>Отвязать</button>
                  </>
                ): tiedChainData.name ?
                 (
                  <>
                    <small>Информация про привязанную цепочку отсутствует</small>
                    <button className="action-btn btn_disconnect disconnect-trigger-btn" type="button" onClick={()=>disconnectDialogFromTriger(tiedChainData.id)}>Отвязать</button>
                  </>)
                 : null }
              </div>
              

              {isLoading ? (
                <>
                
                  <p className='preloader-title'>Объединяем триггер и цепочку</p>
                  <Preloader></Preloader>
                </>
              ) : (
              <>
                {triggerData === '' ? (
                  <>
                  <p className='form-elem'>К триггеру <strong>{triggerName}</strong> не привязана ни одна цепочка!</p>
                  <div className="structure__trigers-create d-flex">
                    {showCombineButton ? (
                      <button className="action-btn form-elem" type="button" onClick={handleCombineClick}>
                        Объединить
                      </button>
                    ) : 
                      null
                    }
                    <button className="action-btn form-elem" type="button" onClick={handleCreateNewChain}>
                        Создать новую цепочку
                      </button>
                    <Select
                      className="select-focus form-elem"
                      options={chainsOptions.map(item => ({value:item.id, label:item.name}))}
                      isDisabled={chainsOptions[0]?.value === 'Не найдено ни одной сохраненной цепочки!'}
                      placeholder={
                        chainsOptions[0]?.value === 'Не найдено ни одной сохраненной цепочки!'
                          ? 'Не найдено ни одной сохраненной цепочки!'
                          : 'Выбрать существующую:'
                      }
                      onChange={handleSelectChange} 
                    />
                  </div>
                </>
                ) : (
                  <>
                  <button className='btn_editchain' onClick={handleEditChainTrigger}>Редактировать цепочку</button>
                  <FlowDiagram
                    key={triggerId} 
                    auth={auth}
                    data={triggerData}
                    lock={lock}
                  />
                  </>
                )}
              </>
                )}



              </>
              
            )}  
          </>
        );
      default:
        return (
          <>
            {chainName ? (
              <div className='render-content-title'>
                <p>{chainName}</p>
                {data && isLoading === false ? (
                    <button className='btn_editchain' onClick={handleEditChain}>Редактировать цепочку</button>
                ) : null}
                <button className="action-btn btn_disconnect" type="button" onClick={handleDeleteChain}>Удалить цепочку</button>
              </div>
            ): (<p>Не найдено ни одной сохраненной цепочки!</p>)} 
            {data  && chainName ? (
              <>
              <div className='form-elem date-create-and-update'>
                <small><p>Cоздана:  {chainsDateCreate}<FaCalendarAlt className='fa'></FaCalendarAlt></p></small>
                <small><p>Обновлена:  {chainsDateUpdate}<FaCalendarCheck className='fa'></FaCalendarCheck></p></small>
              </div>
              <FlowDiagram
                key={chainId} 
                auth={auth}
                data={data}
                lock={lock}
                onDeleteChain={onDeleteChain}
              />
              </>
            ): null}
          </>
        );
    }
  };
  return (
    <>
      <h1 className="main-title">Сценарии ботов</h1>
      {activeComponent && (
        <>
          <p className="breadcrumbs" onClick={() => handleReturnToPanel()}>
            Вернуться к выбору бота
          </p>
          <div className="card">
            <p className={styles['bot-name']}>Ваш бот: <span>{scenarioBotData.name}</span></p>
            <ul className={styles['scenario-list']}>
              <li
                className={`${styles['scenario-menu__item']} ${
                  activeComponent === 'BotStructure'
                    ? styles['scenario-menu__item--active']
                    : ''
                }`}
              >
                <button
                  className={styles['scenario-menu__item-link']}
                  onClick={() => setActiveComponent('BotStructure')}
                >
                  Структура ботов
                </button>
              </li>
              <li
                className={`${styles['scenario-menu__item']} ${
                  activeComponent === 'backupWindow'
                    ? styles['scenario-menu__item--active']
                    : ''
                }`}
              >
                <button
                  className={styles['scenario-menu__item-link']}
                  onClick={() => setActiveComponent('backupWindow')}
                >
                  Восстановить цепочку
                </button>
              </li>
            </ul>
            <div className={styles['scenario-body']}>
            {renderComponent()}
            {activeComponent === 'BotStructure' ? (
              <div id='diagram' className="card ">{renderComponentContent()}</div>
            ) : null}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default BotScript;