import React from 'react';
import styles from './addHeaders.module.css';
import HeaderInputUI from '../inputUI/HeaderInputUI';
import ButtonDashedUI from '../inputUI/ButtonsUI/ButtonDashedUI';

function AddHeadersUI({ headers, addHeader, deleteHeader, changeHeader }) {
  return (
    <div className={styles.box}>
      <p className={styles.title}>Заголовки</p>
      {headers.map((header, i) => (
        <HeaderInputUI
          data={header}
          deleteHeader={deleteHeader}
          changeHeader={changeHeader}
          index={header.id}
          key={header.id}
        />
      ))}
      <ButtonDashedUI
        size={'small'}
        text="Добавить заголовок"
        clickHandler={addHeader}
      />
    </div>
  );
}

export default AddHeadersUI;
