import React from 'react';
import styles from './FilterIfNotBlock.module.css';

function IfNotBlock() {
  return (
    <div className={styles.block}>
      <span className={styles.text}>Если нет:</span>
    </div>
  );
}

export default IfNotBlock;
