
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import '../../../../../../index.css';
import './Statistics.css';
import refreshToken from '../../../../../../refreshToken';
import adapter from '../../../../../../adapter';
import PeriodFilter from './periodFilter/PeriodFilter';
import { toast } from 'react-toastify';
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});

const Statistics = ({selectedBotId}) => {
    const storedData = JSON.parse(localStorage.getItem('dashboardData'));
    const dashboardData = storedData ? storedData: null;

    const [botsStat, setBotsStat] = useState(null);
    const navigate = useNavigate();
   
    const handleGetStatsBot = async () => {
        if(!date.from || !date.to){
            toast.error('Ошибка в дате!');
            return
        }

            const data = {
                    route : 'getStatsBot',
                    user_tg_id: dashboardData.user['telegram_id'],
                    bot_id:selectedBotId,
                    date_from:date.from,
                    date_to:date.to,
                    access_token:dashboardData.token
            };
            const response = await adapter.post('', data);
            try {
                if (response.data.result) {
                    setBotsStat(response.data.array);
                    if (botsStat) {
                        toast.success('Статистика обновлена!');
                    }
                    
                     
                }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
                    try {
                      const refreshTokenResult = await refreshToken();
                    
                      if (refreshTokenResult === 'logout') {
                        navigate('/', { replace: true });
                      }else{
                        dashboardData.token = refreshTokenResult;
                        const data = {
                            route : 'getStatsBot',
                            user_tg_id: dashboardData.user['telegram_id'],
                            bot_id:selectedBotId,
                            date_from:date.from,
                            date_to:date.to,
                            access_token:dashboardData.token
                    };
                        const response = await adapter.post('', data);
                                
                        if (response.data.result === 'success') {
                            setBotsStat(response.data.array);
                            if (botsStat) {
                                toast.success('Статистика обновлена!');
                            }
                             
                        }
                        else {
                          console.log(response.data);
                        }
                      }
                    
                    } catch (error) {
                      console.error(error);
                    }
                  }
                else {
                console.log('Произошла ошибка:  ' + response.data);
                } 
            } catch (error) {
                rollbar.error('Error during request:', error);
            }
       
    }
    const currentDate = new Date().toLocaleDateString('en-CA');
    const [date, setDate] = useState({
        from:currentDate,
        to:currentDate
    });
    
    

    const setStatsDate = (date) => {
        let dateFrom = null;
        let dateTo = null;
    
        if (date.from) {
            dateFrom = new Date(date.from).toLocaleDateString('en-CA'); 
        }
    
        if (date.to) {
            dateTo = new Date(date.to).toLocaleDateString('en-CA');
        }
        setDate({
            ...date,
            from:dateFrom,
            to:dateTo
        })
    };


    useEffect(() => {
        if (date) {
            handleGetStatsBot();
        }
        
    }, [date]);
    const [setshowFilterStats, setSetshowFilterStats] = useState(false);

    const setShowStats = (e) => {
        setSetshowFilterStats(e);
    }

    return(
            <div style={{width: '100%'}}>
                <h4 className='card-title form-elem'>Статистика за весь цикл жизни бота</h4>
                <div class="stat-data">
                    {botsStat !== null ? (
                        Object.entries(botsStat)
                        .filter(([key]) => !['raise_period', 'receive_messages', 'sessions_count'].includes(key))
                        .map(([key, value]) => {
                            let title = '';
                            switch (key) {
                              
                                case 'start_bots':
                                title = 'Всего подписчиков';
                                break;
                                case 'active_users':
                                title = 'Количество активных пользователей';
                                break;
                                case 'blocked_users':
                                title = 'Количество заблокированых пользователей';
                                break;
                                case 'raise_today':
                                title = 'Прирост за сегодня';
                                break;
                                case 'raise_week':
                                title = 'Прирост за неделю';
                                break;
                                case 'raise_month':
                                title = 'Прирост за месяц';
                                break;
                                default:
                                title = key;
                            }

                            return (
                            <div key={key} className="stat-data__item">
                                <strong>{value}</strong>
                                    <p className="stat-data-item__title">{title}</p>
                                {/* <small>Всего: <p>1</p></small> */}
                            </div>
                            );
                        })
                    ) : (
                        <p>Данных не найдено!</p>
                    )}
                </div>
                <PeriodFilter setStatsDate={setStatsDate} setShowStats={setShowStats}></PeriodFilter>
                
                {setshowFilterStats && (
                    <>
                        <h4 className='card-title form-elem'>Отображена статистика в промежутке с {date.from} по {date.to}</h4>
                        <div class="stat-data">
                            {botsStat !== null ? (
                                Object.entries(botsStat)
                                .filter(([key]) => ['raise_period', 'receive_messages', 'sessions_count'].includes(key))
                                .map(([key, value]) => {
                                    let title = '';
                                    switch (key) {
                                        case 'raise_period':
                                        title = 'Прирост за выбранный период';
                                        break;
                                        case 'receive_messages':
                                        title = ' Входящие сообщения за выбранный период';
                                        break;
                                        case 'sessions_count':
                                        title = 'Сессий за выбранный период';
                                        break;
                                        default:
                                        title = key;
                                    }

                                    return (
                                    <div key={key} className="stat-data__item">
                                        <strong>{value}</strong>
                                            <p className="stat-data-item__title">{title}</p>
                                        {/* <small>Всего: <p>1</p></small> */}
                                    </div>
                                    );
                                })
                            ) : (
                                <p>Данных не найдено!</p>
                            )}
                        </div>
                    </>
                   
                )}
             
            </div>
    );

}
    
    
    
    
export default Statistics;