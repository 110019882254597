import React, { useState } from "react";
import styles from "./CreateConstant.module.css";
import TextUI from "../../popup/UI/inputUI/TextUI";
import SelectUI from "../../popup/UI/inputUI/SelectUI";
import { VariablesType } from "../../../config/VariablesType";
import ButtonActionUI from "../../popup/UI/inputUI/ButtonsUI/ButtonActionUI";
import { useStoreApi } from "reactflow";
import { createVariable } from "../../../api/diagramApi";
function CreateConstant({ close }) {
  const store = useStoreApi();

  const [nameConstant, setNameConstant] = useState("");
  const [typeConstant, setTypeConstant] = useState("number");
  const nameConstantHandle = (text) =>
    text.length > 64 ? null : setNameConstant(text);
  const typeConstantHandle = (type) => setTypeConstant(type);

  const createConstant = () => {
    const { auth, apiInfo } = store.getState();

    if (nameConstant < 3 || !typeConstant) {
      return;
    }
    const newConstant = { type: typeConstant, name: nameConstant };
    createVariable(auth["bot_id"], auth["access_token"], newConstant).then(
      (result) => console.log("okay", result)
    );
    const newApiInfo = {
      ...apiInfo,
      variables: [...apiInfo.variables, newConstant],
    };
    store.setState({ ...store.getState(), apiInfo: newApiInfo });
    close();
  };

  return (
    <div className={styles.modal}>
      <div className={styles.popup}>
        <div className={styles.titleBox}>
          <h3>Создать переменную</h3>
          <div className={styles.close} onClick={close}>
            <h3>⨯</h3>
          </div>
        </div>
        <div className={styles.inputBox}>
          <p className={styles.inputTitle}>Имя переменной:</p>
          <TextUI
            placeholder={"Имя"}
            storeValues={false}
            text={nameConstant}
            textHandler={nameConstantHandle}
            limit={64}
          />
        </div>
        <div className={styles.inputBox}>
          <p className={styles.inputTitle}>Тип переменной:</p>
          <SelectUI
            fullWidth={true}
            handler={typeConstantHandle}
            options={VariablesType}
          />
        </div>
        <div className={styles.buttonBox}>
          <ButtonActionUI handler={close} text="Отменить" />
          <ButtonActionUI
            handler={() => createConstant()}
            text="Создать"
            variant="secondary"
          />
        </div>
      </div>
    </div>
  );
}

export default CreateConstant;
