import React, { useState, useEffect} from 'react';
import '../../adapter';
import '../../index.css';
import './RegPage.css';
import adapter from '../../adapter';
import { useNavigate } from 'react-router-dom';
import Preloader from '../../Preloader';
import { toast } from 'react-toastify';
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});

const RegPage = () => {
  const [formData, setFormData] = useState({
    username: '',
    pass: '',
    name: '',
    role: 'Admin',
    route: 'registration',
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const handleRegistration = async (e) => {
    e.preventDefault();
    if (!isFormValid()) {
      return;
    }
    setIsLoading(true);
    try {
      const response = await adapter.post('/', formData);
  
      if (response.data.result === 'success') {
        toast.success('Пользователь создан!');
        setTimeout(() => {
          navigate('/');
        }, 1000);
      } else {
        toast.error(response.data.text);
      }
    } catch (error) {
      console.error(error);
      toast.error(error);
      rollbar.error('Error during request:', error);
    }finally{
      setIsLoading(false);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const isFormValid = () => {
    let isValid = true;
    const newErrors = {};

    if (!formData.username) {
      newErrors.username = 'Пожалуйста, введите ваш username.';
      isValid = false;
    }
    if (!formData.name) {
      newErrors.name = 'Пожалуйста, введите вашe имя.';
      isValid = false;
    }

    if (!formData.pass) {
      newErrors.pass = 'Пожалуйста, введите ваш пароль.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };


  const navigate = useNavigate();
  const handleLoginPage = () => {
    navigate('/');
  };

  const phrases = ['Projects', 'Bloggers'];
  const [displayedText, setDisplayedText] = useState('');
  const [showCursor, setShowCursor] = useState(true);
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isDeleting && currentTextIndex < phrases[currentPhraseIndex].length) {
        setDisplayedText(prevText => prevText + phrases[currentPhraseIndex][currentTextIndex]);
        setCurrentTextIndex(prevIndex => prevIndex + 1);
      } else if (!isDeleting && currentTextIndex === phrases[currentPhraseIndex].length) {
        setIsDeleting(true);
      } else if (isDeleting && currentTextIndex > 0) {
        setDisplayedText(prevText => prevText.slice(0, -1));
        setCurrentTextIndex(prevIndex => prevIndex - 1);
      } else {
        setIsDeleting(false);
        setCurrentTextIndex(0);
        setCurrentPhraseIndex(prevIndex => (prevIndex + 1) % phrases.length);
      }
    }, 250); 

    const cursorInterval = setInterval(() => {
      setShowCursor(prevShowCursor => !prevShowCursor);
    }, 500);

    return () => {
      clearInterval(interval);
      clearInterval(cursorInterval);
    };
  }, [currentTextIndex, currentPhraseIndex, isDeleting]);

  return (
    <div id='authPage' className="auth-page-inner">
     <div className='auth-page__img'></div>
     <div className="auth-page__content">
    
     <h1 className="main-title">Bot4Crypto {displayedText}
            {showCursor && <span style={{ opacity: 0.5 }}>|</span>}</h1>
        {isLoading ? (
                <>
                  <p className='preloader-title'>Регистрируем вас</p>
                  <Preloader></Preloader>
                </>
            ) : (
        <div className="card">
          <h4 className='card-title'>Регистрация</h4>
          <form className="" onSubmit={handleRegistration}>
            <div className="form-elem">
              <input
                type="text"
                value={formData.username}
                onChange={handleChange}
                className="input"
                name="username"
                placeholder='Имя аккаунта'
              />
              {errors.username && (
                <div className={`error ${errors.username && 'fade-in'}`}>{errors.username}</div>
              )}
            </div>
            <div className="form-elem">
              <input type="text" name="name" value={formData.name} onChange={handleChange} className="input"  placeholder='Ваше имя'/>
              {errors.name && (
                <div className={`error ${errors.name && 'fade-in'}`}>{errors.name}</div>
              )}
            </div>
            <div className="form-elem">
              <input
                type="password"
                name="pass"
                value={formData.pass}
                onChange={handleChange}
                className="input"
                placeholder='Пароль'
              />
              {errors.pass && (
                <div className={`error ${errors.pass && 'fade-in'}`}>{errors.pass}</div>
              )}
            </div>
            
            <div className="form-elem">
              <button type="submit" className="auth-btn">
                Зарегистрироваться
              </button>
            </div>
            <div className='btns'>
              <button className="reg-acc-btn" onClick={handleLoginPage}>
                <small>Войти в уже существующий аккаунт</small>
              </button>
            </div>
          </form>
        </div>
         )
        }
    </div>
    </div>
    
  );
};


export default RegPage ;