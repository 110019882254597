import React from 'react';
import styles from './JsonInputUI.module.css';
import TextUI from '../TextUI';
import JsonAutoCompleteUI from '../../JsonAutoCompleteUI';

function JsonInputUI({
  handler,
  text,
  type,
  close,
  json,
  popup,
  placeholder,
  handlerFocus,
  hideIndex,
}) {
  return (
    <div className={styles.container}>
      <TextUI
        textHandler={(text) => handler(text, type)}
        text={text}
        handlerFocus={handlerFocus}
        placeholder={placeholder}
      />
      {popup && (
        <JsonAutoCompleteUI
          data={json}
          handler={(text) => handler(text, type)}
          close={close}
          hideIndex={hideIndex}
          arrayTarget={type === 'arrayAnchor'}
        />
      )}
    </div>
  );
}

export default JsonInputUI;
