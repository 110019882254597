import React, { useCallback, useState } from "react";
import StoreValues from "../../StoreValues";
import { useDetectClickOutside } from "react-detect-click-outside";
import { insertText } from "../../../../../utils/insertText";

import styles from "./TextUI.module.css";
import Counter from "../../MessageTypeText/counter";

function TextUI({
  placeholder,
  storeValues,
  sx,
  textHandler,
  text,
  handlerFocus,
  errorStatus,
  limit,
  type,
}) {
  const [popup, setPopup] = useState(false);
  const [countVisible, setCountVisible] = useState(false);

  const dropDownRef = useDetectClickOutside({
    onTriggered: () => {
      setPopup(false);
    },
  });

  const popupToggle = () => {
    setPopup(!popup);
  };

  const dropDownHandle = (value) => {
    textHandler(insertText(text, value));
    setPopup(false);
  };

  const handleFocus = (boolen) => {
    if (handlerFocus) {
      handlerFocus(boolen);
    }
    setCountVisible(boolen);
  };

  return (
    <div className={styles.box} style={sx}>
      {storeValues && (
        <StoreValues
          popupStatus={popup}
          popupToggle={popupToggle}
          dropDownHandle={dropDownHandle}
          dropDownRef={dropDownRef}
          indent={"small"}
        />
      )}
      {Number.isInteger(limit) && (
        <Counter number={text.length} status={countVisible} limit={limit} />
      )}

      <input
        style={{ paddingRight: `${storeValues ? "40px" : "5px"}` }}
        className={
          errorStatus ? `${styles.input} ${styles.error}` : styles.input
        }
        onChange={(e) => textHandler(e.target.value)}
        value={text}
        type={type ? type : "text"}
        onFocus={() => handleFocus(true)}
        onBlur={() => handleFocus(false)}
        placeholder={placeholder}
      />
    </div>
  );
}

export default TextUI;
