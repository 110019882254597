import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../../../index.css';
import './ScheduleMailingConstructor.css';
import Select from 'react-select';
import botIcon from '../../../../../../assets/bot.png';
import storageRef  from '../../../../../../firebaseForFiles';
import { FaClock , FaRegPlusSquare, FaMobileAlt, FaTelegramPlane , FaLink, FaRegWindowClose, FaTrashRestoreAlt,FaFileVideo,FaFileWord} from 'react-icons/fa';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';

import adapter from '../../../../../../adapter';
import refreshToken from '../../../../../../refreshToken';
import Preloader from '../../../../../../Preloader';

import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});



const MailingCreate = ({botsData, scheduleData, returnToSchedule, setResetScheduleData, refreshSpamsData}) => {

   
    const storedData = JSON.parse(localStorage.getItem('dashboardData'));
    const dashboardData = storedData ? storedData: null;

    const initialOptions = [
        { value: 'contact_id', label: 'id пользователя' },
        { value: 'username', label: 'username пользователя' },
        { value: 'full_name', label: 'имя и фамилия юзера' },
        { value: 'first_name', label: 'имя юзера' },
        { value: 'table_stat', label: 'таблица статистики' },
        { value: 'referal_link', label: 'реферальная ссылка' },
        { value: 'ref_count', label: 'количество рефералов' },
        { value: '[link](text)', label: 'cсылка' },
        { value: 'today', label: 'сегодня' },
        { value: 'tomorrow', label: 'вчера' },
    ];
    const [variableOptions, setVariableOptions] = useState(initialOptions);

   
    const botsOptions = botsData.map(item => ({
        value: item.id,
        label: item.bot_name,
    }));
   

   

    const [multipleBots, setMultipleBots] = useState(false);
    const handleAddMultipleBots = (bots) => {
        if (!selectedBot.some(obj => obj.value === bots.value)) {
            setSelectedBot((prevSelectedBots) => [
                ...prevSelectedBots,
                { value: bots.value, label: bots.label }
            ]);
            setMailingData((prevMailingData) => ({
                ...prevMailingData,
                bot_id: [...prevMailingData.bot_id]
            }));
        } else {
            
            toast.error('Вы уже выбрали этого бота!');
        }
    }

    const mailingMessage = JSON.parse(scheduleData.message);
  
    const [mailingData, setMailingData] = useState({
        name:scheduleData.name ? scheduleData.name : '',
        spam_id:scheduleData.id ? scheduleData.id : '',
        time_send: scheduleData.time_send ? scheduleData.time_send : '',
        bot_id: scheduleData.bot_id ? scheduleData.bot_id : '',
        autodelete: scheduleData.seconds_delete ? scheduleData.seconds_delete : '',
        message: mailingMessage,
        file:mailingMessage.files ? mailingMessage.files : ''
    });
    const [selectedBot, setSelectedBot] = useState([]); 
    const [mailingName, setMailingName] = useState(null);
    
    const [showScheduleConf, setShowScheduleConf] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState('');
    const [deleteTimer, setDeleteTimer] = useState('');

    const parseDateString = (dateString) => {
        const [datePart, timePart] = dateString.split(' ');
        const [year, month, day] = datePart.split('-').map(Number);
        const [hours, minutes, seconds] = timePart.split(':').map(Number);
    
        return new Date(year, month - 1, day, hours, minutes, seconds);
    };
    
     


   
    const [message, setMessage] = useState('');
    const [linkButtons, setLinkButtons] = useState([]);

    useEffect(() => {
        if (scheduleData.bot_id) {
            if(Array.isArray(scheduleData.bot_id)){
                const botInfo = botsOptions.filter(bot => scheduleData.bot_id.includes(bot.value));
                setMultipleBots(true);
                setSelectedBot(botInfo);
                if (botInfo.length === 1) {
                    getBotVariables(botInfo[0].value);  
                }
            }else{
                const botInfo = botsOptions.find(item => item.value === scheduleData.bot_id);
                setSelectedBot(botInfo);
            }
        }
        if (scheduleData.name) {
            setMailingName(scheduleData.name);
        }
        if (mailingMessage) {
          
            if (mailingMessage.text) {
                setMessage(mailingMessage.text);
            }
            if (mailingMessage.buttons) {
                setLinkButtons(mailingMessage.buttons);
            }
            if (mailingMessage.files) {
                setFileUrls(mailingMessage.files);
                mailingMessage.files.forEach(file => {
                    if (file.name === "Photo") {
                        setSelectedPhoto([file.url]);
                    } else if (file.name === "Video") {
                        setSelectedVideo([file.url]);
                    }else if(file.name === "Document"){
                        setSelectedDocs([file.url]);
                    }
                });
            }
        }
        if (scheduleData.time_send) {
            setShowScheduleConf(true);
            const scheduleDate = parseDateString(scheduleData.time_send);
            setSelectedDate(scheduleDate);
            setSelectedTime(scheduleDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
        }
        if (scheduleData.seconds_delete !== '0') {
            setShowAutoDelete(true);
            setDeleteTimer(scheduleData.seconds_delete);
        }
      
    }, [scheduleData]);

    
    const handleExtractInputValue = (input, value) => {
        if(input === 'text'){
            setMailingData({
                ...mailingData,
                message: {
                  [input]: value
                }
              });
            setMessage(value);
        }else if(input === 'file'){
            setMailingData({ ...mailingData,
                [input]: value
            });
        }else if (input === 'bot_id') {
            if (multipleBots) {
                handleAddMultipleBots(value);
                getBotVariables(value.value);
            }else{
                setMailingData({
                    ...mailingData,
                    [input]: value.value,
                });
                setSelectedBot([value]);
            }
            

        }else if (input === 'autodelete') {
            setMailingData({
                ...mailingData,
                [input]: value,
            });
        }
        else{
            setMailingData({ ...mailingData, [input]: value });
        }
        
    };
    const [variableSelect, setVariableSelect] = useState(null);


    const handleAddVarToMess = (selectedOption) => {
        const txt = document.getElementById("messText"); 
        const { selectionStart: start, selectionEnd: end } = txt;
      
        let newText = '';
        if (selectedOption.value === '[link](text)') {
          newText = message.substring(0, start) + selectedOption.value + message.substring(end);
        } else {
          newText = message.substring(0, start) + '{{' + selectedOption.value + '}}' + message.substring(end);
        }
      
        setMessage(newText);
        setVariableSelect(null);
    };
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedDocs, setSelectedDocs] = useState(null);
    const [fileUrls, setFileUrls] = useState([]); 

    const handlePhotoUpload = (event) => {
        const files = event.target.files; 
        const newSelectedPhotos = [];
      
        // Проходимся по каждому файлу в массиве
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
      
          // Создаем URL для выбранного файла и добавляем его в новый массив
          newSelectedPhotos.push(URL.createObjectURL(file));
      
          if (file) {
            const fileRef = storageRef.child(file.name);
            fileRef
              .put(file)
              .then(() => {
                console.log('File uploaded successfully!');
                fileRef
                  .getDownloadURL()
                  .then((url) => {
                    // Добавляем URL файла в список URL-адресов
                    setFileUrls((prevUrls) => [
                      ...prevUrls,
                      { name: 'Photo', url },
                    ]);
                  })
                  .catch((error) => {
                          rollbar.error('Error during request:', error);
                    console.error('Error getting download URL:', error);
                  });
              })
              .catch((error) => {
                      rollbar.error('Error during request:', error);
                console.error('Error uploading file:', error);
              });
          }
        }
      
    };
    const handleVideoUpload = (event) => {
        const files = event.target.files;
        const newSelectedVideos = [];
    
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileUrl = URL.createObjectURL(file);
            newSelectedVideos.push({ id: file.name, url: fileUrl });
    
            if (file) {
                const fileRef = storageRef.child(file.name);
                fileRef
                    .put(file)
                    .then(() => {
                        console.log('File uploaded successfully!');
                        fileRef
                            .getDownloadURL()
                            .then((url) => {
                                setFileUrls((prevUrls) => [...prevUrls, { name: 'Video', url }]);
                            })
                            .catch((error) => {
                                      rollbar.error('Error during request:', error);
                                console.error('Error getting download URL:', error);
                            });
                    })
                    .catch((error) => {
                              rollbar.error('Error during request:', error);
                        console.error('Error uploading file:', error);
                    });
            }
        }
    };
    const handleDocumentUpload = (event) => {
        const files = event.target.files;
        const newSelectedDocs = [];
    
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileName = file.name;
            newSelectedDocs.push(fileName);
    
            if (file) {
                const fileRef = storageRef.child(fileName);
                fileRef
                    .put(file)
                    .then(() => {
                        console.log('File uploaded successfully!');
                        fileRef
                            .getDownloadURL()
                            .then((url) => {
                                setFileUrls((prevUrls) => [...prevUrls, { name: 'Document', url }]);
                            })
                            .catch((error) => {
                                      rollbar.error('Error during request:', error);
                                console.error('Error getting download URL:', error);
                            });
                    })
                    .catch((error) => {
                              rollbar.error('Error during request:', error);
                        console.error('Error uploading file:', error);
                    });
            }
        }
    };
    const handleRemoveFile = (index) => {
        const refreshFiles = [...fileUrls];
        refreshFiles.splice(index, 1);
        setFileUrls(refreshFiles);
    };


    const [showButtonConf, setShowButtonConf] = useState(false);
   
    const [showAutoDelete, setShowAutoDelete] = useState(false);
    
    const handleCancel = (button) => {
        if (button === 'showButtonConf') {
            setShowButtonConf(false); 
        } else if (button === 'showScheduleConf') {
            setShowScheduleConf(false);
        } else if (button === 'showAutoDelete') {
            setShowAutoDelete(false);
            setDeleteTimer('');
            setMailingData({
                ...mailingData,
                autodelete:''
            })
            setShowAutoDelete(false);
        }else if (button === 'multipleBots') {
            setMultipleBots(false);
            const currBot = botsOptions.find(item => item.value === scheduleData.bot_id[0]);
            setSelectedBot([currBot]);
            setMailingData({
                ...mailingData,
                bot_id: currBot.value
            })
        }
    }
    const handleAddButton = (e) => {
        if (selectedBot === null || selectedBot === undefined ) {
            toast.error('Сначала выберите бота!');
        }else{
            setShowButtonConf(e.target.checked);
        }
    }
    const handleScheduleOptions = (e) => {
        if (selectedBot === null || selectedBot === undefined ) {
            toast.error('Сначала выберите бота!');
        }else{
            setShowScheduleConf(e.target.checked);
        }
    }
    const handleAutoDelete = (e) => {
        if (selectedBot === null || selectedBot === undefined ) {
            toast.error('Сначала выберите бота!');
        }else{
            setShowAutoDelete(true);
            setDeleteTimer(mailingData.autodelete);
        }
    }
    const handleAddLinkButton = () => {
        if (!btnName) {
            toast.error('Пожалуйста, заполните поле "Название кнопки"');
          return;
        }
        let newButton;
      
        if (selectedLinkType === 'buttonChainRadio') {
          if (!contScen) {
            toast.error('Пожалуйста, выберите цепочку');
            return;
          }
      
          newButton = {
            title: btnName,
            type: 'continue',
            chain_id: Number(contScen),
          };
        } else if (selectedLinkType === 'buttonlinkRadio' || selectedLinkType === 'buttonWebAppRadio') {
          if (!btnUrl) {
            toast.error('Пожалуйста, заполните поле "Ссылка на страницу"');
            return;
          }
            const urlPattern = /^(?!.*(?:localhost|127\.0\.0\.1)).*https?:\/\/\S+/i;
            const kirilitsaPattern = /[а-яА-ЯЁё]/;
          
          if (!urlPattern.test(btnUrl) || kirilitsaPattern.test(btnUrl)) {
            toast.error('Пожалуйста, введите корректную ссылку. Ссылка не должна включать в себя кирилицу или локальные адреса');
            return;
          }
      
            if (selectedLinkType === 'buttonlinkRadio') {
                newButton = {
                    title: btnName,
                    type: 'link',
                    link: btnUrl,
                };
            }else if(selectedLinkType === 'buttonWebAppRadio'){
                newButton = {
                    title: btnName,
                    type: 'webapp',
                    link: btnUrl,
                };
            }
        }
        if (scenOptions.length > 0 && selectedLinkType === 'buttonChainRadio' && !contScen) {
            toast.error('Пожалуйста, выберите цепочку');
          return;
        }
      
        setLinkButtons([...linkButtons, newButton]);
        
        setBtnName('');
        setBtnUrl('');
        setBtnUrlOption('prev');
        setShowButtonConf(false);
        toast.success('Кнопка создана!');
    };
    const handleRemoveButton = (index, e) => {
        e.preventDefault();
        const updatedButtons = [...linkButtons];
        updatedButtons.splice(index, 1);
        setLinkButtons(updatedButtons);  
    };
    const handleBtnName = (e) => {
        setBtnName(e.target.value);
      };
    const handleBtnUrl = (e) => {
        setBtnUrl(e.target.value);
    };
    const handleLinkSelect = (event) => {
        const selectedValue = event.target.value;
        setSelectedLinkType(selectedValue);
    };
    const handlebtnUrlOptionChange = (e) => {
        setBtnUrlOption(e.target.value);
    };

  
    const [btnName, setBtnName] = useState(null);
    const [btnUrl, setBtnUrl] = useState(null);
    const [btnUrlOption, setBtnUrlOption] = useState('prev');
    const [selectedLinkType, setSelectedLinkType] = useState(null);
    const [contScen, setContScen] = useState(null);
    const [scenVariants, setScenVariants] = useState([]);
    const [scenOptions, setScenOptions] = useState([]);
    const handleContScen = (e) => {
        setContScen(e.value);
    };

    

    const handleConfirmTimer = () => {
        if (deleteTimer && /[^0-9]/.test(deleteTimer) ) {
            toast.error('Буквы не допустимы! Укажите задержку в минутах!');
            return; 
        }
        if (!deleteTimer || deleteTimer < 1) {
            toast.error('Таймер должен быть более или равен 1 минуте!');
            return; 
        }
        if(deleteTimer && deleteTimer > 2880){
            toast.error('Таймер должен быть не более 48 часов (2880 минут)!');
            return;  
        }
    
        handleExtractInputValue('autodelete', deleteTimer);
      
    }


    const moment = require('moment-timezone');

    const handleLaterSendingParams = (name, value) => {
       
        if (name === 'date') {
            setSelectedDate(value);
        } else if (name === 'time') {
            setSelectedTime(value);
           
        }
       
    };

    useEffect(() => {
        if (selectedDate && selectedTime) { 
            const formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
            const formattedDateTime = `${formattedDate} ${selectedTime}`;
            const userTimeZone = moment.tz.guess();
            const momentDateTime = moment.tz(formattedDateTime, userTimeZone);
            const formattedDateTimeUTC1 = momentDateTime.clone().tz('Europe/Paris').format('YYYY-MM-DD HH:mm');
         
            setMailingData({
                ...mailingData,
                time_send: formattedDateTimeUTC1,
            });
            
        }
    }, [selectedDate, selectedTime]);

    

    const navigate = useNavigate();


    const [isLoading, setIsLoading] = useState(false);
    const handleSendMailing = async (timeSend) => {
        if (!mailingData.name) {
            toast.error('Укажите имя для рассылки!');
            return
        }
        if (!mailingData.bot_id) {
            toast.error('Вы не выбрали бота!');
            return
        }
        if (mailingData.message.length === 0) {
            toast.error('Сообщение не должно быть пустым!');
            return
        }
        if (timeSend === 'later') {
            if (!mailingData.time_send) {
                toast.error('Вы не указали время отправки!');
                return
            }
        }
        if (showAutoDelete) {
            toast.error('Подтвердите значение автоудаления!');
            return
        }
        try {
            setIsLoading();
            const data = {
                route : 'updateSpam',
                tg_id: dashboardData.user['telegram_id'],
                access_token:dashboardData.token,
                ...mailingData,
            }
        
            const response = await adapter.post('', data);
            if (response.data.result === 'success') {
                setResetScheduleData(true);
                setIsLoading(false);
                toast.success('Рассылка успешно отредактирована!');
                refreshSpamsData(true);
                
            }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
                try {
                const refreshTokenResult = await refreshToken();
                
                if (refreshTokenResult === 'logout') {
                    navigate('/', { replace: true });
                }else{
                    dashboardData.token = refreshTokenResult;
                    const data = {
                        route : 'updateSpam',
                        tg_id: dashboardData.user['telegram_id'],
                        access_token:dashboardData.token,
                        ...mailingData,
                    }
                    const response = await adapter.post('', data);
                    if (response.data.result === 'success') {
                        setResetScheduleData(true);
                        setIsLoading(false);
                        toast.success('Рассылка успешно отредактирована!');
                        refreshSpamsData(true);
                    }
                }
            
                } catch (error) {
                console.error(error);
                setIsLoading(false);
                }
            }
            else {
                console.log(response.data);
                setIsLoading(false);
            }  
        } catch (error) {
        rollbar.error('Error during request:', error);
        }
    }
    useEffect(() => {
        if (linkButtons) {
            setMailingData({ ...mailingData,
                message:{
                    ...mailingData.message,
                    buttons: linkButtons
                }
            });
        }
    }, [linkButtons]);

    useEffect(() => {
        if (fileUrls) {
            handleExtractInputValue('file', fileUrls);
        }
        setMailingData({
            ...mailingData,
            file: fileUrls,
        });
    }, [fileUrls]);

    const handleDeleteBots = (index) => {
        const refreshBots = [...selectedBot];
        refreshBots.splice(index, 1);
        setSelectedBot(refreshBots);
    };

    useEffect(() => {
        if (selectedBot && Array.isArray(selectedBot)) {
            const botIds = selectedBot.map(bot => bot.value);
            
            setMailingData(prevMailingData => ({
                ...prevMailingData,
                bot_id: botIds,
            }));
        }
    }, [selectedBot]);



    useEffect(() => {
        if (returnToSchedule) {
            setResetScheduleData(true);
        }
    }, [returnToSchedule]);



    const getBotVariables =  async (botId) => {
        try {
            const data = {
                route : 'getVariables',
                access_token:dashboardData.token,
                bot_id: botId
            }
        
            const response = await adapter.post('', data);
            if (response.data.result === 'success') {
                const currVariables = response.data.array;
                const newOptions = currVariables.map(item => ({
                    value: item.name,
                    label: item.name 
                }));
                const updatedOptions = [
                    ...newOptions,
                    ...initialOptions
                    
                ];
                setVariableOptions(updatedOptions);
    
            }else if(response.data.result === 'error' && response.data.text === 'Ошибка, нету переменных у бота'){
                setVariableOptions(initialOptions);
            }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
                try {
                  const refreshTokenResult = await refreshToken();
                
                  if (refreshTokenResult === 'logout') {
                    navigate('/', { replace: true });
                  }else{
                    dashboardData.token = refreshTokenResult;
                    const data = {
                        route : 'getVariables',
                        access_token:dashboardData.token,
                        bot_id: botId
                    }
                    const response = await adapter.post('', data);
                    if (response.data.result === 'success') {
                        const currVariables = response.data.array;
                        const newOptions = currVariables.map(item => ({
                            value: item.name,
                            label: item.name 
                        }));
                        const updatedOptions = [
                            ...newOptions,
                            ...initialOptions
                            
                        ];
                        setVariableOptions(updatedOptions);
            
                    }else {
                        console.log(response.data);
                        setVariableOptions(initialOptions);
                    }
                  }
            
                } catch (error) {
                  console.error(error);
                   setVariableOptions(initialOptions);
                }
              }
            else {
                console.log(response.data);
                 setVariableOptions(initialOptions);
            }
              
        } catch (error) {
            rollbar.error('Error during request:', error);
        }
        }


    return (
        <>
            {isLoading ? (
                <>
                    <p className='preloader-title'>Ожидайте</p>
                    <Preloader></Preloader>
                </>
            ):(
                <>
                    <div>
                        <div className="form-elem ">
                            <label htmlFor="bot__mailing-name">Изменить название рассылки:</label>
                            <input name="name" type="text" id="bot__mailing-name" className="input" placeholder="Введите название"
                            value={mailingName} 
                            onChange={(e) => {
                                handleExtractInputValue('name', e.target.value);
                                setMailingName(e.target.value);
                            }
                            }
                            />  
                        </div>
                       
                        <div className='select-bot-group'>
                            <div className="form-elem">
                                <div>
                                    <Select 
                                    id="botSelect"
                                    name="botId"
                                    placeholder="Выберите бота" 
                                    options={botsOptions} 
                                    value={selectedBot} 
                                    onChange={(e) => { 
                                        handleExtractInputValue('bot_id', e);
                                    }}
                                    />
                                </div>
                                
                                <div className="checkbox-group">
                                    <label htmlFor="addBots" className={`checkbox ${multipleBots ? 'selected-item' : ''}`}>
                                    <input
                                        className="checkbox-input"
                                        type="checkbox"
                                        id="addBots"
                                        onChange={() =>{
                                            setMultipleBots(true);
                                            setMailingData({
                                                ...mailingData,
                                                bot_id: ''
                                            })
                                            setSelectedBot([]);
                                        } 
                                        }
                                        checked={multipleBots}
                                    />
                                    <div className="checkbox-box">
                                        <div className="checkbox-check"></div>
                                    </div>
                                        <span>Выбрать несколько ботов для рассылки</span>
                                    </label>
                                </div>
                               
                                {multipleBots  ? (
                                    <button  
                                    className='mailing-btn cancel-btn' 
                                    type="button" 
                                    onClick={() => handleCancel(multipleBots ? 'multipleBots': '' )}
                                    >
                                        Сброс
                                    </button>
                                ): null}
                               
                            </div>
                            {multipleBots && (
                                <div className='form-elem'>
                                {selectedBot.length > 0 ? (
                                    <>
                                        <p>Рассылка будет отправлена от лица ботов:</p>
                                        <div className='multiple-bots-container '>
                                            {
                                                selectedBot.map((bot, index) => {
                                                    return(
                                                        <div className='multiple-bot' key={index}>
                                                            <span>{bot.label}</span>
                                                            <button type='button' class="delete-link-btn" onClick={() => handleDeleteBots(index)}></button>
                                                        </div>
                                                    )
                                                    
                                                })
                                            }
                                        </div>
                                    </>
                                        ): (
                                            <p>Теперь выберите ботов для рассылки из списка выше!</p>
                                        )}
                                        
                                </div>
                            )}
                        </div>
                        
                        <div className="form-elem">
                           
                           
                        </div>
                      
                    </div>
                    <div className='card'>
                        <h3 className='card-title'>Измените сообщение:</h3>
                        <div className='mailing-body'>
                            <div className='mailing-body__elem'>
                                <div className="form-elem">
                                    <small><p>По желанию задайте дополнительные параметры:</p></small>
                                    <Select 
                                    className='var-select select-focus' 
                                    options={variableOptions} 
                                    placeholder="{Переменные}"  
                                    onChange={handleAddVarToMess} 
                                    value={variableSelect}
                                    styles={{
                                        menu: (baseStyles) => ({
                                            ...baseStyles,
                                            backgroundColor: '#e9ecef',
                                            color:'rgb(32, 34, 37)', 
                                            }),
                                        option: (baseStyles) => ({
                                        ...baseStyles,
                                        cursor:'pointer'
                                        }),
                                        }}
                                    />
                                    <div className="file-input-wrapper">
                                        <input type="file" id="photo" accept="image/jpg, image/png, image/gif" onChange={handlePhotoUpload} multiple/>
                                        <label className='main-btn file-btn' htmlFor="photo"><FaRegPlusSquare className='fa'></FaRegPlusSquare>Фото к посту</label>
                                        
                                    </div>
                                    <div className="file-input-wrapper">
                                        <input type="file" id="video" accept="video/*" onChange={handleVideoUpload}/>
                                        <label className='main-btn file-btn' htmlFor="video"><FaRegPlusSquare className='fa'></FaRegPlusSquare> Видео к посту</label>
                                    </div>  
                                    <div className="file-input-wrapper">
                                        <input type="file" id="document" accept=".pdf,.doc,.docx" onChange={handleDocumentUpload} />
                                        <label className="main-btn file-btn" htmlFor="document">
                                            <FaRegPlusSquare className="fa" /> Файл
                                        </label>
                                    </div>
                                    <label htmlFor="addBtn" className={`checkbox ${showButtonConf ? 'selected-item' : ''}`}>
                                    <input
                                        className="checkbox-input"
                                        type="checkbox"
                                        id="addBtn"
                                        checked={showButtonConf}
                                        onChange={handleAddButton}
                                        disabled={showButtonConf ? true : false}
                                    />
                                    <FaRegPlusSquare className='fa checkbox-fa'></FaRegPlusSquare>
                                    <span>Добавить кнопку</span>
                                    </label>
                                    {showButtonConf ? (
                                    <>
                                        
                                        <p className='form-elem'>Выберите тип кнопки:</p>
                                        <div className="radio-group form-elem">
                                            <label htmlFor="buttonlinkRadio" className="radio">
                                            <input className="radio-input" type="radio" id="buttonlinkRadio" name="buttonlinkRadio" value="buttonlinkRadio" checked={selectedLinkType === "buttonlinkRadio"} onChange={handleLinkSelect} />
                                            <div className="radio-circle">
                                                <div className="radio-mark"></div>
                                            </div>
                                            <small className='radio-label'>Ссылка:</small>
                                            </label>
                                            <label htmlFor="buttonChainRadio" className="radio">
                                            <input className="radio-input" type="radio" id="buttonChainRadio" name="buttonChainRadio" value="buttonChainRadio" checked={selectedLinkType === "buttonChainRadio"} onChange={handleLinkSelect} />
                                            <div className="radio-circle">
                                                <div className="radio-mark"></div>
                                            </div>
                                            <small className='radio-label'>Продолжить цепочку:</small>
                                            </label>
                                            <label htmlFor="buttonWebAppRadio" className="radio">
                                            <input className="radio-input" type="radio" id="buttonWebAppRadio" name="buttonWebAppRadio" value="buttonWebAppRadio" checked={selectedLinkType === "buttonWebAppRadio"} onChange={handleLinkSelect} />
                                            <div className="radio-circle">
                                                <div className="radio-mark"></div>
                                            </div>
                                            <small className='radio-label'>WebApp:</small>
                                            </label>
                                        </div>

                                        {selectedLinkType === 'buttonlinkRadio' && (
                                            <>
                                                <div className="form-elem">
                                                    <label htmlFor="btnName">Название кнопки:</label>
                                                    <input className='input' type='text' name='btnName' placeholder='Название кнопки' value={btnName} onChange={handleBtnName} />
                                                </div>
                                                <div className="form-elem">
                                                    <label htmlFor="urlLink">Ссылка на страницу:</label>
                                                    <input   className={`input`} type='url' name='urlLink' placeholder='https://' value={btnUrl} onChange={handleBtnUrl}/>
                                                </div>
                                                <p className='form-elem'>Выберите вариант ссылки:</p>
                                                <div className="radio-group form-elem">
                                                    <label htmlFor="prev" className="radio">
                                                        <input className="radio-input" type="radio" id="prev" name="linkOption" value="prev" checked={btnUrlOption === "prev"} onChange={handlebtnUrlOptionChange}/>
                                                        <div className="radio-circle">
                                                        <div className="radio-mark"></div>
                                                        </div>
                                                        <small className='radio-label'>С превью</small>
                                                    </label>
                                                    <label htmlFor="outPrev" className="radio">
                                                        <input className="radio-input" type="radio" id="outPrev" name="linkOption" value="outPrev" checked={btnUrlOption === "outPrev"} onChange={handlebtnUrlOptionChange} />
                                                        <div className="radio-circle">
                                                        <div className="radio-mark"></div>
                                                        </div>
                                                        <small className='radio-label'>Без превью</small>
                                                    </label>
                                                </div>
                                                <button type='button' className='action-btn btn_scenario add-button-btn' onClick={handleAddLinkButton}>Добавить кнопку</button>
                                                
                                            
                                            </>
                                        )}

                                        {selectedLinkType === 'buttonChainRadio' && (
                                            <>
                                                <p className='form-elem'>Продолжить сценарий:</p>
                                                {scenOptions.length > 0 ? (
                                                    <>
                                                        <div className="form-elem">
                                                            <label htmlFor="btnName">Название кнопки:</label>
                                                            <input className='input' type='text' name='btnName' placeholder='Название кнопки' value={btnName} onChange={handleBtnName} />
                                                        </div>
                                                        <div className="form-elem">
                                                            <label>Цепочка:</label>
                                                            <Select className='select-focus' options={scenVariants} value={scenVariants.value} placeholder="Продолжить цепочку:" onChange={handleContScen}/>
                                                        </div>
                                                        <button className='action-btn btn_scenario add-button-btn' onClick={handleAddLinkButton}>Добавить цепочку</button>
                                                    </>
                                                ) : 
                                                    <small className='form-elem'>Нет ниодного сценария по выбранному боту!</small>
                                                }
                                            </>
                                        )}
                                        {selectedLinkType === 'buttonWebAppRadio' && (
                                            <>
                                                <div className="form-elem">
                                                    <label htmlFor="btnName">Название кнопки:</label>
                                                    <input className='input' type='text' name='btnName' placeholder='Название кнопки' value={btnName} onChange={handleBtnName} />
                                                </div>
                                                <div className="form-elem">
                                                    <label htmlFor="urlLink">Ссылка:</label>
                                                    <input className={`input`} type='url' name='urlLink' placeholder='https://' value={btnUrl} onChange={handleBtnUrl}/>
                                                </div>
                                               
                                                <button type='button' className='action-btn btn_scenario add-button-btn' onClick={handleAddLinkButton}>Добавить кнопку</button>
                                                
                                            
                                            </>
                                        )}
                                        <button 
                                        className='action-btn mailing-btn cancel-btn' 
                                        type="button" 
                                        onClick={() => handleCancel('showButtonConf')}
                                        >
                                        Сброс
                                        </button>
                                    </>
                                    ): null}
                                    <label htmlFor="autoDeleteTimer" className={`autodelete-btn checkbox ${showAutoDelete ? 'selected-item' : ''}`}>
                                    <input
                                        className="checkbox-input"
                                        type="checkbox"
                                        id="autoDeleteTimer"
                                        checked={showAutoDelete}
                                        onChange={handleAutoDelete}
                                        disabled={showAutoDelete ? true : false}
                                    />
                                    <FaTrashRestoreAlt  className='fa checkbox-fa'></FaTrashRestoreAlt>
                                    <span>Настроить автоудаление</span>
                                    </label>
                                   
                                    
                                    {showAutoDelete ? (
                                        <>
                                            
                                            <div  className="autodelete-timer-container">
                                                <div>
                                                    <small>Укажите время автоудаления в минутах:</small>
                                                    <input type="text" 
                                                    className="input" 
                                                    placeholder="1" 
                                                    value={deleteTimer}
                                                    onChange={(e)=>setDeleteTimer(e.target.value)}
                                                    />
                                                </div>
                                                <div>
                                                    <button  
                                                    className='mailing-btn cancel-btn' 
                                                    type="button" 
                                                    onClick={() => handleCancel('showAutoDelete')}
                                                    >
                                                        Сброс
                                                    </button>
                                                    <button  
                                                    className='action-btn mailing-btn' 
                                                    type="button" 
                                                    onClick={handleConfirmTimer}
                                                    >
                                                        Подтвердить
                                                    </button>
                                                </div>
                                                
                                            </div>
                                            
                                        </>
                                    ):null}
                                   
                                    <div className='sending-btns'>
                                        <button 
                                        className={`mailing-btn main-btn `} 
                                        type="button"      
                                        onClick={()=>handleSendMailing('now')}
                                        disabled={showScheduleConf ? true : false}
                                        
                                        >
                                        
                                        <FaTelegramPlane   className="fa" />
                                            Отправить сейчас
                                        </button>
                                       
                                        <label htmlFor="scheduleOptions" className={`checkbox send-later-btn ${showScheduleConf ? 'selected-item' : ''}`}>
                                        <input
                                            className="checkbox-input "
                                            type="checkbox"
                                            id="scheduleOptions"
                                            checked={showScheduleConf}
                                            onChange={handleScheduleOptions}
                                            disabled={showScheduleConf ? true : false}
                                        />
                                        <FaClock  className='fa checkbox-fa'></FaClock>
                                        <span>Отправить позже</span>
                                        </label>
                                        
                                        {showScheduleConf && (
                                            <>
                                                
                                                <div className="form-elem datepicker-container">
                                                    <div className="react-datepicker-wrapper">
                                                        <small>Измените дату:</small>
                                                        <div className="react-datepicker__input-container">
                                                        <span
                                                            role="alert"
                                                            aria-live="polite"
                                                            className="react-datepicker__aria-live"
                                                        ></span>
                                                        <DatePicker
                                                        dateFormat="yyyy/MM/dd"
                                                        selected={selectedDate}
                                                        onChange={(date)=>handleLaterSendingParams('date', date)}
                                                        className="datepicker-input"
                                                        locale={ru}
                                                        />
                                                        </div>
                                                    </div>
                                                    <div className="react-datepicker-wrapper">
                                                        <small>Измените время:</small>
                                                        <input type="time" id="bot__mailing-name" className="datepicker-input" placeholder="00:00"  value={selectedTime}
                                                        onChange={(e)=>handleLaterSendingParams('time', e.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className='send-later-btns'>
                                                    <button  
                                                    className='action-btn mailing-btn' 
                                                    type="button" 
                                                    onClick={()=>handleSendMailing('later')}
                                                    >
                                                        Сохранить
                                                    </button>
                                                    <button  
                                                    className='action-btn mailing-btn cancel-btn' 
                                                    type="button" 
                                                    onClick={() => handleCancel('showScheduleConf')}
                                                    >
                                                    Отмена
                                                    </button>
                                                </div>
                                                
                                            </>
                                        )}
                                    </div>
                                
                                    
                                </div>
                            </div>
                            <div className='mailing-body__elem'>
                                <div className='form-elem'>
                                    <small><p>Измените текст сообщения:</p></small>
                                    <div className='form-elem'>
                                        <textarea
                                            maxLength="1024" 
                                            id='messText'
                                            className="create-mail-textarea" placeholder="Введите текст сообщения" 
                                            value={message}
                                            onChange={(e) => {
                                                handleExtractInputValue('text', e.target.value);
                                            }}
                                        ></textarea>
                                    </div>
                                    <div className='form-elem'>
                                        {linkButtons.map((button, index) => (
                                        <div className='telegram-link-btn' key={index}>
                                            {button.title}
                                            <FaLink className='fa'></FaLink>
                                            <button title='Удалить кнопку' className='delete-link-btn' onClick={(e) => handleRemoveButton(index, e)}></button>
                                        </div>
                                        ))}
                                    </div>
                                    
                                     {fileUrls && fileUrls.length > 0 && (
                                        
                                        <>
                                            <p className='form-elem'>Загруженные файлы:</p>
                                            {fileUrls.map((file, index) => (
                                                <>
                                                    {file.name === 'Photo' ? (
                                                        <div className='file-wrapper'>
                                                        <img
                                                            src={file.url}
                                                            alt={`Preview ${index + 1}`}
                                                            className='preview-image'
                                                        />
                                                        <button type='button' onClick={() => handleRemoveFile(index)} className='delete-button'>
                                                            <FaRegWindowClose className='delete-file' />
                                                        </button>
                                                        </div>
                                                        ) : file.name === 'Video' ?(
                                                            <div className='file-wrapper' key={index}>
                                                                <div className='image-wrapper'>
                                                                    <video src={file.url} controls className='preview-image' />
                                                                    <button type='button' onClick={() => handleRemoveFile(index)} className='delete-button'>
                                                                        <FaRegWindowClose className='delete-file' />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className='file-wrapper' key={index}>
                                                                <div className='image-wrapper'>
                                                                <a href={file.url} target="_blank" rel="noopener noreferrer">Нажмите что бы посмотреть документ</a>
                                                                    <button type='button' onClick={() => handleRemoveFile(index)} className='delete-button'>
                                                                        <FaRegWindowClose className='delete-file' />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        )
                                                
                                                    }
                                                </>
                                            ))}
                                        </>
                                    )} 
                                </div>
                            </div>
                            <div className='mailing-body__elem'>
                                <div className='phone'>
                                    <div className='home-btn'></div>
                                    <div className='phone-cam'></div>
                                    <div className="phone-screen">
                                        <div className="chat-avatar">
                                            <img className='icon'src={botIcon}/>
                                        </div>
                                        <div className="chat-window">
                                            <p className="chat-message">{message}</p>
                                            <div className="chat-buttons">
                                                {linkButtons && linkButtons.length > 0 && (
                                                    <>
                                                        {linkButtons.map((button, index) => (
                                                            <>
                                                                <button className='telegram-link-btn' key={index}>{button.title}</button>
                                                            </>
                                                        ))}
                                                    </>
                                                )}
                                            </div>
                                            <div className="chat-files">
                                                {fileUrls && fileUrls.length > 0 && (
                                                    <>
                                                    {fileUrls.map((link, index) => {
                                                        if (link.name === "Photo") {
                                                        return (
                                                            <div className="file-wrapper" key={index}>
                                                                <img className="file-inner" src={link.url} alt={link.name} />
                                                            </div>
                                                        );
                                                        } else if (link.name === "Video") {
                                                        return (
                                                            <div className="file-wrapper" key={index}>
                                                                <FaFileVideo className='file-inner'></FaFileVideo>
                                                            </div>
                                                        );
                                                        }
                                                        else if (link.name === "file") {
                                                            return (
                                                                <div className="file-wrapper" key={index}>
                                                                <FaFileWord className='file-inner'></FaFileWord>
                                                                </div>
                                                            );
                                                            } else {
                                                        return null;
                                                        }
                                                    })}
                                                    </>
                                                )}
                                            </div>
                                           
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className='schedule-mailing-params'>
                                    <h3 class="card-title">Другие настройки рассылки:</h3>
                                    {mailingData.autodelete && (
                                        <p className='form-elem'>
                                            Таймер автоудаления: <strong>{mailingData.autodelete}</strong>
                                            {mailingData.autodelete === 1 ? (
                                                ' минута'
                                            ) : (
                                                mailingData.autodelete < 4 ? ' минуты' : ' минут'
                                            )}
                                        </p>
                                    )}
                                    {mailingData.time_send && (
                                            <p className=''>Дата отправки: <strong>{mailingData.time_send}</strong></p>
                                    )}
                                    {scheduleData.receivers && (
                                            <p className=''>Расчитанные получатели при создании рассылки: <strong>{scheduleData.receivers}</strong></p>
                                    )}
                                </div>
                               
                            </div>
                            
                        </div>
                        
                    </div>
                </> 
            )}
        </>
    );
    
};

export default MailingCreate;