import React, { useState } from 'react';
import Counter from '../counter';
import { limitText } from '../../../../valid/limitText';
import styles from './Link.module.css';

const txtLimit = 512;
function Link({ writeLink, linkText, linkTextInvalid, linkValid, typeOfLink }) {
  const [txtStatus, setTxtStatus] = useState(false);
  return (
    <div className={styles.block}>
      <Counter number={linkText.length} status={txtStatus} limit={txtLimit} />
      <div className={styles.title}>{typeOfLink === 'link' ? "Ссылка" : 'WebApp'}</div>
      <input
        className={`${styles.text} ${
          linkTextInvalid ? styles.textInvalid : ''
        }`}
        type="text"
        placeholder="https://example.com"
        value={linkText}
        onChange={(e) => writeLink(limitText(e, txtLimit))}
        onFocus={() => {
          setTxtStatus(true);
          linkValid(false);
        }}
        onBlur={() => setTxtStatus(false)}
      />
    </div>
  );
}

export default Link;
