import React from "react";
import styles from "./RadioBoxUI.module.css";
function RadioBoxUI({
  title,
  description,
  handler,
  value,
  name,
  children,
  ...rest
}) {
  return (
    <div className={`${styles.box} ${value === name ? styles.boxActive : ""}`}>
      <label className={styles.label}>
        <input
          type="radio"
          name="action"
          className={styles.radio}
          value={name}
          checked={value === name}
          onChange={(e) => handler(e.target.value)}
        />
        {title}
      </label>
      {description && <p className={styles.description}>{description}</p>}
      {children}
    </div>
  );
}

export default RadioBoxUI;
