import React from 'react';

import '../../../../../index.css';
import './MailingArchive.css';
import moment from 'moment-timezone';




const MailingArchive = ({spamsArchivedData}) => {

    return (
        <>
            <div className="card">
                {spamsArchivedData.length > 0 ? (
                    <>
                        {spamsArchivedData.map((data, index) => {
                            const message = JSON.parse(data.message);
                            const text = message.text;
                            const bot = data.bot_info;
                            let date = data.time_send

                            const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; 
                            const momentDateTime = moment.tz(date, 'Europe/Paris');
                            const formattedDateTimeUserTimeZone = momentDateTime.clone().tz(userTimeZone).format('YYYY-MM-DD HH:mm'); 
                    
                            date = formattedDateTimeUserTimeZone;

                            return (
                                <div className="mailing-item" key={index}>
                                <div className="mailing-item__desc">
                                    <h3 class="main-title">Название спама: {data.name}</h3>
                                    {!bot ? (
                                        <p>Данные бота неизвестны</p>
                                        ) : (
                                           <>
                                           { typeof bot === 'object' && Object.keys(bot).length > 1 ? (
                                            <>
                                            
                                                
                                                <div className='archived-stat-container'>
                                                    <strong>Рассылка выполнена несколькими ботами:</strong>
                                                    {bot.map((item, index) => (
                                                        <div key={index}>
                                                            <p>{item.bot_name}</p>
                                                            <small>@{item.bot_username}</small>
                                                        </div>
                                                    ))}
                                                </div>
                                                
                                            </>
                                           ) 
                                           : typeof bot === 'object' ? (
                                            <div>
                                                <strong>Рассылка от бота:</strong>
                                                {bot.map((item, index) => (
                                                    <div key={index}>
                                                        <p>{item.bot_name}</p>
                                                        <small>@{item.bot_username}</small>
                                                    </div>
                                                ))}
                                            </div>
                                           ): (
                                            <div>
                                                <strong>Данные бота неизвестны:</strong>
                                            </div>
                                           ) }
                                           
                                          
                                           </>
                                       
                                        )}
                                    <div className='archived-stat-container'>
                                        <strong>Содержимое рассылки:</strong>
                                        <p>{text}</p>
                                    </div>   
                                    <div className='archived-stat-container'>
                                        <strong>Дата отправки:</strong>
                                        <p>{date}</p>
                                    </div>
                                </div>
                                <div className="mailing-item__stat">
                                    <div>
                                    <strong>{data.receivers}</strong>
                                    <p>получателей</p>
                                    </div>
                                    <div>
                                    <strong>{data.success_receivers}</strong>
                                    <p>доставлено</p>
                                    </div>
                                </div>
                                </div>
                            );
                        }).reverse()}
                    </>
                ) : (
                    
                    <>
                        
                            <p>Вы еще не создали ниодной рассылки!</p>
                    </>
                )}
                
            </div>
          
        </>
    );
};

export default MailingArchive;