export const setConstantSchema = {
  number: {
    placeholder: "Число",
    regex: /^\d+$/,
    type: "text",
  },
  string: {
    placeholder: "Строка",
    regex: false,
    type: "text",
  },
  date: {
    placeholder: "Дата",
    regex: false,
    type: "date",
  },
  phone: {
    placeholder: "+12015555555",
    regex: /^\+?\d{0,13}$/,

    type: "text",
  },
  mail: {
    placeholder: "username@example.com",
    regex: false,
    type: "text",
  },
  link: {
    placeholder: "https://example.com",
    regex: false,
    type: "text",
  },
  time: {
    placeholder: "Время",
    regex: false,
    type: "time",
  },
};
