import React from 'react';
import styles from './BlockFIle.module.css';
import BlockButton from '../blockButton';

import { FaArrowAltCircleDown } from 'react-icons/fa';
function BlockFile({ data, idNode }) {
  return (
    <>
      <div className={styles.block}>
        {data.file.fileName || data.file.fileValue ? (
          <span className={styles.text}>
            <FaArrowAltCircleDown className={styles.icon} />
            {data.file.fileName ? data.file.fileName : data.file.fileValue}
          </span>
        ) : (
          <span>Выберите файл...</span>
        )}
      </div>
      {data?.buttons[0]
        ? data.buttons.map((button) => {
            return (
              <BlockButton
                id={`button-${data.id}-${button.id}`}
                key={button.id}
                text={button.textValue}
                type={button.type}
              />
            );
          })
        : null}
    </>
  );
}

export default BlockFile;
