
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../../../index.css';
import './Channels.css';
import refreshToken from '../../../../../../refreshToken';
import adapter from '../../../../../../adapter';
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});
const Channels = ({selectedBotId}) => {
    const storedData = JSON.parse(localStorage.getItem('dashboardData'));
    const dashboardData = storedData ? storedData: null;
 
    const [channelsData, setChannelsData] = useState('');
    const navigate = useNavigate();
    const getChannels = async () => {
        try {
            const data = {
              route: 'getChanels',
              user_tg_id : dashboardData.user['telegram_id'],
              access_token: dashboardData.token,
              bot_id: selectedBotId
            };
            const response = await adapter.post('', data);
            if (response.data.result === 'success') {
                setChannelsData(response.data.array);
            }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
                try {
                  const refreshTokenResult = await refreshToken();
                  if (refreshTokenResult === 'logout') {
                    navigate('/', { replace: true });
                  }else{
                    dashboardData.token = refreshTokenResult;
                    const data = {
                    route: 'getChanels',
                    user_tg_id : dashboardData.user['telegram_id'],
                    access_token: dashboardData.token,
                    bot_id: selectedBotId
                    };
                    const response = await adapter.post('', data);
                    if (response.data.result === 'success') {
                        setChannelsData(response.data.array);
                    }
                    else {
                        console.log(response.data);
                      }
                    }
                } catch (error) {
                    console.error(error);
                }
            }else if(response.data.text === 'bot is not connected'){
                  setChannelsData('');
            }
            else {
             console.log('Произошла ошибка:  ' + response.data.text);
            }
          } catch (error) {
            console.error(error);
            rollbar.error('Error during request:', error);
          }
    } 

    useEffect(() => {
        getChannels();
    },[] )

    return(
        <div className='table-body'>
            <table className='channels-table'>
                <tr className='channels-table__header'>
                    <th>
                        Данные о канале
                    </th>
                    <th>
                        Дата подключения бота
                    </th>
                    <th>
                        Аудитория
                    </th>
                </tr>
                <tbody>
                    {channelsData.length !== 0 ? (
                        channelsData.map((channels, index)=>
                              <tr className='channels-table__row'>
                        <td>
                            <strong class="chanel-name">{channels.name}</strong>
                            <p class="chanel-username">{channels.type}</p>
                        </td>
                        <td>
                            {channels.date}
                        </td>
                        <td>
                            <strong className='subscribers'>{channels.subscribers}</strong>
                            <p>Подписчиков</p>
                        </td>
                    </tr> 
                        )
                    ): (
                        <tr>
                            <td colSpan={6} className="auditory-table_row">У этого бота нет каналов !</td>
                        </tr>
                       
                    )}
                  
                </tbody>
                <div class="chanels-list">
                    <div class="chanels-list__item">
                       
                        <div>
                            
                        </div>
                    </div>
                </div>
            </table>
        </div>
     
            
        
    );

}
    
    
    
    
export default Channels;