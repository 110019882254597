import React, { useEffect, useState, useContext } from 'react';
import '../../../../../index.css';
import styles from './StatsPage.module.css';
import Auditory from './auditory/Auditory';
import Channels from './channels/Channels';
import Statistics from './statistics/Statistics';
import backIcon from '../../../../../assets/get_back.png';
import botIcon from '../../../../../assets/bot.png';
import { FaUsers, FaRegChartBar, FaRocketchat } from 'react-icons/fa';




const StatsPage = ({selectedBot}) => {

  const dashboardData = JSON.parse(localStorage.getItem('dashboardData'));
  const [activeComponent, setActiveComponent] = useState('Statistics');


  


  const renderComponent = () => {
    switch (activeComponent) {
      case 'Auditory':
        return <Auditory selectedBotId={selectedBot.id} />;
      case 'Channels':
        return <Channels selectedBotId={selectedBot.id} />;
      case 'Statistics':
        return <Statistics selectedBotId={selectedBot.id} />;
      default:
          return <Auditory selectedBotId={selectedBot.id} />;
    }
  };

  return (
    <>
      {activeComponent ? (
        <>
          <p className={styles['bot-name']}>Ваш бот: <span>{selectedBot.name}</span></p>
          <ul className={styles['scenario-menu-list']}>
            <li
              className={`${styles['scenario-menu__item']} ${
                activeComponent === 'Statistics'
                  ? styles['scenario-menu__item--active']
                  : ''
              }`}
            >
              <button
                className={styles['scenario-menu__item-link']}
                onClick={() => setActiveComponent('Statistics')}
              >
                Статистика
              </button>
            </li>
            <li
              className={`${styles['scenario-menu__item']} ${
                activeComponent === 'Auditory'
                  ? styles['scenario-menu__item--active']
                  : ''
              }`}
            >
              <button
                className={styles['scenario-menu__item-link']}
                onClick={() => setActiveComponent('Auditory')}
              >
                Аудитория
              </button>
            </li>
            
            <li
              className={`${styles['scenario-menu__item']} ${
                activeComponent === 'Channels'
                  ? styles['scenario-menu__item--active']
                  : ''
              }`}
            >
              <button
                className={styles['scenario-menu__item-link']}
                onClick={() => setActiveComponent('Channels')}
              >
                Каналы и группы
              </button>
            </li>
          </ul>

          <div className={styles['scenario-body']}>
            
            {renderComponent()}
          </div>
        </>
      ) : (
        null
      )}
    </>
  );
};

export default StatsPage;