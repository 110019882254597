import React, { useEffect, useState } from "react";
import styles from "./UnblockUser.module.css";
import SelectUI from "../../inputUI/SelectUI";
import { useStore } from "reactflow";

function UnblockUser({ data, handler }) {
  const [value, setValue] = useState("");
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (data.channel && !init) {
      setValue(data.channel);
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      handler({ ...data, ...(value && { channel: value }) });
    }
  }, [value, init]);

  const { apiInfo } = useStore();
  return (
    <div className={styles.box}>
      <SelectUI
        options={apiInfo.channel.map((elem) => ({
          label: elem.name,
          value: elem.id,
        }))}
        initialValue={value}
        placeholder={"Выберите группу или канал"}
        handler={(id) => setValue(id)}
      />
    </div>
  );
}

export default UnblockUser;
