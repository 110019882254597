import React from 'react';
import styles from './BlockText.module.css';
import BlockButton from '../blockButton';
function BlockText({ data, idNode }) {
  const createMarkup = (text) => {
    return { __html: text.replace(/\n/g, '<br />') };
  };
  return (
    <div
      className={styles.messageContent}
      style={{
        color: data.textValue ? '#333' : 'inherit',
        whiteSpace: 'pre-wrap',
      }}
    >
      {data.textValue ? data.textValue : 'Введите текст сообщения'}
      {data?.buttons[0]
        ? data.buttons.map((button) => {
            return (
              <BlockButton
                id={`button-${data.id}-${button.id}`}
                key={button.id}
                text={button.textValue}
                type={button.type}
              />
            );
          })
        : null}
    </div>
  );
}

export default BlockText;
