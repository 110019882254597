import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../../../../index.css';
import './MailingCreate.css';
import Select from 'react-select';
import botIcon from '../../../../../assets/bot.png';
// import botIcon from '../../../../../assets/bot.png';
import storageRef from '../../../../../firebaseForFiles';
import { FaClock , FaRegPlusSquare, FaMobileAlt, FaTelegramPlane , FaLink, FaRegWindowClose, FaTrashRestoreAlt,FaFileVideo,FaFileWord} from 'react-icons/fa';
import { toast } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ru from 'date-fns/locale/ru';
import adapter from '../../../../../adapter';
import refreshToken from '../../../../../refreshToken';
import Preloader from '../../../../../Preloader';
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});

const MailingCreate = ({botsData, refreshSpamsData}) => {

    const storedData = JSON.parse(localStorage.getItem('dashboardData'));
    const dashboardData = storedData ? storedData: null;


    const initialOptions = [
        { value: 'contact_id', label: 'id пользователя' },
        { value: 'username', label: 'username пользователя' },
        { value: 'full_name', label: 'имя и фамилия юзера' },
        { value: 'first_name', label: 'имя юзера' },
        { value: 'table_stat', label: 'таблица статистики' },
        { value: 'referal_link', label: 'реферальная ссылка' },
        { value: 'ref_count', label: 'количество рефералов' },
        { value: '[link](text)', label: 'cсылка' },
        { value: 'today', label: 'сегодня' },
        { value: 'tomorrow', label: 'вчера' },
    ];
    const [variableOptions, setVariableOptions] = useState(initialOptions);
    let botsOptions = {};
    if (botsData != null) {
        botsOptions = botsData.map(item => ({
            value: {
                id: item.id,
                type: item.type,
                name:item.bot_name
            },
            label: item.bot_name,
            
        }));
    } 
    const [multipleBots, setMultipleBots] = useState(false);

    const [selectedBot, setSelectedBot] = useState([]);

    const handleAddMultipleBots = (bots) => {
        if (!selectedBot.some(obj => obj.bot_id === bots.id)) {
            setSelectedBot((prevSelectedBots) => [
                ...prevSelectedBots,
                { bot_id: bots.id, name:bots.name }
            ]);
            setMailingData((prevMailingData) => ({
                ...prevMailingData,
                bot_id: [...prevMailingData.bot_id, bots.id]
            }));
        } else {
            
            toast.error('Вы уже выбрали этого бота!');
        }
    }
    const initialMailingData = {
        name:'',
        file: '',
        time_send: null,
        bot_id:'',
        receivers: '',
        segment:[],
        autodelete:'',
        message: {
            text: '',
            buttons: []
        }
    };
    const [mailingData, setMailingData] = useState(initialMailingData);
    const handleExtractInputValue = (input, value) => {
        if(input === 'text'){
            setMailingData({ ...mailingData,
                message:{
                    ...mailingData.message,
                    [input]: value
                }
            });
        }else if(input === 'file'){
            setMailingData(prevMailingData => ({
                ...prevMailingData,
                [input]: value
            }));
        }else if (input === 'bot_id') {
            if (multipleBots) {
                handleAddMultipleBots(value);
            }else{
                setMailingData({
                    ...mailingData,  
                    bot_id: [value.id]
                });
                setSelectedBot({ 
                    value: value.id,
                    label: value.name,
                });
                getBotVariables(value.id);
            }
            

        }else if (input === 'autodelete') {
            setDeleteTimer('');
            setShowAutoDelete(false);
            setMailingData({
                ...mailingData,
                [input]: value,
            });
        }
        else{
            setMailingData({ ...mailingData, [input]: value });
        }
        
    };
    

    const getBotVariables =  async (botId) => {
    try {
        const data = {
            route : 'getVariables',
            access_token:dashboardData.token,
            bot_id: botId
        }
    
        const response = await adapter.post('', data);
        if (response.data.result === 'success') {
            const currVariables = response.data.array;
            const newOptions = currVariables.map(item => ({
                value: item.name,
                label: item.name 
            }));
            const updatedOptions = [
                ...newOptions,
                ...initialOptions
                
            ];
            setVariableOptions(updatedOptions);

        }else if(response.data.result === 'error' && response.data.text === 'Ошибка, нету переменных у бота'){
            setVariableOptions(initialOptions);
        }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
            try {
              const refreshTokenResult = await refreshToken();
            
              if (refreshTokenResult === 'logout') {
                navigate('/', { replace: true });
              }else{
                dashboardData.token = refreshTokenResult;
                const data = {
                    route : 'getVariables',
                    access_token:dashboardData.token,
                    bot_id: botId
                }
                const response = await adapter.post('', data);
                if (response.data.result === 'success') {
                    const currVariables = response.data.array;
                    const newOptions = currVariables.map(item => ({
                        value: item.name,
                        label: item.name 
                    }));
                    const updatedOptions = [
                        ...newOptions,
                        ...initialOptions
                        
                    ];
                    setVariableOptions(updatedOptions);
        
                }else {
                    console.log(response.data);
                    setVariableOptions(initialOptions);
                }
              }
        
            } catch (error) {
              console.error(error);
               setVariableOptions(initialOptions);
            }
          }
        else {
            console.log(response.data);
             setVariableOptions(initialOptions);
        }
          
    } catch (error) {
        rollbar.error('Error during request:', error);
    }
    }


    const [drawOptions, setDrawOptions] = useState([]);
    const [tagOptions, setTagOptions] = useState([]);
    const getDrawsInfo = async (selectedBot) =>{
        try {
            const formData = {
              bot_id : selectedBot,
              access_token : dashboardData.token,
              route : 'getDrawsInfo',
              
            };
            const response = await adapter.post('', formData);
            if (response.data.result === 'success') {
              let sortedInfo = response.data.array.sort((a,b)=> b.id -a.id);
              setDrawOptions(sortedInfo); 
             
            }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
                const refreshTokenResult = await refreshToken();
                if (refreshTokenResult === 'logout') {
                  navigate('/', { replace: true });
                }else{
                    dashboardData.token = refreshTokenResult;
                    const formData = {
                        bot_id : selectedBot,
                        access_token : dashboardData.token,
                        route : 'getDrawsInfo',
                        
                    };
                    const response = await adapter.post('', formData);
                    if (response.data.result === 'success') {
                    let sortedInfo = response.data.array.sort((a,b)=> b.id -a.id);
                    setDrawOptions(sortedInfo); 
                    
                    }else {
                    console.log(response.data);
                    }
                }
            }
           
        }catch (error) {
            rollbar.error('Error during request:', error);
        } 
    }
    const getTagsLead = async (selectedBot) =>{
        try {
            const formData = {
            bot_id : selectedBot,
              access_token : dashboardData.token,
              route : 'getTagsLead',
              
            };
            const response = await adapter.post('', formData);
      
            if (response.data.result === 'success') {

              setTagOptions(response.data.array);
             
            }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
              try {
                const refreshTokenResult = await refreshToken();
              
                if (refreshTokenResult === 'logout') {
                  navigate('/', { replace: true });
                }else{
                    dashboardData.token = refreshTokenResult;
                    const formData = {
                    bot_id : selectedBot,
                        access_token : dashboardData.token,
                        route : 'getTagsLead',
                        
                    };
                    const response = await adapter.post('', formData);
                
                    if (response.data.result === 'success') {
                        setTagOptions(response.data.array);
                    }else{
                        console.log('Произошла ошибка:  ' + response.data.text);
                    }     
                        
                }
              } catch (error) {
                console.error(error);
              }
            }else if(response.data.result === 'error' && response.data.text === 'Ошибка, нету тегов у данного бота!') {
                setTagOptions('Tag is empty');
            }
          } catch (error) {
            rollbar.error('Error during request:', error);
          }
    }



    const [textarea, setTextareaVar] = useState('');
    const [variableSelect, setVariableSelect] = useState(null);

    const handleAddVarToMess = (selectedOption) => {
        const txt = document.getElementById("messText");
        const { selectionStart: start, selectionEnd: end } = txt;
      
        let newText = '';
        if (selectedOption.value === '[link](text)') {
          newText = textarea.substring(0, start) + selectedOption.value + textarea.substring(end);
        } else {
          newText = textarea.substring(0, start) + '{{' + selectedOption.value + '}}' + textarea.substring(end);
        }
      
        setTextareaVar(newText);
        setVariableSelect(null);
    };
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedDocs, setSelectedDocs] = useState(null);
    const [fileUrls, setFileUrls] = useState([]); 

    const handlePhotoUpload = (event) => {
        const files = event.target.files; 
        const newSelectedPhotos = [];
      
        // Проходимся по каждому файлу в массиве
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
      
          // Создаем URL для выбранного файла и добавляем его в новый массив
          newSelectedPhotos.push(URL.createObjectURL(file));
      
          if (file) {
            const fileRef = storageRef.child(file.name);
            fileRef
              .put(file)
              .then(() => {
                console.log('File uploaded successfully!');
                fileRef
                  .getDownloadURL()
                  .then((url) => {
                    // Добавляем URL файла в список URL-адресов
                    setFileUrls((prevUrls) => [
                      ...prevUrls,
                      { name: 'Photo', url },
                    ]);
                  })
                  .catch((error) => {
                    rollbar.error('Error during request:', error);
                    console.error('Error getting download URL:', error);
                  });
              })
              .catch((error) => {
                rollbar.error('Error during request:', error);
                console.error('Error uploading file:', error);
              });
          }
        }
      
        // Обновляем состояние с новым массивом выбранных фотографий
        setSelectedPhoto((prevSelectedPhoto) => [
          ...(prevSelectedPhoto || []), // Если prevSelectedPhoto не определен, то используем пустой массив
          ...newSelectedPhotos,
        ]);
    };
    const handleVideoUpload = (event) => {
        const files = event.target.files;
        const newSelectedVideos = [];
    
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileUrl = URL.createObjectURL(file);
            newSelectedVideos.push({ id: file.name, url: fileUrl });
    
            if (file) {
                const fileRef = storageRef.child(file.name);
                fileRef
                    .put(file)
                    .then(() => {
                        console.log('File uploaded successfully!');
                        fileRef
                            .getDownloadURL()
                            .then((url) => {
                                setFileUrls((prevUrls) => [...prevUrls, { name: 'Video', url }]);
                            })
                            .catch((error) => {
                                      rollbar.error('Error during request:', error);
                                console.error('Error getting download URL:', error);
                            });
                    })
                    .catch((error) => {
                              rollbar.error('Error during request:', error);
                        console.error('Error uploading file:', error);
                    });
            }
        }
    
        setSelectedVideo((prevSelectedVideo) => [
            ...(prevSelectedVideo || []),
            ...newSelectedVideos,
        ]);
    };
    const handleDocumentUpload = (event) => {
        const files = event.target.files;
        const newSelectedDocs = [];
    
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileName = file.name;
            newSelectedDocs.push(fileName);
    
            if (file) {
                const fileRef = storageRef.child(fileName);
                fileRef
                    .put(file)
                    .then(() => {
                        console.log('File uploaded successfully!');
                        fileRef
                            .getDownloadURL()
                            .then((url) => {
                                setFileUrls((prevUrls) => [...prevUrls, { name: 'Document', url }]);
                            })
                            .catch((error) => {
                                      rollbar.error('Error during request:', error);
                                console.error('Error getting download URL:', error);
                            });
                    })
                    .catch((error) => {
                              rollbar.error('Error during request:', error);
                        console.error('Error uploading file:', error);
                    });
            }
        }
    
        setSelectedDocs((prevSelectedDocs) => [
            ...(prevSelectedDocs || []),
            ...newSelectedDocs,
        ]);
    };
    const handleRemovePhoto = (e, index) => {
        e.preventDefault();
        setSelectedPhoto((prevSelectedPhoto) => {
            const updatedSelectedPhoto = [...prevSelectedPhoto];
            updatedSelectedPhoto.splice(index, 1); 
            return updatedSelectedPhoto;
        });
    }; 
    const handleRemoveVideo = (id) => {
        setSelectedVideo((prevSelectedVideo) => {
            const updatedSelectedVideo = prevSelectedVideo.filter((video) => video.id !== id);
            return updatedSelectedVideo;
        });
    };
    const handleRemoveDocument = (index) => {
        setSelectedDocs((prevSelectedDocs) => {
            const updatedSelectedDocs = [...prevSelectedDocs];
            updatedSelectedDocs.splice(index, 1);
            return updatedSelectedDocs;
        });
    };

    const [showButtonConf, setShowButtonConf] = useState(false);
    const [showScheduleConf, setShowScheduleConf] = useState(false);
    const [showAutoDelete, setShowAutoDelete] = useState(false);
    
    const handleCancel = (button) => {
        if (button === 'showButtonConf') {
            setShowButtonConf(false); 
        } else if (button === 'showScheduleConf') {
            setShowScheduleConf(false);
        } else if (button === 'showAutoDelete') {
            setDeleteTimer('');
            setShowAutoDelete(false);
            setMailingData({
                ...mailingData,
                autodelete: ''
            })
        }else if (button === 'multipleBots') {
            setMultipleBots(false);
            setSelectedBot([]);
            setMailingData({
                ...mailingData,
                bot_id: ''
            })
            setReceiversCount(null);
        }else if (button === 'segmentConfig') {
            setSegmentConfig(false);
        }
    }
    const handleAddButton = (e) => {
        if (selectedBot === null || selectedBot === undefined ) {
            toast.error('Сначала выберите бота!');
        }else{
            setShowButtonConf(e.target.checked);
        }
    }
    const handleScheduleOptions = (e) => {
        if (selectedBot === null || selectedBot === undefined ) {
            toast.error('Сначала выберите бота!');
        }else{
            setShowScheduleConf(e.target.checked);
        }
    }


    const handleAutoDelete = (e) => {
        if (selectedBot === null || selectedBot === undefined ) {
            toast.error('Сначала выберите бота!');
        }else{
            setShowAutoDelete(e.target.checked);
        }
    }
    const handleAddLinkButton = () => {
        if (!btnName) {
            toast.error('Пожалуйста, заполните поле "Название кнопки"');
          return;
        }
        let newButton;
      
        if (selectedLinkType === 'buttonChainRadio') {
          if (!contScen) {
            toast.error('Пожалуйста, выберите цепочку');
            return;
          }
      
          newButton = {
            title: btnName,
            type: 'continue',
            chain_id: Number(contScen),
          };
        }else if (selectedLinkType === 'buttonlinkRadio' || selectedLinkType === 'buttonWebAppRadio') {
            if (!btnUrl) {
            toast.error('Пожалуйста, заполните поле "Ссылка на страницу"');
            return;
            }
            const urlPattern = /^(?!.*(?:localhost|127\.0\.0\.1)).*https?:\/\/\S+/i;
            const kirilitsaPattern = /[а-яА-ЯЁё]/;
            
            if (!urlPattern.test(btnUrl) || kirilitsaPattern.test(btnUrl)) {
            toast.error('Пожалуйста, введите корректную ссылку. Ссылка не должна включать в себя кирилицу или локальные адреса');
            return;
            }
            if (selectedLinkType === 'buttonlinkRadio') {
                newButton = {
                    title: btnName,
                    type: 'link',
                    link: btnUrl,
                    };
            }else if(selectedLinkType === 'buttonWebAppRadio'){
                newButton = {
                    title: btnName,
                    type: 'webapp',
                    link: btnUrl,
                };
            }
        }
        if (scenOptions.length > 0 && selectedLinkType === 'buttonChainRadio' && !contScen) {
            toast.error('Пожалуйста, выберите цепочку');
          return;
        }
        
        setLinkButtons([...linkButtons, newButton]);
        
        setBtnName('');
        setBtnUrl('');
        setBtnUrlOption('prev');
        setShowButtonConf(false);
        toast.success('Кнопка создана!');
    };
  
    const handleRemoveButton = (index, e) => {
        e.preventDefault();
        const updatedButtons = [...linkButtons];
        updatedButtons.splice(index, 1);
        setLinkButtons(updatedButtons);  
    };

    const handleBtnName = (e) => {
        setBtnName(e.target.value);
      };
    const handleBtnUrl = (e) => {
        setBtnUrl(e.target.value);
    };
    const handleLinkSelect = (event) => {
        const selectedValue = event.target.value;
        setSelectedLinkType(selectedValue);
    };
    const handlebtnUrlOptionChange = (e) => {
        setBtnUrlOption(e.target.value);
    };

    const [linkButtons, setLinkButtons] = useState([]);
    const [btnName, setBtnName] = useState(null);
    const [btnUrl, setBtnUrl] = useState(null);
    const [btnUrlOption, setBtnUrlOption] = useState('prev');
    const [selectedLinkType, setSelectedLinkType] = useState(null);
    const [contScen, setContScen] = useState(null);
    const [scenVariants, setScenVariants] = useState([]);
    const [scenOptions, setScenOptions] = useState([]);
    const handleContScen = (e) => {
        setContScen(e.value);
    };

    const [deleteTimer, setDeleteTimer] = useState('');

    const handleConfirmTimer = () => {
        if (deleteTimer && /[^0-9]/.test(deleteTimer) ) {
            toast.error('Буквы не допустимы! Укажите задержку в минутах!');
            return; 
        }
        if (!deleteTimer || deleteTimer < 1) {
            toast.error('Таймер должен быть более или равен 1 минуте!');
            return; 
        }
        if(deleteTimer && deleteTimer > 2880){
            toast.error('Таймер должен быть не более 48 часов (2880 минут)!');
            return;  
        }
    
        handleExtractInputValue('autodelete', deleteTimer);
      
    }

    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState('');

    const moment = require('moment-timezone');

    const handleLaterSendingParams = (name, value) => {
       
        if (name === 'date') {
            setSelectedDate(value);
        } else if (name === 'time') {
            setSelectedTime(value);
           
        }
       
    };

    useEffect(() => {
        if (selectedDate && selectedTime) { 
            const formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}`;
            const formattedDateTime = `${formattedDate} ${selectedTime}`;
            const userTimeZone = moment.tz.guess();
            const momentDateTime = moment.tz(formattedDateTime, userTimeZone);
            const formattedDateTimeUTC1 = momentDateTime.clone().tz('Europe/Paris').format('YYYY-MM-DD HH:mm');
         
            setMailingData({
                ...mailingData,
                time_send: formattedDateTimeUTC1,
            });
            
        }
    }, [selectedDate, selectedTime]);
    
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(false);

    const handleResetMailing = () => {
        setMailingData(initialMailingData);
        setMultipleBots(false);
        setSelectedBot([]);
        setDeleteTimer('');
        setReceiversCount(null);
        setSegmentConfig(false);
        setSegmentation(null);
        setActivityDate('');
        setBeforeDateSelect(null);
        setAfterDateSelect(null);
        setBetweenStartDateSelect(null);
        setBetweenEndDateSelect(null);
        setTextareaVar('');
        setFileUrls([]);
        setSelectedPhoto(null);
        setSelectedVideo(null);
        setSelectedDocs(null);
        setSelectedDate(null);
        setSelectedTime('');
        setShowScheduleConf(null);
        setLinkButtons([]);
    }

    const handleSendMailing = async (timeSend) => {
        try {
            if (!mailingData.name) {
                toast.error('Укажите имя для рассылки!');
                return
            }
            if (!mailingData.bot_id) {
                toast.error('Вы не выбрали бота!');
                return
            }
            if (!mailingData.message.text) {
                toast.error('Сообщение не должно быть пустым!');
                return
            }
            if (timeSend === 'later') {
                if (!mailingData.time_send) {
                  
                    toast.error('Вы не указали дату или время отправки!');
                    return
                }
            }
            setIsLoading(true);
            let data;
            
            if (timeSend === 'test') {
                if (mailingData.bot_id) {
                    const firstBotId = mailingData.bot_id[0];
                    const testMailing = ['bot_id', 'message', 'file'];
                    const newMailingData = Object.fromEntries(
                        Object.entries(mailingData)
                            .filter(([key]) => testMailing.includes(key))
                            .map(([key, value]) => (key === 'bot_id' ? ['bot_id', firstBotId] : [key, value]))
                    );
                    data = {
                        route :  'testSpam',
                        tg_id: dashboardData.user['telegram_id'],
                        access_token:dashboardData.token,
                        ...newMailingData,
                    }
                } 
            }else{
                data = {
                    route :'createSpam',
                    tg_id: dashboardData.user['telegram_id'],
                    access_token:dashboardData.token,
                    ...mailingData,
                }
            }
            
            const response = await adapter.post('', data);
            if (response.data.result === 'success') {
                if (timeSend !== 'test') {
                    handleResetMailing();
                }
                setIsLoading(false);
                toast.success('Рассылка успешно отправлена!');
                refreshSpamsData(true);
                
            }else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
                try {
                const refreshTokenResult = await refreshToken();
                if (refreshTokenResult === 'logout') {
                    navigate('/', { replace: true });
                }else{
                    dashboardData.token = refreshTokenResult;
                    if (timeSend === 'test') {
                        if (mailingData.bot_id) {
                            const firstBotId = mailingData.bot_id[0];
                            const testMailing = ['bot_id', 'message', 'file'];
                            const newMailingData = Object.fromEntries(
                                Object.entries(mailingData)
                                    .filter(([key]) => testMailing.includes(key))
                                    .map(([key, value]) => (key === 'bot_id' ? ['bot_id', firstBotId] : [key, value]))
                            );
                            data = {
                                route :  'testSpam',
                                tg_id: dashboardData.user['telegram_id'],
                                access_token:dashboardData.token,
                                ...newMailingData,
                            }
                        } 
                    }else{
                        data = {
                            route :'createSpam',
                            tg_id: dashboardData.user['telegram_id'],
                            access_token:dashboardData.token,
                            ...mailingData,
                        }
                    }
                    const response = await adapter.post('', data);
                    if (response.data.result === 'success') {
                        if (!timeSend !== 'test') {
                            handleResetMailing();
                        }
                        setIsLoading(false);
                        toast.success('Рассылка успешно отправлена!');
                        refreshSpamsData(true);
                    }else{
                        console.log(response.data);
                        setIsLoading(false);
                    }
                }
            
                } catch (error) {
                    console.error(error);
                    setIsLoading(false);
                }
            }
            else {
                console.log(response.data);
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            rollbar.error('Error during request:', error);
        }
    }
   

    useEffect(() => {
        if (linkButtons) {
            setMailingData({ ...mailingData,
                message:{
                    ...mailingData.message,
                    buttons: linkButtons
                }
            });
        }
    }, [linkButtons]);

    useEffect(() => {
        if (textarea) {
            handleExtractInputValue('text', textarea);
        }
    }, [textarea]);

    useEffect(() => {
        if (fileUrls) {
            handleExtractInputValue('file', fileUrls);
        }
    }, [fileUrls]);

    const [segmentConfig, setSegmentConfig] = useState(false);
    const handleAddSegment = () =>{
        if (selectedBot.length === 0) {
            toast.error('Для начала выберите бота!');
            return
        }
        setSegmentConfig(true);
    }

    const handleRemoveFile = (index) => {
        const refreshFiles = [...fileUrls];
        refreshFiles.splice(index, 1);
        setFileUrls(refreshFiles);
    };

    const [segmentation, setSegmentation] = useState(null);


    const [activityDate, setActivityDate] = useState('');
    const handleRadioDateChange = (date) => {
        if (date !== activityDate) {
            setActivityDate(date);
            setBeforeDateSelect(null);
            setAfterDateSelect(null);
            setBetweenStartDateSelect(null);
            setBetweenEndDateSelect(null);
          }
    };
    const [afterDateSelect, setAfterDateSelect] = useState(null);
    const [beforeDateSelect, setBeforeDateSelect] = useState(null);
    const [betweenStartDateSelect, setBetweenStartDateSelect] = useState(null);
    const [betweenEndDateSelect, setBetweenEndDateSelect] = useState(null);

    const formatLocalDateToISO = (date) => {
        const formattedDateUTC = date.toISOString();
        const localTimeZoneOffset = date.getTimezoneOffset();
        const formattedDateLocal = new Date(date.getTime() - localTimeZoneOffset * 60000).toISOString().split('T')[0];
        
        return formattedDateLocal;
    }

    const handleAfterDateSelect = (date) => {
        const formattedDate = formatLocalDateToISO(date);
        setAfterDateSelect(date);
        setMailingData({ ...mailingData,
            segment:{
                ...mailingData.segment,
                first_date: formattedDate,
                second_date:''
            }
        });
    };
    const handleBeforeDateSelect = (date) => {
        const formattedDate = formatLocalDateToISO(date);
        setBeforeDateSelect(date);
        setMailingData({ ...mailingData,
            segment:{
                ...mailingData.segment,
                first_date: formattedDate,
                second_date:''
            }
        });
        
    };
    const handleBetweenStartDateSelect = (date) => {
        const formattedDate = formatLocalDateToISO(date);
        setBetweenStartDateSelect(date);
        setMailingData({ ...mailingData,
            segment:{
                ...mailingData.segment,
                first_date: formattedDate,
                
            }
        });
        
    };
    const handleBetweenEndDateSelect = (date) => {
        const formattedDate = formatLocalDateToISO(date);
        setBetweenEndDateSelect(date);
        setMailingData({ ...mailingData,
            segment:{
                ...mailingData.segment,
                second_date:formattedDate
            }
        });
    };
    

    useEffect(() => {
        if (segmentation === 'draw' && selectedBot) {
            getDrawsInfo(selectedBot.id);
        }
        
    }, [segmentation]);
    useEffect(() => {
        if (segmentation === 'tag' && selectedBot) {
            getTagsLead(selectedBot.id);
        }
    }, [segmentation]);
    useEffect(() => {
        setActivityDate(null);
    }, [segmentation]);

    const handleDelete = (index) => {
        const refreshBots = [...selectedBot];
        refreshBots.splice(index, 1);
        setSelectedBot(refreshBots);
    };


    const [receiversCount, setReceiversCount] = useState(null);
    
    const getCountUsersForSpam = async (botId, segment) =>{
    try {
        const data = {
            route: 'getCountUsersForSpam',
            access_token: dashboardData.token,
            bot_id: botId,
            segment : segment
        };
        
        const response = await adapter.post('', data );
        if (response.data.result === 'success') {
            let receivers = response.data.array;
            receivers =  receivers.length;
            setReceiversCount(receivers);
            setMailingData({
                ...mailingData,
                receivers:receivers
            })
        } else if(response.data.result === 'error' && response.data.text === 'Token is expired'){
            try {
                const refreshTokenResult = await refreshToken();
                if (refreshTokenResult === 'logout') {
                    navigate('/', { replace: true });
                  }else{
                    dashboardData.token = refreshTokenResult;
                    const data = {
                        route: 'getCountUsersForSpam',
                        access_token: dashboardData.token,
                        bot_id: botId,
                        segment : segment
                    };
                    const response = await adapter.post('', data);
                    if (response.data.result === 'success') {
                        let receivers = response.data.array;
                        receivers =  receivers.length;
                        setReceiversCount(receivers);
                        setMailingData({
                            ...mailingData,
                            receivers:receivers
                        })
                    } else {
                        console.log(response);
                    }
                  }
            } catch (error) {
                console.error(error);
            }
        }
        else if(response.data.result === 'error' && response.data.text.includes("Don't have user")){
            setReceiversCount(null);
            }
        else if(response.data.result === 'error' && response.data.text.includes("Нет пользователей")){
            setReceiversCount(null);
        }
        else {
            console.log(response);
        }
            
    } catch (error) {
        console.error(error);
        rollbar.error('Error during request:', error);  
    }

    }
    useEffect(() => {

        if (selectedBot.length > 0) {
            const botIds = selectedBot.map(function(bot) {
                return bot.id !== undefined ? bot.id : bot.bot_id;
            });
            
            let segment;
            if (segmentation && afterDateSelect || beforeDateSelect || betweenEndDateSelect) {
                segment = mailingData.segment;
            }else{
                segment = [];
            }
            getCountUsersForSpam(botIds, segment);
        }
    }, [selectedBot, afterDateSelect, beforeDateSelect, betweenEndDateSelect]);


  
    return (
        <>
            {isLoading ? (
                <>
                
                <p className='preloader-title'>Идёт загрузка</p>
                <Preloader></Preloader>
                </>
            ):(
                <form className="form-card">
                    <h2 className='card-title'>
                        <p>Создать рассылку</p>
                    </h2>
                    <div className='card'>
                        <div className="form-elem ">
                            <label htmlFor="bot__mailing-name">Название рассылки:</label>
                            <input name="name" type="text" id="bot__mailing-name" className="input" placeholder="Введите название" 
                            onChange={(e) => handleExtractInputValue('name', e.target.value)} 
                            value={mailingData.name ? mailingData.name :''}
                            />  
                        </div>
                        <div className='select-bot-group'>
                            <div className="form-elem">
                                <Select 
                                id="botSelect"
                                name="botId"
                                placeholder="Выберите бота" 
                                options={botsOptions} 
                                value={Array.isArray(selectedBot) ? selectedBot[0] : selectedBot}
                                onChange={(e) => { 
                                    handleExtractInputValue('bot_id', e.value);
                                }}
                                />
                                <div className="checkbox-group">
                                    <label htmlFor="addBots" className={`checkbox ${multipleBots ? 'selected-item' : ''}`}>
                                    <input
                                        className="checkbox-input"
                                        type="checkbox"
                                        id="addBots"
                                        onChange={() =>{
                                            setMultipleBots(true);
                                            setMailingData({
                                                ...mailingData,
                                                bot_id: ''
                                            })
                                            setSelectedBot([]);
                                        } 
                                        }
                                        checked={multipleBots}
                                    />
                                    <div className="checkbox-box">
                                        <div className="checkbox-check"></div>
                                    </div>
                                        <span>Выбрать несколько ботов для рассылки</span>
                                    </label>
                                </div>
                                <div className="checkbox-group">
                                <label htmlFor="segment" className={`checkbox ${segmentConfig ? 'selected-item' : ''}`}>
                                <input
                                    className="checkbox-input"
                                    type="checkbox"
                                    id="segment"
                                    onChange={handleAddSegment}
                                    checked={segmentConfig}
                                />
                                <div className="checkbox-box">
                                    <div className="checkbox-check"></div>
                                </div>
                                    <span>Сегментировать получателей</span>
                                </label>
                                </div>
                                {multipleBots || segmentConfig ? (
                                    <button  
                                    className='mailing-btn cancel-btn' 
                                    type="button" 
                                    onClick={() => handleCancel(multipleBots ? 'multipleBots': (segmentConfig ?  'segmentConfig': '' ))}
                                    >
                                        Сброс
                                    </button>
                                ): null}
                               
                            </div>
                            {multipleBots && (
                                <div className='form-elem'>
                                {selectedBot.length > 0 ? (
                                    <>
                                        <p>Рассылка будет отправлена от лица ботов:</p>
                                        <div className='multiple-bots-container '>
                                            {
                                                selectedBot.map((bot, index) => {
                                                    return(
                                                        <div className='multiple-bot' key={index}>
                                                            <span>{bot.name}</span>
                                                            <button type='button' className="delete-link-btn" onClick={() => handleDelete(index)}></button>
                                                        </div>
                                                    )
                                                    
                                                })
                                            }
                                        </div>
                                    </>
                                        ): (
                                            <p>Теперь выберите ботов для рассылки из списка выше!</p>
                                        )}
                                        
                                </div>
                            )}
                        </div>
                        
                        <div className="form-elem">
                            {receiversCount && (
                                <p>Рассылка будет отправлена <strong>{receiversCount}</strong> получателям!</p>
                            )}
                           
                        </div>
                        {segmentConfig ? (
                                <>
                                    <p className='form-elem'>Сегментировать по:</p>
                                    <div className="radio-group form-elem">
                                        {selectedBot.type === 'draw'? (
                                            <>
                                                <label htmlFor="segmentSubscriberRadio" className="radio">
                                                    <input 
                                                    className="radio-input" 
                                                    type="radio" 
                                                    id="segmentSubscriberRadio" 
                                                    name="segmentRadio" 
                                                    value='subscription'
                                                    checked={segmentation === "subscription"}  
                                                    onChange={(e) => {
                                                        setSegmentation(e.target.value);
                                                        setMailingData({
                                                            ...mailingData,
                                                            segment: {
                                                                ...mailingData.segment,
                                                                type: e.target.value,
                                                            }
                                                        });
                                                    }} 
                                                    />
                                                    <div className="radio-circle">
                                                        <div className="radio-mark"></div>
                                                    </div>
                                                    <small>Подписке на бота:</small>
                                                </label>
                                                <label htmlFor="segmentActivityRadio" className="radio">
                                                    <input className="radio-input" 
                                                    type="radio" 
                                                    id="segmentActivityRadio" 
                                                    name="segmentRadio" 
                                                    value='last_activity'
                                                    checked={segmentation === "last_activity"} 
                                                    onChange={(e) => {
                                                        setSegmentation(e.target.value);
                                                        setMailingData({
                                                            ...mailingData,
                                                            segment: {
                                                                ...mailingData.segment,
                                                                type: e.target.value,
                                                            }
                                                        });
                                                    }}
                                                    />
                                                    <div className="radio-circle">
                                                        <div className="radio-mark"></div>
                                                    </div>
                                                    <small>По активности:</small>
                                                </label>
                                                <label htmlFor="segmentDrawRadio" className="radio">
                                                    <input 
                                                    className="radio-input" 
                                                    type="radio" 
                                                    id="segmentDrawRadio" 
                                                    name="segmentRadio" 
                                                    value='draw'
                                                    checked={segmentation === "draw"}
                                                    onChange={(e) => {
                                                        setSegmentation(e.target.value);
                                                        setMailingData({
                                                            ...mailingData,
                                                            segment: {
                                                                ...mailingData.segment,
                                                                type: e.target.value,
                                                            }
                                                        });
                                                    }} 
                                                    />
                                                    <div className="radio-circle">
                                                        <div className="radio-mark"></div>
                                                    </div>
                                                    <small>По номеру розыгрыша</small>
                                                </label>
                                                <label htmlFor="segmentTagRadio" className="radio">
                                                    <input 
                                                    className="radio-input" 
                                                    type="radio"
                                                    id="segmentTagRadio" 
                                                    name="tagRadio" 
                                                    value='tag'
                                                    checked={segmentation === "tag"} 
                                                    onChange={(e) => {
                                                        setSegmentation(e.target.value);
                                                        setMailingData({
                                                            ...mailingData,
                                                            segment: {
                                                                ...mailingData.segment,
                                                                type: e.target.value,
                                                            }
                                                        });
                                                    }}  
                                                    />
                                                    <div className="radio-circle">
                                                        <div className="radio-mark"></div>
                                                    </div>
                                                    <small>По тегу</small>
                                                </label>
                                            </>
                                        ):(
                                            <>
                                                <label htmlFor="segmentSubscriberRadio" className="radio">
                                                    <input 
                                                    className="radio-input" 
                                                    type="radio" 
                                                    id="segmentSubscriberRadio" 
                                                    name="segmentRadio"
                                                    value='subscription'
                                                    checked={segmentation === "subscription"} 
                                                    onChange={(e) => {
                                                        setSegmentation(e.target.value);
                                                        setMailingData({
                                                            ...mailingData,
                                                            segment: {
                                                                ...mailingData.segment,
                                                                type: e.target.value,
                                                            }
                                                        });
                                                    }} 
                                                    />
                                                    <div className="radio-circle">
                                                        <div className="radio-mark"></div>
                                                    </div>
                                                    <small>Подписке на бота:</small>
                                                </label>
                                                <label htmlFor="segmentActivityRadio" className="radio">
                                                    <input 
                                                    className="radio-input" 
                                                    type="radio" 
                                                    id="segmentActivityRadio" 
                                                    name="segmentRadio" 
                                                    value='last_activity'
                                                    checked={segmentation === "last_activity"}
                                                    onChange={(e) => {
                                                        setSegmentation(e.target.value);
                                                        setMailingData({
                                                            ...mailingData,
                                                            segment: {
                                                                ...mailingData.segment,
                                                                type: e.target.value,
                                                            }
                                                        });
                                                    }}
                                                    />
                                                    <div className="radio-circle">
                                                        <div className="radio-mark"></div>
                                                    </div>
                                                    <small>По активности:</small>
                                                </label>
                                                <label htmlFor="segmentTagRadio" className="radio">
                                                    <input 
                                                    className="radio-input" 
                                                    type="radio" 
                                                    id="segmentTagRadio"
                                                    name="tagRadio" 
                                                    value='tag'
                                                    checked={segmentation === "tag"} 
                                                    onChange={(e) => {
                                                        setSegmentation(e.target.value);
                                                        setMailingData({
                                                            ...mailingData,
                                                            segment: {
                                                                ...mailingData.segment,
                                                                type: e.target.value,
                                                            }
                                                        });
                                                    }} />
                                                    <div className="radio-circle">
                                                        <div className="radio-mark"></div>
                                                    </div>
                                                    <small>По тегу:</small>
                                                </label>
                                            </>
                                        )}
                                       
                                    </div>
                            {segmentation === 'subscription' || segmentation === 'last_activity' ? (
                                <>
                                    <p className="form-elem">Последняя активность:</p>
                                    <div className="radio-group form-elem">
                                        <label htmlFor="beforeRadio" className="radio">
                                            <input 
                                            className="radio-input" 
                                            type="radio" 
                                            id="beforeRadio" 
                                            name="dateRadio" 
                                            value='before'
                                            checked={activityDate === "before"} 
                                            onChange={(e) => {
                                                    handleRadioDateChange(e.target.value);
                                                    setMailingData({
                                                        ...mailingData,
                                                        segment: {
                                                            ...mailingData.segment,
                                                            condition: e.target.value,
                                                        }
                                                    })
                                                }
                                            } 
                                            />
                                            <div className="radio-circle">
                                            <div className="radio-mark"></div>
                                            </div>
                                            <small>До выбранной даты</small>
                                        </label>
                                        <label htmlFor="afterRadio" className="radio">
                                            <input 
                                            className="radio-input" 
                                            type="radio" id="afterRadio" 
                                            name="dateRadio" 
                                            value="after" 
                                            checked={activityDate === "after"} 
                                            onChange={(e) => {
                                                handleRadioDateChange(e.target.value);
                                                setMailingData({
                                                    ...mailingData,
                                                    segment: {
                                                        ...mailingData.segment,
                                                        condition: e.target.value,
                                                    }
                                                })
                                            }
                                        } 
                                            />
                                            <div className="radio-circle">
                                            <div className="radio-mark"></div>
                                            </div>
                                            <small>После выбранной даты</small>
                                        </label>
                                        <label htmlFor="betweenRadio" className="radio">
                                            <input 
                                            className="radio-input" 
                                            type="radio" 
                                            id="betweenRadio" 
                                            name="dateRadio" 
                                            value="between" 
                                            checked={activityDate === "between"} 
                                            onChange={(e) => {
                                                handleRadioDateChange(e.target.value);
                                                setMailingData({
                                                    ...mailingData,
                                                    segment: {
                                                        ...mailingData.segment,
                                                        condition: e.target.value,
                                                    }
                                                })
                                            }
                                        } 
                                            />
                                            <div className="radio-circle">
                                            <div className="radio-mark"></div>
                                            </div>
                                            <small>За период:</small>
                                        </label>
                                    </div>

                                    <div className='form-elem'>
                                        <div className="form-elem datepicker">
                                            <p className="form-elem"></p>
                                            {activityDate === "before" && (
                                            <div className="form-elem d-flex">
                                                <div className="react-datepicker-wrapper">
                                                <div className="react-datepicker__input-container">
                                                    <span
                                                    role="alert"
                                                    aria-live="polite"
                                                    className="react-datepicker__aria-live"
                                                    ></span>
                                                    <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={beforeDateSelect}
                                                    onChange={handleBeforeDateSelect}
                                                    className="datepicker-input"
                                                    placeholderText='До'
                                                    locale={ru}
                                                    />
                                                </div>
                                                </div>
                                            </div>
                                            )}
                                            {activityDate === "after" && (
                                            <div className="form-elem d-flex">
                                                <div className="react-datepicker-wrapper">
                                                <div className="react-datepicker__input-container">
                                                    <span
                                                    role="alert"
                                                    aria-live="polite"
                                                    className="react-datepicker__aria-live"
                                                    ></span>
                                                    <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={afterDateSelect}
                                                    onChange={handleAfterDateSelect}
                                                    className="datepicker-input"
                                                    placeholderText='После'
                                                    locale={ru}
                                                    />
                                                </div>
                                                </div>
                                            </div>
                                            )}
                                            {activityDate === "between" && (
                                            <div className="form-elem d-flex">
                                                <div className="react-datepicker-wrapper">
                                                <div className="react-datepicker__input-container">
                                                    <span
                                                    role="alert"
                                                    aria-live="polite"
                                                    className="react-datepicker__aria-live"
                                                    ></span>
                                                    <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={betweenStartDateSelect}
                                                    onChange={handleBetweenStartDateSelect}
                                                    className="datepicker-input"
                                                    placeholderText='От:'
                                                    locale={ru}
                                                    />
                                                </div>
                                                </div>
                                                <div className="react-datepicker-wrapper">
                                                <div className="react-datepicker__input-container">
                                                    <span
                                                    role="alert"
                                                    aria-live="polite"
                                                    className="react-datepicker__aria-live"
                                                    ></span>
                                                    <DatePicker
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={betweenEndDateSelect}
                                                    onChange={handleBetweenEndDateSelect}
                                                    className="datepicker-input"
                                                    placeholderText='До:'
                                                    locale={ru}
                                                    disabled={!betweenStartDateSelect}
                                                    />
                                                </div>
                                                </div>
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            ) : null}
                            {segmentation === 'draw' ? (
                                    <div className="form-elem ">
                                        <Select className='select-focus' 
                                        options={drawOptions.map(item => ({ value: item.id, label: item.name }))} 
                                        onChange={(e)=>{
                                            setMailingData({
                                                ...mailingData,
                                                segment: {
                                                    ...mailingData.segment,
                                                    value: e.value,
                                                }
                                            });
                                        }}   
                                        placeholder="Выберите розыгрыш" />
                                    </div>
                                ):null
                            }
                            {segmentation === 'tag' ? (
                                    <div className="form-elem ">
                                        {tagOptions === 'Tag is empty'? (
                                        <div className="form-elem">
                                            <small>У данного бота нет тэгов!</small>
                                        </div>
                                        ): (
                                            <Select className='select-focus' options={tagOptions.map(item => ({ value: item.tag, label: item.tag }))} 
                                            onChange={(e)=>{
                                                setMailingData({
                                                    ...mailingData,
                                                    segment: {
                                                        ...mailingData.segment,
                                                        value: e.value,
                                                    }
                                                });
                                            }}      
                                            placeholder="Выберите тэг" 
                                            />
                                        )}
                                       
                                    </div>
                                ):null
                            }
                                </>
                            ) : null}
                    </div>
                    <div className='card'>
                        <h3 className='card-title'>Сформируйте сообщение:</h3>
                        <div className='mailing-body'>
                            <div className='mailing-body__elem'>
                                <div className="form-elem">
                                    <small><p>По желанию задайте дополнительные параметры:</p></small>
                                    <Select 
                                    className='var-select select-focus' 
                                    options={variableOptions} 
                                    placeholder="{Переменные}"  
                                    onChange={handleAddVarToMess} 
                                    value={variableSelect}
                                    styles={{
                                        menu: (baseStyles) => ({
                                            ...baseStyles,
                                            backgroundColor: '#e9ecef',
                                            color:'rgb(32, 34, 37)', 
                                            }),
                                        option: (baseStyles) => ({
                                        ...baseStyles,
                                        cursor:'pointer'
                                        }),
                                        }}
                                    />
                                    <div className="file-input-wrapper">
                                        <input type="file" id="photo" accept="image/jpg, image/png, image/gif" onChange={handlePhotoUpload} multiple/>
                                        <label className='main-btn file-btn' htmlFor="photo"><FaRegPlusSquare className='fa'></FaRegPlusSquare>Фото к посту</label>
                                        
                                    </div>
                                    <div className="file-input-wrapper">
                                        <input type="file" id="video" accept="video/*" onChange={handleVideoUpload}/>
                                        <label className='main-btn file-btn' htmlFor="video"><FaRegPlusSquare className='fa'></FaRegPlusSquare> Видео к посту</label>
                                    </div>  
                                    <div className="file-input-wrapper">
                                        <input type="file" id="document" accept=".pdf,.doc,.docx" onChange={handleDocumentUpload} />
                                        <label className="main-btn file-btn" htmlFor="document">
                                            <FaRegPlusSquare className="fa" /> Файл
                                        </label>
                                    </div>
                                    <label htmlFor="addBtn" className={`checkbox ${showButtonConf ? 'selected-item' : ''}`}>
                                    <input
                                        className="checkbox-input"
                                        type="checkbox"
                                        id="addBtn"
                                        checked={showButtonConf}
                                        onChange={handleAddButton}
                                        disabled={showButtonConf ? true : false}
                                    />
                                    <FaRegPlusSquare className='fa checkbox-fa'></FaRegPlusSquare>
                                    <span>Добавить кнопку</span>
                                    </label>
                                    {showButtonConf ? (
                                    <>
                                        
                                        <p className='form-elem'>Выберите тип кнопки:</p>
                                        <div className="radio-group form-elem">
                                            <label htmlFor="buttonlinkRadio" className="radio">
                                            <input className="radio-input" type="radio" id="buttonlinkRadio" name="buttonlinkRadio" value="buttonlinkRadio" checked={selectedLinkType === "buttonlinkRadio"} onChange={handleLinkSelect} />
                                            <div className="radio-circle">
                                                <div className="radio-mark"></div>
                                            </div>
                                            <small className='radio-label'>Ссылка:</small>
                                            </label>
                                            <label htmlFor="buttonChainRadio" className="radio">
                                            <input className="radio-input" type="radio" id="buttonChainRadio" name="buttonChainRadio" value="buttonChainRadio" checked={selectedLinkType === "buttonChainRadio"} onChange={handleLinkSelect} />
                                            <div className="radio-circle">
                                                <div className="radio-mark"></div>
                                            </div>
                                            <small className='radio-label'>Продолжить цепочку:</small>
                                            </label>
                                            <label htmlFor="buttonWebAppRadio" className="radio">
                                            <input className="radio-input" type="radio" id="buttonWebAppRadio" name="buttonWebAppRadio" value="buttonWebAppRadio" checked={selectedLinkType === "buttonWebAppRadio"} onChange={handleLinkSelect} />
                                            <div className="radio-circle">
                                                <div className="radio-mark"></div>
                                            </div>
                                            <small className='radio-label'>WebApp:</small>
                                            </label>
                                        </div>

                                        {selectedLinkType === 'buttonlinkRadio' && (
                                            <>
                                                <div className="form-elem">
                                                    <label htmlFor="btnName">Название кнопки:</label>
                                                    <input className='input' type='text' name='btnName' placeholder='Название кнопки' value={btnName} onChange={handleBtnName} />
                                                </div>
                                                <div className="form-elem">
                                                    <label htmlFor="urlLink">Ссылка:</label>
                                                    <input   className={`input`} type='url' name='urlLink' placeholder='https://' value={btnUrl} onChange={handleBtnUrl}/>
                                                </div>
                                               
                                                <button type='button' className='action-btn btn_scenario add-button-btn' onClick={handleAddLinkButton}>Добавить кнопку</button>
                                                
                                            
                                            </>
                                        )}

                                        {selectedLinkType === 'buttonChainRadio' && (
                                            <>
                                                <p className='form-elem'>Продолжить сценарий:</p>
                                                {scenOptions.length > 0 ? (
                                                    <>
                                                        <div className="form-elem">
                                                            <label htmlFor="btnName">Название кнопки:</label>
                                                            <input className='input' type='text' name='btnName' placeholder='Название кнопки' value={btnName} onChange={handleBtnName} />
                                                        </div>
                                                        <div className="form-elem">
                                                            <label>Цепочка:</label>
                                                            <Select className='select-focus' options={scenVariants} value={scenVariants.value} placeholder="Продолжить цепочку:" onChange={handleContScen}/>
                                                        </div>
                                                        <button className='action-btn btn_scenario add-button-btn' onClick={handleAddLinkButton}>Добавить цепочку</button>
                                                    </>
                                                ) : 
                                                    <small className='form-elem'>Нет ниодного сценария по выбранному боту!</small>
                                                }
                                            </>
                                        )}
                                        {selectedLinkType === 'buttonWebAppRadio' && (
                                            <>
                                                <div className="form-elem">
                                                    <label htmlFor="btnName">Название кнопки:</label>
                                                    <input className='input' type='text' name='btnName' placeholder='Название кнопки' value={btnName} onChange={handleBtnName} />
                                                </div>
                                                <div className="form-elem">
                                                    <label htmlFor="urlLink">Ссылка:</label>
                                                    <input className={`input`} type='url' name='urlLink' placeholder='https://' value={btnUrl} onChange={handleBtnUrl}/>
                                                </div>
                                               
                                                <button type='button' className='action-btn btn_scenario add-button-btn' onClick={handleAddLinkButton}>Добавить кнопку</button>
                                                
                                            
                                            </>
                                        )}
                                        <button 
                                        className='action-btn mailing-btn cancel-btn' 
                                        type="button" 
                                        onClick={() => handleCancel('showButtonConf')}
                                        >
                                        Сброс
                                        </button>
                                    </>
                                    ): null}
                                    <label htmlFor="autoDeleteTimer" className={`autodelete-btn checkbox ${showAutoDelete ? 'selected-item' : ''}`}>
                                    <input
                                        className="checkbox-input"
                                        type="checkbox"
                                        id="autoDeleteTimer"
                                        checked={showAutoDelete}
                                        onChange={handleAutoDelete}
                                        disabled={showAutoDelete ? true : false}
                                    />
                                    <FaTrashRestoreAlt  className='fa checkbox-fa'></FaTrashRestoreAlt>
                                    <span>Настроить автоудаление</span>
                                    </label>
                                    {showAutoDelete && (
                                        <>
                                            
                                            <div  className="react-datepicker-wrapper autodelete-timer-container">
                                                <div>
                                                    <small>Укажите время в минутах:</small>
                                                    <input type="text" 
                                                    className="input" 
                                                    placeholder="1" 
                                                    onChange={(e)=>setDeleteTimer(parseInt(e.target.value, 10))}
                                                    />
                                                </div>
                                                <div>
                                                    <button  
                                                    className='mailing-btn cancel-btn' 
                                                    type="button" 
                                                    onClick={() => handleCancel('showAutoDelete')}
                                                    >
                                                        Отмена
                                                    </button>
                                                    <button  
                                                    className='action-btn mailing-btn' 
                                                    type="button" 
                                                    onClick={handleConfirmTimer}
                                                    >
                                                        Подтвердить
                                                    </button>
                                                </div>
                                                
                                            </div>
                                            
                                        </>
                                    )}
                                    {mailingData.autodelete && (
                                        <p className='form-elem'>
                                            Таймер автоудаления: <strong>{mailingData.autodelete}</strong>
                                            {mailingData.autodelete === 1 ? (
                                                ' минута'
                                            ) : (
                                                mailingData.autodelete < 4 ? ' минуты' : ' минут'
                                            )}
                                        </p>
                                    )}
                                    <div className='sending-btns'>
                                        <button 
                                        className={`mailing-btn main-btn `} 
                                        type="button"      
                                        onClick={()=>handleSendMailing('now')}
                                        disabled={showScheduleConf ? true : false}
                                        
                                        >
                                        
                                        <FaTelegramPlane   className="fa" />
                                            Отправить сейчас
                                        </button>
                                        <button 
                                        className={`mailing-btn main-btn test-mailing-btn`} 
                                        type="button"      
                                        onClick={()=>handleSendMailing('test')}
                                        disabled={showScheduleConf ? true : false}
                                        
                                        >
                                        
                                        <FaMobileAlt   className="fa" />
                                            Тестовая рассылка
                                        </button>
                                        <label htmlFor="scheduleOptions" className={`checkbox send-later-btn ${showScheduleConf ? 'selected-item' : ''}`}>
                                        <input
                                            className="checkbox-input "
                                            type="checkbox"
                                            id="scheduleOptions"
                                            checked={showScheduleConf}
                                            onChange={handleScheduleOptions}
                                            disabled={showScheduleConf ? true : false}
                                        />
                                        <FaClock  className='fa checkbox-fa'></FaClock>
                                        <span>Отправить позже</span>
                                        </label>
                                        
                                        {showScheduleConf && (
                                            <>
                                              
                                                <div className="form-elem datepicker-container">
                                                    <div className="react-datepicker-wrapper">
                                                        <small>Выберите дату:</small>
                                                        <div className="react-datepicker__input-container">
                                                        <span
                                                            role="alert"
                                                            aria-live="polite"
                                                            className="react-datepicker__aria-live"
                                                        ></span>
                                                        <DatePicker
                                                        dateFormat="yyyy/MM/dd"
                                                        selected={selectedDate}
                                                        onChange={(date)=>handleLaterSendingParams('date', date)}
                                                        className="datepicker-input"
                                                        locale={ru}
                                                        />
                                                        </div>
                                                    </div>
                                                    <div className="react-datepicker-wrapper">
                                                        <small>Выберите время:</small>
                                                        <input type="time" id="bot__mailing-name" className="datepicker-input" placeholder="00:00"  
                                                        onChange={(e)=>handleLaterSendingParams('time', e.target.value)}/>
                                                    </div>
                                                </div>
                                                <div className='send-later-btns'>
                                                    <button  
                                                    className='action-btn mailing-btn' 
                                                    type="button" 
                                                    onClick={()=>handleSendMailing('later')}
                                                    >
                                                        Отправить
                                                    </button>
                                                    <button  
                                                    className='action-btn mailing-btn cancel-btn' 
                                                    type="button" 
                                                    onClick={() => handleCancel('showScheduleConf')}
                                                    >
                                                    Отменить
                                                    </button>
                                                </div>
                                                
                                            </>
                                        )}
                                    </div>
                                
                                    
                                </div>
                            </div>
                            <div className='mailing-body__elem'>
                                <div className='form-elem'>
                                    <small><p>Введите текст сообщения:</p></small>
                                    <div className='form-elem'>
                                        <textarea
                                            maxLength="1024" 
                                            id='messText'
                                            className="create-mail-textarea" placeholder="Введите текст сообщения" 
                                            value={textarea}
                                            onChange={(e) => setTextareaVar(e.target.value)}
                                        ></textarea>
                                    </div>
                                    <div className='form-elem'>
                                        {linkButtons.map((button, index) => (
                                        <div className='telegram-link-btn' key={index}>
                                            {button.title}
                                            <FaLink className='fa'></FaLink>
                                            <button title='Удалить кнопку' className='delete-link-btn' onClick={(e) => handleRemoveButton(index, e)}></button>
                                        </div>
                                        ))}
                                    </div>
                                    
                                    {fileUrls && fileUrls.length > 0 && (
                                        
                                        <>
                                            <p className='form-elem'>Загруженные файлы:</p>
                                            {fileUrls.map((file, index) => (
                                                <>
                                                    {file.name === 'Photo' ? (
                                                        <div className='file-wrapper'>
                                                        <img
                                                            src={file.url}
                                                            alt={`Preview ${index + 1}`}
                                                            className='preview-image'
                                                        />
                                                        <button type='button' onClick={() => handleRemoveFile(index)} className='delete-button'>
                                                            <FaRegWindowClose className='delete-file' />
                                                        </button>
                                                        </div>
                                                        ) : file.name === 'Video' ?(
                                                            <div className='file-wrapper' key={index}>
                                                                <div className='image-wrapper'>
                                                                    <video src={file.url} controls className='preview-image' />
                                                                    <button type='button' onClick={() => handleRemoveFile(index)} className='delete-button'>
                                                                        <FaRegWindowClose className='delete-file' />
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className='file-wrapper' key={index}>
                                                                <div className='image-wrapper'>
                                                                    
                                                                <a href={file.url} target="_blank" rel="noopener noreferrer">Нажмите что бы посмотреть документ</a>
                                                                    <button type='button' onClick={() => handleRemoveFile(index)} className='delete-button'>
                                                                        <FaRegWindowClose className='delete-file' />
                                                                    </button>
                                                                </div>  
                                                            </div>
                                                        )
                                                
                                                    }
                                                </>
                                            ))}
                                        </>
                                    )} 
                                </div>
                            </div>
                            <div className='mailing-body__elem'>
                                <div className='phone'>
                                    <div className='home-btn'></div>
                                    <div className='phone-cam'></div>
                                    <div className="phone-screen">
                                        {/* <div className="chat-avatar">
                                            <img className='icon'src={botIcon}/>
                                        </div> */}
                                        <div className="chat-window">
                                            <p className="chat-message">{textarea}</p>
                                            <div className="chat-buttons">
                                                {linkButtons && linkButtons.length > 0 && (
                                                    <>
                                                        {linkButtons.map((button, index) => (
                                                            <>
                                                                <button className='telegram-link-btn' key={index}>{button.title}</button>
                                                            </>
                                                        ))}
                                                    </>
                                                )}
                                            </div>
                                            <div className="chat-files">
                                                {fileUrls && fileUrls.length > 0 && (
                                                    <>
                                                    {fileUrls.map((link, index) => {
                                                        if (link.name === "Photo") {
                                                        return (
                                                            <div className="file-wrapper" key={index}>
                                                                <img className="file-inner" src={link.url} alt={link.name} />
                                                            </div>
                                                        );
                                                        } else if (link.name === "Video") {
                                                        return (
                                                            <div className="file-wrapper" key={index}>
                                                                <FaFileVideo className='file-inner'></FaFileVideo>
                                                            </div>
                                                        );
                                                        }
                                                        else if (link.name === "file") {
                                                            return (
                                                                <div className="file-wrapper" key={index}>
                                                                <FaFileWord className='file-inner'></FaFileWord>
                                                                </div>
                                                            );
                                                            } else {
                                                        return null;
                                                        }
                                                    })}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="control-btns">
                            <div className="form-elem">
                            
                                    <div className="form-elem">
                                    <button className="action-btn back-btn" type="button" onClick={handleReturnToMailing}>Назад</button>
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div className="form-elem">
                                    <button className="to-draft-btn" type="button" onClick={handleShowDraft}> Посмотреть сохранённые</button>
                                </div>
                                <div className="form-elem">
                                    <button className="action-btn save-to-draft-btn" type="button" onClick={handleSaveToDraft}><FaSave className='fa'></FaSave> Сохранить</button>
                                </div>
                                <div className="form-elem">
                                    <button className="action-btn save-to-draft-btn" type="button" onClick={handleMailingNextStep}>Следующий шаг <FaAngleRight className='fa'></FaAngleRight></button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </form> 
            )}
        </>
    );
    
};

export default MailingCreate;