import React, { useState, useEffect } from 'react';
import TextDescription from '../textDescription';
import styles from './TypeText.module.css';
import AddButton from '../addButton';
import MessageMoveType from '../../MessageMoveType';

const areaTxtLimit = 2000;
// const exampleMessageText = {
//   type: 'message Text',
//   textValue: '',
//   buttons: [],
//   maxBtnRow: 1,
// };
function TypeText({
  typeContentInit,
  blockElementHandler,
  moveUp,
  moveDown,
  deleteBlock,
  index,
  length,
}) {
  const [txtData, setTxtData] = useState({});

  // для стрелок перемещения
  const [blockStatus, setBlockStatus] = useState(false);

  function blockSatusHandler(boolen) {
    if (blockStatus !== boolen) {
      setBlockStatus(boolen);
    }
  }

  // для текста
  useEffect(() => {
    if (typeContentInit) {
      setTxtData(typeContentInit);
    }
  }, [typeContentInit]);

  // Обновляем информацию
  useEffect(() => {
    if (Object.keys(txtData)[0]) {
      blockElementHandler(txtData, index);
    }
  }, [txtData]);

  // для кнопки
  function buttonsData(buttonsArr) {
    setTxtData((previous) => {
      const newTxtData = { ...previous, buttons: buttonsArr };
      return { ...newTxtData };
    });
  }
  // для текста
  function textData(txt) {
    setTxtData((previous) => {
      const newTxtData = { ...previous, textValue: txt };
      return { ...newTxtData };
    });
  }
  // для выбора кнопок в ряд
  function selectData(value) {
    setTxtData((previous) => {
      const newTxtData = { ...previous, maxBtnRow: value };
      return { ...newTxtData };
    });
  }
  ///////////
  return (
    <div
      className={styles.textBlock}
      onMouseEnter={() => blockSatusHandler(true)}
      onMouseLeave={() => blockSatusHandler(false)}
    >
      <MessageMoveType
        moveUp={moveUp}
        moveDown={moveDown}
        blockStatus={blockStatus}
        deleteBlock={deleteBlock}
        index={index}
        length={length}
      />
      <TextDescription
        textData={textData}
        initTextData={txtData.textValue}
        limit={areaTxtLimit}
      />
      <AddButton
        buttonsData={buttonsData}
        initButtonsData={txtData.buttons}
        selectData={selectData}
        initSelectData={txtData.maxBtnRow}
      />
    </div>
  );
}

export default TypeText;
