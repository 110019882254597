import React, { useState } from 'react';
import './AccountSettings.css';
import adapter from '../../../../adapter';
import refreshToken from '../../../../refreshToken';
import { useNavigate } from 'react-router-dom';
import Preloader from '../../../../Preloader';
import view from '../../../../../public/view.png';
import viewH from '../../../../../public/view-h.png';
import { toast } from 'react-toastify';
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});

const AccountSettings = () => {
  const storedData = JSON.parse(localStorage.getItem('dashboardData'));
  const dashboardData = storedData ? storedData: null;
  
  const [usernameValue, setUsernameValue] = useState(dashboardData.user.login);
  const [nameValue, setNameValue] = useState(dashboardData.user.name);

  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const toggleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleSaveClick = async () => {
    if (oldPassword === newPassword) {
      toast.error('Старый и новый пароли не должны совпадать!');
      return;
    }
    try {
      const data = {
        old_pass: oldPassword,
        new_pass: newPassword,
        username: usernameValue,
        name: nameValue,
        access_token: dashboardData.token,
        route: 'editUser',
      };

      setIsLoading(true);
      const response = await adapter.post('', data);

      if (response.data.result === 'success') {
        toast.success('Данные успешно отредактированы!');
      } else if (response.data.result === 'error' && response.data.text === 'Token is expired') {
        const refreshTokenResult = await refreshToken();
        
          if (refreshTokenResult === 'logout') {
            navigate('/', { replace: true });
          }else{
          dashboardData.token = refreshTokenResult;
          const data = {
          old_pass: oldPassword,
          new_pass: newPassword,
          username: usernameValue,
          name: nameValue,
          access_token: dashboardData.token,
          route: 'editUser',
          };
    
          setIsLoading(true);
          const response = await adapter.post('', data);
          if (response.data.result === 'success') {
            toast.success('Данные успешно отредактированы!');
          }
        }
      
      } else if (response.data.result === 'error' && response.data.text === 'Login or password is not correct') {
        toast.error('Не верный старый пароль или логин!');
      
      } else {
        console.log(response.data);
        toast.error(response.data.text);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
      rollbar.error('Error during request:', error);
    }finally{
      setIsLoading(false);
    }

  };


  return (
    <>
     <h1 className='main-title'>
          <p>Личный кабинет пользователя</p>
        </h1>
      <div id='editUser' className='card'>
        <h4 className='card-title'>
          <p>Изменить данные от аккаунта</p>
        </h4>
       
        {isLoading ? (
          <>
            <p className='preloader-title'>Обновляем данные</p>
            <Preloader></Preloader>
          </>
        ) : (
          <>
            <div className='form-elem'>
              <p className='edited-title'>Текущий логин: </p>
              <input
                className='input'
                placeholder='Ваш логин'
                type='text'
                value={usernameValue}
                onChange={(e) => setUsernameValue(e.target.value)}
                autoComplete="off"
              />
            </div>
            <div className='form-elem'>
              <p className='edited-title'>Текущее имя: </p>
              <input
                className='input'
                placeholder='Ваше имя'
                type='text'
                value={nameValue}
                onChange={(e) => setNameValue(e.target.value)}
                autoComplete="off"
              />
            </div>

            <div>
            <div className='form-elem'>
              <p className='edited-title'>Старый пароль: </p>
              <input
                className='input'
                type={showOldPassword ? 'text' : 'password'}
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                autoComplete="off"
              />
              <button className='show-pass' onClick={toggleShowOldPassword}>
              <img src={showOldPassword  ? view : viewH}/> 
              </button>
            </div>

            <div className='form-elem'>
              <p className='edited-title'>Новый пароль: </p>
              <input
                className='input'
                type={showNewPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                autoComplete="off"
                
              />
              <button className='show-pass' onClick={toggleShowNewPassword}>
                <img src={showNewPassword  ? view : viewH}/> 
              </button>
            </div>
          </div>
              
            
            <div className='form-elem'>
               
                <button  className='main-btn add-bot-btn' onClick={handleSaveClick}>
                  Сохранить
                </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AccountSettings ;