import React, { useEffect, useState } from "react";
import MessageContent from "../sectionsContent/messageContent";
import styles from "./PopupBlock.module.css";
import ChainContent from "../sectionsContent/chainContent";
import FilterContent from "../sectionsContent/filterContent";
import PauseContent from "../sectionsContent/pauseContent";
import ApiContent from "../sectionsContent/apiContent";
import ActionContent from "../sectionsContent/actionContent";
import { toast } from 'react-toastify';
import { useStoreApi } from 'reactflow';

function PopupMessage({ close, nodeID, addData, nodes, prevNodeID }) {
  const [onConfirm, setOnConfirm] = useState(false);

  const store = useStoreApi();
  useEffect(() => {
    if (close) {
      setOnConfirm(true);
    } else {
      setOnConfirm(false);
    }
  }, [close]);

  if (!nodes) {
    close();
  } else {
    function titleRole(role) {
      if (role === "message") return "Сообщение";
      if (role === "chain") return "Цепочка";
      if (role === "filter") return "Фильтр";
      if (role === "pause") return "Пауза";
      if (role === "api") return "Запрос API";
      if (role === "action") return "Действие";
      // Возможно, не нужно вызывать close() здесь
      // close();
      // return 'error';
    }

    const title = titleRole(nodes.data.role);

    return (
      <div className={styles.Blocklayout}>
        <div
          className={` ${styles.popup} ${!prevNodeID ? styles.animate : ""}`}>
          <div className={styles.content}>
            <div className={styles.title}>{title}</div>
            {nodes.data.role === "message" && (
              <MessageContent nodes={nodes} nodeID={nodeID} addData={addData} />
            )}
            {nodes.data.role === "chain" && (
              <ChainContent nodes={nodes} nodeID={nodeID} addData={addData} />
            )}
            {nodes.data.role === "filter" && (
              <FilterContent nodes={nodes} nodeID={nodeID} addData={addData} />
            )}
            {nodes.data.role === "pause" && (
              <PauseContent
                onConfirm={onConfirm}
                nodes={nodes}
                nodeID={nodeID}
                addData={addData}
              />
            )}
            {nodes.data.role === "api" && (
              <ApiContent nodes={nodes} nodeID={nodeID} addData={addData} />
            )}
            {nodes.data.role === "action" && (
              <ActionContent nodes={nodes} nodeID={nodeID} addData={addData} />
            )}
          </div>
        </div>
        <div
          className={`${styles.blockSave} ${
            !prevNodeID ? styles.animate : ""
          }`}>
          <span
            onClick={() => {
              if(nodes.data.role === 'message' && nodes.data.block.elements[0].textValue === ''){
               
                toast.error('Поле сообщения не должно быть пустым!');
                return;
              }
              close();
              setOnConfirm(true);
              store.setState({ isConfirmed: true });
            }}
            className={styles.buttonSave}>
            Применить
          </span>
        </div>
      </div>
    );
  }
  return <></>;
}

export default PopupMessage;
