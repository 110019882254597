import React, { useState } from "react";
import styles from "./TagsInputUI.module.css";

function TagsInputUI({ tags, handler }) {
  const [inputValue, setInputValue] = useState("");

  const [boxFocus, setBoxFocus] = useState(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      if (
        inputValue.trim() !== "" &&
        !tags.includes(inputValue.trim()) &&
        tags.length < 11 &&
        inputValue.trim().length < 40
      ) {
        handler([...tags, inputValue.trim()]);
        setInputValue("");
      }
    }
  };

  const removeTag = (tag) => handler(tags.filter((t) => t !== tag));
  return (
    <div className={`${styles.box} ${boxFocus ? styles.boxFocus : ""}`}>
      <div className={styles.tags}>
        {" "}
        {tags.map((tag, index) => (
          <span key={index} className={styles.tag}>
            {tag}{" "}
            <span onClick={() => removeTag(tag)} className={styles.remove}>
              ×
            </span>
          </span>
        ))}
      </div>
      <input
        className={styles.input}
        value={inputValue}
        onChange={(e) =>
          setInputValue(
            e.target.value.length < 41 ? e.target.value : inputValue
          )
        }
        onKeyDown={handleKeyDown}
        type="text"
        placeholder="Добавить тег"
        onFocus={() => setBoxFocus(true)}
        onBlur={() => setBoxFocus(false)}
      />
    </div>
  );
}

export default TagsInputUI;
