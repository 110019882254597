import React, { useEffect, useState, useRef } from 'react';

import '../../../../index.css';
import './TieAcc.css';
import tgIcon from '../../../../assets/img-01.png';
import errorIcon from '../../../../assets/warning.png';

const TieAcc = () => {
       const storedData = JSON.parse(localStorage.getItem('dashboardData'));
       const dashboardData = storedData ? storedData: null;
       const storedUser = dashboardData.user;
       const userId = storedUser.id;
       const activateLink = `tg://resolve?domain=testbot4crypto_bot&start=${userId}`;
       return (
       <div className="card">
              <h1 className='main-title'>
                     <p>Аккаунт не привязан</p>
                     <img className='icon' src={errorIcon}/>
              </h1>
              <p>Внимание! Для того что бы получить доступ к полному функционалу админ панели, необходимо привязать свой аккаунт Telegram.</p>
              <p>Это можно сделать всего в несколько кликов!</p>
              <div className='tg-btn-container tie-acc-btn'>
                     <a className=' login-tg-btn' href={activateLink}>Привязать сейчас</a>
                     <img src={tgIcon} className='login-tg-icon'></img>
              </div>
       </div>
       );
}
export default TieAcc;