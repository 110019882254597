import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { HiOutlinePhoto } from 'react-icons/hi2';
import styles from './DropZoneUpload.module.css';

const MAX_FILE_SIZE = 2 * 1024 * 1024;

function DropZoneUpload({ imageHandler }) {
  const [isDragActive, setIsDragActive] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(false);

  //Обработка ошибок
  const onDropRejected = useCallback((fileRejections) => {
    console.error(fileRejections[0].errors);
    setIsDragActive(false);
  }, []);

  //Обработка файла и сохранения в стейте
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onload = () => {
        imageHandler({
          imageName: file.name,
          imageType: file.type,
          imageSize: file.size,
          imageValue: reader.result,
        });
        setIsDragActive(false);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxSize: MAX_FILE_SIZE,
    accept: {
      'image/jpeg': [],
      'image/png': [],
    },
    multiple: false,
    onDropRejected,
    onDragEnter: () => setIsDragActive(true),
    onDragLeave: () => setIsDragActive(false),
  });

  return (
    <div
      className={`${styles.dropZone} ${
        isDragActive ? styles.dropZoneDrag : ''
      }`}
      {...getRootProps()}
    >
      <HiOutlinePhoto className={styles.iconPhoto} />
      <div className={styles.chooseFile}>Выберите файл...</div>
      <span className={styles.title}>или перетащите сюда картинку (2MB)</span>
      <input {...getInputProps()} />
    </div>
  );
}

export default DropZoneUpload;
