import { diagramFBInit } from '../../../../../../../firebaseInitialize'; // поставь коректный путь
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadString,
  deleteObject,
} from 'firebase/storage'; // Импортируем модуль Storage из 'firebase/storage'
import { getFirestore, doc, getDoc, setDoc } from 'firebase/firestore';
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});
// Подключаемся к Firebase Storage
const storage = getStorage(diagramFBInit);
// Получаем ссылку на Firestore
const firestore = getFirestore(diagramFBInit);

//
export async function uploadFile(path, file) {
  try {
    // Получаем ссылку на Firebase Storage
    const storageRef = ref(storage, path);

    // Загружаем файл в Firebase Storage из base64 строки (без указания формата и метаданных)
    const snapshot = await uploadString(storageRef, file, 'data_url');

    // Получаем ссылку на загруженный файл
    const link = await getDownloadURL(snapshot.ref);
    return link;
  } catch (error) {
    rollbar.error('Error during request:', error);
    console.error('Ошибка при отправки файла в Firestorage:', error);
    throw error;
  }
}
export async function deleteFiles(table) {
  try {
    if (!table.images[0] && !table.files[0]) return;
    // все сущности что меньше нуля
    const fileUrls = [
      ...table.images.filter((row) => row.count < 1),
      ...table.files.filter((row) => row.count < 1),
    ];
    if (!fileUrls[0]) return;
    // Перебираем массив ссылок на файлы
    for (const fileUrl of fileUrls) {
      // Получаем ссылку на Firebase Storage из переданной ссылки на файл
      const storageRef = ref(storage, fileUrl.link);
      // Удаляем файл из Firebase Storage
      await deleteObject(storageRef);
    }
    // все сущности что больше нуля
    table.files = [...table.files.filter((row) => row.count > 0)];
    table.images = [...table.images.filter((row) => row.count > 0)];
  } catch (error) {
    rollbar.error('Error during request:', error);
    console.error('Ошибка при удалении файлов из Firebase Storage:', error);
    throw error;
  }
}
export async function downloadTable(idUser) {
  try {
    const docRef = doc(firestore, 'HashData', idUser); // Ссылка на документ в коллекции "HashData" с заданным idUser
    const docSnapshot = await getDoc(docRef);

    if (docSnapshot.exists()) {
      // Если документ существует, возвращаем его данные
      return docSnapshot.data();
    } else {
      // Документ не найден, создаем новый с указанным именем и значением по умолчанию
      const initialData = {
        files: [],
        images: [],
      };
      await setDoc(docRef, initialData); // Создаем новый документ с указанным именем и значениями по умолчанию
      return initialData;
    }
  } catch (error) {
    rollbar.error('Error during request:', error);
    console.error('Ошибка при получении данных из Firestore:', error);
    throw error;
  }
}
export async function uploadTable(idUser, data) {
  try {
    const docRef = doc(firestore, 'HashData', idUser); // Ссылка на документ в коллекции "HashData" с заданным idUser
    await setDoc(docRef, data);
  } catch (error) {
    rollbar.error('Error during request:', error);
    console.error('Ошибка при отправки данных из Firestore:', error);
  }
}
