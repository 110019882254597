import React, { useState, useEffect} from 'react';
import { useLocation, useNavigate} from 'react-router-dom';
import './TgLoginPage.css';
import adapter from '../../adapter';
import Preloader from '../../Preloader';
import alertIcon from '../../../public/alertIcon.png';
import axios from 'axios';
import { toast } from 'react-toastify';
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});

function TgLoginPage() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  let storedAppState = localStorage.getItem('storedAppState');
    storedAppState = storedAppState? JSON.parse(storedAppState) : null;

  const [data, setData] = useState({
    ip: '',
    code: code,
    route: 'checkFastLoginTelegram',
  });


  const handleLoginConfirm = async (e) => {
  
    try {
      setIsLoading(true);
      const response = await adapter.post('', data);
      if (response.data.result === 'success') {
        localStorage.setItem('dashboardData', JSON.stringify(response.data));
        localStorage.setItem('authenticated', true);
        localStorage.setItem('ip', data.ip);
        localStorage.setItem('storedAppState', JSON.stringify({
          ...storedAppState,
          page: 'AdminPanel'
        }));
        toast.success('Вход выполнен!');
        setTimeout(() => {
          navigate('/dashboard');
          setIsLoading(false);
        }, 1000);
      } else {
        toast.error(response.data.text);
        console.log(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error);
      setIsLoading(false);
      rollbar.error('Error during request:', error);
    }
  };

  const handleLoginCanceled = () => {
    localStorage.clear(); 
    sessionStorage.clear();
    navigate('/');
  }

 useEffect(() => {
    const fetchUserIP = async () => {
      try {
        const response = await axios.get('https://ipapi.co/json/');
        const userIP = response.data.ip;
        setData({
          ...data,
          ip: userIP || '',
        });
      } catch (error) {
        rollbar.error('Error during request:', error);
        console.error('Error fetching IP address:', error);
      }
    };

    fetchUserIP();
  }, []);

  return (
    <div id='authPage' className="auth-page-inner auth-confirm-page">
    {isLoading ? (
      <div className='preloader-container'>
        
        <p className="preloader-title">Выполняем вход</p>
        <Preloader />
       
      </div>
    ) : (
      <div className="card">
        <div className="alert-img"><img src={alertIcon} alt='alert icon'/></div>
        
        <form className="">
          <h2 className='confirm-autn-title'>Внимание ваш аккаунт телеграм будет использован для входа в админ панель! Вы подтверждаете это действие?</h2>
          <div className="confirm-auth-btns">
            <button type="button" className="auth-btn" onClick={handleLoginConfirm}>
              Подтвердить
            </button>
            <button type="button" className="auth-btn" onClick={handleLoginCanceled}>
              Отмена
            </button>

          </div>
        </form>
      </div>
    )}
  </div>
  );
}

export default TgLoginPage;