import React from "react";
import styles from "./SaveValueButton.module.css";
import SaveValue from "../SaveValue";
import ButtonDeleteUI from "../inputUI/ButtonsUI/ButtonDeleteUI";

function SaveValueButton({ data, changeBtn, deleteBtn, json }) {
  return (
    <div className={styles.box}>
      <div className={styles.btnContainer}>
        <SaveValue
          changeValue={(text, type) => changeBtn(data.id, text, type)}
          keyValue={"text"}
          placeholder={"Текст на кнопке"}
          json={json}
          union={true}
          value={data.text}
          hideIndex={true}
        />
        <SaveValue
          changeValue={(text, type) => changeBtn(data.id, text, type)}
          keyValue={"url"}
          placeholder={"URL на кнопке"}
          json={json}
          union={true}
          value={data.url}
          hideIndex={true}
        />
      </div>
      <ButtonDeleteUI deleteHandler={() => deleteBtn(data.id)} />
    </div>
  );
}

export default SaveValueButton;
