import React, { useState, useEffect } from 'react';

import styles from './PopupPauseContent.module.css';

//часовой пояс
let dateNow = new Date();
let timeUTC = dateNow.toString().split(' ')[5].slice(3);
let timeZone = `UTC ${timeUTC.slice(0, 3) + ':' + timeUTC.slice(3)}`;
let timeArr = [Number(timeUTC.slice(0, 3)), Number(timeUTC.slice(3))];
let timeForData =
  timeArr[1] > 0
    ? timeArr[0] > 0
      ? timeArr[0] + 0.5
      : timeArr[0] - 0.5
    : timeArr[0];
//дата
const today = new Date();
const dateString = `${today.getFullYear()}-${String(
  today.getMonth() + 1
).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

function PauseContent({ addData, nodeID, nodes, onConfirm }) {
  const [selectedValue, setSelectedValue] = useState('time');
  const [date, setDate] = useState(dateString);
  const [time, setTime] = useState('12:00');
  const [delayValue, setDelayValue] = useState(1);
  const [delayType, setDelayType] = useState('minute');
  const [continueType, setContinueType] = useState('passive');
  const [isInitialized, setIsInitialized] = useState(false);




  //иннициализация
  useEffect(() => {
    const dataNode = nodes.data.block;
    if (dataNode && !isInitialized) {
      // если данные есть
      setIsInitialized(true);
      setSelectedValue(dataNode.pause.pause_type);
      setContinueType(dataNode.pause.active_type);
     
      if (dataNode.pause.duration) {
        let timeValue = dataNode.pause.duration.split(' ');
      
        if (dataNode.pause.pause_type === 'time') {
          setDelayValue(timeValue[0]);
          setDelayType(timeValue[1]);
        }
        if (dataNode.pause.pause_type === 'date') {
          setDate(timeValue[0]);
          setTime(timeValue[1]);
        }
      }
    }
  }, [nodes.data.block]);
 
  useEffect(() => {
    if (isInitialized) {
      let duration;
      if (selectedValue === 'time') {
        duration = `${delayValue} ${delayType}`;
      }
      if (selectedValue === 'date') {
        duration = `${date} ${time}`;
      }
      const obj = {
        type: 'pause',
        pause_type: selectedValue,
        duration: duration,
        active_type: continueType,
        timeZone: timeForData,
      };
      addData(obj, 'pause', nodeID);
    }
  }, [date, time, delayType, delayValue, selectedValue, continueType, onConfirm]);

  const handleRadio = (event) => {
    setSelectedValue(event.target.value);
  };
  const handleContinue = (event) => {
    setContinueType(event.target.value);
  };

  const handleDate = (event) => {
    if (new Date(event.target.value) < today) {
      setDate(dateString);
    }
  };
  const handleInputChange = (e) => {
    let inputValue = e.target.value;
    
    if (inputValue > 999999) {
      setDelayValue(999999);
      return;
    }
    if (inputValue < 0 && !inputValue) {
      setDelayValue(1);
      return;
    }
    setDelayValue(inputValue);
  };
  return (
    <>
      <div>
        <label className={styles.title}>Введите значение задержки</label>
        <div className={styles.radio}>
          <input
            id="time"
            type="radio"
            value="time"
            checked={selectedValue === 'time'}
            onChange={handleRadio}
          />
          <label htmlFor="time"> Временной период</label>
        </div>
        <div className={styles.radio}>
          <input
            id="date"
            type="radio"
            value="date"
            checked={selectedValue === 'date'}
            onChange={handleRadio}
          />
          <label htmlFor="date"> До даты</label>
        </div>
      </div>
      {selectedValue === 'date' ? (
        <div className={styles.blockDate}>
          <input
            className={`${styles.condition} ${styles.calendar} ${styles.itemData}`}
            type="date"
            min={dateString}
            value={date}
            onBlur={handleDate}
            onChange={(e) => setDate(e.target.value)}
          />
          <input
            className={`${styles.condition} ${styles.time} ${styles.itemData}`}
            type="time"
            value={time}
            onChange={(e) => setTime(e.target.value)}
          />
          <span className={styles.utc}>{timeZone}</span>
        </div>
      ) : (
        <div className={styles.blockTime}>
          <input
            className={`${styles.condition} ${styles.itemTime}`}
            type="number"
            max={9999}
            min={1}
            value={delayValue}
            onChange={handleInputChange}
          />
          <select
            className={`${styles.condition} ${styles.itemTime}`}
            value={delayType}
            onChange={(e) => setDelayType(e.target.value)}
          >
            <option value={'second'}>Секунд</option>
            <option value={'minute'}>Минут</option>
            <option value={'hour'}>Часов</option>
            <option value={'day'}>Дней</option>
            <option value={'week'}>Недель</option>
          </select>
        </div>
      )}
      <div className={styles.blockContinue}>
        <label className={styles.title}>Продолжить цепочку</label>
        <div className={styles.radio}>
          <input
            id="passive"
            type="radio"
            value="passive"
            checked={continueType === 'passive'}
            onChange={handleContinue}
          />
          <label htmlFor="passive"> При не активности</label>
        </div>
        <div title="В разработке" className={styles.radio}>
          <input
            disabled={true}
            id="active"
            type="radio"
            value="active"
            checked={continueType === 'active'}
            onChange={handleContinue}
          />
          <label htmlFor="active" style={{ color: 'darkgray' }}>
            {' '}
            Всегда
          </label>
          <p className={styles.textRadio}>
            Сработает, даже если пользователь перейдет в другую цепочку
          </p>
        </div>
      </div>
    </>
  );
}

export default PauseContent;
