import React, { useState, useEffect } from 'react';
import styles from './ErrorUploadFile.module.css';
function ErrorUploadFile() {
  const [animateStatus, setAnimateStatus] = useState(false);
  useEffect(() => {
    setAnimateStatus(true);
  }, []);

  return (
    <div
      className={`${styles.errorMessage} ${
        animateStatus ? styles.animate : ''
      }`}
    >
      Слишком большой размер
    </div>
  );
}

export default ErrorUploadFile;
