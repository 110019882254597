import React from 'react';
import JsonView from 'react18-json-view';
import styles from './BodyJson.module.css';
import './json.css';

function BodyJsonUI({ data }) {
  return (
    <div className={styles.body}>
      <JsonView
        src={data}
        collapsed={false}
        customizeCopy={() => ''}
        enableClipboard={false}
      />
    </div>
  );
}

export default BodyJsonUI;
