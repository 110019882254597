import React, { useState } from 'react';
import styles from './Button.module.css';
import AddButtonWindow from '../addButtonWindow';
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from 'react-icons/md';

function Button({
  text,
  link,
  isActive,
  onClick,
  id,
  maxId,
  modifyButton,
  deleteButton,
  moveUp,
  moveDown,
  index,
}) {
  const [blockStatus, setBlockStatus] = useState(false);

  return (
    <>
      <div
        className={styles.button}
        onMouseEnter={() => setBlockStatus(true)}
        onMouseLeave={() => setBlockStatus(false)}
        onClick={() => onClick(id)}
      >
        <div
          className={` ${blockStatus ? '' : styles.arrowsHidden} ${
            styles.arrows
          }`}
        >
          <MdOutlineKeyboardArrowUp
            style={{ display: `${index === 0 ? 'none' : 'block'}` }}
            onClick={(e) => {
              e.stopPropagation();
              if (index !== 0) {
                moveUp();
              }
            }}
            className={styles.arrow}
          />
          <MdOutlineKeyboardArrowDown
            style={{ display: `${index === maxId - 1 ? 'none' : 'block'}` }}
            onClick={(e) => {
              e.stopPropagation();
              if (index !== maxId - 1) {
                moveDown();
              }
            }}
            className={styles.arrow}
          />
        </div>
        <span className={`${styles.change} ${blockStatus ? styles.show : ''}`}>
          Редактировать
        </span>
        <div className={styles.title}>{text}</div>
        {link && <span className={styles.link}>{link}</span>}
      </div>
      {isActive && (
        <AddButtonWindow
          close={() => onClick(id)}
          isButton={{ text, link, id }}
          modifyButton={modifyButton}
          deleteButton={deleteButton}
        />
      )}
    </>
  );
}

export default Button;
