import React, { useEffect, useState } from 'react';
import AddButtonWindow from '../addButtonWindow';
import Button from '../button';
import styles from './AddButton.module.css';
import ButtonRowSelect from '../buttonRowSelect';

function AddButton({
  buttonsData,
  initButtonsData,
  selectData,
  initSelectData,
}) {
  const [buttonsArray, setButtonsArray] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const [activeChildId, setActiveChildId] = useState(null);
  const [isInitialized, setIsInitialized] = useState(false);
  const [counterButtons, setCounterButtons] = useState(null);

  //Подписка на начальные данные
  useEffect(() => {
    if (!isInitialized && initSelectData && initButtonsData) {
      setSelectedValue(initSelectData);
      setButtonsArray(initButtonsData);
      setIsInitialized(true);
    }
  }, [initSelectData, initButtonsData, isInitialized]);

  const handleSelect = (event) => {
    setSelectedValue(event.target.value);
    selectData(event.target.value);
  };

  useEffect(() => {
    if (buttonsArray && isInitialized && !counterButtons) {
      const maxIDElement = counterInitialized(buttonsArray);
      setCounterButtons(maxIDElement);
    }
  }, [buttonsArray]);

  function counterInitialized(array) {
    let maxId = 1;
    if (!array) return maxId;

    for (let i = 0; i < array.length; i++) {
      const element = array[i];
      if (element.id >= maxId) {
        maxId = element.id + 1;
      }
    }
    return maxId;
  }

  // отдаем новые данные родительскому компоненту
  useEffect(() => {
    if (!buttonsArray) return;
    buttonsData(buttonsArray);
  }, [buttonsArray]);

  // popupCloseWindow
  const handleClick = (id) => {
    if (activeChildId === id) {
      setActiveChildId(null); // закрыть всплывающий компонент, если он уже открыт
    } else {
      setActiveChildId(id); // иначе открыть его
    }
  };

  // добавить кнопку
  function addButton(button) {
    const addIDButton = { ...button, id: counterButtons };
    setButtonsArray((previous) => [...previous, addIDButton]);
    setCounterButtons(counterButtons + 1);
  }

  // изменить кнопку
  function modifyButton(buttonChange, id) {
    const newButton = { ...buttonChange, id: id };
    setButtonsArray((previous) => {
      return previous.map((button) => {
        if (button.id === id) {
          return newButton;
        }
        return button;
      });
    });
  }

  //удалить кнопку
  function deleteButton(id) {
    setButtonsArray(buttonsArray.filter((button) => button.id !== id));
  }

  //переместить кнопку
  const moveButton = (index, direction) => {
    const itemsArray = [...buttonsArray];
    const targetIndex = index + direction;
    // Проверка границ массива
    if (targetIndex < 0 || targetIndex > itemsArray.length - 1) return;

    // Меняем местами элементы
    const temp = itemsArray[targetIndex];
    itemsArray[targetIndex] = itemsArray[index];
    itemsArray[index] = temp;

    setButtonsArray(itemsArray);
  };

  return (
    <>
      {buttonsArray && (
        <>
          <div>
            {buttonsArray?.map((element, index) => {
              return (
                <Button
                  deleteButton={deleteButton}
                  modifyButton={modifyButton}
                  key={element.id}
                  id={element.id}
                  maxId={buttonsArray.length}
                  isActive={element.id === activeChildId}
                  onClick={handleClick}
                  moveUp={() => moveButton(index, -1)}
                  moveDown={() => moveButton(index, 1)}
                  text={element.textValue}
                  link={element.linkValue ? element.linkValue : false}
                  index={index}
                />
              );
            })}
            {buttonsArray?.length < 25 && (
              <div className={styles.addButton} onClick={() => handleClick(0)}>
                +Добавить кнопку
              </div>
            )}
          </div>
          {activeChildId === 0 && (
            <AddButtonWindow
              addButton={addButton}
              close={() => handleClick(0)}
            />
          )}
          {buttonsArray?.length > 1 && (
            <ButtonRowSelect
              selectedValue={selectedValue}
              handleSelect={handleSelect}
            />
          )}
        </>
      )}
    </>
  );
}

export default AddButton;
