import React, { useState, useEffect } from 'react';
import { GrTrash } from 'react-icons/gr';
import Link from '../link';
import Counter from '../counter';
import { limitText } from '../../../../valid/limitText';
import styles from './AddButtonWindow.module.css';

const txtLimit = 250;

function AddButtonWindow({
  close,
  addButton,
  isButton,
  modifyButton,
  deleteButton,
}) {
  const [animate, setAnimate] = useState(false);
  const [txtStatus, setTxtStatus] = useState(false);

  const [selectedOption, setSelectedOption] = useState('chainContinue');
  const [text, setText] = useState('');
  const [linkText, setLinkText] = useState('');

  const [buttonMode, setButtonMode] = useState(false);

  const [textInvalid, setTextInvalid] = useState(false);
  const [linkTextInvalid, setLinkTextInvalid] = useState(false);
  // анимация
  useEffect(() => {
    if (!animate) {
      setAnimate(true);
    }
  }, []);

  //Проверка на кнопку
  useEffect(() => {
    if (isButton) {
      setButtonMode(true);
      setText(isButton.text);
      if (isButton.link) {
        setSelectedOption('link');
        setLinkText(isButton.link);
      }
    } else {
      setButtonMode(false);
    }
  }, [isButton]);

  function writeLink(txt) {
    setLinkText(txt);
  }
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  function buttonConstructor() {
    const button = {
      type: selectedOption === 'chainContinue' ? 'text' : selectedOption === 'webapp' ? 'webapp' : 'link',
      textValue: text,
    };
    if (button.type === 'link') {
      button.linkValue = linkText;
    }
    return button;
  }

  function checkValid() {
    if (!text) {
      setTextInvalid(true);
      return false;
    }
    if (selectedOption === 'link' && !linkText.includes('https://')) {
      setLinkTextInvalid(true);
      return false;
    }
    return true;
  }

  // создания конпки
  function createButton() {
    if (checkValid()) {
      const button = buttonConstructor();
      console.log(button);
      addButton(button);
      close();
    }
  }
  function changeButton() {
    if (checkValid()) {
      const button = buttonConstructor();
      modifyButton(button, isButton.id);
      close();
    }
  }

  function btnDelete() {
    deleteButton(isButton.id);
    close();
  }
  function linkValid() {
    setLinkTextInvalid(false);
  }
  return (
    <div className={`${styles.block} ${animate ? styles.animate : ''}`}>
      <div className={styles.blockText}>
        <Counter number={text.length} status={txtStatus} limit={txtLimit} />
        <input
          className={`${styles.text} ${textInvalid ? styles.textInvalid : ''}`}
          type="text"
          placeholder="Текст на кнопке"
          value={text}
          onChange={(e) => setText(limitText(e, txtLimit))}
          onFocus={() => {
            setTxtStatus(true);
            setTextInvalid(false);
          }}
          onBlur={() => setTxtStatus(false)}
        />
      </div>
      <div className={styles.blockRadio}>
        <label className={styles.label}>
          <input
            disabled={buttonMode}
            className={styles.radio}
            type="radio"
            value="chainContinue"
            checked={selectedOption === 'chainContinue'}
            onChange={handleOptionChange}
          />
          Продолжить цепочку
        </label>
        <label className={styles.label}>
          <input
            disabled={buttonMode}
            className={styles.radio}
            type="radio"
            value="link"
            checked={selectedOption === 'link'}
            onChange={handleOptionChange}
          />
          Ссылка
        </label>
        <label className={styles.label}>
          <input
            disabled={buttonMode}
            className={styles.radio}
            type="radio"
            value="webapp"
            checked={selectedOption === 'webapp'}
            onChange={handleOptionChange}
          />
          WebApp
        </label>
      </div>
      {selectedOption === 'link' || selectedOption === 'webapp' ? (
        <Link
          typeOfLink={selectedOption}
          writeLink={writeLink}
          linkText={linkText}
          linkTextInvalid={linkTextInvalid}
          linkValid={linkValid}
        />
      ) : null}
      <div className={styles.blockButton}>
        {buttonMode ? (
          <div className={styles.contentButton}>
            <div onClick={changeButton}>
              <div className={`${styles.button} ${styles.buttonSave}`}>
                Сохранить
              </div>
            </div>
            <div onClick={btnDelete}>
              <div className={`${styles.button} ${styles.buttonDelete}`}>
                Удалить
              </div>
            </div>
          </div>
        ) : (
          <div onClick={createButton}>
            <div className={`${styles.button} ${styles.buttonSave} `}>
              Добавить
            </div>
          </div>
        )}
        <div className={styles.addDropDown} onClick={close}>
          <span>Отмена</span>
        </div>
      </div>
    </div>
  );
}

export default AddButtonWindow;
