import React, { useState, useEffect } from 'react';
import styles from './DropZoneURL.module.css';
import ErrorPopUp from '../../errorUI';

function DropZoneURL({ handler, initUrl, plcHldr }) {
  const [text, setText] = useState('');
  const [invalidSize, setInvalidSize] = useState(false);

  function size(event) {
    if (event.target.value.length > 300_000) {
      setInvalidSize(true);
      return text;
    }
    if (event.target.value.length < 300_000) {
      setInvalidSize(false);
      return event.target.value;
    }
  }

  useEffect(() => {
    if (initUrl) {
      setText(initUrl);
    }
  }, []);

  function clear() {
    setInvalidSize(false);
    setText('');
  }

  return (
    <div className={`${styles.dropZone}`}>
      {invalidSize && (
        <ErrorPopUp
          clear={clear}
          text={'Превышен максимально допустимый размер(300кб)'}
        />
      )}
      <input
        onBlur={() => handler(text)}
        className={
          invalidSize ? `${styles.inputURL} errorInput` : styles.inputURL
        }
        type="text"
        value={text}
        onChange={(e) => setText(size(e))}
        placeholder={plcHldr ? plcHldr : 'https://example.com/image.png'}
      />
    </div>
  );
}

export default DropZoneURL;
