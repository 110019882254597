import React from 'react';
import styles from './ButtonDashedUI.module.css';
function ButtonDashedUI({ text, clickHandler }) {
  return (
    <div>
      <a className={styles.btn} onClick={clickHandler}>
        <span>{text}</span>
      </a>
    </div>
  );
}

export default ButtonDashedUI;
