import React, { useEffect, useState } from "react";
import styles from "./SendWebhook.module.css";

import TextUI from "../../inputUI/TextUI";

function SendWebhook({ data, handler }) {
  const [value, setValue] = useState("");
  const [name, setName] = useState("");
  const [init, setInit] = useState(false);

  useEffect(() => {
    if (data.title && data.link && !init) {
      setValue(data.link);
      setName(data.title);
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      handler({ ...data, ...(value && { title: name, link: value }) });
    }
  }, [value, name, init]);

  return (
    <div className={styles.box}>
      <p title='ключ "title" в данных запроса' className={styles.title}>
        Название вебхука{" "}
        <span style={{ fontSize: 12, cursor: "help" }}>(?)</span>
      </p>
      <TextUI text={name} textHandler={(newValue) => setName(newValue)} />
      <p className={styles.title}>URL</p>
      <TextUI
        text={value}
        textHandler={(newValue) => setValue(newValue)}
        placeholder="https://example.com/handler.php"
      />
    </div>
  );
}

export default SendWebhook;
