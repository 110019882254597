import React, { useState, useEffect } from 'react';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { TbMessageCircle } from 'react-icons/tb';
import { BsTextLeft } from 'react-icons/bs';
import { HiOutlinePhotograph } from 'react-icons/hi';
import { AiOutlinePaperClip } from 'react-icons/ai';

import styles from './MessageAddType.module.css';

const TYPE_IMAGE = {
  type: 'message image',
  image: {
    typeFile: 'file',
    imageValue: '',
    imageName: '',
    imageSize: '',
    imageType: '',
    textValue: '',
    hash: '',
  },
  buttons: [],
  maxBtnRow: 1,
};
const TYPE_TEXT = {
  type: 'message text',
  textValue: '',
  buttons: [],
  maxBtnRow: '1',
};
const TYPE_FILE = {
  type: 'message file',
  file: {
    typeFile: 'file',
    fileValue: '',
    fileName: '',
    fileSize: '',
    fileType: '',
    hash: '',
  },
  buttons: [],
  maxBtnRow: 1,
};
const TYPE_PAUSE = {
  type: 'message pause',
  pauseValue: 1,
  pauseType: 'second',
};
function MessageAddType({ blockElementAdd }) {
  const [popupStatus, setPopupStatus] = useState(false);
  const [animate, setAnimate] = useState(false);
  const dropDownRef = useDetectClickOutside({
    onTriggered: () => {
      setPopupStatus(false);
    },
  });
  useEffect(() => {
    setAnimate(popupStatus);
  }, [popupStatus]);

  function blockElementHandler(type) {
    if (type === 'text') {
      blockElementAdd(TYPE_TEXT);
    }
    if (type === 'image') {
      blockElementAdd(TYPE_IMAGE);
    }
    if (type === 'file') {
      blockElementAdd(TYPE_FILE);
    }
    if (type === 'pause') {
      blockElementAdd(TYPE_PAUSE);
    }
    setPopupStatus(false);
  }

  return (
    <a
      onClick={() => setPopupStatus(!popupStatus)}
      className={`${animate ? styles.addDropDowAnimate : styles.addDropDown}`}
      ref={dropDownRef}
    >
      <span>Добавить...</span>
      <span
        className={`${styles.caret}  ${animate ? styles.caretAnimate : ''}`}
      ></span>
      {popupStatus && (
        <div
          className={`${styles.dropDownMenu} ${animate ? styles.animate : ''}`}
        >
          <div
            className={styles.dropDownElement}
            onClick={() => blockElementHandler('text')}
          >
            <BsTextLeft className={styles.icon} style={{ strokeWidth: 0 }} />
            Текст
          </div>
          <div
            className={styles.dropDownElement}
            onClick={() => blockElementHandler('image')}
          >
            <HiOutlinePhotograph className={styles.icon} />
            Картинка
          </div>
          <div
            className={styles.dropDownElement}
            onClick={() => blockElementHandler('file')}
          >
            <AiOutlinePaperClip className={styles.icon} />
            Файл
          </div>
          <div
            className={styles.dropDownElement}
            onClick={() => blockElementHandler('pause')}
          >
            <TbMessageCircle className={styles.icon} />
            Задержка
          </div>
        </div>
      )}
    </a>
  );
}

export default MessageAddType;
