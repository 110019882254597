export const getJsonSyntaxPath = (
  obj,
  targetKey,
  arrayTarget,
  currentPath = []
) => {
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) {
      currentPath.push(key);

      if (key === targetKey) {
        if (arrayTarget) {
          if (Array.isArray(obj[key])) {
            const jsonSyntax = currentPath
              .map((path) => `['${path}']`)
              .join("");
            return `{{$${jsonSyntax}}}`;
          } else {
            return null;
          }
        }
        if (typeof obj[key] === "object" || Array.isArray(obj[key])) {
          return null;
        }
        const jsonSyntax = currentPath.map((path) => `['${path}']`).join("");
        return `{{$${jsonSyntax}}}`;
      } else if (typeof obj[key] === "object") {
        const result = getJsonSyntaxPath(
          obj[key],
          targetKey,
          arrayTarget,
          currentPath
        );
        if (result) {
          return result;
        }
      }

      currentPath.pop();
    }
  }

  return null;
};
