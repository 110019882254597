import React, { useState , useEffect} from 'react';
import Ajv from 'ajv';
import TextDescription from '../../MessageTypeText/textDescription';
import styles from './RawValidator.module.css';

const schema = {
  type: ['object', 'array'],
};

const ajv = new Ajv();
const validate = ajv.compile(schema);

function RawValidator({text, handler}) {
  const [isInitialize, setIsInitialize] = useState(false)
  const [jsonInput, setJsonInput] = useState('');
  const [validationResult, setValidationResult] = useState(true);

  useEffect(() => {
    if(isInitialize) return;
    setIsInitialize(true)
    setJsonInput(text)
  }, [text, isInitialize])
  
  const handleInputChange = (inputValue) => {
    setJsonInput(inputValue);
    try {
      const parsedJson = JSON.parse(inputValue);
      const valid = validate(parsedJson);
      setValidationResult(valid);
      if(valid){
        handler(inputValue)
      }
    } catch (error) {
      setValidationResult(false);
    }
  };
  return (
    <div className={styles.box}>
      <label className={styles.label}>
        Тіло запиту{' '}
        <span className={styles.subLabel}>RAW (application/json)</span>
      </label>
      <TextDescription
        placeholder={'RAW'}
        initTextData={jsonInput}
        textData={(text) => handleInputChange(text)}
      />
      {!validationResult && <p style={{ color: 'red' }}>Invalid JSON</p>}
    </div>
  );
}

export default RawValidator;
