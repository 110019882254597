import React from "react";
import styles from "./ButtonDeleteUI.module.css";
import { ImCross } from "react-icons/im";

function ButtonDeleteUI({ deleteHandler, size = 15 }) {
  return (
    <ImCross
      className={styles.delete}
      style={{ minWidth: size }}
      size={size}
      onClick={deleteHandler}
    />
  );
}

export default ButtonDeleteUI;
