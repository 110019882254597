import React, { useEffect } from "react";

function Unsubscribe({ data, handler }) {
  useEffect(() => {
    handler({ ...data, unsubscribe: true });
  }, []);

  return <></>;
}

export default Unsubscribe;
