import React from 'react';
import styles from './FilterVaribleLogic.module.css';
import UnionAnd from '../../../../icon/sp-i-and.svg';
import UnionOr from '../../../../icon/sp-i-or.svg';

function VariableLogic({ selectHandle, select }) {
  return (
    <div className={styles.block}>
      Контакты соответствуют{' '}
      <select
        defaultValue={select}
        className={styles.select}
        onChange={selectHandle}
      >
        <option value="or">любому</option>
        <option value="and">каждому</option>
      </select>{' '}
      из условий:
      <span className={styles.icon}>
        {select === 'and' ? (
          <img src={UnionAnd} alt="circleAnd" />
        ) : (
          <img src={UnionOr} alt="circleOr" />
        )}
      </span>
    </div>
  );
}

export default VariableLogic;
