import React, { useEffect, useState } from 'react';
import mailingIcon  from '../../../../../assets/mass.png';
import '../../../../../index.css';
import './ConnectBot.css';
import errorImage from '../../../../../assets/robot-404-error-errors.svg'



const ConnectBot = () => {

    return(
        <div id='mailingPage'>
            <div className="card">
                <h1 className="main-title">
                    <p>Управление рассылками</p> 
                    <img className='icon' src={mailingIcon}/>
                </h1>
                <div className='mailing-pages'>
                    <p>
                        Данные о ботах не найдены! Пожалуйста обновите страницу или добавьте ботов через админ панель!
                    </p>
                </div>
                
            </div>
            <div className="card">
                <img src={errorImage}/>
            </div>
        
        </div>
    );
};

export default ConnectBot;