import axios from "axios";
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});
const API_URL = "https://voronkanec.com/";

// for any thinks diagram
export const fetchDialogsAndTriggers = async (botId, userId, accessToken) => {
  try {
    const result = await axios.post(API_URL, {
      route: "getDialogsAndTrigers",
      bot_id: botId,
      user_tg_id: userId,
      access_token: accessToken,
    });
    return idAndName(result.data.array);
  } catch (err) {
    rollbar.error('Error during request:', err);
    throw err;
  }
};

// for spam
export const fetchSpams = async (userId, accessToken) => {

  try {
    const result = await axios.post(API_URL, {
      route: "getSpams",
      user_tg_id: userId,
      access_token: accessToken,
    });
    return idAndName(result.data.array);
  } catch (err) {
    rollbar.error('Error during request:', err);
    throw err;
  }
};

// for channel
export const fetchChannels = async (botId, tgId, accessToken) => {

  try {
    const result = await axios.post(API_URL, {
      route: "getChanels",
      bot_id: botId,
      user_tg_id: tgId,
      access_token: accessToken,
    });
    return idAndName(result.data.array);
  } catch (err) {
    rollbar.error('Error during request:', err);
    throw err;
  }
};

// prepare data
function idAndName(array) {
  const newArray = [];
  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    const newElement = { name: element.name, id: element.id };
    newArray.push(newElement);
  }
  return newArray;
}

export const getHeaders = async (method, url, headers, bodyJson = "") => {
  const data = {
    route: "getHeaders",
    headers,
    method,
    url,
    bodyJson,
  };
  try {
    const result = await axios.post("https://voronkanec.com", data);
    return result.data.array;
  } catch (error) {
    rollbar.error('Error during request:', error);
    console.error(error);
  }
};
