import React, { useState, useEffect } from "react";
import { useReactFlow, useStoreApi } from "reactflow";
import { BiTrash } from "react-icons/bi";
import styles from "./DeleteBlock.module.css";
import { toggleCountFiles } from "../functionForFireStore";

function DeleteBlock({ id }) {
  const [islock, setIsLock] = useState(false);

  const { setNodes, getNodes } = useReactFlow();
  // иннициализация стора
  const store = useStoreApi();
  const { lock, close, FBTable } = store.getState();

  useEffect(() => {
    setIsLock(lock);
  }, [lock]);

  function deleteEdge(event) {
    event.stopPropagation();
    store.setState({ isDeleted: true });
    if (close) {
      close();
    }

    const nodes = getNodes();
    const deletedNode = nodes.find((nds) => nds.id === id);
    if (FBTable && FBTable.table) {
      toggleCountFiles(deletedNode, FBTable.table, "decrement");
    }
    const newArray = nodes.filter((nds) => nds.id !== id);
    setNodes(newArray);
  }
  if (lock) {
    return;
  }
  return (
    <div className={styles.block} onClick={deleteEdge}>
      <BiTrash className={styles.trash} />
    </div>
  );
}

export default DeleteBlock;
