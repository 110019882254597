import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AdminPanel from './adminPanel/AdminPanel';
import MailingPage from './mailingPage/MailingPage';
import TieAcc from './tieAcc/TieAcc';
import BotStats from './botStats/BotStats';
import AccountSettings from './accountSettings/AccountSettings';
import '../../../index.css';
import './WorkSpace.css';
import { toast } from 'react-toastify';



const Workspace = ({selectedComponent, refreshPage}) => {
    
    let storedAppState = localStorage.getItem('storedAppState');
    storedAppState = storedAppState? JSON.parse(storedAppState) : null;
    const [renderedPage, setRenderedPage] = useState(selectedComponent);
    const [refreshComponent, setRefreshComponent] = useState(false);


    

    useEffect(() => {
        if(!storedAppState){
            localStorage.clear();
        }else{
            if (storedAppState.page && storedAppState.page !== 'tieAcc') {
                setRenderedPage(storedAppState.page);
            }else{
                setRenderedPage('tieAcc');
            }
        }
       
        
        
    },[ selectedComponent, refreshPage]);

    const navigate = useNavigate();
    useEffect(() => {
        if (refreshPage) {

            if (storedAppState && storedAppState.page === selectedComponent) {
                setRefreshComponent(true)
            }else if(!storedAppState){
                toast.error('Срок действия данных сессии истек!Выполните повторный вход')
                localStorage.clear(); 
                sessionStorage.clear();
                navigate('/dashboard');
            }
        }
      }, [refreshPage]);

    useEffect(() => {
        if (refreshComponent) {
          setRefreshComponent(false); 
        }
      }, [refreshComponent]);
      

    const [loading, setLoading] = useState(false);

    const setWorkspaceStyles = (loading) => {
        console.log(loading);
        if (loading === true) {
            setLoading(loading);
        }else{
            setLoading(false);
        }
    }

    

    const renderComponent = () => {
      switch (renderedPage) {
        case 'AdminPanel':
            return <AdminPanel  refreshComponent={refreshComponent} setWorkspaceStyles={setWorkspaceStyles}/>;
        case 'MailingPage':
            return <MailingPage refreshComponent={refreshComponent}/>;
        case 'BotStats':
            return <BotStats refreshComponent={refreshComponent}/>;
        case 'accountSettings':
            return <AccountSettings/>;
        case 'tieAcc':
            return <TieAcc/>;
        default:
            return <AdminPanel refreshComponent={refreshComponent} setWorkspaceStyles={setWorkspaceStyles}/>;
      }
    };
  
    return (

        <div className={`workspace ${loading ? 'loading' : ''}`}>{renderComponent()}</div>
        
    );
  }
  
  export default Workspace;