import React, { useState, useEffect } from 'react';
import Condition from '../FilterCondition';
import VariableLogic from '../FilterVariableLogic';
import styles from './FilterConditionsGroup.module.css';

function ConditionsGroup({
  data,
  length: lengthData,
  deleteCndGroup,
  changeCndGroup,
}) {
  const [conditionsDate, setConditionsDate] = useState(false);
  const [logicSelect, setLogicSelect] = useState(false);

  const handleLogicChange = (event) => {
    setLogicSelect(event.target.value);
  };
  useEffect(() => {
    setConditionsDate(data);
    setLogicSelect(data.typeLogic);
  }, []);

  useEffect(() => {
    if (logicSelect) {
      const newObj = {
        ...conditionsDate,
        typeLogic: logicSelect,
      };
      changeCndGroup(newObj, conditionsDate.id);
    }
  }, [conditionsDate.subConditions, logicSelect]);

  function createNewCondition() {
    const cnd = {
      name: '',
      condition: '',
      value: '',
    };
    setConditionsDate((previous) => ({
      ...previous,
      subConditions: [...previous.subConditions, cnd],
    }));
  }

  function changeCondition(index, obj) {
    setConditionsDate((previous) => {
      const newArray = [...conditionsDate.subConditions];
      newArray[index] = obj;
      return {
        ...previous,
        subConditions: newArray,
      };
    });
  }

  function deleteCnd(index) {
    if (conditionsDate.subConditions.length === 1) {
      deleteCndGroup(conditionsDate.id);
      return;
    }

    setConditionsDate((previous) => {
      const newArray = [...conditionsDate.subConditions].filter(
        (_, i) => i !== index
      );
      return {
        ...previous,
        subConditions: newArray,
      };
    });
  }

  const lengthForDelete =
    lengthData > 1 ? lengthData : conditionsDate.subConditions?.length;

  return (
    <div>
      {conditionsDate && (
        <>
          {conditionsDate.subConditions.length > 1 && (
            <VariableLogic
              selectHandle={handleLogicChange}
              select={logicSelect}
            />
          )}
          {conditionsDate.subConditions.map((cnd, i) => (
            <Condition
              data={cnd}
              handler={changeCondition}
              deleteCnd={() => (lengthForDelete === 1 ? null : deleteCnd(i))}
              length={lengthForDelete}
              index={i}
              key={i}
            />
          ))}
          {conditionsDate.subConditions.length < 9 && (
            <div>
              <span className={styles.add} onClick={createNewCondition}>
                Добавить условие
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default ConditionsGroup;
