export const variablesForSelect = (variables) => {
  if (!variables && !variables[0]) {
    return [];
  }

  const labelAndName = variables.map((v) => ({
    action: `{{${v.name}}}`,
    label: v.name,
    type: translateType(v.type),
    id: v.id,
  }));
  return labelAndName;
};

const translateType = (type) => {
  switch (type) {
    case "number":
      return "число";
    case "string":
      return "строка";
    case "date":
      return "дата";
    case "phone":
      return "телефон";
    case "mail":
      return "емейл";
    case "link":
      return "ссылка";
    case "time":
      return "время";
    default:
      return undefined;
  }
};
export const reverseType = (type) => {
  switch (type) {
    case "число":
      return "number";
    case "строка":
      return "string";
    case "дата":
      return "date";
    case "телефон":
      return "phone";
    case "емейл":
      return "mail";
    case "ссылка":
      return "link";
    case "время":
      return "time";
    default:
      return undefined;
  }
};

export function removeBrackets(text) {
  if (text.startsWith("{{") && text.endsWith("}}")) {
    return text.substring(2, text.length - 2);
  } else {
    return text;
  }
}
