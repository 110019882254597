import axios from "axios";
import Rollbar from 'rollbar';
const rollbar = new Rollbar({
  accessToken: '6dac23a10b4d4eb797cb71bd64e0d80f',
  environment: 'testenv',
});
const API_URL = "https://voronkanec.com/";

// Компиляция всех запросв
export const fetchDataDiagram = async (auth) => {
  try {
    const dialogsAndTriggersResult = await fetchDialogsAndTriggers(
      auth.bot_id,
      auth.user_tg_id,
      auth.access_token
    );
    const spamsResult = await fetchSpams(
      auth.bot_id,
      auth.user_tg_id,
      auth.access_token
    );
    const channelsResult = await fetchChannels(
      auth.bot_id,
      auth.user_tg_id,
      auth.access_token
    );
    const variablesResult = await getVariables(auth.bot_id, auth.access_token);
    return {
      diaglogs: dialogsAndTriggersResult,
      spams: spamsResult,
      channel: channelsResult,
      variables: variablesResult,
    };
  } catch (err) {
    console.error(err);
    rollbar.error('Error during request:', err);
    return {
      diaglogs: [],
      spams: [],
      channel: [],
    };
  }
};

// для различных блок-схем
export const fetchDialogsAndTriggers = async (botId, tgId, accessToken) => {
  try {
    const result = await axios.post(API_URL, {
      route: "getDialogsAndTrigers",
      user_id: tgId,
      user_tg_id: tgId,
      access_token: accessToken,
      bot_id: botId,
    });
    return idAndName(result.data.array);
  } catch (err) {
    rollbar.error('Error during request:', err);
    throw err;
  }
};

// для рассылок
export const fetchSpams = async (tgId, accessToken) => {
  try {
    const result = await axios.post(API_URL, {
      route: "getSpams",
      user_tg_id: tgId,
      access_token: accessToken,
    });

    return idAndName(result.data.array);
  } catch (err) {
    rollbar.error('Error during request:', err);
    throw err;
  }
};

// для каналов
export const fetchChannels = async (botId, tgId, accessToken) => {
  try {
    const result = await axios.post(API_URL, {
      route: "getChanels",
      bot_id: botId,
      user_tg_id: tgId,
      access_token: accessToken,
    });

    return idAndName(result.data.array);
  } catch (err) {
    rollbar.error('Error during request:', err);
    throw err;
  }
};

export const getVariables = async (botId, accessToken) => {
  try {
    const { data } = await axios.post(API_URL, {
      route: "getVariables",
      bot_id: botId,
      access_token: accessToken,
    });

    if (data?.array && data?.array[0]) {
      return data.array;
    }
    return [];
  } catch (error) {
    rollbar.error('Error during request:', error);
    throw error;
  }
};

export const createVariable = async (botId, accessToken, data) => {
  try {
    await axios.post(API_URL, {
      route: "createVariable",
      bot_id: botId,
      access_token: accessToken,
      ...data,
    });

    return true;
  } catch (error) {
    rollbar.error('Error during request:', error);
    throw error;
  }
};

// отсекаем лишнее
function idAndName(array) {
  const newArray = [];
  if (!array || !array[0]) return newArray;

  for (let i = 0; i < array.length; i++) {
    const element = array[i];
    const newElement = { name: element.name, id: element.id };
    newArray.push(newElement);
  }
  return newArray;
}
