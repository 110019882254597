import React, { useEffect, useState } from "react";
import styles from "./SetConstant.module.css";

import ButtonDashedUI from "../../inputUI/ButtonsUI/ButtonDashedUI";
import { useStore } from "reactflow";
import SelectValueUI from "../../inputUI/SelectValueUI";
import TextUI from "../../inputUI/TextUI";
import {
  removeBrackets,
  reverseType,
} from "../../../../../utils/variablesForSelect";
import { setConstantSchema } from "../../../../../config/setConstantSchema";

function SetConstant({ data, handler }) {

  const { openModal } = useStore();
  const [typeVariable, setTypeVariable] = useState("");
  const [value, setValue] = useState("");
  const [variable, setVariable] = useState("");
  const [init, setInit] = useState(false);
  useEffect(() => {
    if (data.name && data.constant_process?.value && !init) {
      setVariable(data.name);
      setValue(data.constant_process?.value);
      setTypeVariable(data.constant_process?.type);
    }
    setInit(true);
  }, []);

  useEffect(() => {
    if (init) {
      handler({
        ...data,
        ...(variable && {
          name: variable,
          constant_process: { type: typeVariable, act: false, value },
        }),
      });
    }
  }, [variable, value, init]);

  const handleValue = (newValue) => {
    const regex = setConstantSchema[typeVariable]?.regex;
    if (!newValue) {
      return setValue(newValue);
    }
    if (regex) {
      const validate = regex.test(newValue);
      if (validate) {
        return setValue(newValue);
      } else {
        return setValue(value);
      }
    }
    setValue(newValue);
  };

  return (
    <>
      <div className={styles.box}>
        <div className={styles.selectTitle}>
          <p className={styles.title}>Выберите переменную</p>
          <ButtonDashedUI
            text="Создать переменную"
            clickHandler={() => openModal()}
          />
        </div>
        <SelectValueUI
          value={variable}
          typeHandler={(type) => setTypeVariable(reverseType(type))}
          handler={(value) => {
            setVariable(removeBrackets(value));
            setValue("");
          }}
        />
        <div className={styles.inputTitle}>
          <p className={styles.title}>Укажите значение переменной</p>
        </div>
        <TextUI
          placeholder={
            typeVariable
              ? setConstantSchema[typeVariable].placeholder
              : "Значение"
          }
          textHandler={(value) => handleValue(value)}
          type={typeVariable ? setConstantSchema[typeVariable].type : "text"}
          text={value}
        />
      </div>
    </>
  );
}

export default SetConstant;
