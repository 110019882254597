import React, { useState, useEffect } from 'react';
import { useReactFlow, useStoreApi } from 'reactflow';
import { RxCopy } from 'react-icons/rx';
import styles from './CopyBlock.module.css';
import { toggleCountFiles } from '../functionForFireStore';

function CopyBlock({ id }) {
  // const [islock, setIsLock] = useState(false);
  const [currentId, setCurrentId] = useState(false);
  const { setNodes, getNodes } = useReactFlow();
  // иннициализация стора
  const store = useStoreApi();
  const { lock, close, FBTable, handleCounterIdNode, counterNode } =
    store.getState();

  useEffect(() => {
    setCurrentId(counterNode);
  }, [counterNode]);

  function deleteEdge(event) {
    event.stopPropagation();
    if (close) {
      close();
    }
    if (handleCounterIdNode && currentId) {
      const nodes = getNodes();
      if (nodes.length > 24) return;
      const copyNode = nodes.find((nds) => nds.id === id);
      const newNode = JSON.parse(JSON.stringify(copyNode));
      newNode.id = `${currentId}`;
      newNode.selected = false;
      newNode.position = {
        x: newNode.position.x + newNode.width + 30,
        y: newNode.position.y,
      };
      newNode.positionAbsolute = {
        x: newNode.positionAbsolute.x + newNode.width + 30,
        y: newNode.positionAbsolute.y,
      };
      handleCounterIdNode();

      toggleCountFiles(newNode, FBTable.table, 'increment');

      setNodes([...nodes, newNode]);
    }
  }
  if (lock) {
    return;
  }
  return (
    <div className={styles.block} onClick={deleteEdge}>
      <RxCopy className={styles.trash} />
    </div>
  );
}

export default CopyBlock;
