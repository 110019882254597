import React, { useState } from 'react';
import styles from './ImageBlock.module.css';

function ImageBlock({ image, imageHandler }) {
  const [imageStatus, setImageStatus] = useState(false);

  function handlerImage(boolen) {
    if (imageStatus !== boolen) {
      setImageStatus(boolen);
    }
  }

  return (
    <div
      className={styles.box}
      onMouseEnter={() => handlerImage(true)}
      onMouseLeave={() => handlerImage(false)}
    >
      <img className={styles.photo} src={`${image}`} />

      <div
        className={`${styles.staticPhoto} ${
          imageStatus ? styles.hoverPhoto : ''
        }`}
      >
        {imageStatus && (
          <span
            className={styles.close}
            onClick={() =>
              imageHandler({
                imageValue: '',
                imageName: '',
                imageSize: '',
                imageType: '',
              })
            }
          >
            ×
          </span>
        )}
      </div>
    </div>
  );
}

export default ImageBlock;
