import React from "react";
import FieldAnswer from "../FieldAnswer";
import styles from "./SaveValuesTab.module.css";
import ButtonActionUI from "../inputUI/ButtonsUI/ButtonActionUI";
import { useStore } from "reactflow";
import ButtonDashedUI from "../inputUI/ButtonsUI/ButtonDashedUI";

function SaveValuesTab({ data, change, deleteValue, add, json }) {
  const { openModal } = useStore();

  return (
    <>
      <div className={styles.titleBox}>
        <p className={styles.graphTitle}>настройка ответа</p>{" "}
        <ButtonDashedUI
          text="Создать переменную"
          clickHandler={() => openModal()}
        />
      </div>
      <div>
        {data[0] &&
          data.map((value) => {
            return (
              <FieldAnswer
                data={value}
                change={change}
                deleteValue={deleteValue}
                key={value.id}
                json={json}
              />
            );
          })}
      </div>
      <ButtonActionUI handler={add} text="+Добавить" />

      <p className={styles.helpBlock}>
        {
          "Используйте данные запроса в следующем текстовом блоке в виде JSONPath {{$['somekey']}}"
        }
      </p>
    </>
  );
}

export default SaveValuesTab;
