import React from "react";
import styles from "./HeadersJsonUI.module.css";
function HeadersJsonUI({ data }) {
  return (
    <div className={styles.headBox}>
      <table className={styles.table}>
        <tbody>
          {data.map((arrHeader) => (
            <tr key={arrHeader[1]} className={styles.headerGroup}>
              <td className={`${styles.headEntity} ${styles.headerKey}`}>
                {arrHeader[0]}:
              </td>
              <td className={styles.headEntity}>{arrHeader[1]}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default HeadersJsonUI;
